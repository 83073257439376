import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { UserModel } from 'src/app/polls/models/user.model';
import { SwUpdate } from '@angular/service-worker';
// Dexie DB
import { db } from 'src/app/db/db';
import { log } from 'console';

@Injectable({
  providedIn: 'root',
})
export class BackgroundSyncApiService {
  user: UserModel;
  constructor(
    private http: HttpClient,
    private swUpdate: SwUpdate
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
  }

  async testBackgroundSyncOLD(data: any) {
    // Registra una sincronización en segundo plano
    const registration = await navigator.serviceWorker.ready;
    try {
      await registration.sync.register('testSyncTag');
    } catch (error) {
      console.log('ERROR register testSyncTag v1.9', error);
    }
  }

  async testBackgroundSync(data: any) {
    // Registra una sincronización en segundo plano
    navigator.serviceWorker.ready.then((registration) => {
      registration.sync.getTags().then(async (tags) => {
        if (tags.includes('testSyncTag')) {
        }
        try {
          await registration.sync.register('testSyncTag');
        } catch (error) {
          console.log('ERROR register testSyncTag ', error);
        }
      });
    });
  }
}
