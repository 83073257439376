<div fxLayoutAlign="center center" fxLayout="column">
  <!-- https://codepen.io/aaronvanston/pen/yNYOXR -->
  <div class="file-upload" style="width: 100%; padding: 0">
    <button class="file-upload-btn" *ngIf="!isFileUpload" type="button">
      {{ fileOptions.title }}
    </button>

    <!-- <div
      class="image-upload-wrap"
      *ngIf="!isFileUpload"
      (click)="callSelectFile()"
    >
      <input
        class="file-upload-input"
        type="file"
        (change)="selectFile($event)"
        accept="image/*"
        [id]="getId()"
        #FileInputQuestion
        *ngIf="onlyPhone === false"
      />
      <input
        class="file-upload-input"
        type="file"
        (change)="selectFile($event)"
        accept="image/*"
        [id]="getId()"
        #FileInputQuestion
        capture="'camera'"
        *ngIf="onlyPhone === true"
      />

      <div class="drag-text">
        <div class="load-file-button-container">
          <button mat-fab color="primary">
            <mat-icon>camera_alt</mat-icon>
          </button>
        </div>
      </div>
    </div> -->
    <div
      class="image-upload-wrap"
      fxLayout="row"
      fxLayoutGap="20px"
      fxLayoutAlign="center center"
      *ngIf="!isFileUpload"
    >
      <div fxLayoutAlign="center center">
        <ng-container *ngIf="onlyPhone === false">
          <label for="upload" class="no-tag">
            <mat-icon class="mat-icon-custom-cam" (click)="callSelectFile()"
              >folder_open</mat-icon
            >
            <input
              class="file-upload-input"
              type="file"
              (change)="selectFile($event)"
              accept="image/*"
              [id]="getId()"
              #FileInputQuestion
              *ngIf="onlyPhone === false"
            />
          </label>
        </ng-container>
      </div>
      <div fxLayoutAlign="center center" fxLayout="column">
        <button mat-icon-button color="primary" (click)="takeWebCam(-1)">
          <mat-icon
            class="mat-icon-custom-cam"
            id="takeCam"
            style="cursor: pointer"
            >camera_enhance</mat-icon
          >
        </button>
      </div>
    </div>
    <div *ngIf="isFileUpload" class="file-upload-content">
      <mat-expansion-panel hideToggle [expanded]="true" class="panel-of-img">
        <img
          class="file-upload-image"
          [src]="getImage()"
          [attr.lazyLoad]="itemImageUrlDefault"
          onerror="this.src='assets/images/1.png'"
        />

        <div class="warnig-exits-file">
          <i class="fa fa-info-circle"></i>
          <strong> Advertencia: </strong> Al seleccionar una nueva foto; la foto
          actual será reemplazada al momento de avanzar a la siguiente pregunta.
        </div>
        <div class="image-title-wrap">
          <button type="button" (click)="replaceImage()" class="remove-image">
            Reemplazar <span class="image-title">Imagen</span>
          </button>
          <br />
          <button
            type="button"
            (click)="removeUpload()"
            class="replace-image"
            *ngIf="required === false"
          >
            Remover <span class="image-title">Imagen</span>
          </button>
        </div>

        <ngx-spinner
          [id]="getLoaderId()"
          [name]="getLoaderId()"
          bdColor="rgba(0, 0, 0, 0.8)"
          size="medium"
          color="#fff"
          type="square-loader"
          [fullScreen]="false"
        >
          <p style="color: white">Cargando imagen...</p>
        </ngx-spinner></mat-expansion-panel
      >
    </div>
  </div>
</div>
