import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { TaskByClient } from '../../interfaces';
import { ETaskStatus } from '../../entities/task.entity';
import { TasksService } from '../../services/tasks.service';

@Component({
  selector: 'app-tasks-created',
  templateUrl: './tasks-created.component.html',
  styleUrls: ['./tasks-created.component.css'],
})
export class TasksCreatedComponent implements OnInit {
  public createdTaskList: TaskByClient[];
  public pending: number = 0;
  public completed: number = 0;

  constructor(private router: Router, private tasksService: TasksService) {}

  ngOnInit(): void {
    const data = localStorage.getItem('created_task');

    if (!data) {
      this.router.navigate(['./tasks']);

      return;
    }

    this.createdTaskList = JSON.parse(data);

    this.calculateCounts();
  }

  calculateCounts() {
    this.createdTaskList.forEach((t) => {
      t.tasks.forEach((task) => {
        if (task.template_status === ETaskStatus.CREATED) this.pending += 1;
        else if (
          task.template_status === ETaskStatus.COMPLETED ||
          task.template_status === ETaskStatus.CONFIRMED ||
          task.template_status === ETaskStatus.REJECTAD
        )
          this.completed += 1;
      });
    });
  }

  onTabChanged(event: any) {
    this.tasksService.setTabIndex(event.index);
  }
}
