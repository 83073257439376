export enum ETaskStatus {
  START = 'Iniciada',
  PUBLISHED = 'Publicada',
  DRAW = 'Borrador',
  CREATED = 'Creada',
  COMPLETED = 'Completada',
  CONFIRMED = 'Confirmada',
  REJECTAD = 'Rechazada',
}

export enum ETaskPeriod {
  'Día(s)' = 'd',
  'Semana(s)' = 'w',
  'Mes(es)' = 'M',
}

export interface Task {
  id: number;
  title: string;
  enterprise_id: number;
  status: ETaskStatus;
  startDate: Date;
  endDate: Date;
  isActive: boolean;
  isObservationQuestion: boolean;
  isBackupPhoto: boolean;
  isDeadlineQuestion: boolean;
  isModifyQuestions: boolean;
  amount: number;
  period: ETaskPeriod;
  questions: Question[];
}

export interface Question {
  id: number;
  task_id: number;
  type_id: number;
  title: string;
  subtitle: string;
  alert: null;
  help: string;
  helpfile: null;
  required: boolean;
  order: number;
  type_desc: string;
  format: string;
  is_visible: boolean;
  is_default: boolean;
  images_allowed: number;
  amount: number;
  period: null | string;
  type_task: null | string;
  options: Option[];
  createdAt: Date;
  updatedAt: Date;
}

export interface Option {
  id: number;
  question_task_id: number;
  title: string;
  value: string;
  type_option: string;
  type_desc: string;
  minScaleLabel: null;
  minScaleValue: null;
  maxScaleLabel: null;
  maxScaleValue: null;
  allowableFile: null;
  order: number;
}

export interface ITaskCreated {
  status: ETaskStatus;
  version: number;
  status_string: ETaskStatus;
  id: number;
  customer_id: number;
  user_id: number;
  task_id: number;
  updatedAt: string;
  createdAt: string;
  date_init: string | null;
  date_finish: string | null;
  position_init: string | null;
  position_finish: string | null;
}

export interface ITaskCompleted {
  status: ETaskStatus;
  version: number;
  status_string: ETaskStatus;
  id: number;
  customer_id: number;
  user_id: number;
  task_id: number;
  updatedAt: string;
  createdAt: string;
  date_init: string | null;
  date_finish: string | null;
  position_init: string | null;
  position_finish: string | null;
  date_created: string;
  date_completed?: string;
  date_confirm?: string;
  date_rejected?: string;
  position_created: string;
  position_completed?: string;
  position_confirm?: string;
  position_rejected?: string;
  user_response_id?: string;
  template_status: string;
  limit_date?: Date;
  original_task?: number;
  reason_rejection?: string;
}
