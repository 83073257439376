<app-shared-header
  [showBackButton]="true"
  [backRoute]="'/tasks/assigned'"
></app-shared-header>

<div class="questions-container">
  <div class="client-box">
    <div class="client-name-box">
      <p class="name-title">Tarea</p>
    </div>

    <div class="client-info-box">
      <div class="client-icon">
        <mat-icon>task_alt</mat-icon>
      </div>

      <div class="client-info">
        <p class="client-txt">Tarea: {{ task.task.title }}</p>
        <p class="client-txt">
          Vencimiento: {{ task.limit_date | date : 'dd MMM yyyy' }}
        </p>

        <div class="limit-date-badge">
          <div
            class="badge"
            [ngClass]="{
              'badge-late': task?.expired,
              'badge-to-expire': task?.toExpired,
              'badge-in-time': task?.inTime
            }"
          >
            {{
              task?.expired
                ? 'Atrasado'
                : task?.toExpired
                ? 'Por vencer'
                : task?.inTime
                ? 'En fecha'
                : ''
            }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="template-question-box">
    <div [ngSwitch]="currentQuestion?.type_id">
      <div class="template-question" *ngSwitchCase="'3'">
        <app-task-response-text
          (handleResponse)="handleTextAnswer($event)"
          [question]="currentQuestion"
        ></app-task-response-text>
      </div>

      <div class="template-question" *ngSwitchCase="'4'">
        <app-task-response-photo
          (answer)="handlePhotoAnswer($event)"
          [question]="currentQuestion"
          [client]="task.customer"
          [user]="user"
        ></app-task-response-photo>
      </div>

      <div class="template-question" *ngSwitchCase="'1'">
        <app-question-single-select-view
          [question]="currentQuestion"
          [selected]="currentQuestion"
          (handleResponse)="handleSingleSelectAnswer($event)"
        ></app-question-single-select-view>
      </div>
    </div>
  </div>

  <div class="movement-btns-box">
    <div class="btn-box back-btn" (click)="handleBack()">
      <p class="btn-title">Volver</p>
    </div>

    <button
      class="btn-box next-btn"
      *ngIf="task.task.questions.length"
      [ngClass]="{ 'btn-disabled': !allowMoveQuestion }"
      [disabled]="!allowMoveQuestion"
      (click)="handleNext()"
    >
      <p
        class="btn-title"
        *ngIf="questionPosition < questions.length - 1; else finish"
      >
        Siguiente
      </p>

      <ng-template #finish>
        <p class="btn-title">Finalizar</p>
      </ng-template>
    </button>
  </div>
</div>

<ngx-spinner
  name="upload-response-spinner"
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="rgb(92, 184, 92)"
  type="ball-spin-fade"
>
  <p style="font-size: 14px; color: rgb(255, 255, 255)">
    Guardando respuestas...
  </p>
</ngx-spinner>
