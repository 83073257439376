<div fxLayoutAlign="center center" fxLayout="row">
  <div fxFlex="15"></div>
  <div fxFlex="20" fxLayoutAlign="center center">
    <button mat-mini-fab class="plus-minus-btn" (click)="updateValue(false)">
      <mat-icon>remove</mat-icon>
    </button>
  </div>
  <div fxFlex="20" fxLayoutAlign="center center">
    <mat-form-field class="input-width">
      <input
        matInput
        type="text"
        [ngModel]="this.selected"
        (input)="onInput($event)"
        (keyup.enter)="onEnterHideKeyboard()"
        #numberInput
        [min]="0"
        ng-init=""
        autocomplete="off"
        inputmode="decimal"
      />
      {{ !keyboardOpened ? numberInput.focus() : null }}
    </mat-form-field>
  </div>

  <div *ngIf="question.percentaje"><strong> % </strong></div>
  <div fxFlex="20" fxLayoutAlign="center center">
    <button mat-mini-fab class="plus-minus-btn" (click)="updateValue(true)">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <div fxFlex="15"></div>
</div>
