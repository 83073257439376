<div
  class="task-response-container"
  *ngIf="!selectedQuestion_1 && !selectedResponse_1; else viewQuestion"
>
  <app-shared-header
    [showBackButton]="true"
    [backRoute]="isCreatedTask ? '/tasks/created' : '/tasks/assigned'"
  ></app-shared-header>

  <div class="client-box">
    <div class="client-name-box">
      <p class="name-title">Cliente</p>
    </div>

    <div class="client-info-box">
      <div class="client-icon">
        <mat-icon>perm_contact_calendar</mat-icon>
      </div>

      <div class="client-info">
        <p class="client-txt">{{ responseTask_1.customer.descr_cliente }}</p>
        <p class="client-txt">{{ responseTask_1.customer.direccion }}</p>
        <p class="client-txt">
          Código: {{ responseTask_1.customer.cliente_id }}
        </p>
      </div>
    </div>
  </div>

  <div class="client-box">
    <div class="client-name-box">
      <p class="name-title">Tarea</p>
    </div>

    <div class="client-info-box">
      <div class="client-icon">
        <mat-icon>task_alt</mat-icon>
      </div>

      <div class="client-info">
        <span class="client-txt">Tarea: {{ responseTask_1.task.title }}</span>
        <p class="client-txt">
          Vencimiento: {{ responseTask_1.limit_date | date : 'dd MMM yyyy' }}
        </p>

        <div class="limit-date-badge" style="margin-top: 5px">
          <div
            class="badge"
            [ngClass]="{
              'badge-late': responseTask_1?.expired,
              'badge-to-expire': responseTask_1?.toExpired,
              'badge-in-time': responseTask_1?.inTime
            }"
          >
            {{
              responseTask_1?.expired
                ? 'Atrasado'
                : responseTask_1?.toExpired
                ? 'Por vencer'
                : responseTask_1?.inTime
                ? 'En fecha'
                : ''
            }}
          </div>
          <div
            class="badge"
            [ngClass]="{
              'badge-late-completed':
                responseTask_1?.template_status === 'Rechazada'
            }"
          >
            {{
              responseTask_1?.template_status === 'Rechazada' ? 'Rechazada' : ''
            }}
          </div>
          <div
            *ngIf="isTabIndex_0"
            class="badge"
            [ngClass]="{
              'badge-in-time': responseTask_1?.original_task
            }"
          >
            {{ responseTask_1?.original_task ? 'Reactivada' : '' }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="response-task-box">
    <div class="task-info">
      <p class="task-info-txt">Información de respaldo</p>
    </div>

    <div class="task-questions">
      <div
        *ngIf="responseTask_1.date_rejected"
        class="task-question-box"
        [ngClass]="{
          'selected-color': selectConfirm === '1',
          'red-color': selectConfirm !== '1'
        }"
      >
        <div class="question">
          <strong>1</strong>. Tarea Realizada:
          {{ selectConfirm === '1' ? 'Sí' : 'No' }}
        </div>
      </div>

      <div class="task-question-box">
        <div class="question">
          <strong>{{ !responseTask_1.date_rejected ? 1 : 2 }}</strong
          >. Observación
        </div>
        <div
          class="view-options"
          (click)="handleViewInfo(obsConfirm, obsCreate)"
        >
          <mat-icon>navigate_next</mat-icon>
        </div>
      </div>
      <div class="task-question-box">
        <div class="question">
          <strong>{{ !responseTask_1.date_rejected ? 2 : 3 }}</strong
          >. Fotos
        </div>
        <div
          class="view-options"
          (click)="handleViewResponse(photosRef, photosResp)"
        >
          <mat-icon>navigate_next</mat-icon>
        </div>
      </div>
      <div
        class="task-question-box"
        *ngIf="
          responseTask_1.template_status === 'Rechazada' ||
          (responseTask_1.template_status !== 'Rechazada' &&
            responseTask_1.original_task)
        "
      >
        <div class="question"><strong>4</strong>. Motivo de Rechazo</div>
        <div
          class="view-options"
          (click)="handleRejectedMessageResponse(responseTask_1)"
        >
          <mat-icon>navigate_next</mat-icon>
        </div>
      </div>
    </div>

    <div class="action-btns-box">
      <div
        [ngClass]="{
          'btn-confirm-disabled':
            (isCreatedTask && isTabIndex_0) ||
            (!isCreatedTask && !isTabIndex_0),
          'btn-confirm': !isCreatedTask && isTabIndex_0,
          disabled:
            responseTask_1.template_status !== 'Creada' &&
            responseTask_1.template_status !== 'Rechazada'
        }"
        (click)="handleResponse(responseTask_1)"
      >
        <div>
          <p class="confirm">Responder</p>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #viewQuestion>
  <div *ngIf="selectedQuestion_1; else viewResponse">
    <app-shared-header
      [showBackButton]="true"
      backRoute="/tasks/{{ selectedQuestion_1.created_task_id }}/response"
      (click)="cleanSelectedQuestion()"
    ></app-shared-header>

    <div class="task-response-container">
      <div [ngSwitch]="selectedQuestion_1?.question_task?.type_id">
        <div class="template-question" *ngSwitchCase="'3'">
          <app-question-text-view
            [question_1]="selectedQuestion_1"
            [responseTask_1]="responseTask_1"
            [question_2]="selectedQuestion_2"
            [allowEdit]="false"
          ></app-question-text-view>
        </div>

        <div class="template-question" *ngSwitchCase="'4'">
          <app-question-photo-view
            [questions]="selectedQuestion_1"
            [responseTask]="responseTask_1"
            [photos_1]="photosRef"
            [photos_2]="photosResp"
          ></app-question-photo-view>
        </div>
        <div class="template-question" *ngSwitchDefault>
          <app-question-text-view
            [question_1]="{ value: reasonRejection }"
            [responseTask_1]="responseTask_1"
            [question_2]="selectedQuestion_2"
            [subTitle]="'MOTIVO DE RECHAZO'"
            [allowEdit]="false"
          ></app-question-text-view>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #viewResponse>
  <app-shared-header
    [showBackButton]="true"
    backRoute="/tasks/{{ selectedResponse_1.created_task_id }}/response"
    (click)="cleanSelectedQuestion()"
  ></app-shared-header>

  <div class="task-response-container">
    <div [ngSwitch]="selectedResponse_1?.question_task?.type_id">
      <div class="template-question" *ngSwitchCase="'3'">
        <app-question-text-view
          [question_1]="selectedResponse_1"
          [responseTask_1]="responseTask_1"
          [question_2]="selectedResponse_2"
        ></app-question-text-view>
      </div>

      <div class="template-question" *ngSwitchCase="'4'">
        <app-question-photo-view
          [questions]="selectedResponse_1"
          [responseTask]="responseTask_1"
          [photos_1]="photosRef"
          [photos_2]="photosResp"
        ></app-question-photo-view>
      </div>
      <div class="template-question" *ngSwitchDefault>
        <app-question-text-view
          [question_1]="selectedResponse_1"
          [responseTask_1]="responseTask_1"
          [question_2]="selectedResponse_2"
          [subTitle]="'MOTIVO DE RECHAZO'"
          [allowEdit]="false"
        ></app-question-text-view>
      </div>
    </div>
  </div>
</ng-template>

<ngx-spinner
  name="confirm-spinner"
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="rgb(92, 184, 92)"
  type="ball-spin-fade"
>
  <p
    style="
      font-size: 16px;
      color: rgb(255, 255, 255);
      padding-top: 30px;
      text-align: center;
    "
  >
    Rechazando tarea...
  </p>
</ngx-spinner>
