import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shared-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderV2Component {
  @Input() title: string;
  @Input() showTitle: boolean = false;
  @Input() backRoute: string = '/';
  @Input() showBackButton: boolean = false;
  @Input() showHeader: boolean = true;
  @Input() badgeNumber: number = 0;
  @Input() showBadge: boolean = true;

  constructor(private readonly router: Router) {}

  onGoBack() {
    this.router.navigate([this.backRoute]);
  }
}
