import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    try {
      var isAuth = this.authService.getIsAuth();
      if (isAuth == undefined || isAuth == false) {
        this.router.navigate(['/login'], { queryParams: route.queryParams });
      }
      return isAuth;
    } catch (error) {
      this.router.navigate(['/login']);
    }
  }
}
