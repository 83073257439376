import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent } from 'ngx-bootstrap/carousel';
import { UploadService } from 'src/app/polls/services/upload.service';

@Component({
  selector: 'app-question-photo-view',
  templateUrl: './question-photo-view.html',
  styleUrls: ['./question-photo-view.scss'],
})
export class QuestionPhotoViewComponent implements OnInit {
  @ViewChild('carousel_1', { static: false }) carousel_1: CarouselComponent;
  @ViewChild('carousel_2', { static: false }) carousel_2: CarouselComponent;
  @Input() questions: any[];
  @Input() responseTask: any;
  @Input() photos_1: any[];
  @Input() photos_2: any[];
  public images_1: string[] = [];
  public images_2: string[] = [];

  constructor(private readonly uploadService: UploadService) {}

  ngOnInit(): void {
    this.photos_1 = Array.isArray(this.photos_1)
      ? this.photos_1
      : [this.photos_1];
    this.photos_1.forEach((question) => {
      this.getSignedImages(question?.value);
    });
    if (this.photos_2) {
      this.photos_2 = Array.isArray(this.photos_2)
        ? this.photos_2
        : [this.photos_2];
      this.photos_2.forEach((question) => {
        this.getSignedImages(question?.value, false);
      });
    }
  }

  getSignedImages(img, isCarousel_1: boolean = true) {
    if (isCarousel_1)
      this.uploadService.getFiles(img).subscribe((url) => {
        this.images_1.push(url.url);
      });
    else {
      this.uploadService.getFiles(img).subscribe((url) => {
        this.images_2.push(url.url);
      });
    }
  }

  fullScreenImage: string | null = null;

  toggleFullScreen(imageUrl?: string) {
    this.fullScreenImage = imageUrl || null;
  }
}
