<div fxLayoutAlign="center center" fxLayout="column">
  <mat-form-field class="input-full-width mb-3" appearance="outline">
    <mat-label>{{ title }}</mat-label>
    <input
      matInput
      [ngxMatDatetimePicker]="picker"
      [min]="minDateExpire"
      [max]="maxDatePublish"
      [(ngModel)]="this.selected"
      (dateInput)="addDateTimeResponse('input', $event)"
      (dateChange)="addDateTimeResponse('change', $event)"
      autocomplete="off"
    />

    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker
      #picker
      [showSpinners]="showSpinners"
      [showSeconds]="showSeconds"
      [stepHour]="stepHour"
      [stepMinute]="stepMinute"
      [stepSecond]="stepSecond"
      [touchUi]="touchUi"
      [color]="color"
      [enableMeridian]="enableMeridian"
    >
    </ngx-mat-datetime-picker>
  </mat-form-field>
</div>
