import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-response-photo-modal',
  templateUrl: './response-photo-modal.component.html',
  styleUrls: ['./response-photo-modal.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ResponsePhotoModalComponent implements OnInit {
  facingMode = 'environment';
  public errors: WebcamInitError[] = [];
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();

  @Output() onAdd = new EventEmitter<any>(true);
  width: number;
  height: number;

  public mediaConstraints = {
    video: {
      optional: [
        { minWidth: 720 },
        { minWidth: 1024 },
        { minWidth: 1280 },
        { minWidth: 1920 },
        { minWidth: 2560 },
        { minHeight: 720 },
        { minHeight: 1024 },
        { minHeight: 1280 },
        { minHeight: 1920 },
        { minHeight: 2560 },
      ],
    },
  };

  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public captureImageData: boolean = true;
  public mirrorImage: string = 'always';
  private urls: number;
  private images_allowed: number;
  public matValue: number;

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    const win = !!event ? (event.target as Window) : window;
    this.width = win.innerWidth - 45;
    this.height = win.innerHeight;
  }
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.onResize();
  }

  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {
      width: { min: 640, ideal: 1920 },
      height: { min: 480, ideal: 1080 },
    };

    if (this.facingMode && this.facingMode !== '') {
      result.facingMode = { ideal: this.facingMode };
    }

    return result;
  }

  public ngOnInit(): void {
    this.urls = this.data.urls;
    this.images_allowed = this.data.images_allowed;

    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      }
    );
    if (
      this.multipleWebcamsAvailable &&
      localStorage.getItem('deviceId')?.length > 0
    ) {
      this.nextWebcam.next(localStorage.getItem('deviceId'));
    }
  }

  public triggerSnapshot(): void {
    if (
      !this.multipleWebcamsAvailable &&
      localStorage.getItem('deviceId')?.length == 0
    ) {
      Swal.fire({
        icon: 'info',
        title: `Desea mantener la camara actual como camara por defecto `,
        showCancelButton: true,
        confirmButtonText: 'Si, mantener cámara actual',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.setItem('deviceId', this.deviceId);
        }
      });
    }
    this.trigger.next();

    this.dialogRef.close();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.urls++;

    this.onAdd.emit(this.webcamImage.imageAsDataUrl);
  }

  public cameraWasSwitched(deviceId: string): void {
    if (localStorage.getItem('deviceId')?.length > 0) {
      this.deviceId = deviceId;
    } else {
      this.deviceId = deviceId;
    }
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  disabledTake() {
    if (this.urls > this.images_allowed) {
      return true;
    } else {
      return false;
    }
  }
  closeModal(): void {
    this.dialogRef.close();
  }
  private switchTorch: Subject<void> = new Subject<void>();
  public torchAvailable: boolean = false;

  public switchingTorch(): void {
    this.switchTorch.next();
  }

  public get switchTorchObservable(): Observable<void> {
    return this.switchTorch.asObservable();
  }

  public torchIsAvailable($event: boolean) {
    this.torchAvailable = $event;
  }
}
