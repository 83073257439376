<ngx-spinner
  name="loading-polls"
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="rgb(92, 184, 92)"
  type="ball-clip-rotate-pulse"
  class="content-spinner"
>
  <p style="color: green">Cargando información ...</p>
</ngx-spinner>
