import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

import { PollModel } from '../../models/poll.model';
import { PollsService } from '../../services/polls.service';
import { MatSidenav } from '@angular/material/sidenav';
import * as Sentry from '@sentry/angular-ivy';

// Dexie DB
import { db } from 'src/app/db/db';

import * as jwt_decode from 'jwt-decode';

import {
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexMarkers,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  ApexStroke,
} from 'ng-apexcharts';
import { StatsService } from '../../services/stats.service';
import { filter } from 'rxjs/operators';
import { OfflineService } from '../../services/offline.service';
import { UserModel } from '../../models/user.model';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: any; //ApexChart;
  legend: any; //ApexChart;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  grid: any; //ApexGrid;
  colors: any;
  toolbar: any;
};

@Component({
  selector: 'app-dashboard-period-poll',
  templateUrl: './dashboard-period-poll.component.html',
  styleUrls: ['./dashboard-period-poll.component.css'],
})
export class DashboardPeriodPollComponent implements OnInit {
  // Chart
  public chartPollPeriod: Partial<ChartOptions>;
  public commonOptions: Partial<ChartOptions> = {};

  single = [];
  poll: any;
  initDate: string = '';
  endDate: string = '';
  daysElapsed: string = '';
  daysremaining: string = '';
  pollByDay: any = {
    counts: [],
    series: [],
  };
  totalFinish: any = 0;
  average: any = 0;
  projection: any = 0;
  date = new Date();
  today =
    this.date.getFullYear() +
    '-' +
    (this.date.getMonth() + 1) +
    '-' +
    this.date.getDate();
  isOfflineMode: boolean = false;
  isHandleGoal: boolean = false;
  goalValue: number;
  months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  monthLabel: string = '';
  yearLabel: any = new Date().getFullYear();
  user: UserModel;
  offlineMode: any = {
    status: false,
    from: 'nonde',
  };
  @ViewChild(MatSidenav) sidenav!: MatSidenav;

  constructor(
    private router: Router,
    private pollsService: PollsService,
    private spinner: NgxSpinnerService,
    private statsService: StatsService,
    private offlineService: OfflineService
  ) {
    this.poll = JSON.parse(localStorage.getItem('poll'));
    this.user = jwt_decode(localStorage.getItem('token')).user;
    this.monthLabel = this.months[this.date.getMonth()];

    Sentry.configureScope((scope) => {
      scope.setUser({
        email: this.user.email,
      });
      scope.setTags({
        email: this.user.email,
      });

      scope.setTags({
        poll: this.poll.name + '(ID:' + this.poll.id + ')',
      });
      Sentry.setContext('Encuesta', {
        name: this.poll.name,
        id: this.poll.id,
      });
    });
  }

  ngOnInit(): void {
    this.isOfflineMode = false;
    this.spinner.show();
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        if (offlineHeader) {
          this.offlineMode = offlineHeader;
        }
        this.fetchStats();
      })
      .finally(() => {
        this.spinner.hide();
      });
  }
  public initCharts(): void {
    this.chartPollPeriod = {
      series: [
        {
          name: 'chart2',
          data: this.pollByDay.series,
        },
      ],
      // xaxis: {
      //   labels: {
      //     rotate: -45
      //   },
      //   categories: this.pollByDay.categories,
      //   tickPlacement: "on"
      // },
      xaxis: {
        type: 'datetime',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      markers: {
        size: 6,
        hover: {
          size: 10,
        },
      },
      tooltip: {
        followCursor: false,
        theme: 'dark',
        x: {
          show: false,
        },
        marker: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return '';
            },
          },
        },
      },
      grid: {
        clipMarkers: false,
      },
      legend: {
        show: false,
      },
      chart: {
        id: 'tw',
        group: 'social',
        type: 'line',
        width: '100%',
        height: 160,
        toolbar: {
          show: false,
        },
        offsetX: 0,
      },
      colors: ['#205c40'],
      yaxis: {
        tickAmount: 2,
        labels: {
          minWidth: 40,
        },
      },
    };
  }
  async pollByDayItems(data, filters) {
    return new Promise<any>(async (resolve, reject) => {
      var itemsDay = data;

      resolve(null);
    });
  }
  async dayTodaySync(id, startDate, endDate, filters) {
    return new Promise<any>(async (resolve, reject) => {
      let itemsDay = [];
      let day;
      let dayItem;
      let fromDate = moment(startDate);
      let toDate = moment(endDate);
      let diffDay = toDate.diff(fromDate, 'days') + 1;
      let countSync = 0;
      let filter = filters?.length > 0 ? filters[0] : [];
      for (let i = 0; i <= diffDay; i++) {
        countSync = 0;
        day = moment(startDate).add(i, 'day').format('DD-MM-YYYY');
        dayItem = moment(startDate).add(i, 'day').format('YYYY-MM-DD');
        var data = await db.clientsCompleted
          .where('[poll_id+date_finish]')
          .equals([id, day])
          .count();
        if (filters?.length > 0) {
          const dataFilter = await db.clientsCompleted
            .where('[poll_id+date_finish]')
            .equals([id, day])
            .toArray();
          data = dataFilter.filter((element) => {
            return element.descr_vendedor === filter.name;
          }).length;
        }

        const dataSync = await db.syncpolls
          .where('[poll_id+date_finish]')
          .equals([id, day])
          .toArray();

        if (dataSync.length > 0) {
          const countSyncData = dataSync.filter((item) => {
            return (
              item.status === 'closed' ||
              item.status === 'completada' ||
              item.status === 'sync-completed'
            );
          });
          if (filters.length > 0) {
            countSync = countSyncData.filter((element) => {
              return element.descr_vendedor === filter.name;
            }).length;
          } else {
            countSync = countSyncData.length;
          }
        }

        itemsDay.push({
          day: dayItem,
          count: data + countSync,
        });
        if (i == diffDay) {
          resolve(itemsDay);
        }
      }
    });
  }

  async fetchStats() {
    const itemFilterDb = JSON.parse(localStorage.getItem('filterPollData'));
    // Init Offline Mode
    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      // var stats = await db.stats.get(this.poll.id).then((stats) => {
      //   return stats;
      // });
      // var initDatePoll;
      // var finishDatePoll;
      // var dataTMP;
      // var daysElapsed;
      // var daysremaining;

      // const dataStat = {};
      // console.info(this.poll);
      // initDatePoll = moment().startOf('month').format('YYYY-MM-DD hh:mm:ss');
      // finishDatePoll = moment().endOf('month').format('YYYY-MM-DD hh:mm:ss');

      // daysElapsed = 1;
      // daysremaining = 1;
      // dataTMP = await this.dayTodaySync(
      //   this.poll.id,
      //   initDatePoll,
      //   finishDatePoll,
      //   itemFilterDb
      // );

      // dataStat['pollByDay'] = dataTMP.filter((p) => {
      //   return p.count !== 0;
      // });
      // dataStat['initDate'] = initDatePoll;
      // dataStat['endDate'] = finishDatePoll;
      // dataStat['daysElapsed'] = daysElapsed;

      // dataStat['daysremaining'] = daysremaining;
      // // console.log('stats', stats.data);
      // this.loadData(dataStat);
      // db.stats.get(this.poll.id).then((stats) => {
      //   // this.loadData(stats.data);
      // });
      const idStats = `${this.poll.id}-${this.user.id}`;
      db.statsPolls.get(idStats).then(async (statsData) => {
        this.loadData(statsData);
      });
      return;
    }
    // End Offline Mode

    this.statsService.getStatsByPollPeriod(this.poll.id).subscribe((data) => {
      this.pollByDay = {
        counts: [],
        series: [],
      };

      this.loadData(data);
      this.closeNav();
    });
  }

  async setEndDate(period_id: number) {}

  loadData(data) {
    if (data.hasOwnProperty('dataPollGoals')) {
      if (data.dataPollGoals.hasOwnProperty('meta')) {
        this.isHandleGoal = true;
        this.goalValue = data.dataPollGoals.meta;
      } else {
        this.isHandleGoal = false;
      }
    } else {
      this.isHandleGoal = false;
    }
    this.pollByDay.counts = [];
    this.pollByDay.series = [];
    // Data Chart
    // const sortedPollByDay  = data.pollByDay.sort( (a,b) => { return +new Date(moment(a.day).format('YYYY-MM-DD')) - +new Date(moment(b.day).format('YYYY-MM-DD')) } )
    const dataChart = data.pollByDay.map((p) => parseInt(p.count));
    const categories = data.pollByDay.map((p) =>
      moment(p.day).format('DD-MMM')
    );
    const initDateNumber: number = parseInt(moment(data.initDate).format('DD'));
    const endDateNumber: number = parseInt(moment(data.endDate).format('DD'));
    this.initDate = moment(data.initDate).format('DD/MM');
    this.endDate = moment(data.endDate).format('DD/MM');

    const month = moment(data.endDate).format('MMM YYYY');
    const year = moment(data.endDate).format('YYYY');

    var fecha1 = moment('2022-03-22');
    var fecha2 = moment('2022-03-25');
    var diferencia = fecha2.diff(fecha1, 'days');

    this.daysElapsed = data.daysElapsed;
    this.daysremaining = data.daysremaining;
    // const totalDays = parseInt(moment(data.endDate).format('DD'));
    const totalDays = moment(data.endDate).diff(moment(data.initDate), 'days');

    let index = -1;
    let monthP: any = '';
    let countP: any = '';
    for (let i = initDateNumber; i <= endDateNumber; i++) {
      index = categories.findIndex((d) => {
        return parseInt(moment(new Date(d)).format('DD')) === i;
      });
      monthP =
        index === -1
          ? i < 10
            ? `0${i}-${month}`
            : `${i}-${month}`
          : categories[index] + ' ' + year;
      monthP = new Date(monthP);
      countP = index === -1 ? 0 : dataChart[index];
      this.pollByDay.counts.push(countP);
      this.pollByDay.series.push([monthP, countP]);
    }
    this.initCharts();

    this.totalFinish = this.pollByDay.counts.reduce((acc, count) => {
      return acc + count;
    }, 0);
    this.average = parseInt(this.totalFinish) / parseInt(this.daysElapsed);
    this.projection =
      Math.round(this.average * data.daysremaining) + this.totalFinish;
  }

  DaysBetween(StartDate, EndDate) {
    let startDate = new Date(StartDate);
    let endDate = new Date(EndDate);

    return Math.floor(
      (Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
        Date.UTC(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        )) /
        (1000 * 60 * 60 * 24)
    );
  }

  aplyPoll() {
    this.router.navigate(['/poll/client'], {
      queryParams: { id: this.poll.id },
    });
  }
  getSelected(value: any) {
    this.closeNav();
  }
  closeNav() {
    this.sidenav.close();
  }
  returnList() {
    this.router.navigate(['/poll'], {});
  }
  public throwTestError2(): void {
    throw new Error('P-dddd  ' + new Date());
  }
}
