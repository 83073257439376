import {
  Component,
  SecurityContext,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as jwt_decode from 'jwt-decode';

import { auth } from 'src/environments/environment';
import { PollModel } from '../../models/poll.model';
import { PollMessageModel } from '../../models/pollmesagge.model';
import { UserModel } from '../../models/user.model';
import { ClientModel } from '../../models/client.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientsService } from '../../services/clients.service';
import { Observable } from 'rxjs';
import { PollsService } from '../../services/polls.service';
import { OfflineService } from '../../services/offline.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-message-finish',
  templateUrl: './message-finish.component.html',
  styleUrls: ['./message-finish.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageFinishComponent implements OnInit {
  user: UserModel;
  poll: PollModel;
  client: ClientModel;
  html: SafeHtml;
  pollMessageFinish: PollMessageModel;
  sectionImg: string = 'poll.png';
  typeaccess: string;
  typesource: string;
  polls: PollModel[];
  polls$: Observable<PollModel[]>;

  syncPollsCompleted: Array<any> = [];
  syncPollsIncomplete: Array<any> = [];
  offlinePolls: Array<any> = [];
  offlineMode: any = {
    status: false,
    from: 'nonde',
  };

  constructor(
    private clientsService: ClientsService,
    private pollsService: PollsService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private offlineService: OfflineService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
    this.poll = JSON.parse(localStorage.getItem('poll'));
    this.typesource = localStorage.getItem('source');
    this.client = JSON.parse(localStorage.getItem('client'));
    this.pollMessageFinish = this.poll.messages.find(
      (x) => x.type_message == 'finish'
    );
    const decodedToken = jwt_decode(localStorage.getItem('token'));
    this.typeaccess = decodedToken.user.typeaccess;

    if (this.pollMessageFinish === undefined) {
      this.pollMessageFinish = {
        title: 'Encuesta realizada',
        message: '',
        type_message: 'finish',
      };
    }

    if (this.pollMessageFinish !== undefined) {
      this.html = this.pollMessageFinish.message;
    } else {
      if (this.user.id === this.client.id) {
        localStorage.clear();
        localStorage.clear();
        this.dialog.open(DialogComponent, {
          data: {
            message: 'Encuesta realizada!',
            status: 2,

            onlyClose: true,
          },
        });
      } else {
        this.router.navigate([
          '/poll/client',
          { id: this.poll.id, name: this.poll.name },
        ]);
      }
    }
    0;
  }

  ngOnInit(): void {
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        this.offlineMode = offlineHeader;
        this.spinner.show('loading-polls');
        if (offlineHeader && offlineHeader.status) {
          this.client = JSON.parse(localStorage.getItem('client'));
          localStorage.removeItem('idResponse');
          localStorage.removeItem('navHistory');
          this.offlineService
            .getPollofClientsWithExclude(
              this.client,
              this.poll.id,
              this.user.id
            )
            .then((polls) => {
              this.polls = polls;
            })
            .finally(() => {
              this.spinner.hide('loading-polls');
              return;
            });
        }
        this.clientsService
          .getPollofClientsWithExclude(this.client.cliente_id, this.poll.id)
          .pipe(
            finalize(() => {
              this.spinner.hide('loading-polls');
            })
          )
          .subscribe((polls) => {
            this.polls = polls;
          });
        localStorage.removeItem('idResponse');
        localStorage.removeItem('navHistory');
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
        }, 5000);
      });
  }

  returnPoll() {
    if (this.user.id === this.client.id && !this.offlineMode.status) {
      localStorage.clear();
      localStorage.clear();
      this.dialog.open(DialogComponent, {
        data: {
          message: 'Encuesta finalizada!',
          status: 2,
          onlyClose: true,
        },
      });
    } else {
      switch (this.typesource) {
        case 'list_client':
          localStorage.removeItem('poll');
          // this.router.navigate(['/poll', { tab: 2 }]);
          this.router.navigate(['/poll']);
          break;
        case 'poll':
          // this.router.navigate([
          //   '/poll/client',
          //   { id: this.poll.id, name: this.poll.name },
          // ]);
          this.router.navigate(['/poll/client'], {
            queryParams: { id: this.poll.id, name: this.poll.name },
          });
          break;

        default:
          this.router.navigate([
            '/poll/client',
            { id: this.poll.id, name: this.poll.name },
          ]);
          break;
      }
    }
  }

  endPoll() {
    // this.dialog.open(DialogComponent, {
    //   data: {
    //     message: 'Encuesta finalizada!',
    //     status: 2,
    //     onlyClose: true
    //   }
    // });
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 3000);
    window.location.href = `${auth.urlB2B}`;
  }

  btnSyncControlClass(poll) {
    let classHTML = '';
    classHTML = this.syncPollsCompleted.includes(poll.id)
      ? 'blink btn-sync'
      : '';
    classHTML += this.syncPollsIncomplete.includes(poll.id)
      ? ' btn-warning'
      : ' ';
    return classHTML;
  }
  async checkOffline(poll: any, preview: boolean) {}
  getCantPollToSync(pollId) {}
  async pollSyncResponses(poll) {}

  sortJSON(data, key, orden) {
    return data.sort(function (a, b) {
      var x = a[key],
        y = b[key];

      if (orden === 'asc') {
        return x < y ? -1 : x > y ? 1 : 0;
      }

      if (orden === 'desc') {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  }
  async initPoll(poll: any) {
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        // localStorage.removeItem('navHistory');
        if (offlineHeader && offlineHeader.status) {
          this.client = JSON.parse(localStorage.getItem('client'));
          this.offlineService.initPoll(poll.id, this.client, this.user.id);
          return;
        }
        this.clientsService
          .getCustomerCandidateById(poll.customer_id, poll.id)
          .subscribe((data) => {
            localStorage.removeItem('poll');
            const dataPoll = {
              id: data.poll.id,
              period_id: data.poll.period_id,
              name: data.poll.name,
              description: data.poll.description,
              sections: this.sortJSON(data.poll.sections, 'weight', 'asc'),
              messages: data.poll.messages,
              expire_date: data.poll.expire_date,
              publish_date: data.poll.publish_date,
              countquestion: data.poll.countquestion,
              geolocation: data.poll.geolocation,
              minutes: data.poll.minutes,
              infoextra: data.poll.infoextra,
              infoextrapoll: data.poll.infoextrapoll,
              infoextracustomer: data.poll.infoextracustomer,
              showmessageinit: data.poll.showmessageinit,
              addgoals: data.poll.addgoals,
              measuring_period: poll.measuring_period,
            };
            localStorage.setItem('poll', JSON.stringify(dataPoll));
            delete data.poll;
            localStorage.removeItem('filterPollData');
            localStorage.removeItem('filterPollAllData');

            localStorage.setItem('client', JSON.stringify(data));

            if (
              dataPoll.infoextra == true &&
              dataPoll.infoextrapoll !== null &&
              dataPoll.infoextrapoll !== undefined &&
              dataPoll.infoextracustomer !== null &&
              dataPoll.infoextracustomer !== undefined
            ) {
              this.router.navigate(['poll/client/info']);
            } else {
              this.router.navigate(['poll/client/init']);
            }
          });
      });
  }
}
