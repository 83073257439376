import { HostListener, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, from, of, timer } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, tap } from 'rxjs/operators';
import { auth } from 'src/environments/environment';
import NoSleep from 'nosleep.js';
import { OfflineService } from './offline.service';
import { ClientsService } from './clients.service';
import { StatsService } from './stats.service';
import { formatDate } from '@angular/common';
import * as jwt_decode from 'jwt-decode';
import { UserModel } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  private nosleep = new NoSleep();
  private API_URL: string = auth.urlConfig;
  user: UserModel;
  lastVerificationDateTime: string;

  constructor(
    private http: HttpClient,
    private clientsService: ClientsService,
    private offlineService: OfflineService,
    private statsService: StatsService
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
  }

  @HostListener('window:online', ['$event'])
  handleOnlineEvent() {
    this.initVerification().subscribe();
  }

  initVerification(): Observable<boolean> {
    return timer(0, 3600000).pipe(
      map(() => {
        this.offlineService.getOfflineHeader(1, this.user.id).then((offlineHeader) => {
          this.lastVerificationDateTime = offlineHeader.lastVerificationDateTime;
        });
        return (
          !this.lastVerificationDateTime ||
          new Date(this.lastVerificationDateTime).getTime() + 3600000 < Date.now()
        );
      }),
      switchMap((hasToVerify) => {
        if (hasToVerify) {
          return this.verifyChanges().pipe(
            map((res) => {
              if (res) {
                this.syncChanges();
              }
              return res;
            })
          );
        } else {
          return new Observable<boolean>((subscriber) => {
            subscriber.next(false);
            subscriber.complete();
          });
        }
      })
    );
  }

  private verifyChanges(): Observable<boolean> {
    let params = new HttpParams();
    params = params.set('sellers', '[]');
    return this.http.get<any>(`${this.API_URL}/checks/all`, { params }).pipe(
      tap((response) => {
        const keysWithFalseValue = Object.keys(response).filter((key) => response[key] === true);
        if (keysWithFalseValue.length) {
          this.syncChanges();
        }
      }),
      filter((response) => response.changed)
    );
  }

  private async syncChanges() {
    this.nosleep.enable();
    this.clientsService.getClientsBySellers([]).subscribe(async (data) => {
      const clientsLimit = data.clients;
      const enterprises = JSON.parse(localStorage.getItem('enterprises'))?.map((e) => e.id);
      const customerSku = await this.clientsService.getAllSkus(enterprises).toPromise();
      await this.offlineService.setCustomersSku(customerSku['customersSku']);

      await this.offlineService.setClients(clientsLimit);

      let polls: any = await this.offlineService.getSectionsPoll(data.polls);

      await this.offlineService.setPolls(polls);

      await this.offlineService.downloadCategories([]);

      await this.offlineService.clearPollStats();

      const clientPolls = data.polls;

      from(clientPolls)
        .pipe(
          concatMap((poll: any) =>
            this.statsService.getStatsByPollPeriodGoals(poll.id, []).pipe(
              map((data) => ({
                ...data,
                user_id: this.user.id,
                id: `${poll.id}-${this.user.id}`,
              })),
              catchError((error) => {
                return of(null);
              })
            )
          )
        )
        .subscribe(async (data) => {
          if (data) {
            await this.offlineService.setPollStats(data);
          }
        });

      await this.offlineService.setOfflineHeader({
        id: 1,
        user_id: this.user.id,
        date: formatDate(new Date(), 'yyyy/MM/dd', 'en'),
        number_clients: data.clients.length,
        number_polls: polls.length,
        status: true,
        mode: 'syncAll',
        from: 'clients',
        sellers: [],
        dataFilters: {
          enterpriseId: null,
          filters: [],
          isFilter: false,
          keepFilter: false,
        },
        dataFiltersPoll: [],
      });

      this.nosleep.disable();
    });

    await this.offlineService.setOfflineHeader({
      id: 1,
      user_id: this.user.id,
      lastVerificationDateTime: new Date().toISOString(),
    });
  }
}
