import {
  HttpRequest,
  HttpClient,
  HttpEvent,
  HttpEventType,
} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  FilePickerAdapter,
  UploadResponse,
  UploadStatus,
  FilePreviewModel,
} from 'ngx-awesome-uploader';
import { v4 as uuidv4 } from 'uuid';
import { auth } from 'src/environments/environment';

export class CoolerLoadFilePickerAdapter extends FilePickerAdapter {
  token: string;

  constructor(private http: HttpClient) {
    super();
  }
  public uploadFile(fileItem: FilePreviewModel): Observable<UploadResponse> {
    this.token = uuidv4();
    const form = new FormData();
    // form.append('file', fileItem.file);
    form.append('token', this.token);
    form.append('files', fileItem.file);
    // const api = 'https://ngx-awesome-uploader-2.free.beeceptor.com/upload';
    const api = `${auth.urlConfig}/file/upload`;
    const req = new HttpRequest('POST', api, form, { reportProgress: true });
    return this.http.request(req).pipe(
      map((res: HttpEvent<any>) => {
        if (res.type === HttpEventType.Response) {
          const responseFromBackend = res.body;
          return {
            body: responseFromBackend,
            status: UploadStatus.UPLOADED,
          };
        } else if (res.type === HttpEventType.UploadProgress) {
          /** Compute and show the % done: */
          const uploadProgress = +Math.round((100 * res.loaded) / res.total);
          return {
            status: UploadStatus.IN_PROGRESS,
            progress: uploadProgress,
          };
        }
      }),
      catchError((er) => {
        return of({ status: UploadStatus.ERROR, body: er });
      })
    );
  }

  public removeFile(fileItem: FilePreviewModel): Observable<any> {
    const responseFromBackend = fileItem.uploadResponse;
    const removeApi = `${auth.urlConfig}/file/upload/delete`;
    this.token = null;
    return this.http.post(removeApi, { token: `${this.token}` });
  }
  public getToken() {
    return this.token;
  }
}
