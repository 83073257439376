import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  GoogleMap,
  MapDirectionsService,
  MapInfoWindow,
  MapMarker,
  MapAnchorPoint,
} from '@angular/google-maps';
import { async } from '@firebase/util';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { ClientModel } from 'src/app/polls/models/client.model';
import { ClientsService } from 'src/app/polls/services/clients.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.css'],
})
export class MapsComponent implements OnInit, AfterViewInit {
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow>;
  @ViewChildren(MapMarker) MapMarkers: QueryList<MapMarker>;
  @ViewChild('mapSearchField') searchField: ElementRef;

  @Input() clientsSubject: Observable<ClientModel[]>;
  @Input() currentPositionSubject: Observable<any>;
  @Input() centerPositionSubject: Observable<any>;
  @Input() typeCenterSubject: Observable<string>;
  @Output() pollSelected = new EventEmitter();
  inputVal: string;
  center: google.maps.LatLngLiteral;
  positionActuall: google.maps.LatLngLiteral;
  zoom = 14;
  markerOptions: google.maps.MarkerOptions = { draggable: true };
  markerPositions: any[] = [];
  markerPosition: any[] = [];
  centerInit: google.maps.LatLngLiteral;
  typeCenter = '';
  codeClientMaker = '';
  nameClientMaker = '';
  dirClientMaker = '';
  polls: Observable<any[]>;
  pollsClient: Subject<any[]> = new Subject<any[]>();

  options: google.maps.MapOptions = {
    // mapTypeId: 'hybrid',
    // zoomControl: false,
    // scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 8,
    disableDefaultUI: true,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: true,
  };
  directionsResults$: Observable<google.maps.DirectionsResult | undefined>;

  constructor(
    mapDirectionsService: MapDirectionsService,
    private spinner: NgxSpinnerService,
    private clientService: ClientsService
  ) {
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      this.centerInit = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });

    // Para renderizar rutas
    // const request: google.maps.DirectionsRequest = {
    //   destination: { lat: -33.4545, lng: -70.6001 },
    //   origin: { lat: -33.443, lng: -70.6378 },
    //   travelMode: google.maps.TravelMode.DRIVING,
    // };
    // this.directionsResults$ = mapDirectionsService.route(request).pipe(
    //   tap(() => (this.zoom = 16)),
    //   map((response) => {
    //     return response.result;
    //   })
    // );

    // document.addEventListener('click', (clickEvent: MouseEvent) => {
    //   console.log('Click Event Details: ', clickEvent);
    // });
  }

  ngOnInit(): void {
    this.typeCenterSubject.subscribe((type) => {
      this.typeCenter = type;
    });

    this.clientsSubject.subscribe(async (clients) => {
      this.markerPositions = [];
      let clientsMarkes = await clients.filter(
        (client) =>
          client.georeferencia_x !== '0' && client.georeferencia_y !== '0'
      );
      clientsMarkes.forEach((client) => {
        let maker = {
          position: {
            lat: Number(client.georeferencia_x),
            lng: Number(client.georeferencia_y),
          },
          // label: {
          //   text: client.descr_cliente,
          // },
          descr_cliente: client.descr_cliente,
          title: client.direccion,
          info: client.cliente_id,
          id: client.cliente_id,
          polls: client.polls,
          empresa_id: client.empresa_id,
          opacity: 0.6,
          options: {
            animation: google.maps.Animation.DROP,
            draggable: false,
            title: client.descr_cliente,

            // icon: '../../assets/images/truck_pin.svg',
            // icon: '../../assets/images/truck_pin_ccu.png',
          },
          lat: Number(client.georeferencia_x),
          lng: Number(client.georeferencia_y),
        };
        this.markerPositions.push(maker);
      });
      this.setActualPosition();
    });

    this.currentPositionSubject.subscribe((position) => {
      this.positionActuall = position;

      this.setActualPosition();
    });

    this.centerPositionSubject.subscribe((position) => {
      this.center = position;
      const idxMark = this.markerPositions.findIndex(
        (mar) =>
          mar.position.lat === this.center.lat &&
          mar.position.lng === this.center.lng
      );

      // Se marca cliente seleccionado
      if (idxMark !== -1) {
        this.markerPositions[idxMark].options = {
          // animation: google.maps.Animation.BOUNCE,
          draggable: false,
          label: {
            text: this.markerPositions[idxMark].descr_cliente,
            fontSize: '12px',
          },
          zIndex: 10000,
          // icon: {
          //   url: '../../assets/images/icon_walk.svg',
          // },
        };
        // this.markerPositions.splice(idxMark, 1);
      }

      // const request: google.maps.DirectionsRequest = {
      //   destination: this.center,
      //   origin: this.positionActuall,
      //   travelMode: google.maps.TravelMode.DRIVING,
      // };-33.454843187642865, -70.63322786202713
    });

    this.typeCenterSubject.subscribe((type) => {});
  }

  setActualPosition() {
    // Creando marcador manual
    let maker = {
      position: this.positionActuall,
      label: {
        text: 'Posicion Actual',
      },

      opacity: 0.6,
      options: {
        animation: google.maps.Animation.DROP,
        draggable: false,
        title: 'Posicion Actual',

        // icon: '../../assets/images/truck_pin.svg',
        // icon: '../../assets/images/truck_pin_ccu.png',
      },
    };

    this.markerPositions.push(maker);
  }
  addMarker(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.markerPositions.push(event.latLng.toJSON());
  }
  async openInfo(marker: MapMarker, content: any, i: number) {
    if (!this.markerPositions[i].polls) {
      const pollClient = await this.getPolls(content.id, content.empresa_id);
      this.markerPositions[i].polls = pollClient;
    }

    this.searchField.nativeElement.v;
    this.dirClientMaker = content.title;
    this.nameClientMaker = content.descr_cliente;
    this.codeClientMaker = content.info;
    this.polls = this.markerPositions[i].polls;
    this.markerPosition = content.position;
    this.inputVal = '';
    if (this.infoWindow != undefined) this.infoWindow.open(marker);
  }

  eventHandler(event: any, name: string) {}

  viewMore(event: any, poll_id: any, client_id: any) {
    this.pollSelected.emit([poll_id, client_id]);
  }

  goToLink(position: any, transport: string) {
    https: window.open(
      'https://www.google.com/maps/dir/' +
        this.positionActuall.lat +
        ',' +
        this.positionActuall.lng +
        '/' +
        position.lat +
        ',' +
        position.lng +
        '/data=' +
        transport,
      '_blank'
    );
  }

  moveCenter() {
    this.center = this.centerInit;
  }

  ngAfterViewInit() {
    // const service = new google.maps.places.PlacesService();
    const searchBox = new google.maps.places.SearchBox(
      this.searchField.nativeElement
    );

    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(
      this.searchField.nativeElement
    );
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();
      if (places.length === 0) {
        return;
      }

      const bounds = new google.maps.LatLngBounds();
      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          return;
        }
        if (place.geometry.viewport) {
          this.center = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };

          const icon = {
            url: place.icon as string,
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(25, 25),
            options: {
              animation: google.maps.Animation.DROP,
              draggable: false,
              title: 'Posicion buscada',
            },
          };

          // Create a marker for each place.
          this.markerPositions.push(
            new google.maps.Marker({
              icon,
              title: place.name,
              position: place.geometry.location,
              label: {
                text: 'Posicion buscada',
              },
            })
          );

          // this.markerPositions.push(maker);
          bounds.union(place.geometry.viewport);
        } else {
        }
      });
      this.map.mapMouseout;
    });
  }

  async getPolls(cliente_id: string, empresa_id: number) {
    return new Promise<any>(async (resolve, reject) => {
      this.clientService
        .getAllPollCustomerAvailable(cliente_id, empresa_id)
        .subscribe(
          (data) => {
            this.pollsClient.next(data);
            resolve(data);
            this.spinner.hide();
          },
          (err) => {
            Swal.fire({
              icon: 'error',
              title: `Error en el envio de la Imagen la servidor`,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        );
    });
  }
}
