import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Output,
  EventEmitter,
  OnChanges,
  OnInit,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { HttpResponse, HttpClient, HttpEventType } from '@angular/common/http';
import { PollsService } from '../../services/polls.service';
import { OfflineService } from '../../services/offline.service';
import { PollModel } from '../../models/poll.model';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { UserModel } from '../../models/user.model';
import { ClientModel } from '../../models/client.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { DOCUMENT } from '@angular/common';
import { debounceTime, delay, map, startWith } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { COMMA, ENTER, EQUALS, X } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { CategoryTargetService } from '../../services/category-target.service';
import { MatSelectionListChange } from '@angular/material/list';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ResponseService } from 'src/app/polls/services/response.service';
import { UploadService } from 'src/app/polls/services/upload.service';
import { Category } from '../../models/category.models';

// Dexie DB
import { db } from 'src/app/db/db';

// SYNC
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { StatsService } from '../../services/stats.service';
import { Subject } from 'rxjs';

export interface ItemFilter {
  name: string;
}

export interface Categories {
  category: string;
  source: string;
  label: string;
  values: Category[];
}

export interface EstructuraOrg {
  name: string;
  updated: Date;
}

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
// export class ClientsComponent implements OnInit, OnChanges, AfterViewInit {
export class ClientsComponent implements OnInit, AfterViewInit {
  @Output() runPollSync = new EventEmitter();
  searchCustomerSubject: Subject<string> = new Subject();
  search: string;
  loading_pending_clientes: boolean = false;
  loading_completed_clientes: boolean = false;
  // selected: string = '';
  searchPend: string = '';
  searchComp: string = '';
  pageSize: number = 25;
  idPoll: number;
  isFilters: boolean = false;
  numberFilters: number = 0;
  current_selected: string;
  allClients: any;
  allClientsCompleted: any;
  timeout: any = null;
  isTpmFilter: boolean = false;

  clientsPoll: ClientModel[];
  pending_clients: ClientModel[] = [];
  completed_clients: ClientModel[] = [];
  clientsPendingBackup: ClientModel[] = [];
  clientsCompleteBackup: ClientModel[] = [];
  pendingClientscount: any = 0;
  pendingClientscountLabel = '0';
  pageNumPend = 1;
  pageNumComp = 1;
  maxpageNumComp = 0;
  maxpageNumPend = 0;
  completedClientscount: any = 0;
  completedClientscountLabel = '0';
  client: ClientModel;
  loading: boolean;
  preview: boolean;
  statusSearch: boolean = false;
  poll: PollModel;
  user: UserModel;
  showButtonTop: boolean = false;
  offlineMode: any = {
    status: false,
    from: 'none',
  };
  offlineHeader: any;
  isOfflineMode: boolean = false;

  statusCompletedUploadRes: any = 0;
  controlCompletedUploadRes: number = 0;
  progressUpload: Array<any> = [];
  progressUploadResMode: ProgressSpinnerMode = 'determinate';
  progressUploadResValue: number = 0;
  progressUploadResText: string = 'Syncronizando respuestas';

  @ViewChild('text_pend') text_pend: ElementRef;
  @ViewChild('text_comp') text_comp: ElementRef;
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  itemsFilter: any[] = [];
  syncPollsCompleted: any[] = [];
  syncPollsInCompleted: any[] = [];

  selectedOptions: any[];
  categoriesClientCount: number;

  clients: Array<any> = [];
  clientsCompleted: Array<any> = [];
  arrayClients: Array<any> = [];
  arrayClientsCompleted: Array<any> = [];

  categoriesTarget: Categories[] = [];
  distance = 2;
  scrollDisabled: boolean;

  compareFunction = (o1: any, o2: any) => o1.id === o2.id;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private pollsService: PollsService,
    private uploadService: UploadService,
    private responseService: ResponseService,
    private categoryTargetService: CategoryTargetService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private renderer: Renderer2,
    private observer: BreakpointObserver,
    private offlineService: OfflineService,
    private statsService: StatsService,
    public dialog: MatDialog
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
    this.poll = JSON.parse(localStorage.getItem('poll'));
    this.preview = JSON.parse(localStorage.getItem('preview'));
  }

  @HostListener('window:scroll')
  onWindowScroll(): void {
    const yOffset = window.pageXOffset;
    const scrollTop = this.document.documentElement.scrollTop;
    this.showButtonTop = (yOffset || scrollTop) > 350;
  }

  onScrollTop(): void {
    this.document.documentElement.scrollTop = 0;
  }

  onScrollDownPend() {
    // Init Offline Mode
    if (this.isOfflineMode) {
      this.paginatePendingClients();
      return;
    }
    // End Offline Mode
    if (
      this.statusSearch === false &&
      this.pageNumPend <= this.maxpageNumPend
    ) {
      this.spinner.show('loadingClients');

      this.pollsService
        .getClientByPollByPage(
          this.idPoll,
          this.preview,
          this.pageNumPend,
          this.pageSize,
          this.statusSearch,
          this.searchPend,
          this.itemsFilter
        )
        .subscribe((clients) => {
          this.pending_clients = [...this.pending_clients, ...clients.rows];
          this.pageNumPend++;
          this.spinner.hide('loadingClients');
        });
    }
  }

  onScrollDownComp() {
    // Init Offline Mode
    if (this.isOfflineMode) {
      this.paginateCompletedClients();
      return;
    }
    // End Offline Mode

    /* // Init Offline Mode
      if (this.offlineMode.status && this.offlineMode.from === 'poll') {
        this.completed_clients = [];
        for (let i = 0; i < this.pageNumComp; i++) {
          this.clientsCompleted[i].rows.forEach((client) => {
            this.completed_clients.push(client);
          });
        }
        this.pageNumComp++;
        this.spinner.hide('loadingClients');
        return;
      }
      // End Offline Mode */

    if (this.statusSearch === true && this.pageNumComp <= this.maxpageNumComp) {
      this.spinner.show('loadingClients');

      this.pollsService
        .getClientByPollByPage(
          this.idPoll,
          this.preview,
          this.pageNumComp,
          this.pageSize,
          this.statusSearch,
          this.searchComp,
          this.itemsFilter
        )
        .subscribe((clients) => {
          this.completed_clients = [...this.completed_clients, ...clients.rows];
          this.pageNumComp++;
          this.spinner.hide('loadingClients');
        });
    }
  }

  async checkFilters() {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const res: any = localStorage.getItem('filterPollData');
        if (res !== null) {
          this.itemsFilter = JSON.parse(localStorage.getItem('filterPollData'));
        }
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  ngOnInit(): void {
    this.onSearchClientChange();
    this.offlineService.loadOfflineHeader().then(() => {
      this.offlineService
        .getOfflineHeader(1, this.user.id)
        .then(async (offlineHeader) => {
          this.offlineHeader = offlineHeader ? offlineHeader : null;
          this.offlineMode = offlineHeader;
          this.isOfflineMode =
            offlineHeader && offlineHeader.status ? true : false;

          this.route.queryParamMap.subscribe((params) => {
            this.idPoll = Number(params.get('id'));
          });
          // Init Offline Mode
          if (this.isOfflineMode) {
            this.spinner.show('loadingClients');
            this.fetchPendingClients({});
            this.fetchClientsCompleted('');
            return;
          }
          // End Offline Mode
          this.checkFilters().then((data) => {
            this.fetchPendingClients({});
            this.fetchClientsCompleted('');
          });

          if (this.poll.geolocation === true) {
            navigator.geolocation.getCurrentPosition((position) => {
              const coords = position.coords;
              const latLong = [coords.latitude, coords.longitude];
              localStorage.setItem('lat', coords.latitude.toString());
              localStorage.setItem('long', coords.longitude.toString());
            });
          }
        });
    });
  }

  onSearchClientChange() {
    this.searchCustomerSubject
      .pipe(debounceTime(1000))
      .subscribe((searchText) => {
        if (searchText.length === 0) {
          this.fetchPendingClients({});
        } else {
          this.pageNumPend = 1;
          this.pageNumComp = 1;

          this.searchClient();
        }
      });
  }

  onSearchCustomerChange(searchText: string) {
    this.searchCustomerSubject.next(searchText);
  }

  resetSearchEmpty(val) {
    if (val === null || val === '') {
      if (this.statusSearch === true) {
        this.fetchClientsCompleted('');
      } else {
        this.fetchPendingClients({});
      }
    }
  }

  searchClient() {
    if (this.statusSearch === true) {
      this.fetchClientsCompleted(this.searchComp);
    } else {
      this.fetchPendingClients({ stringSearch: this.searchPend });
    }
  }

  fetchClientsFilter({ isTpmFilter }) {
    this.isTpmFilter = isTpmFilter;
    this.fetchPendingClients({ isTpmFilter });
  }

  async fetchPendingClients({ isTpmFilter = false, stringSearch = '' }) {
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then(async (offlineHeader) => {
        this.spinner.hide('loadingClients');
        this.pending_clients = [];
        // Init Offline Mode
        if (this.isOfflineMode) {
          let id: any = this.idPoll;
          id = parseInt(id);
          this.poll = await this.offlineService.getPoll(id);
          const { enterprise_id } = this.poll.pollsunes[0];
          this.isFilters =
            offlineHeader &&
            offlineHeader.dataFiltersPoll[this.idPoll] &&
            offlineHeader.dataFiltersPoll[this.idPoll].isFilter &&
            (offlineHeader.dataFiltersPoll[this.idPoll].keepFilter ||
              isTpmFilter);
          this.numberFilters =
            offlineHeader &&
            offlineHeader.dataFiltersPoll[this.idPoll] &&
            offlineHeader.dataFiltersPoll[this.idPoll].filters.length > 0
              ? offlineHeader.dataFiltersPoll[this.idPoll].filters.length
              : 0;

          this.pageNumPend = 1;
          this.loading_pending_clientes = true;
          this.scrollDisabled = true;
          this.pendingClientscount = '...';
          try {
            const [clients, pendingClientscount] = await Promise.all([
              this.offlineService.getCLientsByPoll({
                pollId: id,
                pageNum: this.pageNumPend,
                pageSize: this.pageSize,
                type: 'pending',
                isTpmFilter,
                stringSearch,
                enterprise_id,
              }),
              this.offlineService.getCLientsByPoll({
                pollId: id,
                type: 'pending',
                count: true,
                isTpmFilter,
                stringSearch,
                enterprise_id,
              }),
            ]);

            this.pending_clients = clients;
            this.pendingClientscount = pendingClientscount;
            this.loading_pending_clientes = false;
          } catch (error) {
            console.error('Error fetching clients:', error);
            this.loading_pending_clientes = false;
          }
          return;
        }
        // End Offline Mode
      });
  }

  async paginatePendingClients() {
    this.spinner.show('loadingClients');
    this.pageNumPend++;
    const { enterprise_id } = this.poll.pollsunes[0];
    const clients = await this.offlineService.getCLientsByPoll({
      pollId: this.idPoll,
      pageNum: this.pageNumPend,
      pageSize: this.pageSize,
      isTpmFilter: this.isTpmFilter,
      type: 'pending',
      stringSearch: this.searchPend,
      enterprise_id,
    });
    if (clients.length > 0) {
      this.pending_clients = [...this.pending_clients, ...clients];
      this.clientsPendingBackup = [...this.clients, ...clients];
    }
    this.spinner.hide('loadingClients');
  }

  async paginateCompletedClients() {
    this.spinner.show('loadingClients');
    this.pageNumComp++;
    const { enterprise_id } = this.poll.pollsunes[0];
    const clients = await this.offlineService.getCLientsByPoll({
      pollId: this.idPoll,
      pageNum: this.pageNumComp,
      pageSize: this.pageSize,
      type: 'completed',
      stringSearch: this.searchComp,
      enterprise_id,
    });
    if (clients.length > 0) {
      this.completed_clients = [...this.pending_clients, ...clients];
      this.clientsCompleteBackup = [...this.clients, ...clients];
    }
    this.spinner.hide('loadingClients');
  }

  builQuery(poll_id, item) {
    let filter: any = {};
    switch (item.source) {
      case 'seg':
        // query.descr_segmento = item.code;
        break;
      case 'rel':
        // query.descr_relevancia = item.code;
        break;
      case 'niv':
        // query.descr_nivel = item.code;
        break;
      case 'ven':
        // query.vendedor_id = item.code;
        filter = {
          index: '[poll_id+descr_vendedor]',
          value: [poll_id, item.name],
        };
        break;
      case 'jef':
        // query.jefe_ventas_id = item.code;
        break;
      case 'sgr':
        // query.subgerente_id = item.code;
        break;
      case 'grt':
        // query.gerente_id = item.code;
        break;
      case 'ofc':
        // query.oficina_comercial_id = item.code;
        break;
      case 'dtt':
        // query.orden_distrito_venta = item.code;
        break;
      case 'zna':
        // query.orden_zona_venta = item.code;
        break;
    }
    return filter;
  }

  searchOnFilterPendingClients(localClientsCompleted, stringSearch) {
    let result = this.allClients.filter(function (client) {
      return client.searchStr.match(stringSearch.toUpperCase()) ? true : false;
    });
    result.toArray((clients) => {
      this.loading_pending_clientes = false;
      let clientsPending = [];
      let index;
      for (let i = 0; i < clients.length; i++) {
        index = localClientsCompleted.findIndex(
          (lc) => lc.client.id === clients[i].id
        );
        if (index === -1) {
          clientsPending.push(clients[i]);
        }
      }
      this.paginateClients(clientsPending);
    });
  }

  searchOnFilterCompletedClients(localClientsCompleted, stringSearch) {
    let clients = localClientsCompleted.filter(function (client) {
      return client.searchStr.match(stringSearch.toUpperCase()) ? true : false;
    });
    this.loading_completed_clientes = false;
    this.paginateClientsCompleted(clients);
  }

  async searchOnFullTextPendingClients(localClientsPending, stringSearch) {
    let result = await db.clients
      .where('messageWords')
      .startsWithIgnoreCase(stringSearch)
      .distinct();
    result.toArray((clients) => {
      this.loading_pending_clientes = false;
      let clientsPending = [];
      let index;
      let id: any = this.idPoll;
      id = parseInt(id);
      for (let i = 0; i < clients.length; i++) {
        /* if (clients[i].poll_id === id) {
          index = localClientsPending.findIndex(
            (lc) => lc.client.id === clients[i].id
          );
          if (index === -1) {
            clientsPending.push(clients[i]);
          }
        } */
      }
      this.paginateClients(clientsPending);
    });
  }

  async searchOnFullTextCompletedClients(localClientsCompleted, stringSearch) {
    let result = await db.clients
      .where('messageWords')
      .startsWithIgnoreCase(stringSearch)
      .distinct();
    result.toArray((clients) => {
      this.loading_completed_clientes = false;
      let clientsPending = [];
      let index;
      for (let i = 0; i < clients.length; i++) {
        index = localClientsCompleted.findIndex(
          (lc) => lc.client.id === clients[i].id
        );
        if (index === -1) {
          clientsPending.push(clients[i]);
        }
      }
      this.paginateClients(clientsPending);
    });
  }

  paginateClients(clients) {
    this.arrayClients = clients;
    if (clients.length === 0) {
      this.loadData([]);
      return;
    }

    const controlNumberClients = this.arrayClients.length / 50;
    let controlInterval: any = 0;
    for (let c = 0; c < controlNumberClients; c++) {
      this.clients[c] = {
        rows: new Array(),
        count: this.arrayClients.length,
      };
      for (let i = 0; i < 50; i++) {
        if (this.arrayClients[controlInterval]) {
          this.clients[c].rows[controlInterval] =
            this.arrayClients[controlInterval];
          controlInterval++;
        }
      }
    }
    this.loadData(this.clients[0]);
  }

  paginateClientsCompleted(clients) {
    this.arrayClientsCompleted = clients;
    if (this.arrayClientsCompleted.length === 0) {
      this.loadClientsCompleted([]);
      return;
    }
    const controlNumberClients = this.arrayClientsCompleted.length / 50;
    let controlInterval: any = 0;
    for (let c = 0; c < controlNumberClients; c++) {
      this.clientsCompleted[c] = {
        rows: new Array(),
        count: this.arrayClientsCompleted.length,
      };
      for (let i = 0; i < 50; i++) {
        if (this.arrayClientsCompleted[controlInterval]) {
          this.clientsCompleted[c].rows[controlInterval] =
            this.arrayClientsCompleted[controlInterval];
          controlInterval++;
        }
      }
    }
    this.loadClientsCompleted(this.clientsCompleted[0]);
  }

  async fetchClientsPoll(idPoll, status) {
    return new Promise<void>(async (resolve, reject) => {
      const res: any = await this.pollsService
        .getClientByPollByPage(idPoll, false, 1, 0, status, '', [])
        .pipe(debounceTime(2000))
        .toPromise();
      resolve(res);
    });
  }

  async delResponses(poll, client) {
    await db.responses
      .where({
        poll_id: parseInt(poll.poll_id),
        client_id: parseInt(client.id),
      })
      .delete();
  }

  async closePoll(client, idResponse, idPoll) {
    let syncPollId: any = `${idPoll}-${client.id}-${this.user.id}`;
    const syncPollData = await db.syncpolls.get(syncPollId);
    this.responseService
      .closeResponse({
        customer_id: client.id,
        poll_id: idPoll,
        cliente_id: client.cliente_id,
        response_id: idResponse,
        lat: syncPollData.coords_finish.lat,
        long: syncPollData.coords_finish.long,
      })
      .subscribe(
        async (data) => {
          await this.delResponses(syncPollData, client);
          await db.setSyncPoll({ id: syncPollId, status: 'sync-delResponses' });
          this.completeSync(client, idPoll, {});
        },
        (err) => {
          Swal.fire({
            icon: 'error',
            title: `Error de sincronización al cerrar la encuesta, por favor verifique su conexión a internet`,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Reintentar',
            denyButtonText: `Cancelar`,
          }).then((result) => {
            if (result.isConfirmed) {
              this.closePoll(client, idResponse, idPoll);
            } else if (result.isDenied) {
              this.spinner.hide('uploading');
            }
          });
        }
      );
  }

  async uploadResponses(idResponse, idPoll, responses, por, client) {
    let response = responses[this.controlCompletedUploadRes];
    response.data.idResponse = idResponse;
    response.data.response_id = idResponse;
    const promises = [];
    const { data, type_question, type_save } = response;
    if (type_save === 'saveFileQuestion') {
      const file = await db.files.get(response.id);
      if (file) {
        const { imagenBase64, fileName } = file.data;
        const fileUploaded = await this.uploadFile(imagenBase64, fileName);
        const { token, name } = fileUploaded.body;
        data.value = `${token}/${name}`;
      } else {
        delete data.value;
        delete data.idResponse;
      }
    }

    if (type_question === 'cooler') {
      const { answerCoolerBody } = data;
      await answerCoolerBody.forEach(async (responseCooler) => {
        await responseCooler.fields.forEach(async (responseCoolerField) => {
          if (responseCoolerField.questionType === 4) {
            promises.push(
              new Promise(async (resolve, reject) => {
                const { questionAnswer, questionName } = responseCoolerField;
                const fileUploaded = await this.uploadFile(
                  questionAnswer,
                  questionName
                );
                const { token, name } = fileUploaded.body;
                responseCoolerField.questionAnswer = `${token}/${name}`;
                resolve(responseCoolerField);
              })
            );
          }
        });
      });
    }

    if (type_question === 'cooler') {
      Promise.all(promises).then(async (values) => {
        await this.save(
          data,
          type_question,
          responses,
          idResponse,
          idPoll,
          por,
          client,
          type_save
        );
      });
    } else if (type_question !== 'cooler') {
      await this.save(
        data,
        type_question,
        responses,
        idResponse,
        idPoll,
        por,
        client,
        type_save
      );
    }
  }

  async fetchStatsPoll(poll) {
    return new Promise<void>(async (resolve, reject) => {
      if (poll.period_id === 1) {
        try {
          const res: any = await this.statsService
            .getStatsByPoll(poll.id)
            .toPromise();
          resolve(res);
        } catch (error) {
          reject(error);
        }
      } else {
        try {
          const res: any = await this.statsService
            .getStatsByPollPeriod(poll.id)
            .toPromise();
          resolve(res);
        } catch (error) {
          reject(error);
        }
      }
    });
  }

  async completeSync(
    client,
    idPoll,
    {
      stastService = false,
      clientsService = false,
      clientsCompletedService = false,
    }
  ) {
    const idClientPoll: any = parseInt(idPoll);
    const poll = await db.polls.get(idClientPoll);
    let syncPollId: any = `${idPoll}-${client.id}-${this.user.id}`;
    if (!stastService) {
      try {
        // const stats = await this.fetchStatsPoll(poll.data);
        await db.stats.delete(idClientPoll);
        // await db.setStast({ id: poll.data.id, data: stats });
        await db.setSyncPoll({ id: syncPollId, status: 'sync-stast' });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: `Error sincronzando: estadisticas, por favor verifique su conexión a internet`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: 'Reintentar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.completeSync(client, idPoll, {});
          }
        });
        return;
      }
    }

    if (!clientsService) {
      try {
        const clients: any = await this.fetchClientsPoll(idClientPoll, false);
        await db.clients.where('poll_id').equals(idClientPoll).delete();
        await db.clients.bulkAdd(clients.rows);
        await db.setSyncPoll({ id: syncPollId, status: 'sync-clientsPending' });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: `Error sincronzando: clientes, por favor verifique su conexión a internet`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: 'Reintentar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.completeSync(client, idPoll, { stastService: true });
          }
        });
        return;
      }
    }

    if (!clientsCompletedService) {
      try {
        const clientsCompleted: any = await this.fetchClientsPoll(
          idClientPoll,
          true
        );
        await db.clientsCompleted
          .where('poll_id')
          .equals(idClientPoll)
          .delete();
        if (clientsCompleted.count > 0) {
          await db.clientsCompleted.bulkAdd(clientsCompleted.rows);
        }
        await db.setSyncPoll({ id: syncPollId, status: 'sync-completed' });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: `Error sincronzando: clientes completados, por favor verifique su conexión a internet`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: 'Reintentar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.completeSync(client, idPoll, {
              stastService: true,
              clientsService: true,
            });
          }
        });
        return;
      }
    }

    await db.syncpolls.delete(syncPollId);
    this.fetchPendingClients({});
    this.fetchClientsCompleted('');
    this.progressUploadResText = 'Completado';
    this.progressUploadResValue = 100;
    this.spinner.hide('uploading');
  }

  uploadFile(fileBase64, name) {
    return new Promise<any>(async (resolve, reject) => {
      this.uploadService
        .pushFileBase64ToStorage(fileBase64, name, '')
        .subscribe(
          (event) => {
            if (event.type === HttpEventType.UploadProgress) {
              let percentage = Math.round((event.loaded / event.total) * 100);
            } else if (event instanceof HttpResponse) {
              resolve(event);
            }
          },
          (err) => {
            Swal.fire({
              icon: 'error',
              title: `Error en el envio de la Imagen la servidor`,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        );
    });
  }

  async uploadPoll(idPoll, client) {
    this.spinner.show('uploading');
    this.progressUploadResText = 'Syncronizando respuestas';
    this.progressUploadResValue = 0;
    const id = `${idPoll}-${client.id}`;
    const responses = await db.responses
      .where({ poll_id: parseInt(idPoll), client_id: parseInt(client.id) })
      .toArray();
    this.createResponse(idPoll, client, responses);
  }

  async createResponse(idPoll, client, responses) {
    let syncPollId: any = `${idPoll}-${client.id}`;
    const syncPollData = await db.syncpolls.get(syncPollId);
    const data = {
      customer_id: client.id,
      poll_id: idPoll,
      user_id: this.user.id,
      lat: syncPollData.coords_init.lat,
      long: syncPollData.coords_init.long,
      cliente_id: client.cliente_id,
      empresa_id: client.empresa_id,
    };
    this.responseService.sendResponse(data).subscribe(
      (response) => {
        localStorage.setItem('idResponse', response.id);
        const por: number = 100 / responses.length;
        this.statusCompletedUploadRes = 0;
        this.controlCompletedUploadRes = 0;
        this.uploadResponses(response.id, idPoll, responses, por, client);
      },
      (err) => {
        Swal.fire({
          icon: 'error',
          title: `Error de sincronización al crear la respuesta, por favor verifique su conexión a internet`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Reintentar',
          denyButtonText: `Cancelar`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.createResponse(idPoll, client, responses);
          } else if (result.isDenied) {
            this.spinner.hide('uploading');
          }
        });
      }
    );
  }

  loadData(clients) {
    if (clients === null) {
      this.dialog.open(DialogComponent, {
        data: {
          message: 'Operación no permitida.',
          status: 0,
        },
      });
    } else {
      this.pageNumPend++;
      this.pending_clients = clients.rows;
      this.maxpageNumPend = Math.ceil(Number(clients.count) / this.pageSize);
      this.pendingClientscount = clients.count;
      this.pendingClientscountLabel = this.generateLabel(
        this.pendingClientscount
      );
      this.loading_pending_clientes = false;
      if (this.text_pend !== undefined) {
        this.renderer.removeAttribute(this.text_pend.nativeElement, 'disabled');
      }
    }
  }

  async fetchClientsCompleted(stringSearch: string) {
    // Init Offline Mode
    if (this.isOfflineMode) {
      this.completed_clients = [];
      let id: any = this.idPoll;
      id = parseInt(id);
      this.poll = await this.offlineService.getPoll(id);
      const { enterprise_id } = this.poll.pollsunes[0];
      this.pageNumComp = 1;
      this.loading_completed_clientes = true;
      this.completedClientscount = '...';
      this.scrollDisabled = true;
      this.pendingClientscount = '...';
      const clients = await this.offlineService.getCLientsByPoll({
        pollId: id,
        pageNum: this.pageNumComp,
        pageSize: this.pageSize,
        type: 'completed',
        stringSearch,
        enterprise_id,
      });
      this.completed_clients = clients;
      this.loading_completed_clientes = false;
      this.completedClientscount = await this.offlineService.getCLientsByPoll({
        pollId: id,
        type: 'completed',
        count: true,
        stringSearch,
        enterprise_id,
      });
      return;
    }
    // End Offline Mode

    this.pollsService
      .getClientByPollByPage(
        this.idPoll,
        this.preview,
        1,
        this.pageSize,
        true,
        stringSearch,
        this.itemsFilter
      )
      .pipe(debounceTime(2000))
      .subscribe((clients) => {
        if (clients !== null) {
          this.loadClientsCompleted(clients);
        }
      });
  }

  loadClientsCompleted(clients) {
    this.pageNumComp++;
    this.completed_clients = clients ? clients.rows : [];

    const count = clients ? clients.count : 0;
    this.maxpageNumComp = Math.ceil(Number(count) / this.pageSize);
    this.completedClientscount = count;
    this.completedClientscountLabel = this.generateLabel(
      this.completedClientscount
    );
    this.spinner.hide('loadingClients');
    if (this.text_comp !== undefined) {
      this.renderer.removeAttribute(this.text_comp.nativeElement, 'disabled');
    }
  }

  async fetchCategoryTargetFilter(categories: Categories[]) {
    this.spinner.show('loadingClients');
    // Init Offline Mode
    if (this.offlineMode.status && this.offlineMode.from === 'poll') {
      const targetpoll = await db.targetpoll.get(this.poll.id);
      if (categories.length > 0) {
        this.itemsFilter.filter((item) => {
          const index = targetpoll.categories.findIndex(
            (tc) => tc.source === item.source
          );
          if (index != -1) {
            targetpoll.categories[index].values = [item];
          }
        });
      }
      const categoriesPoll = targetpoll.categories.find(
        (c) => c.category === 'Vendedor'
      );
      this.categoriesClientCount = targetpoll.categories[0].count;
      return;
    }
    // End Offline Mode
    this.categoryTargetService
      .getCategoryTarget(this.poll.id, categories)
      .subscribe((categories) => {
        this.categoriesClientCount = categories[0].count;
      });
  }

  // loadCategoryTargetFilter(categories) {
  //   this.categoriesTarget = categories;

  //   const SegmentoItems = categories.filter(
  //     (category) => category.category.trim().toLowerCase() === 'segmento'
  //   );
  //   SegmentoItems.length > 0
  //     ? (this.allSegmento = SegmentoItems[0].values)
  //     : [];

  //   const RelevanciaItems = categories.filter(
  //     (category) => category.category.trim().toLowerCase() === 'relevancia'
  //   );
  //   RelevanciaItems.length > 0
  //     ? (this.allRelevancia = RelevanciaItems[0].values)
  //     : [];

  //   const NivelItems = categories.filter(
  //     (category) => category.category.trim().toLowerCase() === 'nivel'
  //   );
  //   NivelItems.length > 0 ? (this.allNivel = NivelItems[0].values) : [];

  //   const VendedoresItems = categories.filter(
  //     (category) => category.category.trim().toLowerCase() === 'vendedor'
  //   );
  //   VendedoresItems.length > 0
  //     ? (this.allVendedor = VendedoresItems[0].values)
  //     : [];

  //   const JefeVtaItems = categories.filter(
  //     (category) => category.category.trim().toLowerCase() === 'jefe venta'
  //   );
  //   JefeVtaItems.length > 0 ? (this.allJefeVta = JefeVtaItems[0].values) : [];

  //   const SubGrteItems = categories.filter(
  //     (category) => category.category.trim().toLowerCase() === 'subgerente'
  //   );
  //   SubGrteItems.length > 0
  //     ? (this.allSubGerente = SubGrteItems[0].values)
  //     : [];

  //   const GrteItems = categories.filter(
  //     (category) => category.category.trim().toLowerCase() === 'gerente'
  //   );
  //   GrteItems.length > 0 ? (this.allGerente = GrteItems[0].values) : [];

  //   const OficComItems = categories.filter(
  //     (category) =>
  //       category.category.trim().toLowerCase() === 'oficina comercial'
  //   );
  //   OficComItems.length > 0 ? (this.allOficCom = OficComItems[0].values) : [];

  //   const DistritoItems = categories.filter(
  //     (category) => category.category.trim().toLowerCase() === 'distrito'
  //   );
  //   DistritoItems.length > 0
  //     ? (this.allDistrito = DistritoItems[0].values)
  //     : [];

  //   const ZonaItems = categories.filter(
  //     (category) => category.category.trim().toLowerCase() === 'zona'
  //   );
  //   ZonaItems.length > 0 ? (this.allZona = ZonaItems[0].values) : [];

  //   if (categories.length > 0) {
  //     if (
  //       categories.findIndex(
  //         (category) => category.category.toLowerCase() === 'segmento'
  //       ) !== -1
  //     ) {
  //       this.checkedSegmentos();
  //     }

  //     if (
  //       categories.findIndex(
  //         (category) => category.category.toLowerCase() === 'relevancia'
  //       ) !== -1
  //     ) {
  //       this.checkedRelevancia();
  //     }

  //     if (
  //       categories.findIndex(
  //         (category) => category.category.toLowerCase() === 'nivel'
  //       ) !== -1
  //     ) {
  //       this.checkedNivel();
  //     }

  //     if (
  //       categories.findIndex(
  //         (category) => category.category.toLowerCase() === 'vendedor'
  //       ) !== -1
  //     ) {
  //       this.checkedVendedor();
  //     }
  //     if (
  //       categories.findIndex(
  //         (category) => category.category.toLowerCase() === 'jefe venta'
  //       ) !== -1
  //     ) {
  //       this.checkedJfeVenta();
  //     }

  //     if (
  //       categories.findIndex(
  //         (category) => category.category.toLowerCase() === 'subgerente'
  //       ) !== -1
  //     ) {
  //       this.checkedSubGerente();
  //     }

  //     if (
  //       categories.findIndex(
  //         (category) => category.category.toLowerCase() === 'gerente'
  //       ) !== -1
  //     ) {
  //       this.checkedGerente();
  //     }

  //     // if (
  //     //   categories.findIndex(
  //     //     (category) => category.category.toLowerCase() === 'gerente'
  //     //   ) !== -1
  //     // ) {
  //     //   this.checkedOficinaCom();
  //     // }
  //     if (
  //       categories.findIndex(
  //         (category) => category.category.toLowerCase() === 'distrito'
  //       ) !== -1
  //     ) {
  //       this.checkedDistrito();
  //     }
  //     if (
  //       categories.findIndex(
  //         (category) => category.category.toLowerCase() === 'zona'
  //       ) !== -1
  //     ) {
  //       this.checkedZona();
  //     }
  //   }

  //   this.spinner.hide('loadingClients');
  // }

  generateLabel(value: number = 0): string {
    switch (true) {
      case value > 999:
        return '+' + (value / 1000).toString();
        break;
      case value > 9999:
        return '+' + (value / 10000).toString();
        break;
      default:
        return value.toString();
    }
  }

  async verifyInitPoll(poll, client) {
    const pollId = poll.id;
    // Init Offline
    if (this.isOfflineMode) {
      /*
      const synchronizationIsNotUpToDate =
        await this.offlineService.synchronizationIsNotUpToDate();
      const syncPoll = await this.offlineService.getSyncPoll(
        `${pollId}-${client.id}-${this.user.id}`
      );

      if (
        synchronizationIsNotUpToDate === true ||
        (syncPoll && syncPoll.status === 'unsynchronized') ||
        this.offlineHeader.statusSync === 'problems' ||
        poll.edit === 'trueOffline'
      ) {
        let message = '';
        if (syncPoll && syncPoll.status === 'problems') {
          message =
            'Tienes encuestas con problemas de sincronización, por favor sincronize e intente de nuevo';
        }
        if (synchronizationIsNotUpToDate === true) {
          message =
            'Tienes encuestas sin sincronizar, por favor sincronize e intente de nuevo';
        }
        if (this.offlineHeader.statusSync === 'unsynchronized') {
          message =
            'Tienes encuestas sin sincronizar, por favor sincronize e intente de nuevo';
        }

        if (poll.edit === 'trueOffline') {
          message =
            'Esta encuesta esta sin sincronizar, se procedera a editar esta de acuerdo?';
        }

        Swal.fire({
          title: 'Aviso importante',
          html: message,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.isConfirmed) {
            if (poll.edit === 'trueOffline') {
              this.initPoll(pollId, client);
              return;
            }
            this.router.navigate(['poll'], {
              queryParams: { isToSync: true },
            });
          }
        });
        return;
      }*/

      if (poll.edit === 'true') {
        this.editPoll(pollId, client);
        return;
      }
    }

    this.initPoll(pollId, client);
    // End Offline
  }

  async initPoll(id: number, client: ClientModel) {
    localStorage.setItem('client', JSON.stringify(client));
    localStorage.removeItem('navHistory');
    localStorage.removeItem('idResponse');
    // Init Offline Mode
    if (this.isOfflineMode) {
      this.offlineService.initPoll(this.poll.id, client, this.user.id);
      return;
    }
    // End Offline Mode

    if (
      this.poll.infoextra == true &&
      this.poll.infoextrapoll !== null &&
      this.poll.infoextrapoll !== undefined &&
      this.poll.infoextracustomer !== null &&
      this.poll.infoextracustomer !== undefined
    ) {
      this.router.navigate(['poll/client/info']);
    } else {
      this.router.navigate(['poll/client/init']);
    }
  }

  returnDashboard() {
    if (this.preview == true) {
      localStorage.removeItem('poll');
      localStorage.removeItem('preview');
      this.router.navigate(['/poll']);
    } else {
      if (this.poll.addgoals === true) {
        this.router.navigate(['/poll/dashboard-goals'], {
          queryParams: { id: this.poll.id },
        });
      } else if (this.poll.period_id === 1) {
        this.router.navigate(['/poll/dashboard'], {
          queryParams: { id: this.poll.id },
        });
      } else {
        this.router.navigate(['/poll/dashboard-period'], {
          queryParams: { id: this.poll.id },
        });
      }
    }
  }

  changeTab($event) {
    if ($event.index === 1) {
      this.statusSearch = true;
    } else {
      this.statusSearch = false;
    }
  }
  onKey(x) {
    if (this.statusSearch === true) {
      // this.renderer.setAttribute(this.text_comp.nativeElement, "disabled", "true");
      this.pageNumComp = 1;
      this.searchComp = x;
      this.fetchClientsCompleted(this.searchComp);
    } else {
      // this.renderer.setAttribute(this.text_pend.nativeElement, "disabled", "true");
      this.searchPend = x;
      this.pageNumPend = 1;
      this.fetchPendingClients({ stringSearch: this.searchPend });
    }
  }

  executeSearchg(value: string) {
    // alert(value);
    this.fetchPendingClients({});
  }

  /// Filtros
  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit() {
    // Init Offline Mode
    if (this.isOfflineMode) {
      return;
    }
    // End Offline Mode

    this.observer
      .observe(['(max-width: 800px)'])
      .pipe(delay(1))
      .subscribe((res) => {
        if (res.matches) {
          this.sidenav.mode = 'over';
          this.sidenav.close();
        } else {
          this.sidenav.mode = 'side';
          this.sidenav.open();
        }
      });
  }

  addItemFilter(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.itemsFilter.push({ name: value });
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  onListSelectionChange(ob: MatSelectionListChange) {
    const idxFilter = this.itemsFilter.findIndex(
      (elementFilter) =>
        elementFilter.source.trim() === ob.options[0]['_value'].source.trim() &&
        elementFilter.code.toString().trim() ===
          ob.options[0]['_value'].code.toString().trim()
    );
    if (idxFilter !== -1) {
      this.itemsFilter.splice(idxFilter, 1);
    } else {
      this.itemsFilter.push(ob.options[0]['_value']);
    }
    setTimeout(() => {
      this.refreshItemsCategory();
    }, 100);
  }

  refreshItemsCategory() {
    this.fetchCategoryTargetFilter(this.itemsFilter);
  }

  applyFilter() {
    localStorage.setItem('filterPollData', JSON.stringify(this.itemsFilter));
    this.fetchPendingClients({});
    this.fetchClientsCompleted('');
  }

  restartPoll(
    clientName: string,
    clientID: number,
    pollId: number,
    filter_by_user: boolean
  ) {
    Swal.fire({
      title: 'Reiniciar encuesta',
      html:
        'Se eliminarán las respuesta del cliente<br><b>' +
        clientName +
        '</b>, <br>' +
        ' y podras genera un nuevo set de respuesta. <br>¿Deseas continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.pollsService
          .sendPollRestart({
            customer_id: clientID,
            poll_id: pollId,
            method: 'customer_id',
            filter_by_user,
          })
          .subscribe((data) => {
            this.applyFilter();
            if (data !== null) {
              Swal.fire(
                'Reiniciar encuesta',
                ' Para aplicar nuevamente la encuesta, seleccione a cliente:<br><b>' +
                  clientName +
                  '</b><br>del listado de pendientes.',
                'success'
              );
            } else {
              Swal.fire(
                'Reiniciar encuesta',
                'No se ha podido reinicar la encuesta.',
                'error'
              );
            }
          });
      }
    });
  }

  editPoll(pollId: number, client: ClientModel) {
    var lat;
    var long;
    if (this.poll.geolocation === true) {
      lat = localStorage.getItem('lat');
      long = localStorage.getItem('long');
    }

    Swal.fire({
      title: 'Editar encuesta',
      html:
        'Se creará registros adicionales de las respuesta del cliente<br><b>' +
        client.descr_cliente +
        '</b>, <br>' +
        'que posteriormente podrás modificar. <br>¿Deseas continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.pollsService
          .sendPollEdit({
            customer_id: client.id,
            poll_id: pollId,
            user_id: this.user.id,
            lat: lat,
            long: long,
            method: 'customer_id',
          })
          .subscribe((data) => {
            this.applyFilter();
            if (data !== null) {
              // Swal.fire(
              //   '¡Nueva encuesta generada!',
              //   ' Para aplicar nuevamente la encuesta, seleccione al cliente:<br><b>'+clientName+'</b><br>del listado de pendientes.',
              //   'success'
              // )
              Swal.fire({
                title: 'Editar encuesta',
                html:
                  'Desea ir a la ediccion de la encuesta del cliente:<br><b>' +
                  client.descr_cliente +
                  '</b>',
                icon: 'success',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Editar',
              }).then((result) => {
                if (result.isConfirmed) {
                  this.initPoll(pollId, client);
                }
              });
            } else {
              Swal.fire(
                'Editar encuesta',
                'No se ha podido editar la encuesta.',
                'error'
              );
            }
          });
      }
    });
  }
  // helper
  async save(
    data,
    type_question,
    responses,
    idResponse,
    idPoll,
    por,
    client,
    type_save
  ) {
    await this.responseService
      .sendResponseQuestion(data, type_question)
      .subscribe(
        async (res) => {
          this.statusCompletedUploadRes = parseInt(
            this.statusCompletedUploadRes + por
          );
          if (this.statusCompletedUploadRes < 100) {
            this.progressUploadResValue = this.statusCompletedUploadRes;
          }
          this.controlCompletedUploadRes++;

          if (this.controlCompletedUploadRes < responses.length) {
            this.uploadResponses(idResponse, idPoll, responses, por, client);
          } else {
            this.closePoll(client, idResponse, idPoll);
          }
        },
        (err) => {
          Swal.fire({
            icon: 'error',
            title: `Error sincronzando: ${type_save}`,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Reintentar',
            denyButtonText: `Cancelar`,
          }).then((result) => {
            if (result.isConfirmed) {
              this.uploadResponses(idResponse, idPoll, responses, por, client);
            } else if (result.isDenied) {
              this.spinner.hide('uploading');
            }
          });
        }
      );
  }
  closeNav() {
    this.sidenav.close();
  }
  getSelected(value: any) {
    this.closeNav();
  }
}
