import * as jwt_decode from 'jwt-decode';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IGetResponseTask, IQuestionAnswer } from 'src/app/tasks/interfaces';
import { UploadService } from 'src/app/polls/services/upload.service';
import { ETaskStatus, ITaskCompleted, ITaskCreated, Question } from 'src/app/tasks/entities/task.entity';
import { NgxSpinnerService } from 'ngx-spinner';
import { TasksService } from 'src/app/tasks/services/tasks.service';
import { finalize } from 'rxjs/operators';
import { TaskResponseDTO } from 'src/app/tasks/dtos';
import { forkJoin } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-questions-to-response',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css'],
})
export class QuestionsToResponseComponent implements OnInit {
  public task;
  public questions;
  public user;
  public location;
  public questionPosition: number = 0;
  public currentQuestion: Question = null;
  public responses: IQuestionAnswer[] = [];
  public allowMoveQuestion: boolean = false;
  public responseInfo: ITaskCreated;

  constructor(
    private readonly router: Router,
    private readonly uploadService: UploadService,
    private readonly spinner: NgxSpinnerService,
    private readonly taskService: TasksService
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
    this.location = JSON.parse(localStorage.getItem('currenLocation'));
  }

  ngOnInit(): void {
    const data = localStorage.getItem('question_to_response');

    if (!data) this.router.navigate(['tasks']);

    this.task = JSON.parse(data);
    this.questions =
      this.task?.task?.questions
        .filter((q) => q.type_task === 'confirm')
        .sort((a, b) => a.type_id - b.type_id) || [];

    this._setCurrentQuestion();
  }

  handleBack() {
    this.questionPosition -= 1;

    if (this.questionPosition < 0) return this.router.navigate(['/tasks/assigned']);

    this._setCurrentQuestion();
  }

  handleNext() {
    if (this.questionPosition === this.questions.length - 1) {
      Swal.fire({
        title: 'Finalizar respuesta',
        html: 'Se procederá a finalizar la respuesta, <br>' + '<strong>¿Deseas continuar?</strong>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.handleFinish();
        } else if (result.isDenied) {
          return;
        }
      });
    } else {
      this.questionPosition += 1;
    }

    this._setCurrentQuestion();
  }

  handleFinish() {
    this._saveResponses();
  }

  handleTextAnswer(event: any) {
    if (!event?.length) {
      this.allowMoveQuestion = false;
    } else {
      this.allowMoveQuestion = true;
      const questionAnswered = this._buildResponse(event);
      this._pushResponse(questionAnswered);
    }
  }

  handleSingleSelectAnswer(event: any) {
    if (typeof event === 'object') {
      this.allowMoveQuestion = true;
      const questionAnswered = this._buildResponse(event);
      this._pushResponse(questionAnswered);
    } else if (Array.isArray(event) && event.length > 0) {
      this.allowMoveQuestion = true;
      const questionAnswered = this._buildResponse(event);
      this._pushResponse(questionAnswered);
    } else {
      this.allowMoveQuestion = false;
    }
  }

  handlePhotoAnswer(event: any) {
    if (!event?.length) {
      this.allowMoveQuestion = false;

      return;
    }

    this.allowMoveQuestion = true;
    const questionAnswered = this._buildResponse(event);

    this._pushResponse(questionAnswered);
  }

  _buildResponse(answer) {
    const question = this.questions[this.questionPosition];
    const response = { answer };
    return { ...question, response };
  }

  _pushResponse(response) {
    const responseExist = this.responses.find((res) => res.id === response.id);

    if (!responseExist) this.responses.push(response);
    else {
      this.responses = this.responses.map((res) => {
        if (res.id === response.id) return response;

        return res;
      });
    }
  }

  _setCurrentQuestion() {
    this.currentQuestion = this.questions?.[this.questionPosition] || null;

    if (!this.currentQuestion) return;

    const existResponse = this.responses.find((res) => res.id === this.currentQuestion.id);

    if (existResponse) {
      this.allowMoveQuestion = true;
      this.currentQuestion = existResponse;

      return;
    }

    this.allowMoveQuestion = false;
  }

  async _saveResponses() {
    const requests = [];

    this.responses.forEach((response: IQuestionAnswer) => {
      if (response.type_id === 4) {
        response.response.answer.forEach((image) => {
          this.uploadService
            .pushFileBase64ToStorage(image.image, image.fileName, image.token)
            .subscribe(() => {});

          const body = {
            question_id: response.id,
            option_id: response.options[0].id,
            type_id: response.type_id,
            value: `${image.token}/${image.fileName}`,
          };

          requests.push(body);
        });
      } else {
        let body = {};
        if (response.type_id === 1) {
          body = {
            question_id: response.id,
            option_id: response.response.answer.id,
            type_id: response.type_id,
            value: response.response.answer.title,
          };
        } else if (response.type_id === 3) {
          body = {
            question_id: response.id,
            option_id: response.options[0].id,
            type_id: response.type_id,
            value: response.response.answer,
          };
        }

        requests.push(body);
      }
    });

    const responses = requests.map((request) => {
      const id: number = this.task.id;

      return this.taskService.saveResponse(id, request);
    });

    this.spinner.show('upload-response-spinner');

    forkJoin(responses)
      .pipe(
        finalize(() => {
          this.taskService
            .updateStatus(this.task.id, {
              user_id: this.user.id,
              customer_id: this.task.customer.id,
              task_id: this.task.task_id,
              date_created: this.task.date_created,
              date_completed: new Date().toISOString(),
              position_created: this.task.position_created,
              position_completed: `${this.location.lat};${this.location.lng}`,
              template_status: ETaskStatus.COMPLETED,
              limit_date: this.task.limit_date,
              user_response_id: this.user.id,
            } as ITaskCompleted)
            .pipe(
              finalize(() => {
                this.spinner.hide('upload-response-spinner');
                this._cleanContext();
              })
            )
            .subscribe();
        })
      )
      .subscribe();
  }

  _cleanContext() {
    this.allowMoveQuestion = false;
    this.currentQuestion = null;
    this.questionPosition = 0;
    this.responses = [];
    this.responseInfo = null;
    this.router.navigate(['/tasks']);
  }
}
