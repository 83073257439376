import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

import { PollModel } from '../../models/poll.model';
import { StatsService } from '../../services/stats.service';

// Dexie DB
import { db } from 'src/app/db/db';
//Dashboard
import { MatSidenav } from '@angular/material/sidenav';
import { OfflineService } from '../../services/offline.service';
import { UserModel } from '../../models/user.model';
import * as jwt_decode from 'jwt-decode';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'app-dashboard-poll',
  templateUrl: './dashboard-poll.component.html',
  styleUrls: ['./dashboard-poll.component.css'],
})
export class DashboardPollComponent implements OnInit {
  view: any[] = [250, 320];
  single = [];

  colorScheme = {
    domain: ['#205C40', '#64A70B', '#C7B42C'],
  };
  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  poll: PollModel;
  initDate: string;
  endDate: string;
  remain: number = 0;
  missingPolls: number;
  dailyGoal: number = 0;
  percentage: number = 0;
  totalToday: number = 0;
  diffToday: number = 0;
  totalPoll: number = 0;
  advanceDays: number = 0;
  advancePerSuccesDay: number = 0;
  advancePerDay: number = 0;
  projectedProgressFinish: number = 0;
  isOfflineMode: boolean = false;
  user: UserModel;
  @ViewChild(MatSidenav) sidenav!: MatSidenav;

  date = new Date();
  today =
    this.date.getFullYear() +
    '-' +
    (this.date.getMonth() + 1) +
    '-' +
    this.date.getDate();

  offlineMode: any = {
    status: false,
    from: 'nonde',
  };

  constructor(
    private router: Router,
    private statsService: StatsService,
    private spinner: NgxSpinnerService,
    private offlineService: OfflineService
  ) {
    this.poll = JSON.parse(localStorage.getItem('poll'));
    this.user = jwt_decode(localStorage.getItem('token')).user;
    this.initDate = moment(this.poll.publish_date).format('DD/MM');
    this.endDate = moment(this.poll.expire_date).format('DD/MM');
    const remain = this.DaysBetween(this.today, this.poll.expire_date);
    const weeks1 = Math.floor(+remain / 7);
    this.view = [720, 355];

    this.remain = +remain - weeks1 * 1;
    this.diffToday = this.DaysBetween(
      this.poll.publish_date,
      this.poll.expire_date
    );

    this.advanceDays = this.DaysBetween(this.poll.publish_date, new Date());

    this.single = [
      {
        name: 'Completados',
        value: 0,
      },
      {
        name: 'Pendiente',
        value: 0,
      },
      {
        name: 'Pausados',
        value: 0,
      },
    ];
    Sentry.configureScope((scope) => {
      scope.setUser({
        email: this.user.email,
      });
      scope.setTags({
        email: this.user.email,
      });

      scope.setTags({
        poll: this.poll.name + '(ID:' + this.poll.id + ')',
      });
      Sentry.setContext('Encuesta', {
        name: this.poll.name,
        id: this.poll.id,
      });
    });
  }

  ngOnInit(): void {
    this.isOfflineMode = false;
    this.spinner.show();
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        if (offlineHeader) {
          this.offlineMode = offlineHeader;
        }
        this.spinner.show();
        this.fetchStats();
      });
  }

  async generateFilter(id, filters) {
    return new Promise<any>(async (resolve, reject) => {
      var filtersOut: any = {};
      var filtersString = `{poll_id:${id},`;
      let count = 0;
      filters.forEach((filter) => {
        count += 1;
        switch (filter.source) {
          // case 'seg':
          //   filtersOut.descr_segmento = filter.name;
          //   filtersString += `client.descr_segmento: "${filter.name}",`;
          //   break;
          // case 'rel':
          //   filtersOut.descr_relevancia = filter.name;
          //   filtersString += `client.descr_relevancia: "${filter.name}",`;
          //   break;
          case 'ven':
            // filtersOut.descr_vendedor = filter.name;
            filtersOut.index = `descr_vendedor`;
            filtersOut.value = filter.name;
            filtersString += `client.descr_vendedor: "${filter.name}",`;
            break;
          // case 'niv':
          //   filtersOut.descr_nivel = filter.name;
          //   filtersString += `client.descr_nivel: "${filter.name}",`;
          //   break;
          // case 'sgr':
          //   filtersOut.descr_subgerente = filter.name;
          //   break;
          // case 'jef':
          //   filtersOut.descr_jefe_ventas = filter.name;
          //   break;
          default:
            break;
        }
        if (count == filters.length) {
          resolve(filtersOut);
        }
      });
    });
  }

  multiFilter = async (array, filters) => {
    return new Promise<any>(async (resolve, reject) => {
      const DataFilter = array.filter((o) =>
        Object.keys(filters).every((k) =>
          [].concat(filters[k]).some((v) => o[k].includes(v))
        )
      );
      resolve(DataFilter);
    });
  };

  clearObject = async (data, filter) => {
    return new Promise<any>(async (resolve) => {
      const result = data
        .map((p) => {
          if (p[filter.index] === filter.value) {
            return p;
          }
        })
        .filter((element) => {
          return element !== undefined;
        });
      resolve(result);
    });
  };
  // Para buscar data ya sincronizada
  dayTodaySync = async (id, today, filters) => {
    return new Promise<any>(async (resolve, reject) => {
      const data = await db.clientsCompleted
        .where('[poll_id+date_finish]')
        .equals([id, today.toLocaleDateString()])
        .toArray();
      if (filters.length === 0) {
        resolve(data.length);
      } else {
        const result = await this.clearObject(data, filters);

        resolve(result.length);
      }
    });
  };

  totalTodayLocalSync = async (id, today, filters) => {
    return new Promise<any>(async (resolve, reject) => {
      const data = await db.syncpolls
        .where('[poll_id+date_finish]')
        .equals([id, today.toLocaleDateString()])
        .toArray();
      if (filters.length === 0) {
        resolve(data.length);
      } else {
        const result = await this.clearObject(data, filters);
        resolve(result.length);
      }
    });
  };

  async loadDataFull(id) {
    const today = new Date(Date.now());
    const totalClient = await db.clients.where({ poll_id: id }).count();
    const dataTableComple = await db.clientsCompleted
      .where({ poll_id: id })
      .count();

    const dataTableLocalComple = await db.syncpolls
      .where('[poll_id+status]')
      .anyOf([
        [id, 'completada'],
        [id, 'closed'],
        [id, 'sync-completed'],
      ])
      .count();

    const totalTodaySync = await this.dayTodaySync(id, today, []);

    const totalTodayLocalSync = await this.totalTodayLocalSync(id, today, []);

    this.loadData({
      total: totalClient + dataTableComple,
      totalInit: await db.syncpolls
        .where('[poll_id+status]')
        .anyOf([[id, 'iniciada']])
        .count(),
      totalFinit: dataTableLocalComple + dataTableComple,
      totalToday: totalTodaySync + totalTodayLocalSync,
    });
  }

  async fetchStats() {
    const today = new Date(Date.now());

    // Init Offline Mode
    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      let id: any = this.poll.id.toString();
      id = parseInt(id);
      // return
      const itemFilterDb = JSON.parse(localStorage.getItem('filterPollData'));
      // db.stats.get(id).then((stats) => {
      //   console.log(stats.data);
      //   // this.loadData(stats.data);
      // });
      // if (itemFilterDb !== null) {
      //   if (itemFilterDb.length === 0) {
      //     console.log('itemFilterDb');
      //     this.loadDataFull(id);
      //   } else {
      //     console.log('203');
      //     this.generateFilter(id, itemFilterDb).then(async (dataFilter) => {
      //       console.log(dataFilter);

      //       const totalTodaySync = await this.dayTodaySync(
      //         id,
      //         today,
      //         dataFilter
      //       );
      //       console.log('totalTodaySync ', totalTodaySync);
      //       const totalTodayLocalSync = await this.totalTodayLocalSync(
      //         id,
      //         today,
      //         dataFilter
      //       );
      //       console.log('totalTodayLocalSync ', totalTodayLocalSync);

      //       const statsDataComplete: any = await db.clientsCompleted
      //         .where('[poll_id+descr_vendedor]')
      //         .equals([id, dataFilter.value])
      //         .toArray();

      //       const statsData: any = await db.clients
      //         .where({
      //           poll_id: id,
      //           descr_vendedor: dataFilter.value,
      //         })
      //         .toArray();

      //       const statsCompleted: any = await db.syncpolls
      //         .where('[poll_id+status]')
      //         .anyOf([
      //           [id, 'completada'],
      //           [id, 'closed'],
      //           [id, 'sync-completed'],
      //         ]);

      //       const statsTotal = statsDataComplete.length + statsData.length;

      //       // console.log(statsCompleted);
      //       const statsInit: any = await db.syncpolls
      //         .where('[poll_id+status]')
      //         .equals([this.poll.id, 'iniciada']);

      //       const dataFinish = await statsCompleted
      //         .filter((data) => {
      //           if (
      //             dataFilter.index != undefined &&
      //             dataFilter.index != undefined
      //           ) {
      //             return data.client[dataFilter.index] === dataFilter.value;
      //           } else {
      //             return;
      //           }
      //         })
      //         .toArray();
      //       const dataInit = await statsInit
      //         .filter((data) => {
      //           if (
      //             dataFilter.index != undefined &&
      //             dataFilter.index != undefined
      //           ) {
      //             return data.client[dataFilter.index] === dataFilter.value;
      //           } else {
      //             return;
      //           }
      //         })
      //         .toArray();
      //       this.loadData({
      //         total: statsTotal,
      //         totalInit: dataInit.length,
      //         totalFinit: dataFinish.length + statsDataComplete.length,
      //         totalToday: totalTodaySync + totalTodayLocalSync,
      //       });
      //     });
      //   }
      // } else {
      //   console.log('265');
      //   this.loadDataFull(id);
      // }
      const idStats = `${this.poll.id}-${this.user.id}`;
      db.statsPolls.get(idStats).then(async (statsData) => {
        this.loadData(statsData);
      });
      return;
    }
    // End Offline Mode

    this.statsService.getStatsByPoll(this.poll.id).subscribe((data) => {
      this.loadData(data);
    });
  }

  async loadData(data) {
    const totalInit = await this.offlineService.getSyncPollStatsInit(
      this.poll.id,
      this.user.id
    );
    this.single = [
      {
        name: 'Completados',
        value: data.totalFinit,
      },
      {
        name: 'Pendiente',
        value: data.total - data.totalFinit - data.totalInit,
      },
      {
        name: 'Pausados',
        value: totalInit.length,
      },
    ];
    this.totalPoll = data.total;
    this.missingPolls = data.total - data.totalFinit;
    this.totalToday = data.totalToday;
    if (data.totalFinit > 0) {
      this.dailyGoal = Math.round(
        (+this.totalPoll - data.totalFinit) / +this.remain
      );
    } else {
      this.dailyGoal = Math.round(+this.totalPoll / +this.remain);
    }

    this.percentage = (data.totalFinit * 100) / data.total;
    this.advancePerDay = data.total / +this.diffToday;
    this.advancePerSuccesDay = data.totalFinit / +this.advanceDays;
    if (isNaN(this.advancePerSuccesDay)) {
      this.advancePerSuccesDay = 0;
    }
    this.projectedProgressFinish =
      Math.round((+this.advancePerSuccesDay * 100) / +this.advancePerDay) > 100
        ? 100
        : Math.round((+this.advancePerSuccesDay * 100) / +this.advancePerDay);
    this.spinner.hide();
  }

  DaysBetween(StartDate, EndDate) {
    let startDate = new Date(StartDate);
    let endDate = new Date(EndDate);

    return Math.floor(
      (Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
        Date.UTC(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        )) /
        (1000 * 60 * 60 * 24)
    );
  }

  aplyPoll() {
    // this.router.navigate(['/poll/client', { id: this.poll.id }]);
    this.router.navigate(['/poll/client'], {
      queryParams: { id: this.poll.id },
    });
  }
  getSelected(value: any) {
    this.closeNav();
  }
  closeNav() {
    this.sidenav.close();
  }
  refreshForFilter() {
    this.fetchStats();
  }
  returnList() {
    // this.router.navigate(['/poll/client', { id: this.poll.id }]);
    this.router.navigate(['/poll'], {});
  }
  public throwTestError2(): void {
    throw new Error('U-ddddddd  ' + new Date());
  }
}
