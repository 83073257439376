import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment, dev, sentry } from './environments/environment';
Sentry.init({
  dsn: sentry.dsn,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: dev.env,
  // debug: true,
  //   beforeSend: (event, hint) => {
  //     const error = hint.originalException
  //     if (error?.name === 'ChunkLoadError') {
  //       log.info('Sentry sees ChunkLoadError')
  //     } else {
  //       Sentry.showReportDialog({
  //         eventId: event.event_id,
  //         title: 'Something went wrong.',
  //         subtitle: 'It might be your internet connection. Please tell what you did before this happened.',
  //         successMessage: 'Your feedback has been sent. Thank you!'
  //       })
  //     }
  //   },
});
Sentry.showReportDialog({
  title: 'Report Issue',
  subtitle: 'Please provide details about the issue you encountered.',
  labelName: 'Name',
  labelEmail: 'Email',
  labelComments: 'Comments',
  labelClose: 'Close',
  labelSubmit: 'Submit',
});
// Sentry.init({
//   dsn: env('SENTRY_DSN'),
//   release: `perseus-pwa@${env('VERSION')}`,
//   environment: env('ENV'),
//   beforeSend: (event, hint) => {
//     const error = hint.originalException
//     if (error?.name === 'ChunkLoadError') {
//       log.info('Sentry sees ChunkLoadError')
//     } else {
//       Sentry.showReportDialog({
//         eventId: event.event_id,
//         title: 'Something went wrong.',
//         subtitle: 'It might be your internet connection. Please tell what you did before this happened.',
//         successMessage: 'Your feedback has been sent. Thank you!'
//       })
//     }
//   },
//   integrations: [
//     new Integrations.BrowserTracing()
//   ],
//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: env('ENV') === 'production' && 1.0
// })

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => console.log(`Bootstrap success`))
  .catch((err) => console.error(err));
