// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  firebaseConfig: {apiKey:"AIzaSyAIPa0jIgFTKX9LkTHJ8Wv3-fXi4Qx0BZk",authDomain:"ccusa-090321000-mis-arg-dev.firebaseapp.com",projectId:"ccusa-090321000-mis-arg-dev",storageBucket:"ccusa-090321000-mis-arg-dev.appspot.com",messagingSenderId:"792267138725",appId:"1:792267138725:web:4bf7e0633c250655480f4c",measurementId:"G-4RYZF8F28V"},
  vapidKey: 'FIREBASE_CREDENTIALS_API_MESSASING',
};

const base = {
  api: 'http://34.98.94.180/api/v1',
};

export const auth = {
  login: base.api + '/login',
  googleLoginProvider:
    '792267138725-34cajea1i077raomo3v0nb11k942v9el.apps.googleusercontent.com',
  urlConfig: 'https://api-config-bke.endpoints.ccusa-090321000-mis-arg-dev.cloud.goog/api/v1',
  urlB2B: 'http://35.237.235.136:5000',
  urlBase: 'https://api-config-bke.endpoints.ccusa-090321000-mis-arg-dev.cloud.goog/api',
};

export const gcp = {
  ApiKey: 'AIzaSyCp81F9owkqw_zeJaLvkGdfZZI43crTaW4',
};

export const client = {
  list: base.api + '/clientsBySeller',
  resume: base.api + '/client',
  countInfo: base.api + '/countBySeller',
  dailyAdvance: base.api + '/dailyAdvance',
  getClient: base.api + '/getClient',
};

export const poll = {
  question: base.api + '/question',
  initPoll: base.api + '/initPoll',
  updatePoll: base.api + '/updatePoll',
  importantQuestions: base.api + '/importantQuestions',
  finalizePoll: base.api + '/finalizePoll',
  restartPoll: base.api + '/restartPoll',
  editQuestion: base.api + '/editQuestion',
};

export const seller = {
  selection: base.api + '/sellersByOperation',
};

export const sw = {
  serviceWorkerScript: 'sw-master.js',
};
export const dev = {
  env: 'develop',
};
export const sentry = {
  dsn: 'https://73d8f7672f894e20a3cd0fe36274c921@o4505115915583488.ingest.sentry.io/4505115919450112',
};
