import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSidenav } from '@angular/material/sidenav';
import { PollModel } from '../../models/poll.model';
import { StatsService } from '../../services/stats.service';
import * as jwt_decode from 'jwt-decode';
// Dexie DB
import { db } from 'src/app/db/db';
import * as Sentry from '@sentry/angular-ivy';

//Dashboard

import {
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexMarkers,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  ApexStroke,
} from 'ng-apexcharts';
import { OfflineService } from '../../services/offline.service';
import { UserModel } from '../../models/user.model';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: any; //ApexChart;
  legend: any; //ApexChart;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  grid: any; //ApexGrid;
  colors: any;
  toolbar: any;
};
@Component({
  selector: 'app-dashboard-poll-goals',
  templateUrl: './dashboard-poll-goals.component.html',
  styleUrls: ['./dashboard-poll-goals.component.css'],
})
export class DashboardPollGoalsComponent implements OnInit {
  public chartPollPeriod: Partial<ChartOptions>;
  view: any[] = [250, 320];
  user: UserModel;
  single = [];

  colorScheme = {
    domain: ['#205C40', '#64A70B', '#C7B42C'],
  };
  // options

  poll: PollModel;
  initDate: string;
  endDate: string;
  remain: number = 0;
  missingPolls: number;
  dailyGoal: number = 0;
  percentage: number = 0;
  totalToday: number = 0;
  totalDays: number = 0;
  diffToday: number = 0;
  totalPoll: number = 0;
  advanceDays: number = 0;
  totalFinish: number = 0;
  advancePerSuccesDay: number = 0;
  advancePerDay: number = 0;
  projectedProgressFinish: number = 0;
  isOfflineMode: boolean = false;
  offlineMode: any = {
    status: false,
    from: 'nonde',
  };
  monthLabel: string = '';
  goalLabel: string = '';
  yearLabel: any = new Date().getFullYear();
  pollByDay: any = {
    counts: [],
    series: [],
  };
  average: any = 0;
  projection: any = 0;
  months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  goal = [
    '---',
    'Unica',
    'Diaria',
    'Semanal',
    'Quincenal',
    'Mensual',
    'Mensual',
    'Mensual',
    'Mensual',
    'Mensual',
  ];
  @ViewChild(MatSidenav) sidenav!: MatSidenav;

  date = new Date();
  today =
    this.date.getFullYear() +
    '-' +
    (this.date.getMonth() + 1) +
    '-' +
    this.date.getDate();

  constructor(
    private router: Router,
    private statsService: StatsService,
    private spinner: NgxSpinnerService,
    private offlineService: OfflineService
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
    this.monthLabel = this.months[this.date.getMonth()];

    this.poll = JSON.parse(localStorage.getItem('poll'));

    this.view = [720, 355];

    this.diffToday = this.DaysBetween(
      this.poll.publish_date,
      this.poll.expire_date
    );

    this.advanceDays = this.DaysBetween(this.poll.publish_date, new Date());
    this.single = [
      {
        name: 'Completados',
        value: 0,
      },
      {
        name: 'Pendiente',
        value: 0,
      },
    ];
    Sentry.configureScope((scope) => {
      scope.setUser({
        email: this.user.email,
      });
      scope.setTags({
        email: this.user.email,
      });

      scope.setTags({
        poll: this.poll.name + '(ID:' + this.poll.id + ')',
      });
      Sentry.setContext('Encuesta', {
        name: this.poll.name,
        id: this.poll.id,
      });
    });
  }

  ngOnInit(): void {
    // this.isOfflineMode = false;
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        if (offlineHeader) {
          this.offlineMode = offlineHeader;
        }
        this.spinner.show();
        this.fetchStats();
      });
  }

  async generateFilter(id, filters) {
    return new Promise<any>(async (resolve, reject) => {
      var filtersOut: any = {};
      var filtersString = `{poll_id:${id},`;
      let count = 0;
      filters.forEach((filter) => {
        count += 1;
        switch (filter.source) {
          case 'ven':
            filtersOut.index = `descr_vendedor`;
            filtersOut.value = filter.name;
            filtersString += `client.descr_vendedor: "${filter.name}",`;
            break;
          default:
            break;
        }
        if (count == filters.length) {
          resolve(filtersOut);
        }
      });
    });
  }

  multiFilter = async (array, filters) => {
    return new Promise<any>(async (resolve, reject) => {
      const DataFilter = array.filter((o) =>
        Object.keys(filters).every((k) =>
          [].concat(filters[k]).some((v) => o[k].includes(v))
        )
      );
      resolve(DataFilter);
    });
  };

  clearObject = async (data, filter) => {
    return new Promise<any>(async (resolve) => {
      const result = data
        .map((p) => {
          if (p[filter.index] === filter.value) {
            return p;
          }
        })
        .filter((element) => {
          return element !== undefined;
        });
      resolve(result);
    });
  };
  // Para buscar data ya sincronizada
  dayTodaySync = async (id, today, filters) => {
    return new Promise<any>(async (resolve, reject) => {
      const data = await db.clientsCompleted
        .where('[poll_id+date_finish]')
        .equals([id, today.toLocaleDateString()])
        .toArray();
      if (filters.length === 0) {
        resolve(data.length);
      } else {
        const result = await this.clearObject(data, filters);

        resolve(result.length);
      }
    });
  };

  totalTodayLocalSync = async (id, today, filters) => {
    return new Promise<any>(async (resolve, reject) => {
      const data = await db.syncpolls
        .where('[poll_id+date_finish]')
        .equals([id, today.toLocaleDateString()])
        .toArray();
      if (filters.length === 0) {
        resolve(data.length);
      } else {
        const result = await this.clearObject(data, filters);
        resolve(result.length);
      }
    });
  };

  async loadDataFull(id) {
    const today = new Date(Date.now());
    const totalClient = await db.clients.where({ poll_id: id }).count();
    const dataTableComple = await db.clientsCompleted
      .where({ poll_id: id })
      .count();

    const dataTableLocalComple = await db.syncpolls
      .where('[poll_id+status]')
      .anyOf([
        [id, 'completada'],
        [id, 'closed'],
        [id, 'sync-completed'],
      ])
      .count();

    const totalTodaySync = await this.dayTodaySync(id, today, []);

    const totalTodayLocalSync = await this.totalTodayLocalSync(id, today, []);

    this.loadData({
      total: totalClient + dataTableComple,
      totalInit: await db.syncpolls
        .where('[poll_id+status]')
        .anyOf([[id, 'iniciada']])
        .count(),
      totalFinit: dataTableLocalComple + dataTableComple,
      totalToday: totalTodaySync + totalTodayLocalSync,
    });
  }

  async fetchStats() {
    this.goalLabel = 'Meta ' + this.goal[this.poll.measuring_period];
    const today = new Date(Date.now());

    // Init Offline Mode
    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      const idStats = `${this.poll.id}-${this.user.id}`;
      db.statsPolls.get(idStats).then(async (statsData) => {
        this.loadData(statsData);
        this.loadDataDaily(statsData);
      });
      return;
    }

    this.statsService
      .getStatsByPollPeriodGoals(this.poll.id, [])
      .subscribe((data) => {
        this.loadData(data);
        this.loadDataDaily(data);
      });
  }

  loadData(data) {
    this.single = [
      {
        name: 'Completados',
        value: data.totalFinit,
      },
      {
        name: 'Pendiente',
        value:
          data.totalFinit > data.totalGoals
            ? 0
            : data.totalGoals - data.totalFinit - data.totalInit,
      },
    ];

    this.initDate = moment(data.initDate).format('DD/MM');
    this.endDate = moment(data.endDate).format('DD/MM');
    this.remain = data.daysremaining;
    this.advanceDays = data.daysElapsed;
    this.totalToday = data.totalToday;
    this.totalFinish = data.total;

    this.totalPoll = data.totalGoals;
    this.missingPolls = data.total - data.totalFinit;
    this.totalDays = data.totalDays;

    const totalDayObject = data.pollByDay.filter(
      (obj) => obj.day === moment().format('YYYY-MM-DD')
    )[0];

    if (totalDayObject !== undefined) {
      this.totalToday = totalDayObject.count;
    } else {
      this.totalToday = 0;
    }
    if (data.totalFinit > 0) {
      this.dailyGoal = Math.round(
        (+this.totalPoll - data.totalFinit) / +this.remain
      );
    } else {
      this.dailyGoal = Math.round(+this.totalPoll / +this.remain);
    }

    this.percentage = (data.totalFinit * 100) / data.totalGoals;
    this.advancePerDay = data.totalGoals / +this.diffToday;
    this.advancePerSuccesDay = data.totalFinit / +this.advanceDays;
    if (isNaN(this.advancePerSuccesDay)) {
      this.advancePerSuccesDay = 0;
    }

    this.projectedProgressFinish =
      Math.round((+this.advancePerSuccesDay * 100) / +this.advancePerDay) > 100
        ? 100
        : Math.round((+this.advancePerSuccesDay * 100) / +this.advancePerDay);

    this.spinner.hide();
  }

  DaysBetween(StartDate, EndDate) {
    let startDate = new Date(StartDate);
    let endDate = new Date(EndDate);

    return Math.floor(
      (Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
        Date.UTC(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        )) /
        (1000 * 60 * 60 * 24)
    );
  }

  aplyPoll() {
    this.router.navigate(['/poll/client'], {
      queryParams: { id: this.poll.id },
    });
  }
  getSelected(value: any) {
    this.closeNav();
  }
  closeNav() {
    this.sidenav.close();
  }

  refreshForFilter() {
    this.fetchStats();
  }

  public initCharts(): void {
    this.chartPollPeriod = {
      series: [
        {
          name: 'chart2',
          data: this.pollByDay.series,
        },
      ],
      xaxis: {
        type: 'datetime',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      markers: {
        size: 6,
        hover: {
          size: 10,
        },
      },
      tooltip: {
        followCursor: false,
        theme: 'dark',
        x: {
          show: false,
        },
        marker: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return '';
            },
          },
        },
      },
      grid: {
        clipMarkers: false,
      },
      legend: {
        show: false,
      },
      chart: {
        id: 'tw',
        group: 'social',
        type: 'line',
        width: '100%',
        height: 160,
        toolbar: {
          show: false,
        },
        offsetX: 0,
        // sparkline: {
        //   enabled: true,
        // },
      },
      colors: ['#205c40'],
      yaxis: {
        tickAmount: 2,
        labels: {
          minWidth: 40,
        },
      },
    };
    this.spinner.hide();
  }
  loadDataDaily(data) {
    this.pollByDay.counts = [];
    this.pollByDay.series = [];
    const dataChart = data.pollByDay.map((p) => parseInt(p.count));
    const categories = data.pollByDay.map((p) =>
      moment(p.day).format('DD-MMM')
    );
    const initDateNumber: number = parseInt(moment(data.initDate).format('DD'));
    const endDateNumber: number = parseInt(moment(data.endDate).format('DD'));
    this.initDate = moment(data.initDate).format('DD/MM');
    this.endDate = moment(data.endDate).format('DD/MM');

    const month = moment(data.endDate).format('MMM YYYY');
    const year = moment(data.endDate).format('YYYY');

    var fecha1 = moment('2022-03-22');
    var fecha2 = moment('2022-03-25');
    var diferencia = fecha2.diff(fecha1, 'days');

    const totalDays = moment(data.endDate).diff(moment(data.initDate), 'days');
    let index = -1;
    let monthP: any = '';
    let countP: any = '';
    for (let i = initDateNumber; i <= endDateNumber; i++) {
      index = categories.findIndex((d) => {
        return parseInt(moment(new Date(d)).format('DD')) === i;
      });
      monthP =
        index === -1
          ? i < 10
            ? `0${i}-${month}`
            : `${i}-${month}`
          : categories[index] + ' ' + year;
      monthP = new Date(monthP);
      countP = index === -1 ? 0 : dataChart[index];
      this.pollByDay.counts.push(countP);
      this.pollByDay.series.push([monthP, countP]);
    }
    this.initCharts();

    this.totalFinish = this.pollByDay.counts.reduce((acc, count) => {
      return acc + count;
    }, 0);
  }
  returnList() {
    // this.router.navigate(['/poll/client', { id: this.poll.id }]);
    this.router.navigate(['/poll'], {});
  }
  public throwTestError2(): void {
    throw new Error('G-ddddddd  ' + new Date());
  }
}
