<div fxLayout="row">
  <div fxFlex="100">
    <div fxLayout="column">
      <mat-divider class="mt-divider"></mat-divider>
    </div>
    <div fxLatout="row" class="mt">
      <div
        fxFlex=""
        *ngFor="let section of sections"
        fxLayoutAlign="center center"
      >
        <div
          fxLayoutAlign="center center"
          class="
          {{ this.sectionCompare > section.number ? 'number' : '' }}
          {{ this.sectionCompare < section.number ? 'number-blocked' : '' }}
          {{ this.sectionCompare === section.number ? 'number-selected' : '' }}"
        >
          <span class="span-number">{{ section.number }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div fxLayout="column"></div>
