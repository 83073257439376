<div fxLayoutAlign="center center" fxLayout="column">
  <!-- https://codepen.io/aaronvanston/pen/yNYOXR -->
  <div class="file-upload">
    <button
      class="file-upload-btn"
      id="upload-btn"
      type="button"
      (click)="callSelectFile()"
    >
      {{ question.questionoptions[0].title }}
    </button>

    <div
      class="image-upload-wrap"
      id="image-upload-wrap"
      (click)="callSelectFile()"
    >
      <input
        multiple
        class="file-upload-input"
        type="file"
        (change)="selectFile($event)"
        accept="image/*"
        id="control-input-file"
        #FileInputQuestion
        *ngIf="question.only_phone === false"
      />
      <input
        multiple
        class="file-upload-input"
        type="file"
        (change)="selectFile($event)"
        accept="image/*"
        id="control-input-file"
        #FileInputQuestion
        capture="'camera'"
        *ngIf="question.only_phone === true"
      />

      <div class="drag-text">
        <div class="load-file-button-container">
          <button mat-fab color="primary">
            <mat-icon>camera_alt</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="file-upload-content" id="file-upload-content">
      <mat-expansion-panel hideToggle [expanded]="true" class="panel-of-img">
        <img
          class="file-upload-image"
          id="image-question"
          [attr.lazyLoad]="itemImageUrlDefault"
          onerror="this.src='assets/images/1.png'"
        />
        <div class="warnig-exits-file" id="warnig-exits-file">
          <i class="fa fa-info-circle"></i>
          <strong> Advertencia: </strong> Al seleccionar una nueva foto; la foto
          actual será reemplazada al momento de avanzar a la siguiente pregunta.
        </div>
        <div class="image-title-wrap">
          <button type="button" (click)="replaceImage()" class="remove-image">
            Reemplazar <span class="image-title">Imagen</span>
          </button>
          <br />
          <button
            type="button"
            (click)="removeUpload()"
            class="replace-image"
            *ngIf="question.required === false"
          >
            Remover <span class="image-title">Imagen</span>
          </button>
        </div>

        <ngx-spinner
          name="sp6"
          bdColor="rgba(0, 0, 0, 0.8)"
          size="medium"
          color="#fff"
          type="square-loader"
          [fullScreen]="false"
        >
          <p style="color: white">Cargando imagen...</p>
        </ngx-spinner>
      </mat-expansion-panel>
    </div>
  </div>
</div>
