import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-question-text-view',
  templateUrl: './question-text-view.component.html',
  styleUrls: ['./question-text-view.component.css'],
})
export class QuestionTextViewComponent {
  @Input() question_1: any;
  @Input() responseTask_1: any;
  @Input() question_2: any;
  @Input() subTitle: string;
  @Input() allowEdit: boolean = true;

  constructor() {}
}
