import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
// import { QuestionsService  } from 'src/app/questions/components/questions.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css'],
})
export class DialogComponent {
  // Status = 0: Success, 1: Warning, 2: Error

  message: String;
  status: number;
  route: String;
  confirm: Boolean;
  codClient: String;
  codSeller: String;
  onlyClose: Boolean;
  noOneButton: Boolean;
  constructor(
    public dialog: MatDialog,
    // private questionService: QuestionsService,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.message = data.message;
    this.status = data.status;
    this.route = data.route;
    this.confirm = data.confirm;
    this.codClient = data.codClient;
    this.codSeller = data.codSeller;
    this.onlyClose = data.onlyClose;
    this.noOneButton = data.noOneButton || false;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  // Save seller to local storage - user logued
  saveLocalStorage(seller: any) {
    localStorage.setItem('seller', JSON.stringify(seller));
  }

  restartPoll() {
    // this.questionService.restartPoll(this.codClient, this.codSeller);
    this.closeDialog();
  }
}
