import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ImagesService } from '../../services/images.service';
import { OfflineService } from '../../services/offline.service';
import * as jwt_decode from 'jwt-decode';
import { UserModel } from '../../models/user.model';

@Component({
  selector: 'app-dialog-image-help',
  templateUrl: './dialog-image-help.component.html',
  styleUrls: ['./dialog-image-help.component.css'],
})
export class DialogImageHelpComponent implements OnInit {
  image: string;
  message: string;
  images: string;
  imageGcp: string;
  imageGcpSubject: Subject<string> = new Subject<string>();
  offlineMode: any = {
    status: false,
    from: 'none',
  };

  itemImageUrlDefault = '/assets/images/questions/poll.png';
  user: UserModel;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) data,
    private imagesService: ImagesService,
    private offlineService: OfflineService,
    private spinner: NgxSpinnerService
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
    this.image = data.image;
    this.message = data.message;
    this.imageGcpSubject.subscribe((imageGcp) => {
      this.imageGcp = imageGcp;
      this.spinner.hide();
    });
  }

  ngOnInit(): void {
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        if (offlineHeader) {
          this.offlineMode = offlineHeader;
        }
        if (this.image.length > 0) {
          if (this.offlineMode.status && this.offlineMode.from === 'clients') {
            this.imageGcpSubject.next(this.image);
            return;
          }
          this.imagesService
            .getImageHelpQuestion(this.image)
            .subscribe((data) => {
              this.spinner.show();
              this.image = data.url;
              this.imageGcpSubject.next(data.url);
            });
        }
      });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
