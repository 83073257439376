import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ApplicationRef,
  OnInit,
} from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { Categories } from '../../clients/clients.component';
import { ClientsService } from '../../../services/clients.service';
import { OfflineService } from '../../../services/offline.service';
import { UserModel } from '../../../models/user.model';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

import {
  MatListOption,
  MatSelectionList,
  MatSelectionListChange,
} from '@angular/material/list';
import { Observable, from, of } from 'rxjs';
import { catchError, concatMap, map, startWith } from 'rxjs/operators';
import { Category } from '../../../models/category.models';
import Swal from 'sweetalert2';
import { formatDate } from '@angular/common';
import { StatsService } from 'src/app/polls/services/stats.service';
import { Network } from '@ngx-pwa/offline';

// No Sleep
import NoSleep from 'nosleep.js';
import { seller } from '../../../../../environments/environment.develop';

@Component({
  selector: 'app-select-route',
  templateUrl: './select-route.component.html',
  styleUrls: ['./select-route.component.scss'],
})
export class SelectRouteComponent implements OnInit {
  @Output() closeNavSelectRouteEvent = new EventEmitter();
  @Output() setListOptionsVendedor = new EventEmitter();

  @Output() progressTextChange = new EventEmitter<string>();
  @Output() progressChange = new EventEmitter<number>();
  @Output() progressSpinnerChange = new EventEmitter<boolean>();

  @ViewChild('vendedorInput') vendedorInput: ElementRef;
  @ViewChild('VendedorTarget') VendedorTarget: MatSelectionList;
  @ViewChildren('optionsVendedores')
  optionsVendedores: QueryList<MatListOption>;
  user: UserModel;
  private nosleep = new NoSleep();
  vendedorCtrl = new FormControl();
  filteredVendedores: Observable<string[]>;
  allVendedor: Category[] = [];
  searchPend: string = '';
  progressText: string = 'Descargando Clientes';
  progressValue: number = 0;

  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  itemsFilter: any[] = [];
  sellers: any[] = [];
  sellersList: any[] = [];
  compareFunction = (o1: any, o2: any) =>
    o1.code?.toLowerCase() === o2.code?.toLowerCase();
  listOptionsVendedor: Category[] = [];
  selectedOptionsVendedor: Category[] = [];
  selectOption: boolean = true;
  selectAll: boolean = false;
  selectBySelle: boolean = false;

  selected: [] = [];

  firstTime: boolean = true;

  syncOptionSelected: boolean | undefined;
  syncOptions = [
    { value: false, viewValue: 'Sincronizar todos clientes' },
    { value: true, viewValue: 'Sincronizar por vendedor' },
  ];

  online$ = this.network.onlineChanges;
  isOnline: boolean = true;

  constructor(
    private clientsService: ClientsService,
    private offlineService: OfflineService,
    private statsService: StatsService,
    private analytics: AngularFireAnalytics,
    private appRef: ApplicationRef,
    protected network: Network
  ) {
    this.filteredVendedores = this.vendedorCtrl.valueChanges.pipe(
      startWith(null),
      map((nivel: string | null) =>
        nivel ? this._filterVendedor(nivel) : this.allVendedor.slice()
      )
    );
    this.fetchCategoryTargetFilter([]);
    this.user = jwt_decode(localStorage.getItem('token')).user;

    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then(async (offlineHeader) => {
        this.firstTime = offlineHeader ? false : true;
      });
  }
  ngOnInit() {
    if (this.firstTime) {
      this.offlineService.loadOfflineHeader().then(() => {
        this.offlineService
          .getOfflineHeader(1, this.user.id)
          .then((offlineHeader) => {
            if (offlineHeader?.sellers?.length > 0) {
              this.selectedOptionsVendedor = offlineHeader.sellers;
              this.listOptionsVendedor = offlineHeader.sellers;
              this.syncOptionSelected = true;
            }
            if (offlineHeader?.sellers?.length === 0) {
              this.syncOptionSelected = false;
            }
          });
      });
    }
    this.isOnline = localStorage.getItem('statusConnection') === 'true';
  }

  selectAllVendedor(event) {
    this.syncOptionSelected = event.value;
  }

  closeNav({ deleteSelected = false }) {
    this.closeNavSelectRouteEvent.emit({ deleteSelected });
  }

  showSelectOption() {
    this.selectAll = false;
    this.selectBySelle = false;
    this.selectOption = true;
  }

  showSelectAll() {
    this.selectBySelle = false;
    this.selectOption = false;
    this.selectAll = true;
  }

  showSelectBySelle() {
    this.listOptionsVendedor = [];
    this.selectedOptionsVendedor = [];
    this.selectOption = false;
    this.selectAll = false;
    this.selectBySelle = true;
  }

  async fetchCategoryTargetFilter(categories: Categories[]) {
    // Init Offline Mode
    // this.spinner.show('loading');
    // if (this.isOfflineMode) {
    // }
    // End Offline Mode
    this.clientsService.getCustomerCategories('ven').subscribe((sellers) => {
      this.sellers = sellers;
      this.sellersList = sellers.values;
      this.loadSellersTargetFilter(sellers);
    });
  }

  onListSelectionChange(ob: MatSelectionListChange) {
    const valueItemFilter = ob.options[0]['_value'];
    const index = this.listOptionsVendedor.findIndex(
      (lo) => lo.code?.toLowerCase() === valueItemFilter.code?.toLowerCase()
    );
    index === -1
      ? this.listOptionsVendedor.push(valueItemFilter)
      : this.listOptionsVendedor.splice(index, 1);
    setTimeout(() => {
      this.selectedOptionsVendedor = this.listOptionsVendedor;
    }, 100);
  }

  loadSellersTargetFilter(sellers) {
    sellers.values.length > 0 ? (this.allVendedor = sellers.values) : [];
  }

  private _filterVendedor(value: any): any[] {
    let searchValue = '';

    typeof value === 'string'
      ? (searchValue = value)
      : (searchValue = value.name);
    return this.allVendedor.filter((vendedor) => {
      return (
        vendedor.name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        vendedor.code?.toLowerCase().includes(searchValue?.toLowerCase())
      );
    });
  }

  searchOnFilterSellers(stringSearch) {
    this.allVendedor = this.sellersList.filter((vendedor) => {
      return (
        vendedor.name?.toLowerCase().includes(stringSearch?.toLowerCase()) ||
        vendedor.code?.toLowerCase().includes(stringSearch?.toLowerCase())
      );
    });
    setTimeout(() => {
      this.selectedOptionsVendedor = this.listOptionsVendedor;
    }, 100);
  }

  syncAll() {
    if (!(localStorage.getItem('statusConnection') === 'true')) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Debe tener conexión a internet para sincronizar.',
      });
      return;
    }
    this.listOptionsVendedor = [];
    this.selectedOptionsVendedor = [];
    this.applySellersSelected(true);
  }

  async applySellersSelected(syncAll = false) {
    if (!(localStorage.getItem('statusConnection') === 'true')) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Debe tener conexión a internet para sincronizar.',
      });
      return;
    }
    if (this.listOptionsVendedor.length === 0 && syncAll === false) {
      Swal.fire(
        'Seleccionar vendedor',
        'Por favor seleccione al menos un vendedor',
        'error'
      );
      return;
    }

    await this.offlineService.setOfflineHeader({
      id: 1,
      user_id: this.user.id,
      lastVerificationDateTime: new Date().toISOString(),
    });

    this.nosleep.enable();
    this.progressSpinnerChange.emit(true);
    this.appRef.tick();

    this.clientsService
      .getClientsAndPollsBySellers(this.listOptionsVendedor)
      .subscribe(async (data) => {
        this.analytics.logEvent(`activacion_sin_conexion`, {
          usuario: this.user.email,
          method: this.listOptionsVendedor.length == 0 ? 'all' : 'filter',
        });

        const clientsLimit = data.clients;
        // const clientsLimit = data.clients.splice(0, 300);

        this.progressValue = 10;
        this.progressText = 'Descargando SKUs';
        this.progressChange.emit(this.progressValue);
        this.progressTextChange.emit(this.progressText);
        const enterprises = JSON.parse(
          localStorage.getItem('enterprises')
        )?.map((e) => e.id);
        const customerSku = await this.clientsService
          .getAllSkus(enterprises)
          .toPromise();
        await this.offlineService.setCustomersSku(customerSku['customersSku']);

        this.progressValue = 20;
        this.progressText = 'Descargando Clientes';
        this.progressChange.emit(this.progressValue);
        this.progressTextChange.emit(this.progressText);

        await this.offlineService.setClients(clientsLimit);

        this.progressValue = 25;
        this.progressText = 'Descargando Polls';
        this.progressChange.emit(this.progressValue);
        this.progressTextChange.emit(this.progressText);

        let polls: any = await this.offlineService.getSectionsPoll(data.polls);

        await this.offlineService.setPolls(polls);

        this.progressValue = 25;
        this.progressText = 'Descargando Categorias';
        this.progressChange.emit(this.progressValue);
        this.progressTextChange.emit(this.progressText);

        await this.offlineService.downloadCategories(this.listOptionsVendedor);

        this.progressValue = 40;
        this.progressChange.emit(this.progressValue);
        this.progressTextChange.emit(this.progressText);

        await this.offlineService.clearPollStats();

        this.progressText = 'Descargando Estadisticas';

        const clientPolls = data.polls;

        from(clientPolls)
          .pipe(
            concatMap((poll: any) =>
              this.statsService
                .getStatsByPollPeriodGoals(poll.id, this.listOptionsVendedor)
                .pipe(
                  map((data) => ({
                    ...data,
                    user_id: this.user.id,
                    id: `${poll.id}-${this.user.id}`,
                  })),
                  catchError((error) => {
                    return of(null);
                  })
                )
            )
          )
          .subscribe(async (data) => {
            if (data) {
              await this.offlineService.setPollStats(data);
            }
          });

        await this.offlineService.setOfflineHeader({
          id: 1,
          user_id: this.user.id,
          date: formatDate(new Date(), 'yyyy/MM/dd', 'en'),
          number_clients: data.clients.length,
          number_polls: polls.length,
          status: true,
          mode: syncAll ? 'syncAll' : 'sellers',
          from: 'clients',
          sellers: this.listOptionsVendedor,
          dataFilters: {
            enterpriseId: null,
            filters: [],
            isFilter: false,
            keepFilter: false,
          },
          dataFiltersPoll: [],
        });

        this.setListOptionsVendedor.emit(this.listOptionsVendedor);
        this.progressValue = 100;
        this.progressText = 'Completado';
        this.progressChange.emit(this.progressValue);
        this.progressTextChange.emit(this.progressText);
        this.progressSpinnerChange.emit(false);
        this.listOptionsVendedor = [];
        this.selectedOptionsVendedor = [];
        this.showSelectOption();
        this.nosleep.disable();
        this.closeNav({ deleteSelected: true });
        this.progressValue = 0;
        this.progressChange.emit(this.progressValue);

        setTimeout(() => {
          this.appRef.tick();
        }, 500);
      });
  }
}
