<app-header
  [isOfflineMode]="offlineMode.status && offlineMode.from === 'clients'"
  [title]="this.sectionTitle"
  [img]="this.sectionImg"
  [actual_section]="1"
  [total_section]="this.totalSection + 1"
>
</app-header
><br /><br />
<app-header-user></app-header-user>
<div fxLayout="column" *ngIf="this.poll.showmessageinit === true">
  <app-bread-crumb
    [sectionCompare]="1"
    [sectionTotal]="this.totalSection + 1"
  ></app-bread-crumb
  ><br />
</div>

<div fxLayout="column" class="div-advance">
  <div fxLayout="row" fxLayoutAlign="center center">
    <p>{{ pollMessageInit?.title }}</p>
  </div>
  <div fxLayout="column">
    <!-- <div *ngIf="html" [innerHTML]="html"></div> -->
    <div *ngIf="html" [innerHtml]="html | noSanitize: 'html'"></div>
  </div>
</div>
<div fxLayout="row" class="btn-footer">
  <div fxFlex="40" fxLayoutAlign="start center">
    <button class="back-button" mat-button (click)="returnPoll()">
      Volver
    </button>
  </div>
  <div fxFlex="60" fxLayoutAlign="end center">
    <button class="next-button" mat-button (click)="initPoll()">
      Continuar
    </button>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="rgb(92, 184, 92)"
  type="ball-spin-fade"
>
  <p style="font-size: 20px; color: rgb(92, 184, 92)">Cargando...</p>
</ngx-spinner>
