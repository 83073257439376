import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PollModel } from '../polls/models/poll.model';
import { Authb2bService } from '../polls/services/authb2b.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-autlogin',
  templateUrl: './autlogin.component.html',
  styleUrls: ['./autlogin.component.css'],
})
export class AutloginComponent implements OnInit {
  codClient: string;
  pollToken: string;
  poll: PollModel;
  constructor(
    private route: ActivatedRoute,
    private authb2bService: Authb2bService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.codClient = params.codClient;
      this.pollToken = params.pollToken;
      this.fetchData();
    });
  }
  fetchData() {
    this.spinner.show('loading-polls');
    if (this.codClient !== undefined && this.pollToken !== undefined) {
      this.authb2bService
        .getClientByCodClient(this.codClient, this.pollToken)
        .subscribe((client) => {
          if (client === null) {
            this.dialog.open(DialogComponent, {
              data: {
                message: 'Parametro de Encuesta no validos!',
                status: 2,

                onlyClose: true,
              },
            });
            this.spinner.hide('loading-polls');
            // window.location.href = `${auth.urlB2B}`;
          } else {
            if (client.status === true) {
              this.dialog
                .open(DialogComponent, {
                  data: {
                    message: 'Encuesta ya se encuetra realizada!',
                    status: 2,

                    onlyClose: true,
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  this.router.navigate(['/poll/client'], {
                    queryParams: { id: this.poll.id, name: this.poll.name },
                  });
                });
            } else {
              localStorage.setItem('client', JSON.stringify(client));
            }
          }
        });
    }
    this.authb2bService.getPollByToken(this.pollToken).subscribe((poll) => {
      localStorage.removeItem('poll');
      localStorage.removeItem('idResponse');
      localStorage.removeItem('navHistory');
      if (poll != null) {
        this.poll = poll;
        if (poll.geolocation === true) {
          navigator.geolocation.getCurrentPosition((position) => {
            const coords = position.coords;
            const latLong = [coords.latitude, coords.longitude];
            localStorage.setItem('lat', coords.latitude.toString());
            localStorage.setItem('long', coords.longitude.toString());
          });
        }
        const dataPoll = {
          id: poll.id,
          name: poll.name,
          description: this.poll.description,
          sections: this.sortJSON(poll.sections, 'weight', 'asc'),
          messages: poll.messages,
          expireDate: poll.expire_date,
          publishDate: poll.publish_date,
          countquestion: poll.countquestion,
          geolocation: poll.geolocation,
          minutes: poll.minutes,
          infoextra: poll.infoextra,
          showmessageinit: poll.showmessageinit,
          infoextrapoll: poll.infoextrapoll,
          infoextracustomer: poll.infoextracustomer,
          addgoals: poll.addgoals,
          measuring_period: poll.measuring_period,
        };
        localStorage.setItem('poll', JSON.stringify(dataPoll));

        this.spinner.hide('loading-polls');
        if (this.poll.infoextra) {
          this.router.navigate(['poll/client/info']);
        } else {
          this.router.navigate(['poll/client/init']);
        }
      }
    });
  }
  sortJSON(data, key, orden) {
    return data.sort(function (a, b) {
      var x = a[key],
        y = b[key];

      if (orden === 'asc') {
        return x < y ? -1 : x > y ? 1 : 0;
      }

      if (orden === 'desc') {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  }
}
