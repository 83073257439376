import {
  Component,
  EventEmitter,
  OnInit,
  Inject,
  Output,
  ViewChild,
  HostListener,
  QueryList,
  ViewChildren,
  Input,
  ApplicationRef,
  ChangeDetectorRef,
  OnDestroy,
  Injector,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatAccordion } from '@angular/material/expansion';
import { DOCUMENT, formatDate } from '@angular/common';
import { ClientsService } from '../../services/clients.service';
import { OfflineService } from '../../services/offline.service';
import { ClientModel } from '../../models/client.model';
import { Subject } from 'rxjs';
import { PollsService } from '../../services/polls.service';
import { UserModel } from '../../models/user.model';
import { Router } from '@angular/router';
import { ResponseService } from '../../services/response.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import * as jwt_decode from 'jwt-decode';
import { PollModel } from '../../models/poll.model';
import { LocationService } from '../../services/location.service';
import { MatProgressBar } from '@angular/material/progress-bar';
import Swal from 'sweetalert2';
import { db } from 'src/app/db/db';
import { EnterpriseModel } from '../../models/enterprise';
import { debounceTime, finalize } from 'rxjs/operators';
import { IndicatorsService } from '../../services/indicators.service';
import { IndicatorModel } from '../../models/indicator.model';
import { SynchronizationService } from 'src/app/synchronization/services/synchronization.service';
import { auth, dev } from 'src/environments/environment';
import { Network } from '@ngx-pwa/offline';
import { MatTabGroup } from '@angular/material/tabs';
import { IndependentMapsComponent } from 'src/app/shared/components/independent-maps/independent-maps.component';
export interface matProgresBarModel {
  id: number;
  value: number;
}
@Component({
  selector: 'app-client-all',
  templateUrl: './client-all.component.html',
  styleUrls: ['./client-all.component.css'],
})
export class ClientAllComponent implements OnInit, OnDestroy {
  @Output() countClients = new EventEmitter();
  @Output() openNavSelectRouteEvent = new EventEmitter();
  @Output() closeNavSelectRouteEvent = new EventEmitter();
  @Output() runPollSync = new EventEmitter();
  @Input() offlineActive: boolean;
  @Output() openNavSelectFilterEvent = new EventEmitter();
  @ViewChild(MatAccordion) accordion: MatAccordion;
  // @ViewChild(MatProgressBar) clientMatProgressBar: QueryList<MatProgressBar>;
  @ViewChildren('clientMatProgressBar')
  clientMatProgressBar: QueryList<MatProgressBar>;
  timeout: any = null;
  selectedTabIndex: number = 0;
  error: any = '';
  searchCustomer: string = '';
  searchCustomerSubject: Subject<string> = new Subject();
  statusSearch: boolean = false;
  clients: ClientModel[] = [];
  clientsSubject: Subject<ClientModel[]> = new Subject<ClientModel[]>();
  currentPositionSubject: Subject<any> = new Subject<any>();
  centerPositionSubject: Subject<any> = new Subject<any>();
  typeCenterSubject: Subject<string> = new Subject<string>();
  pageNum = 1;
  maxpageNum = 0;
  progressUploadPollValue: number = 0;
  progressUploadPollText: string = 'Descargando respuestas';
  total: number = 0;
  numberFilters: number = 0;
  pageSize: number = 25;
  showButtonTop: boolean = false;
  isTpmFilter: boolean = false;
  isFilters: boolean = false;
  currentPosition: { lat: number; lng: number };
  isOfflineMode: boolean = false;
  offlineHeader: any;
  offlineSyncPollsCount: number = 0;
  syncPollsCompleted: Array<any> = [];
  syncPollsIncomplete: Array<any> = [];
  scrollCheck: boolean;
  scrollDisabled: boolean;
  displayMaps: boolean = true;
  throttle = 0;
  distance = 2;
  user: UserModel;
  poll: PollModel;
  bufferValue = 75;
  valuesMatProgresBar: matProgresBarModel[] = [];
  client: ClientModel;
  filterEnterpriseID: number;
  devenv: String;
  clientSelected: ClientModel;
  hasActivePolls: boolean;
  customerIndicators: IndicatorModel[] = [];
  showIndicators: boolean = false;
  private stopObservable: any;
  public activeInEnv =
    dev.env === 'local' || dev.env === 'develop' || dev.env === 'qa';
  online$ = this.network.onlineChanges;
  isOnline = false;
  isOnlineFlag: boolean;
  @ViewChild(IndependentMapsComponent)
  IndependentMapsComponent!: IndependentMapsComponent;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private clientsService: ClientsService,
    private offlineService: OfflineService,
    private spinner: NgxSpinnerService,
    private pollsService: PollsService,
    private clientService: ClientsService,
    private router: Router,
    private analytics: AngularFireAnalytics,
    private locationService: LocationService,
    private appRef: ApplicationRef,
    private changeRef: ChangeDetectorRef,
    private indicatorsService: IndicatorsService,
    private synchronizationService: SynchronizationService,
    protected network: Network
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
    this.synchronizationService.getStopObservable().subscribe(() => {
      this.runOfflineDeactive();
    });
  }
  @HostListener('window:scroll')
  onWindowScroll(): void {
    const yOffset = window.pageXOffset;
    const scrollTop = this.document.documentElement.scrollTop;
    this.showButtonTop = (yOffset || scrollTop) > 270;
  }
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.hideIndicators();
  }
  @HostListener('window:online', ['$event'])
  handleOnlineEvent() {
    this.runBackgroundSync('webworker');
  }
  async runBackgroundSync(option = 'backsynapi') {
    const API_URL = `${auth.urlConfig}`;
    const userId = localStorage.getItem('userId');
    await this.offlineService.setBackSyncHeader({
      id: 1,
      user: userId,
      token: localStorage.getItem('token'),
      status: 'running',
      api_url: API_URL,
    });
    if (option === 'webworker') {
      if (typeof Worker !== 'undefined') {
        const worker = new Worker(
          new URL('src/app/web-workers/background-sync.worker', import.meta.url)
        );
        worker.onmessage = ({ data }) => {};
        worker.postMessage({ isAllPolls: true });
      } else {
        console.log('Web workers are not supported in this environment.');
      }
    } else {
      await this.offlineService.runBackgroundSync();
    }
  }
  ngOnDestroy(): void {
    this.stopObservable?.unsubscribe();
  }
  ngOnInit(reset = false): void {
    this.onSearchClientChange();

    this.isOnline = localStorage.getItem('statusConnection') === 'true';
    this.hasActivePolls = true;
    this.isDisplayMaps();
    let kf = localStorage.getItem('keepFilterAllClients');
    let keepFilter = kf ? (kf === 'true' ? true : false) : false;

    if (keepFilter === false) {
      localStorage.removeItem('enterpriseFilterAllClients');
      localStorage.removeItem('filterClientsAllData');
    }
    this.spinner.show('loading-clients-all');
    this.offlineService.loadOfflineHeader().then(() => {
      this.offlineService
        .getOfflineHeader(1, this.user.id)
        .then((offlineHeader) => {
          if (reset) {
            this.clients = [];
            this.pageNum = 0;
          }
          this.offlineHeader = offlineHeader ? offlineHeader : null;
          this.isOfflineMode = offlineHeader ? offlineHeader.status : false;
          this.offlineActive = this.isOfflineMode;
          this.fetchClients({});
        });
    });
  }

  onSearchClientChange() {
    this.searchCustomerSubject
      .pipe(debounceTime(1000))
      .subscribe((searchText) => {
        if (this.isOfflineMode && searchText.length === 0) {
          this.fetchClients({ isTpmFilter: this.isTpmFilter });
        } else {
          this.pageNum = 1;
          this.clients = [];
          this.executeSearchg(searchText);
        }
      });
  }

  onSearchCustomerChange(searchText: string) {
    this.searchCustomerSubject.next(searchText);
  }

  checkOfflineActive() {
    this.offlineActive
      ? this.runOfflineDeactive()
      : this.openNavSelectRouteEvent.emit();
  }

  isPollEdit(edit) {
    // return this.isOfflineMode ? edit.split(',')[1] : edit;
    return edit;
  }

  runOfflineDeactive() {
    let message = 'Esta seguro(a) de realizar esta acción?';
    if (this.offlineHeader.statusSync === 'unsynchronized') {
      message =
        'Tienes encuestas sin sincronizar, se va proceder a sincronizar y luego a desactivar, esta seguro(a) de realizar esta acción?';
    }
    Swal.fire({
      title: 'Desactivación de modo offline',
      html: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (this.offlineHeader.statusSync === 'unsynchronized') {
          this.synchronizationService.nextSynchronizationPolls({
            deactiveOffline: true,
          });
          return;
        }
        this.deactiveOffline();
      }
    });
  }
  async deactiveOffline() {
    this.analytics.logEvent(`desactivacion_sin_conexion`, {
      usuario: this.user.email,
    });
    this.spinner.show('loading-clients');
    this.isFilters = false;
    this.numberFilters = 0;
    await this.offlineService.deactiveOffline();
    this.synchronizationService.nextStopIsRunning({
      status: false,
      step: '',
    });
    setTimeout(() => {
      this.ngOnInit(true);
    }, 100);
    localStorage.removeItem('filterClientsAllData');
    this.closeNavSelectRouteEvent.emit({ deleteSelected: true });
    this.appRef.tick();
  }

  loadSyncPollsCount() {
    this.offlineService.getSyncPolls().then(async (syncPolls) => {
      const resSyncPoll = syncPolls.filter(
        (sp) =>
          sp.status === 'unsynchronized-edit' ||
          sp.status === 'unsynchronized' ||
          sp.status === 'sync-responses-completed' ||
          sp.status === 'sync-close-response' ||
          sp.status === 'sync-completed'
      );
      this.offlineSyncPollsCount = resSyncPoll.length;
    });
  }

  async resetDB() {}

  fetchClientsFilter(enterprise: EnterpriseModel) {
    this.pageNum = 1;
    if (enterprise) {
      this.filterEnterpriseID = enterprise.id;
    }
    this.fetchClients({ isTpmFilter: true });
  }

  resetClients() {
    this.clients = [];
  }

  async fetchClients(
    { isTpmFilter = false, stringSearch = '' },
    isNewSearch = false
  ) {
    if (isNewSearch) this.pageNum = 1;
    this.isTpmFilter = isTpmFilter;
    this.scrollDisabled = true;
    this.spinner.show('loading-clients-all');
    this.statusSearch = true;
    // Init Offline
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then(async (offlineHeader) => {
        if (offlineHeader && offlineHeader.status) {
          this.loadSyncPollsCount();
          this.offlineHeader = offlineHeader ? offlineHeader : null;
          this.isFilters =
            offlineHeader.dataFilters.isFilter &&
            (offlineHeader.dataFilters.keepFilter || isTpmFilter);
          this.numberFilters =
            offlineHeader.dataFilters.filters.length > 0
              ? offlineHeader.dataFilters.filters.length
              : 0;
          const clients: any = await this.offlineService.getClients({
            pageSize: this.pageSize,
            pageNum: this.pageNum,
            isTpmFilter,
            stringSearch: this.searchCustomer,
          });
          this.scrollDisabled = false;
          this.clients = clients.length > 0 ? clients : [];
          this.scrollCheck = false;
          this.countClients.emit('...');

          const countClients: any = await this.offlineService.getClients({
            pageSize: this.pageSize,
            pageNum: this.pageNum,
            isTpmFilter,
            stringSearch: this.searchCustomer,
            count: true,
          });

          this.countClients.emit(countClients);
          return;
        }
        // End Offline
        this.numberFilters =
          localStorage.getItem('filterClientsAllData') &&
          JSON.parse(localStorage.getItem('filterClientsAllData')).length > 0
            ? JSON.parse(localStorage.getItem('filterClientsAllData')).length
            : 0;
        setTimeout(() => {
          this.clientsService
            .getClientsByUser(
              this.pageNum,
              this.pageSize,
              this.searchCustomer,
              this.currentPosition,
              this.total,
              this.filterEnterpriseID,
              this.user,
              'clients',
              0
            )
            .subscribe((clients) => {
              this.clientsSubject.next(this.clients);
              this.countClients.emit(clients['count']);
              if (this.pageNum == 1) {
                this.total = clients['count'];
              }
              this.pageNum++;
              this.scrollDisabled = false;
              if (clients['count'] > 0) {
                this.maxpageNum = Math.ceil(
                  Number(clients['count']) / this.pageSize
                );
                this.clients = [...this.clients, ...clients['rows']];
              } else {
                this.maxpageNum = 0;
                this.clients = [];
              }
              this.clientsSubject.next(this.clients);
              this.countClients.emit(clients['count']);
              this.scrollCheck = false;
              this.statusSearch = false;
            });
        }, 10);
      })
      .finally(() => {
        this.spinner.hide('loading-clients-all');
      });
  }

  btnSyncControlClass() {
    let classHTML = '';
    classHTML =
      this.offlineHeader.statusSync === 'unsynchronized'
        ? 'blink btn-upload'
        : '';
    classHTML +=
      this.offlineHeader?.statusSync === 'problems' ? ' btn-warning' : ' ';
    return classHTML;
  }

  onScrollTop(): void {
    this.document.documentElement.scrollTop = 0;
  }

  viewMore(): void {}

  openMaps(client: ClientModel): void {
    if (!(localStorage.getItem('statusConnection') === 'true')) {
      Swal.fire({
        title: 'Sin conexión a internet',
        html: 'Intente más tarde',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK',
      }).then(async (result) => {
        if (result.isConfirmed) {
          return;
        }
      });
    }
    this.centerPositionSubject.next({
      lat: Number(client.georeferencia_x),
      lng: Number(client.georeferencia_y),
      id: client.id,
      label: client.id,
    });
    this.selectedTabIndex = 1;
    this.typeCenterSubject.next('marker');
  }

  changeTab(index: number): void {
    this.selectedTabIndex = index;
  }

  tabChanged(event) {
    if (
      event.index === 1 &&
      localStorage.getItem('statusConnection') === 'true'
    ) {
      this.IndependentMapsComponent.onMapTab();
    }
    if (event.cancelable) event.preventDefault();
    if (
      event.index === 1 &&
      !(localStorage.getItem('statusConnection') === 'true')
    ) {
      Swal.fire({
        title: 'Sin conexión',
        text: 'Debe tener conexión a Internet para visualizar el mapa.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } else {
      this.selectedTabIndex = event.index;
      if (event.index === 0) {
        this.currentPosition
          ? this.centerPositionSubject.next(this.currentPosition)
          : null;
        this.typeCenterSubject.next('marker');
      } else {
        this.typeCenterSubject.next('current');
      }
      this.selectedTabIndex = event.index;
    }
  }

  onScrollDownClients() {
    // Init Offline Mode
    if (this.isOfflineMode) {
      this.spinner.show('loading-clients');
      this.paginateClients();
      return;
    }
    // End Offline Mode
    if (this.pageNum <= this.maxpageNum && this.selectedTabIndex == 0) {
      this.spinner.show('loading-clients');
      this.fetchClients({});
    }
  }

  async paginateClients() {
    this.pageNum++;

    const clients = await this.offlineService.getClients({
      pageSize: this.pageSize,
      pageNum: this.pageNum,
      isTpmFilter: this.isTpmFilter,
      stringSearch: this.searchCustomer,
    });
    if (clients.length > 0) {
      this.clients = [...this.clients, ...clients];
    }
    // this.spinner.hide('loading-clients');
  }

  async verifyInitPoll(poll, client) {
    const pollId = poll.id;
    // Init Offline
    if (this.isOfflineMode) {
      const synchronizationIsNotUpToDate =
        await this.offlineService.synchronizationIsNotUpToDate();
      const syncPoll = await this.offlineService.getSyncPoll(
        `${pollId}-${client.id}-${this.user.id}`
      );
      if (
        synchronizationIsNotUpToDate === true ||
        (syncPoll && syncPoll.status === 'unsynchronized') ||
        this.offlineHeader.statusSync === 'problems' ||
        poll.edit === 'trueOffline'
      ) {
        if (poll.edit === 'trueOffline') {
          this.initPoll(pollId, client);
          return;
        }
      }
      if (poll.edit === 'true') {
        this.editPoll(poll, client);
        return;
      }
    }
    this.initPoll(pollId, client);
    // End Offline
  }

  async initPollMaps(event) {
    console.log('440, ', event);
  }

  async initPoll(pollId, client) {
    localStorage.removeItem('navHistory');
    // Init Offline
    if (this.isOfflineMode) {
      this.offlineService.initPoll(pollId, client, this.user.id);
      return;
    }
    // End Offline
    this.clientService
      .getClientsById(pollId, client.cliente_id)
      .subscribe((client) => {
        localStorage.removeItem('idResponse');
        localStorage.removeItem('poll');
        this.poll = client.poll;
        if (this.poll.geolocation === true) {
          navigator.geolocation.getCurrentPosition((position) => {
            const coords = position.coords;
            localStorage.setItem('lat', coords.latitude.toString());
            localStorage.setItem('long', coords.longitude.toString());
          });
        }
        const dataPoll = {
          id: this.poll.id,
          period_id: this.poll.period_id,
          name: this.poll.name,
          description: this.poll.description,
          sections: this.sortJSON(this.poll.sections, 'weight', 'asc'),
          messages: this.poll.messages,
          expire_date: this.poll.expire_date,
          publish_date: this.poll.publish_date,
          countquestion: this.poll.countquestion,
          geolocation: this.poll.geolocation,
          minutes: this.poll.minutes,
          infoextra: this.poll.infoextra,
          infoextrapoll: this.poll.infoextrapoll,
          infoextracustomer: this.poll.infoextracustomer,
          showmessageinit: this.poll.showmessageinit,
          addgoals: this.poll.addgoals,
          measuring_period: this.poll.measuring_period,
        };
        localStorage.setItem('poll', JSON.stringify(dataPoll));
        delete client.poll;
        localStorage.setItem('client', JSON.stringify(client));
        localStorage.setItem('source', 'list_client');
        if (
          this.poll.infoextra == true &&
          this.poll.infoextrapoll !== null &&
          this.poll.infoextrapoll !== undefined &&
          this.poll.infoextracustomer !== null &&
          this.poll.infoextracustomer !== undefined
        ) {
          this.router.navigate(['poll/client/info']);
        } else {
          this.router.navigate(['poll/client/init']);
        }
      });
  }

  sortJSON(data, key, orden) {
    return data.sort(function (a, b) {
      var x = a[key],
        y = b[key];
      if (orden === 'asc') {
        return x < y ? -1 : x > y ? 1 : 0;
      }
      if (orden === 'desc') {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  }

  resetSearchEmpty(val) {
    if (val === null || val === '') {
      this.fetchClients({});
    }
  }

  onKey(x) {
    if (this.statusSearch === false) {
      this.fetchClients({});
    }
  }

  /* onKeySearch(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    // Init Offline
    if (this.isOfflineMode) {
      if (event.keyCode != 13) {
        this.fetchClients({ isTpmFilter: this.isTpmFilter });
      }
      return;
    }
    // End Offline
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.pageNum = 1;
        $this.clients = [];
        $this.executeSearchg(event.target.value);
      }
    }, 1000);
  } */

  executeSearchg(value: string) {
    // alert(value);
    this.fetchClients({});
  }

  getPolls(
    cliente_id: string,
    empresa_id: number,
    index: number,
    open: boolean
  ) {
    // Init Offline
    this.clientSelected = this.clients[index];
    if (this.isOfflineMode) {
      if (!this.clients[index]['tempPolls'] || open) {
        this.spinner.show('client-poll-spinner');
        this.clients[index]['tmpPolls'] = this.clients[index]['polls'];
        this.spinner.hide('client-poll-spinner');
        this.clientsSubject.next(this.clients);
        return;
      }
    }
    // End Offline
    if (!this.clients[index]['polls'] || open) {
      this.spinner.show('client-poll-spinner');
      this.hasActivePolls = true;
      this.clientService
        .getAllPollCustomerAvailable(cliente_id, empresa_id)
        .pipe(
          finalize(() => {
            this.spinner.hide('client-poll-spinner');
            this.changeRef.detectChanges();
          })
        )
        .subscribe(
          (data) => {
            if (data.length) {
              this.hasActivePolls = true;
              this.clients[index]['polls'] = data;
              this.clients[index]['tmpPolls'] = data;
              this.spinner.hide('client-poll-spinner');
              this.clientsSubject.next(this.clients);
            } else {
              this.hasActivePolls = false;
            }
          },
          () => {
            this.hasActivePolls = false;
          }
        );
    }
  }

  async editPoll(pollEdit: PollModel, client: ClientModel) {
    var lat;
    var long;
    if (pollEdit.geolocation === true) {
      lat = localStorage.getItem('lat');
      long = localStorage.getItem('long');
    }
    Swal.fire({
      title: 'Editar encuesta',
      html:
        'Se creará registros adicionales de las respuesta del cliente<br><b>' +
        client.descr_cliente +
        '</b>, <br>' +
        'que posteriormente podrás modificar. <br>¿Deseas continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        // Init Offline
        if (this.isOfflineMode) {
          this.progressUploadPollValue = 0;
          this.progressUploadPollText = 'Descargando respuestas';
          let porcentaje = 0;
          const params = {
            clientId: client.cliente_id,
            pollId: pollEdit.id,
          };
          this.spinner.show('synchronizingEditPoll');
          this.clientsService
            .getResponsesPollsBySeller(params)
            .subscribe(async (poll: any) => {
              this.progressUploadPollValue = 50;
              this.progressUploadPollText = 'Guardando respuestas';
              const dataSyncPoll = {
                id: `${pollEdit.id}-${client.id}-${this.user.id}`,
                poll_id: pollEdit.id,
                period_id: pollEdit.period_id,
                client,
                status: 'edit-offline',
                coords_init: {
                  lat: localStorage.getItem('lat'),
                  long: localStorage.getItem('long'),
                },
                descr_segmento: client.descr_segmento,
                descr_relevancia: client.descr_relevancia,
                descr_nivel: client.descr_nivel,
                descr_vendedor: client.descr_vendedor,
                descr_jefe_ventas: client.descr_jefe_ventas,
                descr_subgerente: client.descr_subgerente,
                descr_gerente: client.descr_gerente,
                descr_ofcom_venta: client.descr_ofcom_venta,
                descr_distrito_venta: client.descr_distrito_venta,
                descr_zona_venta: client.descr_distrito_venta,
                coords_finish: null,
              };
              await db.setSyncPoll(dataSyncPoll);
              porcentaje = 50 / poll.sections.length;
              for (const section of poll.sections) {
                this.progressUploadPollValue =
                  this.progressUploadPollValue + porcentaje;
                for (const question of section.questions) {
                  question.questionoptions = question.responseoption;
                  await this.offlineService.setResponse({
                    question: question,
                    poll: pollEdit,
                    client: client,
                    user: this.user,
                    mode: 'offlineEdit',
                  });
                }
              }
              const idClient: any = client.id;
              const clientDexie = await db.clients
                .where({ id: idClient })
                .first();
              const indexPoll: number = clientDexie.polls.findIndex(
                (p) => p.id === pollEdit.id
              );
              if (indexPoll !== -1) {
                clientDexie.polls[indexPoll].edit = 'trueOffline';
              }
              await db.clients.update(idClient, clientDexie);
              const indexClient = this.clients.findIndex(
                (c) => c.id === idClient
              );
              if (indexClient !== -1) {
                this.clients[indexClient].polls[indexPoll].edit = 'trueOffline';
              }
              this.spinner.hide('synchronizingEditPoll');
              this.initPoll(pollEdit.id, client);
            });
          return;
        }
        // End Offline
        this.pollsService
          .sendPollEdit({
            customer_id: client.cliente_id,
            poll_id: pollEdit.id,
            user_id: this.user.id,
            lat: lat,
            long: long,
            method: 'cliente_id',
          })
          .subscribe((data) => {
            client.customer_id = data.customer_id;
            client.id = data.customer_id;
            if (data !== null) {
              Swal.fire({
                title: 'Editar encuesta',
                html:
                  'Desea ir a la ediccion de la encuesta del cliente:<br><b>' +
                  client.descr_cliente +
                  '</b>',
                icon: 'success',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Editar',
              }).then((result) => {
                if (result.isConfirmed) {
                  localStorage.setItem('client', JSON.stringify(client));
                  localStorage.removeItem('navHistory');
                  localStorage.setItem('idResponse', data.id);
                  this.poll = pollEdit;
                  localStorage.setItem('poll', JSON.stringify(this.poll));
                  localStorage.setItem('preview', 'false');
                  this.gotoeditPoll(pollEdit.id, client);
                }
              });
            } else {
              Swal.fire(
                'Editar encuesta',
                'No se ha podido editar la encuesta.',
                'error'
              );
            }
          });
      }
    });
  }

  async gotoeditPoll(id: number, client: ClientModel) {
    // Init Offline Mode
    if (this.isOfflineMode) {
      /* const data = {
        id: `${this.poll.id}-${client.id}-${this.user.id}`,
        poll_id: this.poll.id,
        period_id: this.poll.period_id,
        client,
        status: 'iniciada',
        coords_init: {
          lat: localStorage.getItem('lat'),
          long: localStorage.getItem('long'),
        },
        descr_segmento: client.descr_segmento,
        descr_relevancia: client.descr_relevancia,
        descr_nivel: client.descr_nivel,
        descr_vendedor: client.descr_vendedor,
        descr_jefe_ventas: client.descr_jefe_ventas,
        descr_subgerente: client.descr_subgerente,
        descr_gerente: client.descr_gerente,
        descr_ofcom_venta: client.descr_ofcom_venta,
        descr_distrito_venta: client.descr_distrito_venta,
        descr_zona_venta: client.descr_distrito_venta,
        coords_finish: null,
      };
      await db.setSyncPoll(data); */
      this.offlineService.initPoll(this.poll.id, client, this.user.id);
    }
    // debugger;
    // End Offline Mode
    if (
      this.poll.infoextra == true &&
      this.poll.infoextrapoll !== null &&
      this.poll.infoextrapoll !== undefined &&
      this.poll.infoextracustomer !== null &&
      this.poll.infoextracustomer !== undefined
    ) {
      this.router.navigate(['poll/client/info']);
    } else {
      this.router.navigate(['poll/client/init']);
    }
  }

  restartPoll(
    clientName: string,
    clientID: number,
    enterpriseId: number,
    pollId: number,
    filter_by_user: boolean,
    id: number
  ) {
    Swal.fire({
      title: 'Reiniciar encuesta',
      html:
        'Se eliminarán las respuesta del cliente<br><b>' +
        clientName +
        '</b>, <br>' +
        ' y podras genera un nuevo set de respuesta. <br>¿Deseas continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.pollsService
          .sendPollRestart({
            customer_id: clientID,
            poll_id: pollId,
            method: 'cliente_id',
            filter_by_user,
          })
          .subscribe((data) => {
            if (data !== null) {
              Swal.fire(
                'Reiniciar encuesta',
                ' Para aplicar nuevamente la encuesta, seleccione a cliente:<br><b>' +
                  clientName +
                  '</b><br>del listado de pendientes.',
                'success'
              );
              this.getPolls(clientID.toString(), enterpriseId, id, true);
            } else {
              Swal.fire(
                'Reiniciar encuesta',
                'No se ha podido reinicar la encuesta.',
                'error'
              );
            }
          });
      }
    });
  }

  isDisplayMaps() {
    const enterprises = JSON.parse(localStorage.getItem('enterprises'))?.filter(
      (item) => {
        return (
          Number(item.id) === 98 ||
          Number(item.id) === 99 ||
          Number(item.id) === 92
        );
      }
    );
    enterprises?.length > 0
      ? (this.displayMaps = false)
      : (this.displayMaps = true);
  }

  findIndicators(client) {
    const message =
      localStorage.getItem('statusConnection') === 'true'
        ? 'Ocurrio un error en la busqueda de los indicadores.'
        : 'Debe tener conexión a internet para acceder a indicadores.';

    this.spinner.show('loading-indicators');
    this.indicatorsService
      .getIndicator(client.cliente_id, client.empresa_id)
      .subscribe(
        (data) => {
          this.customerIndicators = data;
          this.spinner.hide('loading-indicators');
          if (this.customerIndicators.length === 1) {
            return window.open(this.customerIndicators[0].url);
          }
          this.showIndicators = true;
        },
        (error) => {
          this.spinner.hide('loading-indicators');
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: message,
          });
        }
      );
  }

  hideIndicators() {
    this.showIndicators = false;
  }

  handleCreateTask(client) {
    if (!(localStorage.getItem('statusConnection') === 'true')) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Debe tener conexión a internet para acceder a tareas.',
      });
      return;
    }

    localStorage.setItem(
      `create_task_${client.cliente_id}`,
      JSON.stringify(client)
    );

    this.router.navigate([`/tasks/create/${client.cliente_id}`]);
  }

  trackByClientId(index: number, client: any): string {
    return `${index}-${client.cliente_id}`;
  }
}
