<div class="photo-container">
  <div class="photo-title-box">
    <p class="photo-title">{{ question.title }}</p>
  </div>

  <div class="photo-box">
    <div class="photo-help-box">
      <p class="photo-help">{{ question.help }}</p>
    </div>

    <div
      class="photo-input"
      [ngClass]="{ disable: question.images_allowed <= images.length }"
      (click)="handleAnswer(null)"
    >
      <mat-icon>camera_enhance</mat-icon>
    </div>

    <div class="photo-count">
      <p>{{ images.length }}/{{ question.images_allowed }}</p>
    </div>
  </div>

  <div class="photo-preview">
    <div *ngFor="let image of images">
      <div class="photo-box-preview">
        <div class="photo-view-box">
          <img [src]="image.image" loading="lazy" />
        </div>

        <div class="photo-card-btns">
          <div class="card-btn warning" (click)="handleAnswer(image.id)">
            <mat-icon>refresh</mat-icon>
          </div>

          <div class="card-btn danger" (click)="removePhoto(image.id)">
            <mat-icon>delete_outline</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
