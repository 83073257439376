import { Component, OnInit, HostListener } from '@angular/core';
import { Injectable, NgZone } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
// import { auth } from 'src/environments/environment';
// import {
//   SocialAuthService,
//   GoogleLoginProvider,
//   SocialUser,
// } from 'angularx-social-login';
import { ActivatedRoute, Router } from '@angular/router';

import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import * as auth from 'firebase/auth';

// import firebase from 'firebase/compat/app';
import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import { dev } from '../../../../environments/environment';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { trace } from '@angular/fire/compat/performance';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  username: String;
  password: String;
  devenv: String;
  /* socialUser: SocialUser; */
  isLoggedin: boolean;
  installEvent: any = null;
  authForm: FormGroup;
  submitted = false;
  openPopUp = false;
  error = '';
  hide = true;
  token$: Observable<any>;
  message$: Observable<any>;
  showRequest = false;
  codClient: string;
  pollToken: string;
  lat: number;
  lng: number;

  constructor(
    public authService: AuthService,
    /* private socialAuthService: SocialAuthService, */
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    public readonly messaging: AngularFireMessaging,
    private route: ActivatedRoute
  ) {
    navigator.geolocation.getCurrentPosition((position) => {
      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;
    });
    this.message$ = messaging.messages;
    this.token$ = messaging.tokenChanges.pipe(
      trace('token'),
      tap((token) => (this.showRequest = !token))
    );
  }

  ngOnInit(): void {
    // this.authService.loginAuth();
    if (navigator.geolocation) {
      console.log('support for geolocation');
      navigator.geolocation.getCurrentPosition((position) => {
        const longitude = position.coords.longitude;
        const latitude = position.coords.latitude;
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
      });
    } else {
      console.log('No support for geolocation');
    }
    if (this.checkSession()) {
      this.router.navigateByUrl('/poll');
      return;
    }
    this.init();
    this.route.queryParams.subscribe((params) => {
      this.codClient = params.codClient;
      this.pollToken = params.pollToken;
    });
  }

  init() {
    this.devenv = dev.env;
    this.authForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    /*    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
      this.isLoggedin = user != null;
    }); */
  }

  checkSession() {
    const token = localStorage.getItem('token');
    const expiresInDuration = parseInt(
      localStorage.getItem('expiresInDuration')
    );

    if (token && moment.unix(expiresInDuration) > moment()) {
      return true;
    }
    return false;
  }

  @HostListener('window: beforeinstallprompt', ['$event'])
  onBeforeInstallPrompt(event: Event) {
    event.preventDefault();
    this.installEvent = event;
  }

  installByUser() {
    if (this.installEvent) {
      this.installEvent.prompt();
      this.installEvent.userChoice.then((rta: any) => {});
    }
  }

  onAuthenticate(form: NgForm) {
    if (form.invalid) {
      return;
    }
    this.authService.authenticateUser(form.value.username, form.value.password);
  }

  loginFake(): void {
    var localtoken =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjo4NjYsInVzZXJuYW1lIjoiYWxleGlzLnF1ZXZlZG9AemVudGFncm91cC5jb20iLCJlbWFpbCI6ImFsZXhpcy5xdWV2ZWRvQHplbnRhZ3JvdXAuY29tIiwibmFtZSI6IkFsZXhpcyBRdWV2ZWRvIiwiY29kX3NlbGxlciI6IjE3NDM5Njc4Iiwic2FsZXNfbGV2ZWwiOjE3LCJpbml0X2RhdGUiOiIyMDIzLTA3LTA0VDE0OjUyOjQxLjc4OFoiLCJlbmRfZGF0ZSI6MTY4ODQ4MjM2MTc4OCwibm9tYnJlIjoiQWxleGlzIFF1ZXZlZG8iLCJwb3NpdGlvbl9pZCI6MSwidHlwZWFjY2VzcyI6InVzZXIifSwiaWF0IjoxNjg4Mzk1OTYxLCJleHAiOjE2ODg0ODIzNjF9.fxlgpiSh3nmY3w5c72ZOwzHHA8sH6fEqb7lpxxlo5oc';
    localStorage.setItem('token', localtoken);
    localStorage.setItem('expiresInDuration', '1906298987');
    localStorage.setItem('isOfflineMode', 'no');
    localStorage.setItem(
      'currenLocation',
      JSON.stringify({ lat: this.lat, lng: this.lng })
    );
    this.router.navigateByUrl('/poll');
  }

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  onSubmit() {
    this.submitted = true;
    this.error = '';

    if (this.authForm.invalid) {
      this.error = 'Correo electrónico ó contraseña no válidos !';

      return;
    } else {
      if (this.validateEmail(this.authForm.value.username) !== null) {
        this.authService.SignInEmailPass(
          this.authForm.value.username,
          this.authForm.value.password,
          this.codClient,
          this.pollToken,
          this.lat,
          this.lng
        );
      } else {
        this.authService
          .SignInusername(
            this.authForm.value.username,
            this.authForm.value.password
          )
          .subscribe((resp) => {
            if (resp === null) {
              this.authService.SignOut();

              this.dialog
                .open(DialogComponent, {
                  data: {
                    message:
                      'Usuario o contraseña incorrectos. Verifique sus credenciales y vuelva a intentarlo',
                    status: 2,
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  this.router.navigate(['login']);
                });
            } else {
              this.authService.SetUserData(
                resp,
                this.codClient,
                this.pollToken,
                this.lat,
                this.lng
              );
            }
          });
      }
    }
  }
  async GoogleAuth() {
    this.openPopUp = true;

    await this.authService.GoogleAuth(
      this.codClient,
      this.pollToken,
      this.lat,
      this.lng
    );

    this.openPopUp = false;
  }

  login() {}
  request() {
    this.messaging.requestPermission.subscribe(console.log, console.error);
  }
  public throwTestError(): void {
    throw new Error('Sentry Test Error');
  }
}
