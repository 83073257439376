<div class="text-container">
  <div class="client-box">
    <div class="client-name-box">
      <p class="name-title">Tarea</p>
    </div>

    <div class="client-info-box">
      <div class="client-icon">
        <mat-icon>task_alt</mat-icon>
      </div>

      <div class="client-info">
        <p class="client-txt">Tarea: {{ responseTask_1.task.title }}</p>
        <p class="client-txt">
          Vencimiento: {{ responseTask_1.limit_date | date : 'dd MMM yyyy' }}
        </p>

        <div class="limit-date-badge">
          <div
            class="badge"
            [ngClass]="{
              'badge-late': responseTask_1?.expired,
              'badge-to-expire': responseTask_1?.toExpired,
              'badge-in-time': responseTask_1?.inTime
            }"
          >
            {{
              responseTask_1?.expired
                ? 'Atrasado'
                : responseTask_1?.toExpired
                ? 'Por vencer'
                : responseTask_1?.inTime
                ? 'En fecha'
                : ''
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-title-box">
    <h3>Observación</h3>
  </div>

  <div class="text-title-box">
    <p class="text-title">{{ subTitle ? subTitle : 'Información de referencia:' }}</p>
  </div>

  <div class="text-box">
    <div class="text-textarea">
      <textarea
        name="text"
        cols="30"
        rows="10"
        [defaultValue]="question_1.value || ''"
        #textAreaInput
        [disabled]="!allowEdit"
      ></textarea>
    </div>
  </div>
  <div *ngIf="question_2 ? true : false">
    <div class="text-title-box">
      <p class="text-title">Información de respaldo:</p>
    </div>

    <div class="text-box">
      <div class="text-textarea">
        <textarea
          name="text"
          cols="30"
          rows="10"
          [defaultValue]="question_2.value || ''"
          #textAreaInput
          [disabled]="!allowEdit"
        ></textarea>
      </div>
    </div>
  </div>
</div>
