import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.css'],
})
export class BreadCrumbComponent implements OnInit {
  @Input() sectionCompare: number;
  @Input() sectionTotal: number;
  sections: Array<any> = [
    { number: '1' },
    { number: '2' },
    { number: '3' },
    { number: '4' },
    { number: '5' },
  ];
  constructor() {
    this.sectionTotal = 5;
  }

  ngOnInit(): void {
    this.sections = [];
    for (let i = 1; i <= this.sectionTotal; i++) {
      this.sections.push({ number: i });
    }
  }
}
