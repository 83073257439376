import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { SwUpdate } from '@angular/service-worker';
import { Network } from '@ngx-pwa/offline';
import { filter } from 'rxjs/operators';
import { auth, dev } from 'src/environments/environment';
import { OfflineService } from './polls/services/offline.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  online$ = this.network.onlineChanges;
  isOnline = false;
  isOfflineMode = false;
  public showNavbarFlag: boolean = false;
  public environment = dev.env;

  constructor(
    private authService: AuthService,
    private swUpdate: SwUpdate,
    protected network: Network,
    private router: Router
  ) {}
  title = 'ccu-forms';

  ngOnInit() {
    this.updatePWA();
    this.authService.autoAuthUser();
    this.online$.subscribe((status) => {
      localStorage.setItem('statusConnection', `${status}`);
      this.isOnline = status;
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.showNavbarFlag = this.showNavbar(event.url);
      });
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }

  updatePWA() {
    this.swUpdate.available.subscribe((value) => {
      window.location.reload();
    });
  }

  private showNavbar(url: string): boolean {
    const routesWithNavbar = [
      '/home',
      '/poll',
      '/synchronization',
      '/published',
      '/tasks',
    ];

    return routesWithNavbar.includes(url);
  }
}
