import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ETaskStatus } from 'src/app/tasks/entities/task.entity';
import Swal from 'sweetalert2';
import { ICreatedTask } from 'src/app/tasks/interfaces';
import { TasksService } from '../../../../services/tasks.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-response-questions',
  templateUrl: './response-questions.component.html',
  styleUrls: ['./response-questions.component.css'],
})
export class ResponseQuestionsComponent implements OnInit {
  public responseTask_1: ICreatedTask;
  public selectedQuestion_1: any;
  public selectedQuestion_2: any;
  public selectedResponse_1: any;
  public selectedResponse_2: any;
  public actionType: string;
  public responsesCount_1: any;
  public responsesCount_2: any;
  public selectedToResponse: any;
  public isTabIndex_0: boolean = true;
  public isCreatedTask: boolean = false;
  public reasonRejection: string = '';

  task_id: number;
  user_id: string;

  obsCreate: any;
  obsConfirm: any;
  selectConfirm: any;
  photosResp: any[];
  photosRef: any[];

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private tasksService: TasksService
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extras && navigation.extras.state) {
      this.isCreatedTask = navigation.extras.state.isCreatedTask;
    } else {
      this.isCreatedTask = false;
    }
  }

  ngOnInit(): void {
    this.tasksService.$isTabIndex_0.subscribe((tabIndex) => {
      this.isTabIndex_0 = tabIndex !== 1 ? true : false;
    });
    this.responseTask_1 = JSON.parse(localStorage.getItem('response_task'));
    this.user_id = localStorage.getItem('userId');

    if (!this.responseTask_1) {
      this.route.params.subscribe((params) => {
        if (params['id']) {
          this.task_id = params['id'];
        }
      });

      this.tasksService
        .getAssignedTasks(this.user_id)
        .pipe(
          finalize(() => {
            if (!this.responseTask_1) {
              this.router.navigate(['/tasks']);

              return;
            }
          })
        )
        .subscribe((tasks) => {
          this.responseTask_1 = tasks.data.find((item) => item.id === +this.task_id);
          localStorage.setItem('response_task', JSON.stringify(this.responseTask_1));
        });
    }

    this.loadData();

    this.checkResponses();
  }

  loadData() {
    this.responseTask_1.response_options.forEach((response) => {
      if (response.type === 'Texto' && response.question_task.type_task === 'create') {
        this.obsCreate = response;
      }
      if (response.type === 'Texto' && response.question_task.type_task === 'confirm') {
        this.obsConfirm = response;
      }
      if (response.type === 'Selección simple' && response.question_task.type_task === 'confirm') {
        const responseSimple = response.value === 'Sí' ? '1' : '0';
        this.selectConfirm = responseSimple;
      }
    });

    this.photosRef = this.responseTask_1.response_options.filter(
      (ref) => ref.type === 'Carga de archivo' && ref.question_task.type_task === 'create'
    );

    this.photosResp = this.responseTask_1.response_options.filter(
      (resp) => resp.type === 'Carga de archivo' && resp.question_task.type_task === 'confirm'
    );
  }

  handleViewInfo(obsConfirm, obsCreate) {
    this.selectedQuestion_1 = Array.isArray(obsCreate) ? obsCreate[0] : obsCreate;
    this.selectedQuestion_2 = Array.isArray(obsConfirm) ? obsConfirm[0] : obsConfirm;

    if (
      !obsCreate ||
      (!obsCreate && obsCreate.type) ||
      (Array.isArray(obsCreate) && obsCreate.length === 0)
    ) {
      Swal.fire({
        title: 'Observación de referencia excluida.',
        text: 'Esta tarea se ha configurado para excluir la observación de referencia.',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar',
      });
    }
  }

  cleanSelectedQuestion() {
    this.selectedQuestion_1 = null;
    this.selectedResponse_1 = null;
    this.selectedQuestion_2 = null;
    this.selectedResponse_2 = null;
  }

  handleViewResponse(photosRef, photosResp) {
    if (
      !photosRef ||
      (!photosRef && photosRef.type) ||
      (Array.isArray(photosRef) && photosRef.length === 0)
    ) {
      Swal.fire({
        title: 'Fotos de referencia excluidas.',
        text: 'Esta tarea se ha configurado para excluir las fotos de referencia.',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar',
      });

      return;
    }

    this.selectedResponse_1 = Array.isArray(photosRef) ? photosRef[0] : photosRef;

    this.selectedResponse_2 = Array.isArray(photosResp) ? photosResp[0] : photosResp;
  }

  checkResponses() {
    this.responsesCount_1 = this.responseTask_1.response_options.reduce(
      (acc, res) => {
        if (res.question_task.type_task === 'create') acc.created += 1;
        else if (res.question_task.type_task === 'confirm') acc.confirmed += 1;

        return acc;
      },
      {
        created: 0,
        confirmed: 0,
      }
    );

    this.responsesCount_2 = this.responseTask_1.response_options.reduce(
      (acc, res) => {
        if (res.question_task.type_task === 'create') acc.created += 1;
        else if (res.question_task.type_task === 'confirm') acc.confirmed += 1;

        return acc;
      },
      {
        created: 0,
        confirmed: 0,
      }
    );
  }

  handleResponse(task) {
    if (this.isCreatedTask || !this.isTabIndex_0) return;
    if (
      this.responseTask_1.template_status !== ETaskStatus.CREATED &&
      this.responseTask_1.template_status !== ETaskStatus.REJECTAD
    ) {
      return;
    }

    this.selectedToResponse = this.getTaskById(JSON.parse(localStorage.getItem('assigned_task')), task.id);

    if (!this.selectedToResponse) {
      this.selectedToResponse = this.getTaskById(JSON.parse(localStorage.getItem('created_task')), task.id);
    }

    localStorage.setItem('question_to_response', JSON.stringify(this.selectedToResponse, task.id));

    return this.router.navigate([`/tasks/${task.id}/response/${task.id}`]);
  }

  getTaskById(data, taskId) {
    for (let i = 0; i < data.length; i++) {
      const tasks = data[i].tasks;
      for (let j = 0; j < tasks.length; j++) {
        if (tasks[j].id === taskId) {
          return tasks[j];
        }
      }
    }
    return null;
  }

  handleRejectedMessageResponse(responseTask_1) {
    this.selectedQuestion_1 = 5;
    if (responseTask_1.reason_rejection) {
      this.reasonRejection = responseTask_1.reason_rejection;
    } else if (!responseTask_1.reason_rejection && responseTask_1.original_task) {
      this.reasonRejection = this.tasksService.findReasonRejection(
        JSON.parse(localStorage.getItem('assigned_task')),
        responseTask_1.original_task
      );
    }
  }
}
