import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { UploadService } from '../../../services/upload.service';
import { OfflineService } from 'src/app/polls/services/offline.service';
import Swal from 'sweetalert2';
import { NgxImageCompressService } from 'ngx-image-compress';
import { MatDialog } from '@angular/material/dialog';
import { ModalViewPreviewComponent } from 'src/app/shared/components/modal-view-preview/modal-view-preview.component';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResponseService } from '../../../services/response.service';
// Dexie DB
import { db } from 'src/app/db/db';
import { QuestionModel } from 'src/app/polls/models/question.model';
import { UserModel } from 'src/app/polls/models/user.model';
import { PollModel } from 'src/app/polls/models/poll.model';
import * as jwt_decode from 'jwt-decode';

import { ModalsComponent } from 'src/app/polls/components/questions/files-webcam/modals/modals.component';
import { NONE_TYPE } from '@angular/compiler';
import { BehaviorSubject, Subject } from 'rxjs';
import { ClientModel } from 'src/app/polls/models/client.model';
import { dev } from '../../../../../environments/environment';

export interface URL {
  name?: string;
  file?: any;
  files?: any;
  fileName: string;
  token: string;
  isUpdate: boolean;
  type?: string;
}

// this.urls.push({
//   fileName: fileData.data.fileName,
//   token: fileData.data.token,
//   isUpdate: false,
//   type: extension,
// });

@Component({
  selector: 'app-files-webcam',
  templateUrl: './files-webcam.component.html',
  styleUrls: ['./files-webcam.component.scss'],
})
export class FilesWebcamComponent implements OnInit, OnChanges {
  @Input() question;
  @Input() idResponse: number;
  @Output() optionSelected = new EventEmitter();
  @Output() showHideSpiner = new EventEmitter();
  devenv: String;
  offlineMode: any = {
    status: false,
    from: 'nonde',
  };
  // urls = [];
  urls: URL[] = [];
  // urls$: Subject<URL[]> = new Subject<URL[]>();

  urlsWithResponse = [];
  fileTypes: any;
  urlLength: number;
  indexForReplace;
  activeReplace: any;
  progress: { percentage: number } = { percentage: 0 };
  selectedFiles: any;
  public dataToEmit;
  hasResponse: boolean = false;
  allData = [];
  itemSelectedToReplace: any;
  tagQuestion: any;
  user: UserModel;
  poll: PollModel;
  indexReplace: number;
  isDisabled: boolean = false;
  client: ClientModel;
  constructor(
    private uploadService: UploadService,
    private offlineService: OfflineService,
    private imageCompress: NgxImageCompressService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private responseService: ResponseService,
    private ngZone: NgZone
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
    this.poll = JSON.parse(localStorage.getItem('poll'));
    this.client = JSON.parse(localStorage.getItem('client'));
  }

  ngOnInit(): void {
    this.devenv = dev.env;
    this.urls.length = 0;
    this.urlsWithResponse.length = 0;
    this.allData.length = 0;
    this.tagQuestion = this.question.questionoptions[0].title;
    this.fileTypes =
      this.question.questionoptions[0].allowableFile.toLocaleLowerCase();
    this.question.images_allowed =
      this.question.images_allowed === 0 ? 1 : this.question.images_allowed;
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        if (offlineHeader) {
          this.offlineMode = offlineHeader;
        }
      });
  }

  ngOnChanges(): void {
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        if (offlineHeader) {
          this.offlineMode = offlineHeader;
        }
        this.getResponse();
      });
    // if (this.adapter.getToken() === null) {
    //   this.optionSelected.emit(null);
    // }
  }

  getResponse(change?) {
    if (!change) {
      this.urls.length = 0;
    }
    if (this.allData.length === 0 && this.urls.length === 0) {
      this.optionSelected.emit(null);
    }
    this.allData.length = 0;
    this.urlsWithResponse.length = 0;
    this.spinner.show();
    //  debugger;
    // Init Offline Mode
    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      const client = JSON.parse(localStorage.getItem('client'));
      const id = `${this.question.id}-${this.poll.id}-${client.id}-${this.user.id}`;
      this.offlineService
        .getQuestionResponseDB(id)
        .then(async (questionData) => {
          if (questionData) {
            const dataQuestion = questionData.data.value;
            if (dataQuestion.length > 0) {
              this.hasResponse = dataQuestion.length > 0 ? true : false;
              dataQuestion.forEach((element) => {
                //  debugger;
                const token = element.value.split('/')[0].toLowerCase();
                const newId = `${id}-${token}`;
                db.files.get(newId).then(async (fileData) => {
                  if (fileData) {
                    const extension = fileData.data.fileName
                      .split('.')
                      .pop()
                      .toLowerCase();
                    if (extension !== 'pdf') {
                      this.urls.push({
                        files: fileData.data.imagenBase64,
                        file: fileData.data.imagenBase64,
                        fileName: fileData.data.fileName,
                        token: fileData.data.token,
                        isUpdate: true,
                        type: extension,
                      });
                    } else {
                      this.urls.push({
                        fileName: fileData.data.fileName,
                        token: fileData.data.token,
                        isUpdate: true,
                        type: extension,
                      });
                    }
                    this.urlLength = this.urls.length;
                    this.upload();
                  } else {
                    this.optionSelected.emit(null);
                  }
                });
                setTimeout(() => {
                  this.spinner.hide('sp6');
                }, 2000);
              });
            } else {
              try {
                if (dataQuestion.value !== undefined) {
                  const token = dataQuestion.value.split('/')[0].toLowerCase();
                  const newId = `${id}-${token}`;
                  db.files.get(newId).then(async (fileData) => {
                    if (fileData) {
                      //Compresión
                      const filesCompressed: any = await this.compressFile(
                        fileData.data.imagen
                      );
                      this.urls.push({
                        files: filesCompressed,
                        file: this.dataURItoBlob(filesCompressed.split(',')[1]),
                        fileName: fileData.data.fileName,
                        token: fileData.data.token,
                        isUpdate: false,
                      });
                      this.urlLength = this.urls.length;
                      this.upload();
                    } else {
                      this.optionSelected.emit(null);
                    }
                  });
                  setTimeout(() => {
                    this.spinner.hide('sp6');
                  }, 2000);
                }
              } catch (error) {
                console.error(error);
              }
            }
          }
          this.spinner.hide();
        });
      return;
    }
    // End Offline Mode

    this.responseService
      .getResponseQuestion({
        response_id: this.idResponse,
        question_id: this.question.id,
      })
      .subscribe(
        (data) => {
          this.hasResponse = data.length > 0 ? true : false;
          if (this.hasResponse) {
            data = data.sort(function (a, b) {
              return a['id'] - b['id'];
            });
            data.forEach(async (element) => {
              const extension = element.value.split('.').pop().toLowerCase();
              const name = element.value.split('/').pop().toLowerCase();
              const file: any = await this.getSignedImag(element.value);
              this.urlsWithResponse.push({
                files: file.url,
                fileName: file.filename,
                token: uuidv4(),
                isUpdate: true,
                type: extension,
                name,
              });
              if (this.urlsWithResponse.length === data.length) {
                this.urlLength = this.urlsWithResponse.length;
                if (this.urlLength >= this.question.images_allowed) {
                  this.isDisabled = true;
                } else {
                  this.isDisabled = false;
                }
                this.upload();
              }
            });

            // debugger;
          } else {
            setTimeout(() => {
              this.spinner.hide();
            }, 500);
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  async upload() {
    const fileUploadBase64 = [];
    this.spinner.show();
    if (this.hasResponse === true) {
      this.allData = this.urls.concat(this.urlsWithResponse);
      this.urlLength = this.allData.length;
      this.dataToEmit = await this.repeatValidationFile(this.allData);
    } else {
      this.dataToEmit = await this.repeatValidationFile(this.urls);
    }
    const client = JSON.parse(localStorage.getItem('client'));
    const poll = JSON.parse(localStorage.getItem('poll'));
    this.urls.forEach(async (element) => {
      const file = {
        id: `${this.question.id}-${poll.id}-${client.id}-${this.user.id}-${element.token}`,
        sync_poll_id: `${poll.id}-${client.id}-${this.user.id}`,
        poll_id: poll.id,
        data: {
          imagenBase64: element.files,
          imagen: element.file,
          token: element.token,
          fileName: element.fileName,
        },
      };
      setTimeout(async () => {
        await db.setFile(file);
      }, 500);

      if (Number(this.urls.length) >= Number(this.question.images_allowed)) {
        this.isDisabled = true;
      }
    });
    this.validateLengthAllow();
    if (typeof this.dataToEmit == 'object' && this.dataToEmit.length == 0) {
      this.dataToEmit = null;
    }
    this.optionSelected.emit(this.dataToEmit);
    this.spinner.hide();
    return;
    // }

    // this.progress.percentage = 0;
    // this.uploadService.pushFileToStorages(this.urls).subscribe(
    //   async (event: any) => {
    //     if (event.body) {
    //       this.spinner.hide();
    //       this.optionSelected.emit(this.dataToEmit);
    //     }
    //     if (event.type === HttpEventType.UploadProgress) {
    //       this.progress.percentage = Math.round(
    //         (event.loaded / event.total) * 100
    //       );
    //     } else if (event instanceof HttpResponse) {
    //       // this.prepareComponentFile(token, fileName);
    //     }
    //     // this.itemImageVisible = true;
    //   },
    //   (err) => {
    //     // this.selectedFiles = undefined;
    //     this.spinner.hide();
    //     // console.log('HTTP Error', err)
    //     Swal.fire({
    //       icon: 'error',
    //       title: `Error en el envio del archivo al servidor`,
    //       showConfirmButton: false,
    //       timer: 3000,
    //     });
    //   }
    // );
    // this.selectedFiles = undefined;
  }

  async onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.selectedFiles = event.target.files;
      const dataLength =
        this.hasResponse === true ? this.allData.length : this.urls.length;
      const filesAmount = event.target.files.length;
      this.urlLength = dataLength + filesAmount;
      if (this.urlLength > this.question.images_allowed) {
        Swal.fire({
          icon: 'error',
          title: `La cantidad máxima de archivos permitidos es ${this.question.images_allowed}`,
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        for (let i = 0; i < filesAmount; i++) {
          const nameFile = event.target.files[i].name;
          const extension = event.target.files[i].name
            .split('.')
            .pop()
            .toLowerCase();
          const isSuccess = this.fileTypes.indexOf(extension) > -1;
          if (isSuccess) {
            if (
              extension === 'png' ||
              extension === 'gif' ||
              extension === 'jpeg' ||
              extension === 'jpg'
            ) {
              //Compresión
              const filesCompressed: any = await this.compressFile(
                event.target.files[i]
              );
              if (!this.activeReplace) {
                // console.info(filesCompressed);
                this.urls.push({
                  files: filesCompressed,
                  file: this.dataURItoBlob(filesCompressed.split(',')[1]),
                  fileName: nameFile,
                  token: uuidv4(),
                  isUpdate: false,
                });
                this.urlLength = this.urls.length;

                this.upload();
              } else {
                if (this.itemSelectedToReplace) {
                  this.reempSelectedFileOnDb({
                    files: filesCompressed,
                    file: this.dataURItoBlob(filesCompressed.split(',')[1]),
                    fileName: nameFile,
                    token: uuidv4(),
                  });
                } else {
                  this.reempSelectedFile({
                    files: filesCompressed,
                    file: this.dataURItoBlob(filesCompressed.split(',')[1]),
                    fileName: nameFile,
                    token: uuidv4(),
                  });
                }
              }
            } else if (extension === 'pdf') {
              if (!this.activeReplace) {
                this.urls.push({
                  files: event.target.files[i],
                  file: event.target.files[i],
                  fileName: nameFile,
                  token: uuidv4(),
                  isUpdate: false,
                  type: extension,
                });
                this.urlLength = this.urls.length;
                this.upload();
              } else {
                // if (this.itemSelectedToReplace) {
                //   this.reempSelectedFileOnDb({
                //     files: event.target.files[i],
                //     file: this.dataURItoBlob(event.target.files[i]),
                //     fileName: nameFile,
                //     token: uuidv4(),
                //   });
                // } else {
                //   this.reempSelectedFile({
                //     files: event.target.files[i],
                //     file: this.dataURItoBlob(event.target.files[i]),
                //     fileName: nameFile,
                //     token: uuidv4(),
                //   });
                // }
              }
            } else {
              Swal.fire({
                icon: 'error',
                title: `Tipo de archivo .${extension} no admitido por el momento.`,
                showConfirmButton: true,
              });
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: `Tipo de archivo .${extension} no permitido.`,
              showConfirmButton: true,
            });
          }
        }
      }
    }
  }
  async removeSelectedFileOffline(index, items) {
    const client = JSON.parse(localStorage.getItem('client'));
    const id = `${this.question.id}-${client.id}`;
    this.client = JSON.parse(localStorage.getItem('client'));
    let item = items[index];
    // items[index] = { value: `${item.token}/${item.fileName}` };
    let values: Array<any> = [];
    for (const item of items) {
      values.push({ value: `${item.token}/${item.fileName}` });
    }
    const newFormatId = `${this.question.id}-${this.poll.id}-${this.client.id}-${this.user.id}`;

    await db.deleteFile(newFormatId);
    /* const response = {
      id: newFormatId,
      sync_poll_id: `${this.poll.id}-${client.id}-${this.user.id}`,
      poll_id: this.poll.id,
      client_id: client.id,
      type_save: 'saveFileQuestion',
      service: 'sendResponseQuestion',
      data: {
        response_id: null,
        question_id: this.question.id,
        option_id: null,
        value: values,
      },
      type_question: 'file',
    };
    await db.setResponse(response); */
    // delete file from UrlList
    if (this.allData.length > 0) {
      this.urlLength = this.urls.length;
      this.upload();
    } else {
      this.urlLength = this.urls.length;
      if (this.urlLength !== 0) {
        this.upload();
      } else {
        this.optionSelected.emit(null);
      }
    }
    if (this.urls.length <= this.question.images_allowed) {
      this.isDisabled = false;
    }
  }
  async removeSelectedFile(index, item) {
    const modeSync = localStorage.getItem('modeSync');
    const newFormatId = `${this.question.id}-${this.poll.id}-${this.client.id}-${this.user.id}`;

    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      Swal.fire({
        icon: 'warning',
        title: 'Alerta',
        html: `<br><strong>¿Deseas continuar con la eliminación de este archivo?</strong>`,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const client = JSON.parse(localStorage.getItem('client'));
          const id = `${this.question.id}-${client.id}`;
          // const newId = `${id}-${item.token}`;
          const newId = `${newFormatId}-${item.token}`;

          await db.deleteFile(newId);
          // delete file from UrlList
          if (this.allData.length > 0) {
            this.urls.splice(index, 1);
            this.urlLength = this.urls.length;
            this.upload();
          } else {
            this.urls.splice(index, 1);
            this.urlLength = this.urls.length;
            if (this.urlLength !== 0) {
              this.upload();
            } else {
              this.optionSelected.emit(null);
            }
          }
          if (this.hasResponse && this.allData.length == 0) {
            this.hasResponse = false;
            this.dataToEmit = null;
            this.optionSelected.emit(null);
          }
          if (this.urls.length == 0) {
            this.dataToEmit = null;
            this.optionSelected.emit(null);
          }
          if (this.urls.length <= this.question.images_allowed) {
            this.isDisabled = false;
          }
        }
      });
      return;
    }

    if (item.isUpdate === false || !item.isUpdate) {
      // delete file from UrlList
      if (this.allData.length > 0) {
        this.urls.splice(index, 1);
        this.urlLength = this.urls.length;
        if (this.urls.length == 0) {
          this.dataToEmit = null;
          this.optionSelected.emit(null);
        }
        this.upload();
      } else {
        this.urls.splice(index, 1);
        this.urlLength = this.urls.length;
        if (this.urlLength !== 0) {
          this.upload();
        } else {
          this.dataToEmit = null;
          this.optionSelected.emit(null);
        }
      }
      if (this.urls.length <= this.question.images_allowed) {
        this.isDisabled = false;
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Alerta',
        html: `<br><strong>¿Deseas continuar con la eliminación de este archivo?</strong>`,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.allData.length > 0) {
            this.allData.splice(index, 1);
            this.urlLength = this.allData.length;
          }
          if (this.allData.length <= this.question.images_allowed) {
            this.isDisabled = false;
          }
          this.deleteFile(item.fileName);
        }
      });
    }
  }

  onSelectFileReemp(index, item) {
    Swal.fire({
      icon: 'warning',
      title: 'Alerta',
      html: `<br><strong>¿Deseas reemplazar este archivo?</strong>`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.activeReplace = true;
        this.indexForReplace = index;
        this.itemSelectedToReplace = item.isUpdate === true ? item : null;
        // const elementButton = document.getElementById('upload') as HTMLElement;
        // elementButton.click();
        this.indexReplace = index;
        this.takeWebCam(index);
      }
    });
  }

  async reempSelectedFile(value) {
    const modeSync = localStorage.getItem('modeSync');
    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      // reeplace file from UrlList
      const client = JSON.parse(localStorage.getItem('client'));
      const id = `${this.question.id}-${client.id}`;
      const newId = `${id}-${value.token}`;
      await db.deleteFile(newId);
      this.urls[this.indexForReplace] = value;
      this.urlLength = this.urls.length;
      this.activeReplace = false;

      this.upload();
      return;
    }
    // reeplace file from UrlList
    this.urls[this.indexForReplace] = value;
    this.urlLength = this.urls.length;
    this.activeReplace = false;
    this.upload();
  }

  reempSelectedFileOnDb(data) {
    data.value = `${data.token}/${data.fileName}`;
    data.idResponse = this.idResponse;
    data.question_id = this.question.id;
    data.oldValue = this.itemSelectedToReplace.fileName;
    this.activeReplace = false;
    this.spinner.show();
    this.responseService.replaceResponseFile(data).subscribe(
      (data) => {
        this.spinner.hide();
        this.getResponse(true);
      },
      (err) => {
        this.spinner.hide();
        console.log(err);
      }
    );
  }

  compressFile(file: File) {
    return new Promise(async (resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageCompress
          .compressFile(event.target.result, -1, 50, 50)
          .then((result) => {
            resolve(result);
          });
      };
      reader.readAsDataURL(file);
    });
  }

  getSignedImag(img) {
    return new Promise(async (resolve, reject) => {
      this.uploadService.getFiles(img).subscribe((url) => {
        resolve(url);
      });
    });
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  repeatValidationFile(dataToProcess) {
    return new Promise(async (resolve, reject) => {
      let uni = [];
      for (let i = 0; i < dataToProcess.length; i++) {
        let arrayDeCadenas = dataToProcess[i].fileName.split('/');
        uni.push({
          value:
            arrayDeCadenas.length === 1
              ? `${dataToProcess[i].token}/${dataToProcess[i].fileName}`
              : `${dataToProcess[i].fileName}`,
        });
      }
      if (dataToProcess.length === uni.length) {
        const reDuplicates = [...new Set(uni)];
        resolve(reDuplicates);
      }
    });
  }

  view(image) {
    this.dialog.open(ModalViewPreviewComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal',
      data: {
        image,
      },
    });
  }

  deleteFile(value) {
    this.spinner.show();
    this.responseService
      .deleteResponseFile({
        response_id: this.idResponse,
        question_id: this.question.id,
        value,
      })
      .subscribe(
        (data) => {
          this.spinner.hide();
          this.getResponse(true);
        },
        (err) => {
          this.spinner.hide();
          console.log(err);
        }
      );
  }

  refreshOptions(data: QuestionModel) {
    this.isDisabled = false;
    this.optionSelected.emit(null);
    this.urls = [];
  }

  async takeWebCam(index: number) {
    // this.dialog.open(ModalsComponent, {
    //   maxWidth: '100vw',
    //   maxHeight: '100vh',
    //   height: '100%',
    //   width: '100%',
    //   panelClass: 'full-screen-modal',
    //   data: {
    //     labelButton: this.tagQuestion,
    //   },
    // });
    const client = JSON.parse(localStorage.getItem('client'));
    const poll = JSON.parse(localStorage.getItem('poll'));

    let dialogRef = this.dialog.open(ModalsComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'custom-dialog-container',
      data: {
        labelButton: this.tagQuestion,
        index: index,
      },
      disableClose: true,
    });
    dialogRef.componentInstance.data = {
      labelButton: this.tagQuestion,
      images_allowed: this.question.images_allowed,
      urls: this.hasResponse ? this.allData.length : this.urls.length,
    };

    const sub = dialogRef.componentInstance.onAdd.subscribe(
      async (data: any) => {
        // debugger;
        const token = uuidv4();
        const fileUploadBase64 = [];
        let nameFile: string;
        if (this.hasResponse) {
          nameFile =
            this.question.title.replace(/\s/g, '') +
            '_' +
            this.allData.length.toString() +
            '.jpg';
        } else {
          nameFile =
            this.question.title.replace(/\s/g, '') +
            '_' +
            this.urls.length.toString() +
            '.jpg';
        }
        // Validamos que existe para reemplazar

        // if (this.offlineMode.status && this.offlineMode.from === 'clients') {
        //   const client = JSON.parse(localStorage.getItem('client'));
        //   const id = `${this.question.id}-${this.poll.id}-${client.id}-${this.user.id}`;
        // }
        if (this.indexReplace !== undefined) {
          if (this.hasResponse) {
            if (this.allData[this.indexForReplace].isUpdate) {
              if (
                this.offlineMode.status &&
                this.offlineMode.from === 'clients'
              ) {
                this.removeSelectedFileOffline(this.indexReplace, this.urls);
                this.urls[this.indexForReplace] = {
                  files: data,
                  file: {},
                  fileName: nameFile,
                  type: 'jpg',
                  token: token,
                  isUpdate: false,
                };
              } else {
                this.uploadFile(data, nameFile, token);
                this.responseService
                  .replaceResponseFileValue(
                    {
                      question_id: this.question.id,
                      oldValue: this.allData[this.indexForReplace].fileName,
                      nameFile: `${token}/${nameFile}`,
                      response_id: this.idResponse,
                      value: `${token}/${nameFile}`,
                    },
                    this.idResponse
                  )
                  .subscribe((data) => {
                    console.info(data);
                  });
              }
            }

            this.allData[this.indexForReplace] = {
              files: data,
              file: {},
              fileName: nameFile,
              type: 'jpg',
              token: token,
              isUpdate: false,
            };
          } else {
            this.urls[this.indexForReplace] = {
              files: data,
              file: {},
              fileName: nameFile,
              type: 'jpg',
              token: token,
              isUpdate: false,
            };
            this.indexReplace = undefined;
          }

          // this.urls.splice(this.indexForReplace, 1);
          // this.urls[this.indexForReplace] = {
          //   files: data,
          //   file: {},
          //   fileName: nameFile,
          //   type: 'jpg',
          //   token: token,
          //   isUpdate: false,
          // };
          this.allData = this.allData.filter(function (el) {
            return el != null;
          });
          this.urls = this.urls.filter(function (el) {
            return el != null;
          });
          this.indexReplace = undefined;

          setTimeout(() => {
            this.indexReplace = undefined;
            this.validateLengthAllow();
          }, 500);
        } else {
          this.urls.push({
            files: data,
            file: {},
            fileName: nameFile,
            type: 'jpg',
            token: token,
            isUpdate: false,
          });
          this.allData.push({
            files: data,
            file: {},
            fileName: nameFile,
            type: 'jpg',
            token: token,
            isUpdate: false,
          });
          this.indexForReplace = undefined;
          this.validateLengthAllow();
        }

        // this.urls$.next(this.urls);

        this.urls.forEach(async (element) => {
          const file = {
            id: `${this.question.id}-${poll.id}-${client.id}-${this.user.id}-${element.token}`,
            sync_poll_id: `${poll.id}-${client.id}-${this.user.id}`,
            poll_id: poll.id,
            data: {
              imagenBase64: element.files,
              imagen: element.file,
              token: element.token,
              fileName: element.fileName,
            },
          };
          // fileUploadBase64.push(file);
          await db.setFile(file);
          // localStorage.setItem(
          //   'fileResponse',
          //   JSON.stringify(fileUploadBase64)
          // );
        });
        setTimeout(async () => {
          let dataEmitTemp = [];

          // this.urls$.next();
          // this.urls.forEach(async (element) => {
          //   // console.info(element);
          //   if (element != undefined) {
          //     dataEmitTemp.push({
          //       value: `${element.token}/${element.fileName}`,
          //     });
          //   }
          // });
          for await (const element of this.urls) {
            if (element != undefined) {
              dataEmitTemp.push({
                value: `${element.token}/${element.fileName}`,
              });
            }
          }

          this.dataToEmit = dataEmitTemp;
          this.optionSelected.emit(this.dataToEmit);
        }, 100);
      }
    );
  }
  async takeWebCamDisable() {
    if (this.hasResponse) {
      if (Number(this.allData.length) >= Number(this.question.images_allowed)) {
        return true;
      } else {
        return false;
      }
    } else {
      if (Number(this.urls.length) >= Number(this.question.images_allowed)) {
        return true;
      } else {
        return false;
      }
    }
  }

  async uploadFile(fileBase64, name, token) {
    return new Promise<any>(async (resolve, reject) => {
      this.uploadService
        .pushFileBase64ToStorage(fileBase64, name, token)
        .subscribe(
          (event) => {
            if (event.type === HttpEventType.UploadProgress) {
              let percentage = Math.round((event.loaded / event.total) * 100);
            } else if (event instanceof HttpResponse) {
              resolve(event);
            }
          },
          (err) => {
            Swal.fire({
              icon: 'error',
              title: `Error en el envio de la Imagen la servidor`,
              customClass: {
                container: 'poll-popup-alert-position',
              },
              showConfirmButton: false,
              timer: 3000,
            });
          }
        );
    });
  }

  handleClick() {
    document.getElementById('upload-file').click();
  }

  validateLengthAllow() {
    if (this.hasResponse) {
      if (this.allData.length >= this.question.images_allowed) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    } else {
      if (this.urls.length >= this.question.images_allowed) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    }
  }
}
