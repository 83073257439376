import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-view-preview',
  templateUrl: './modal-view-preview.component.html',
  styleUrls: ['./modal-view-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalViewPreviewComponent {
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeModal(): void {
    this.dialogRef.close();
  }
}
