<div>
  <div style="display: flex; flex-direction: column; height: 80vh">
    <mat-form-field class="stretch-height text-area-class">
      <textarea
        matInput
        placeholder="Escriba su respuesta"
        [(ngModel)]="response"
        (ngModelChange)="onTextResponseChange($event)"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="5"
        cdkAutosizeMaxRows="10"
      >
      </textarea>
    </mat-form-field>

    <div class="spacer"></div>
  </div>
</div>
