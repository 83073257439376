import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  @Input() title: string;
  @Input() sub_title: String;
  @Input() img: String;
  @Input() actual_section: String;
  @Input() total_section: String = '5';
  @Input() isOfflineMode: Boolean = false;
  constructor() {}
}
