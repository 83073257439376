import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { gcp } from 'src/environments/environment';
import { catchError, timeout } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  defaultTimeout = 1800000;
  constructor(private authService: AuthService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    try {
      const timeoutValue =
        Number(req.headers.get('timeout')) || this.defaultTimeout;
      const authToken = this.authService.getToken();
      const authRequest = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + authToken),
        params: req.params.set('key', gcp.ApiKey),
      });
      return next.handle(authRequest).pipe(
        timeout(timeoutValue),
        catchError((error) => {
          return throwError(error);
        })
      );
    } catch (error) {
      return error;
    }
  }
}
