import Swal from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { ICreatedTask } from '../../interfaces';
import { TasksService } from '../../services/tasks.service';
import { ETaskStatus, ITaskCompleted } from '../../entities/task.entity';
import { finalize } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';

@Component({
  selector: 'app-response-view',
  templateUrl: './response-view.component.html',
  styleUrls: ['./response-view.component.css'],
})
export class ResponseViewComponent implements OnInit {
  public responseTask_1: ICreatedTask;
  public selectedQuestion_1: any;
  public selectedQuestion_2: any;
  public selectedResponse_1: any;
  public selectedResponse_2: any;
  public actionType: string;
  public responsesCount_1: any;
  public responsesCount_2: any;
  public selectedToResponse: any;
  public allowSaveMsg: boolean = false;
  public responseRejectedMsg: string;
  public isRejectedView: boolean = false;
  public isTabIndex_0: boolean = true;
  public spinnerMssg = 'Cargando...';
  public reasonRejection: string = '';
  public isConfirmDateView: boolean = false;
  public allowConfirmDate: boolean = false;
  public limitDate: Date;
  public dialogRef: MatDialogRef<DialogComponent>;

  obsCreate: any;
  obsConfirm: any;
  selectConfirm: any;
  photosResp: any[];
  photosRef: any[];

  lat: number;
  lng: number;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly taskService: TasksService,
    private readonly spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.taskService.$isTabIndex_0.subscribe((tabIndex) => {
      this.isTabIndex_0 = tabIndex !== 1 ? true : false;
    });

    navigator.geolocation.getCurrentPosition((position) => {
      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;
    });
    const data = localStorage.getItem('response_task');

    if (!data) {
      this.router.navigate(['/tasks']);

      return;
    }

    this.route.queryParams.subscribe((data) => {
      this.actionType = data.type;
    });

    this.responseTask_1 = JSON.parse(data);

    this.loadData();

    this.checkResponses();
  }

  loadData() {
    this.responseTask_1.response_options.forEach((response) => {
      if (
        response.type === 'Texto' &&
        response.question_task.type_task === 'create'
      ) {
        this.obsCreate = response;
      }
      if (
        response.type === 'Texto' &&
        response.question_task.type_task === 'confirm'
      ) {
        this.obsConfirm = response;
      }
      if (
        response.type === 'Selección simple' &&
        response.question_task.type_task === 'confirm'
      ) {
        const responseSimple = response.value === 'Sí' ? '1' : '0';
        this.selectConfirm = responseSimple;
      }
    });

    this.photosRef = this.responseTask_1.response_options.filter(
      (ref) =>
        ref.type === 'Carga de archivo' &&
        ref.question_task.type_task === 'create'
    );

    this.photosResp = this.responseTask_1.response_options.filter(
      (resp) =>
        resp.type === 'Carga de archivo' &&
        resp.question_task.type_task === 'confirm'
    );
  }

  handleViewInfo(obsConfirm, obsCreate) {
    this.selectedQuestion_1 = Array.isArray(obsCreate)
      ? obsCreate[0]
      : obsCreate;
    this.selectedQuestion_2 = Array.isArray(obsConfirm)
      ? obsConfirm[0]
      : obsConfirm;

    if (
      !obsCreate ||
      (!obsCreate && obsCreate.type) ||
      (Array.isArray(obsCreate) && obsCreate.length === 0)
    ) {
      Swal.fire({
        title: 'Observación de referencia excluida.',
        text: 'Esta tarea se ha configurado para excluir la observación de referencia.',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar',
      });
    }
  }

  cleanSelectedQuestion() {
    this.selectedQuestion_1 = null;
    this.selectedResponse_1 = null;
    this.selectedQuestion_2 = null;
    this.selectedResponse_2 = null;
  }

  handleViewResponse(photosRef, photosResp) {
    if (
      !photosRef ||
      (!photosRef && photosRef.type) ||
      (Array.isArray(photosRef) && photosRef.length === 0)
    ) {
      Swal.fire({
        title: 'Fotos de referencia excluidas.',
        text: 'Esta tarea se ha configurado para excluir las fotos de referencia.',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar',
      });

      return;
    }

    this.selectedResponse_1 = Array.isArray(photosRef)
      ? photosRef[0]
      : photosRef;

    this.selectedResponse_2 = Array.isArray(photosResp)
      ? photosResp[0]
      : photosResp;
  }

  checkResponses() {
    this.responsesCount_1 = this.responseTask_1.response_options.reduce(
      (acc, res) => {
        if (res.question_task.type_task === 'create') acc.created += 1;
        else if (res.question_task.type_task === 'confirm') acc.confirmed += 1;

        return acc;
      },
      {
        created: 0,
        confirmed: 0,
      }
    );

    this.responsesCount_2 = this.responseTask_1.response_options.reduce(
      (acc, res) => {
        if (res.question_task.type_task === 'create') acc.created += 1;
        else if (res.question_task.type_task === 'confirm') acc.confirmed += 1;

        return acc;
      },
      {
        created: 0,
        confirmed: 0,
      }
    );
  }

  handleResponse(task) {
    if (
      this.responseTask_1.template_status !== ETaskStatus.CREATED &&
      this.responseTask_1.template_status !== ETaskStatus.REJECTAD
    ) {
      return;
    }

    this.selectedToResponse = JSON.parse(localStorage.getItem('assigned_task'));

    localStorage.setItem(
      'question_to_response',
      JSON.stringify(this.getTaskById(this.selectedToResponse, task.id))
    );

    return this.router.navigate([`/tasks/${task.id}/response/${task.id}`]);
  }

  getTaskById(data, taskId) {
    for (let i = 0; i < data.length; i++) {
      const tasks = data[i].tasks;
      for (let j = 0; j < tasks.length; j++) {
        if (tasks[j].id === taskId) {
          return tasks[j];
        }
      }
    }
    return null;
  }

  handleReject() {
    if (this.responseTask_1.template_status === ETaskStatus.REJECTAD) return;
    this.isRejectedView = true;
  }

  handleSaveRejectedMsg() {
    Swal.fire({
      title: 'Confirmar rechazo de tarea.',
      text: `¿Estás seguro que deseas rechazar esta tarea?`,
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'NO',
      confirmButtonColor: '#0a4e31',
      confirmButtonText: 'SI',
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.isRejectedView = false;
        this.reactivateTask();
      } else {
        return;
      }
    });
  }

  reactivateTask() {
    Swal.fire({
      title: 'Finalizar respuesta.',
      text: '¿Desea volver a activar la tarea?',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'NO',
      confirmButtonColor: '#0a4e31',
      confirmButtonText: 'SI',
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.spinnerMssg = 'Guardando cambios...';
        if (!this.responseTask_1.task.isDeadlineQuestion) {
          this.isConfirmDateView = true;
        } else {
          this.rejectTask(true);
        }
      } else {
        this.rejectTask(false);
      }
    });
  }

  rejectTask(withDuplicate: boolean = false) {
    this.spinnerMssg = 'Guardando cambios...';
    this.spinner.show('confirm-spinner');
    console.log('INIT - rechazo de tarea');
    this.taskService
      .updateStatus(this.responseTask_1.id, {
        user_id: this.responseTask_1.user_id,
        customer_id: this.responseTask_1.customer.id,
        task_id: this.responseTask_1.task_id,
        date_rejected: new Date().toISOString(),
        position_rejected: `${this.lat};${this.lng}`,
        template_status: ETaskStatus.REJECTAD,
        reason_rejection: this.responseRejectedMsg,
      } as ITaskCompleted)
      .pipe(
        finalize(() => {
          console.log('FIN - rechazo de tarea');
          if (!withDuplicate) this.showSuccessDialog();
        })
      )
      .subscribe(
        () => {
          if (withDuplicate) {
            console.log('INIT - Duplicate task');
            this.taskService
              .duplicateCreatedTask(this.responseTask_1.id)
              .pipe(
                finalize(() => {
                  console.log('FIN - Duplicate task');
                  if (this.responseTask_1.task.isDeadlineQuestion)
                    this.showSuccessDialog();
                })
              )
              .subscribe(
                (response) => {
                  if (!this.responseTask_1.task.isDeadlineQuestion) {
                    console.log('INIT - Set limit date');
                    this.taskService
                      .updateStatus(response.data.id, {
                        user_id: this.responseTask_1.user_id,
                        customer_id: this.responseTask_1.customer.id,
                        date_rejected: new Date().toISOString(),
                        limit_date: this.limitDate,
                      } as ITaskCompleted)
                      .pipe(
                        finalize(() => {
                          console.log('FIN - Set limit date');
                          this.showSuccessDialog();
                        })
                      )
                      .subscribe(
                        () => {},
                        () => {
                          this.showSuccessDialog(true);
                        }
                      );
                  }
                },
                () => {
                  this.showSuccessDialog(true);
                }
              );
          }
        },
        () => {
          this.showSuccessDialog(true);
        }
      );
  }

  handleDateAnswer(event) {
    this.allowConfirmDate = true;
    this.limitDate = event;
  }

  handleDateConfirm() {
    this.isConfirmDateView = false;
    this.rejectTask(true);
  }

  handleRejectedMessageResponse(responseTask_1) {
    this.selectedQuestion_1 = 5;
    if (responseTask_1.reason_rejection) {
      this.reasonRejection = responseTask_1.reason_rejection;
    } else if (
      !responseTask_1.reason_rejection &&
      responseTask_1.original_task
    ) {
      this.reasonRejection = this.taskService.findReasonRejection(
        JSON.parse(localStorage.getItem('assigned_task')),
        responseTask_1.original_task
      );
    }
  }

  handleRejectedMsgAnswer(msg) {
    if (!msg?.length) {
      this.allowSaveMsg = false;
    } else {
      this.allowSaveMsg = true;
      this.responseRejectedMsg = msg;
    }
  }

  showSuccessDialog(isError: boolean = false) {
    this.spinner.hide('confirm-spinner');

    Swal.fire({
      text: `${
        isError ? 'ERROR AL GUARDAR CAMBIOS' : 'CAMBIOS GUARDADOS CON ÉXITO'
      }`,
      icon: `${isError ? 'warning' : 'success'}`,
      showConfirmButton: true,
      confirmButtonColor: '#0a4e31',
      confirmButtonText: 'Aceptar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/tasks']);
      } else {
        this.router.navigate(['/tasks']);
      }
    });
  }
}
