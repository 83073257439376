import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { PollModel } from '../../models/poll.model';

import { auth } from 'src/environments/environment';

import { Authb2bService } from '../../services/authb2b.service';

@Component({
  selector: 'app-b2blogin',
  templateUrl: './b2blogin.component.html',
  styleUrls: ['./b2blogin.component.css'],
})
export class B2bloginComponent implements OnInit {
  codClient: string;
  pollToken: string;
  authToken: string;
  authUserToken: string;

  poll: PollModel;

  constructor(
    private authb2bService: Authb2bService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) {
    localStorage.clear();
    localStorage.clear();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.codClient = params.codClient;
      this.pollToken = params.pollToken;
      this.authToken = params.authToken;
      this.fetchData();
    });
  }

  fetchData() {
    if (
      this.codClient !== undefined &&
      this.pollToken !== undefined &&
      this.authToken !== undefined
    ) {
      this.spinner.show();
      this.authb2bService
        .getValidateTokenClient({
          codClient: this.codClient,
          pollToken: this.pollToken,
          authToken: this.authToken,
        })
        .subscribe((client) => {
          localStorage.setItem('token', JSON.stringify(this.authToken));
          if (client === null) {
            this.dialog.open(DialogComponent, {
              data: {
                message: 'Parametro de Encuesta no validos!',
                status: 2,

                onlyClose: true,
              },
            });
            this.spinner.hide();
            window.location.href = `${auth.urlB2B}`;
          } else {
            if (client.status === true) {
              this.getClient();
            } else {
              this.removeData();
            }
          }
        });
    } else {
      this.removeData();
    }
  }
  getClient() {
    this.authb2bService
      .getClientByCodClient(this.codClient, this.pollToken)
      .subscribe((client) => {
        if (client === null) {
          this.dialog.open(DialogComponent, {
            data: {
              message: 'Parametro de Encuesta no validos!',
              status: 2,

              onlyClose: true,
            },
          });
          this.spinner.hide();
          window.location.href = `${auth.urlB2B}`;
        } else {
          if (client.status === true) {
            this.dialog.open(DialogComponent, {
              data: {
                message: 'Encuesta ya se encuetra realizada!',
                status: 2,

                onlyClose: true,
              },
            });
          } else {
            this.authUserToken = client.token.toString();
            localStorage.setItem('token', client.token.toString());
            localStorage.setItem('client', JSON.stringify(client));

            this.getPoll();
          }
        }
      });
  }

  getPoll() {
    this.authb2bService.getPollByToken(this.pollToken).subscribe((poll) => {
      localStorage.removeItem('poll');
      if (poll != null) {
        this.poll = poll;
        if (poll.geolocation === true) {
          navigator.geolocation.getCurrentPosition((position) => {
            const coords = position.coords;
            const latLong = [coords.latitude, coords.longitude];
            localStorage.setItem('lat', coords.latitude.toString());
            localStorage.setItem('long', coords.longitude.toString());
          });
        }
        const dataPoll = {
          id: poll.id,
          name: poll.name,
          description: this.poll.description,
          sections: this.sortJSON(poll.sections, 'weight', 'asc'),
          messages: poll.messages,
          expireDate: poll.expire_date,
          publishDate: poll.publish_date,
          countquestion: poll.countquestion,
          geolocation: poll.geolocation,
          minutes: poll.minutes,
          infoextra: poll.infoextra,
          showmessageinit: poll.showmessageinit,
          infoextrapoll: poll.infoextrapoll,
          infoextracustomer: poll.infoextracustomer,
          addgoals: poll.addgoals,
          measuring_period: poll.measuring_period,
        };
        localStorage.setItem('poll', JSON.stringify(dataPoll));
        this.initPoll();
      } else {
        this.removeData();
      }
    });
  }

  sortJSON(data, key, orden) {
    return data.sort(function (a, b) {
      var x = a[key],
        y = b[key];

      if (orden === 'asc') {
        return x < y ? -1 : x > y ? 1 : 0;
      }

      if (orden === 'desc') {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  }
  removeData(): void {
    this.dialog.open(DialogComponent, {
      data: {
        message: 'No Ingresará con el usuario indicado.',
        status: 2,
        route: 'login',
        onlyClose: true,
      },
    });
  }

  initPoll() {
    this.spinner.hide();
    localStorage.removeItem('navHistory');
    if (this.poll.infoextra) {
      this.router.navigate(['poll/client/info']);
    } else {
      this.router.navigate(['poll/client/init']);
    }
  }
}
