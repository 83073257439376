import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { auth } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PollstersService {
  public API_URL: string = auth.urlConfig;

  constructor(private readonly httpClient: HttpClient) {}

  getPollsters(data: any): Observable<any> {
    const params = {
      pageNum: data.pageNum,
      pageSize: data.pageSize,
      name: data?.name || '',
    };

    const url: string = `${this.API_URL}/pollsters-clients`;

    return this.httpClient
      .get(url, { params })
      .pipe(catchError(this.handleError));
  }

  getPollstersByPosition(data: any): Observable<any> {
    const params = {
      pageNum: data.pageNum,
      pageSize: data.pageSize,
      searchString: data.searchString,
      methodSearch: data.methodSearch,
      currentPosition: `${data.currentPosition.lat},${data.currentPosition.lng}`,
      distance: data.distance,
    };

    const url: string = `${this.API_URL}/pollsters-clients`;

    return this.httpClient
      .get(url, { params })
      .pipe(catchError(this.handleError));
  }

  getPollsterByPosition(data: any): Observable<any> {
    const params = {
      pageNum: data.pageNum,
      pageSize: data.pageSize,
      methodSearch: data.methodSearch,
      distance: data.distance,
      client: data.client,
    };

    const url: string = `${this.API_URL}/pollsters-clients`;

    return this.httpClient
      .get(url, { params })
      .pipe(catchError(this.handleError));
  }

  getPollstersCount(): Observable<any> {
    const url: string = `${this.API_URL}/pollsters-clients/count`;

    return this.httpClient.get(url).pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    console.error('An error occurred:', error);

    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }

    return throwError('Something bad happened; please try again later.');
  }
}
