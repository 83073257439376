<div fxLayout="column">
  <mat-sidenav-container>
    <mat-sidenav #sidenav="matSidenav" class="mat-elevation-z7">
      <app-filters-menu
        (optionSelected)="getSelected($event)"
        (refreshStats)="fetchStats()"
        (closeNavEvent)="closeNav()"
      ></app-filters-menu>
    </mat-sidenav>
    <mat-sidenav-content>
      <app-header
        [isOfflineMode]="isOfflineMode"
        [title]="poll.name"
        [sub_title]="'Dashboard'"
        [isOfflineMode]="offlineMode.status"
      >
      </app-header
      ><br />

      <app-header-user></app-header-user>
      <div fxLayout="column">
        <div fxLayout="row-reverse">
          <button
            mat-mini-fab
            color="primary"
            class="example-tab-icon"
            (click)="sidenav.toggle()"
            class="mat-elevation-z"
          >
            <mat-icon> filter_alt</mat-icon>
          </button>
        </div>
        <div fxLayout="column" id="line-chart-parent ">
          <ngx-charts-advanced-pie-chart
            [view]="view"
            [scheme]="colorScheme"
            [results]="single"
          >
          </ngx-charts-advanced-pie-chart>
        </div>
        <br /><br /><br /><br /><br /><br />
        <div fxLayout="column" class="div-advance">
          <div fxLayout="row" fxLayoutAlign="center center" class="divstats">
            <div fxFlex="25">
              <div fxLayout="column">
                <span class="indicators-text">Inicio:</span>
                <span class="indicators-value">{{ initDate }}</span>
              </div>
            </div>

            <div fxFlex="25">
              <div fxLayout="column">
                <span class="indicators-text">Transcurrido:</span>
                <!-- <span class="indicators-text" *ngIf="advanceDays<0">Para iniciar:</span> -->
                <span class="indicators-value">{{ advanceDays }} días</span>
              </div>
            </div>
            <div fxFlex="25">
              <div fxLayout="column">
                <span class="indicators-text">Quedan:</span>
                <span class="indicators-value">{{ remain }} días</span>
              </div>
            </div>
            <div fxFlex="25">
              <div fxLayout="column">
                <span class="indicators-text">Cierre:</span>
                <span class="indicators-value">{{ endDate }}</span>
              </div>
            </div>
          </div>
          <br />

          <span class="indicators-text span-value"
            >Meta diaria para llegar a 100%</span
          >
          <span class="indicators-text span-value"
            ><span class="indicators-value">
              {{ this.totalToday }}/{{ this.dailyGoal }}</span
            ></span
          >
        </div>
      </div>

      <div fxLayout="column">
        <div fxLayout="column" class="div-advance-bar">
          <span class="indicators-text span-value">Avance proyectado</span>
          <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
            <span class="indicators-value"
              >{{ projectedProgressFinish | number: '1.0-0' }} %</span
            >
            <mat-progress-bar
              fxFlex
              mode="determinate"
              [value]="projectedProgressFinish"
            ></mat-progress-bar>
          </div>

          <span class="indicators-text span-value">Completado</span>
          <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
            <span class="indicators-value"
              >{{ percentage | number: '1.0-0' }} %</span
            >
            <mat-progress-bar
              fxFlex
              mode="determinate"
              [value]="percentage"
            ></mat-progress-bar>
          </div>
        </div>
      </div>

      <div fxLayout="row" class="btn-footer">
        <div fxFlex="50" fxLayoutAlign="start center">
          <button class="back-button" mat-button (click)="returnList()">
            Volver
          </button>
        </div>

        <div fxFlex="50" fxLayoutAlign="end center">
          <button class="next-button" mat-button (click)="aplyPoll()">
            Ir a Encuestar
          </button>
        </div>
      </div>
      <ngx-spinner
        bdColor="rgba(51,51,51,0.8)"
        size="medium"
        color="rgb(92, 184, 92)"
        type="ball-clip-rotate-pulse"
        class="content-spinner"
      ></ngx-spinner>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
