<div class="text-container">
  <div class="text-title-box">
    <p class="text-title">{{ question.title }}</p>
  </div>

  <div class="text-box">
    <div class="text-help-box">
      <p class="text-help">{{ question.help }}</p>
    </div>

    <div class="text-textarea">
      <textarea
        [(ngModel)]="question?.response?.answer || response"
        (ngModelChange)="handleAnswer($event)"
        name="text"
        cols="30"
        rows="10"
        #textAreaInput
      ></textarea>
    </div>
  </div>
</div>
