import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalsComponent implements OnInit {
  facingMode = 'environment';

  @Output() onAdd = new EventEmitter<any>(true);
  width: number;
  height: number;
  public mediaConstraints = {
    video: {
      optional: [
        { minWidth: 720 },
        { minWidth: 1024 },
        { minWidth: 1280 },
        { minWidth: 1920 },
        { minWidth: 2560 },
        { minHeight: 720 },
        { minHeight: 1024 },
        { minHeight: 1280 },
        { minHeight: 1920 },
        { minHeight: 2560 },
      ],
    },
  };

  // toggle webcam on/off
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public captureImageData: boolean = true;
  public mirrorImage: string = 'always';
  private urls: number;
  private images_allowed: number;
  public matValue: number;

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    const win = !!event ? (event.target as Window) : window;
    this.width = win.innerWidth - 45;
    this.height = win.innerHeight;
  }
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.onResize();
  }

  public get videoOptions(): MediaTrackConstraints {
    //you can set ideal,min,max for width and height
    const result: MediaTrackConstraints = {
      width: { min: 640, ideal: 1920 },
      height: { min: 480, ideal: 1080 },
    };

    if (this.facingMode && this.facingMode !== '') {
      result.facingMode = { ideal: this.facingMode };
    }

    return result;
  }
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();

  public ngOnInit(): void {
    this.urls = this.data.urls;
    console.info(!navigator.mediaDevices);
    // if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    this.images_allowed = this.data.images_allowed;
    // if (!navigator.mediaDevices?.enumerateDevices) {
    //   console.log('enumerateDevices() not supported.');
    // } else {
    //   // List cameras and microphones.
    //   navigator.mediaDevices
    //     .enumerateDevices()
    //     .then((devices) => {
    //       devices.forEach((device) => {
    //         console.log(device);
    //         console.log(
    //           `Tipo de DEVICE: ${device.kind}: ${device.label} id = ${device.deviceId}`
    //         );
    //       });
    //     })
    //     .catch((err) => {
    //       console.error(`${err.name}: ${err.message}`);
    //     });
    // }
    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      }
    );
    if (
      this.multipleWebcamsAvailable &&
      localStorage.getItem('deviceId')?.length > 0
    ) {
      this.nextWebcam.next(localStorage.getItem('deviceId'));
    }
  }

  public triggerSnapshot(): void {
    if (
      !this.multipleWebcamsAvailable &&
      localStorage.getItem('deviceId')?.length == 0
    ) {
      Swal.fire({
        icon: 'info',
        title: `Desea mantener la camara actual como camara por defecto `,
        showCancelButton: true,
        confirmButtonText: 'Si, mantener cámara actual',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.setItem('deviceId', this.deviceId);
        }
      });
    }
    this.trigger.next();

    this.dialogRef.close();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    // console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
    // console.info('received webcam base64', this.webcamImage.imageAsDataUrl);
    // console.info('received webcam base64', this.webcamImage.imageAsBase64);
    this.urls++;
    // if (this.urls > this.images_allowed) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: `La cantidad máxima de archivos permitidos es ${this.images_allowed}`,
    //     showConfirmButton: false,
    //     timer: 3000,
    //   });
    // } else {
    this.onAdd.emit(this.webcamImage.imageAsDataUrl);
    // if (this.urls == this.images_allowed) {
    //   Swal.fire({
    //     icon: 'warning',
    //     title: `Se ha alcanzado la cantidad máxima de archivos permitidos (${this.images_allowed})`,
    //     showConfirmButton: false,
    //     timer: 3000,
    //   });
    // }
    // }
  }

  public cameraWasSwitched(deviceId: string): void {
    if (localStorage.getItem('deviceId')?.length > 0) {
      this.deviceId = deviceId;
    } else {
      this.deviceId = deviceId;
    }
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  disabledTake() {
    if (this.urls > this.images_allowed) {
      return true;
    } else {
      return false;
    }
  }
  closeModal(): void {
    this.dialogRef.close();
  }
  private switchTorch: Subject<void> = new Subject<void>();
  public torchAvailable: boolean = false;

  public switchingTorch(): void {
    this.switchTorch.next();
  }

  public get switchTorchObservable(): Observable<void> {
    return this.switchTorch.asObservable();
  }

  public torchIsAvailable($event: boolean) {
    this.torchAvailable = $event;
  }
}
