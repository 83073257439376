import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';

import { auth } from '../../../environments/environment';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StatsService {
  constructor(private httpClient: HttpClient) {}

  getStatsByPoll(id: number) {
    const url = `${auth.urlConfig}/pollsters/stats/poll/${id}`;
    let params = new HttpParams();
    const res = JSON.parse(localStorage.getItem('filterPollData'));
    if (res !== undefined && res !== null) {
      res.forEach((itemParam) => {
        params = params.append(itemParam.source, itemParam.code.toString());
      });
    }

    return this.httpClient
      .get<{
        total: number;
        totalInit: number;
        totalFinit: number;
        totalFinish: any;
        totalToday: number;
      }>(url, { params })
      .pipe(retry(1), catchError(this.handleError));
  }

  getStatsByPollPeriod(id: number) {
    const url = `${auth.urlConfig}/pollsters/stats/pollperiod/${id}`;
    let params = new HttpParams();
    const res = JSON.parse(localStorage.getItem('filterPollData'));
    if (res !== undefined && res !== null) {
      res.forEach((itemParam) => {
        params = params.append(itemParam.source, itemParam.code.toString());
      });
    }
    return this.httpClient
      .get<{
        pollByDay: Array<any>;
        total: number;
        initDate: any;
        endDate: any;
        daysElapsed: any;
        daysremaining: any;
      }>(url, { params })
      .pipe(catchError(this.handleError));
  }

  // getStatsByPollPeriodGoals(id: number) {
  //   // const url = `${auth.urlConfig}/poll/${id}/statsPeriod`;
  //   const url = `${auth.urlConfig}/pollsters/stats/pollgoalp/${id}`;
  //   let params = new HttpParams();
  //   const res = JSON.parse(localStorage.getItem('filterPollData'));
  //   if (res !== undefined && res !== null) {
  //     res.forEach((itemParam) => {
  //       // console.log(itemParam);
  //       params = params.append(itemParam.source, itemParam.code.toString());
  //     });
  //   }
  //   return this.httpClient.get<any>(url, { params }).pipe(
  //     // retry(1),
  //     catchError(this.handleError)
  //   );
  // }

  getStatsByPollPeriodGoals(id: number, data: Array<any>) {
    const url = `${auth.urlConfig}/pollsters/stats/pollgoalp/${id}`;
    let params = new HttpParams();
    const res = JSON.parse(localStorage.getItem('filterPollData'));

    if (data.length > 0) {
      // let sellers = [];
      data.forEach((seller) => {
        params = params.append('ven', seller.code);
      });
    } else {
      if (res !== undefined && res !== null) {
        res.forEach((itemParam) => {
          params = params.append(itemParam.source, itemParam.code.toString());
        });
      }
    }

    return this.httpClient
      .get<any>(url, { params })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      return throwError('Something bad happened; please try again later.');
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
