import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { OptionModel } from 'src/app/polls/models/option.model';
import { QuestionModel } from 'src/app/polls/models/question.model';
import { ResponseService } from 'src/app/polls/services/response.service';
import { OfflineService } from 'src/app/polls/services/offline.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
// Dexie DB
import { db } from 'src/app/db/db';
import { UserModel } from 'src/app/polls/models/user.model';
import { PollModel } from 'src/app/polls/models/poll.model';
import * as jwt_decode from 'jwt-decode';
@Component({
  selector: 'app-multiple',
  templateUrl: './multiple.component.html',
  styleUrls: ['./multiple.component.css'],
})
export class MultipleComponent implements OnChanges {
  @ViewChild('select') select: MatSelect;
  @Input() question: QuestionModel;
  @Input() selected: any;
  @Input() idResponse: number;
  @Output() optionSelected = new EventEmitter();
  @Output() showHideSpiner = new EventEmitter();
  offlineMode: any = {
    status: false,
    from: 'nonde',
  };
  user: UserModel;
  poll: PollModel;

  selecteds = [];
  constructor(
    private responseService: ResponseService,
    private offlineService: OfflineService
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
    this.poll = JSON.parse(localStorage.getItem('poll'));
  }

  ngOnChanges(): void {
    if (this.selected == null) {
      this.selecteds = [];
      this.getResponse();
    }
  }

  onSelected(i: number, question: String) {
    this.selected = i;
    const index = this.selecteds.indexOf(this.selected, 0);

    if (index === -1) {
      this.selecteds.push(i);
      setTimeout(() => {
        this.optionSelected.emit(this.selecteds);
      }, 500);
    } else {
      this.selecteds.splice(index, 1);

      setTimeout(() => {
        this.optionSelected.emit(this.selecteds);
      }, 500);
    }
    // this.nextQuestion.emit(question);
  }

  getResponse() {
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        if (offlineHeader) {
          this.offlineMode = offlineHeader;
        }

        // Init Offline Mode
        if (this.offlineMode.status && this.offlineMode.from === 'clients') {
          const client = JSON.parse(localStorage.getItem('client'));
          const id = `${this.question.id}-${this.poll.id}-${client.id}-${this.user.id}`;
          this.offlineService
            .getQuestionResponseDB(id)
            .then(async (questionData) => {
              if (questionData && questionData.data.options != null) {
                Object.values(questionData.data.options).forEach(
                  (element: any) => {
                    this.selecteds.push(
                      this.question.questionoptions.indexOf(
                        this.question.questionoptions.find(
                          (x) => x.id == element.option_id
                        )
                      )
                    );
                  }
                );

                if (this.selecteds.length > 0) {
                  this.select?.options.forEach((item: MatOption, index) => {
                    if (this.selecteds.indexOf(index) != -1) {
                      item.select();
                      this.optionSelected.emit(this.selecteds);
                    }
                  });
                  this.optionSelected.emit(this.selecteds);
                }
              }
              this.showHideSpiner.emit('hide');
            });
          return;
        }
        // End Offline Mode

        this.showHideSpiner.emit('show');
        this.responseService
          .getResponseQuestion({
            response_id: this.idResponse,
            question_id: this.question.id,
          })
          .subscribe((data) => {
            if (data != null) {
              Object.values(data).forEach((element) => {
                const idxOptionsQ = this.question.questionoptions.find(
                  (x) => x.id == element.option_id
                );
                if (idxOptionsQ) {
                  this.selecteds.push(
                    this.question.questionoptions.indexOf(
                      this.question.questionoptions.find(
                        (x) => x.id == element.option_id
                      )
                    )
                  );
                }
              });

              if (this.selecteds.length > 0) {
                this.select?.options.forEach((item: MatOption, index) => {
                  if (this.selecteds.indexOf(index) != -1) {
                    item.select();
                    this.optionSelected.emit(this.selecteds);
                  }
                });
                this.optionSelected.emit(this.selecteds);
              }
            } else {
              this.optionSelected.emit([]);
            }
            this.showHideSpiner.emit('hide');
          });

        // ques-8-opt-20
      });
  }
}
