import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css'],
})
export class ConfirmComponent {
  title: String;
  message: String;
  confirm: Boolean;
  cancel: Boolean;
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.cancel = data.cancel === false ? data.cancel : true;
    this.title = data.title ? data.title : 'Confirmación';
    this.message = data.message;
    this.confirm = data.confirm;
  }
}
