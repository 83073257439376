import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { HttpResponse, HttpClient, HttpEventType } from '@angular/common/http';
import { CoolerLoadFilePickerAdapter } from './cooler-load-file-picker.adapter';
import { FilePickerComponent } from 'ngx-awesome-uploader';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxImageCompressService } from 'ngx-image-compress';
import { UploaderCaptions } from 'ngx-awesome-uploader';
import { Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

import { ResponseService } from 'src/app/polls/services/response.service';
import { UploadService } from 'src/app/polls/services/upload.service';
import { OfflineService } from 'src/app/polls/services/offline.service';
import { UserModel } from 'src/app/polls/models/user.model';

import * as jwt_decode from 'jwt-decode';
import { ModalsComponent } from '../../../files-webcam/modals/modals.component';
import { MatDialog } from '@angular/material/dialog';
import { QuestionModel } from 'src/app/polls/models/question.model';

export interface FilePreviewModel {
  /** uploadResponse is the response of api after file uploaded */
  uploadResponse?: any;
  file: File | Blob;
  fileName: string;
  token?: any;
}
@Component({
  selector: 'app-cooler-loadfile',
  templateUrl: './cooler-loadfile.component.html',
  styleUrls: ['./cooler-loadfile.component.css'],
})
export class CoolerLoadfileComponent implements OnInit, OnChanges {
  // @ViewChild(Loadfile2Component, { static: false }) loadfilechild2C: Loadfile2Component;
  @ViewChild('uploader', { static: false }) uploader: FilePickerComponent;
  public adapter = new CoolerLoadFilePickerAdapter(this.http);
  public myFiles: FilePreviewModel[] = [];

  @Input() idResponse: number;
  // @Input() question: QuestionModel;

  @Input() questionId: number;
  @Input() fieldId: number;
  @Input() fileOptions: any;
  @Input() onlyPhone: boolean;
  @Input() required: boolean;
  @Input() dataImageSelected;
  @Input() question: QuestionModel;

  @Input() selected: any;
  @Output() optionSelected = new EventEmitter();
  @Output() imageSelected = new EventEmitter();
  @ViewChild('FileInputQuestion', { static: false })
  _questionId;
  _fieldId;

  // this InputVar is a reference to our input.
  FileInputQuestionControl: ElementRef;

  progress: { percentage: number } = { percentage: 0 };
  acceptFileString = '';
  acceptFile = [];
  fileExtensions = [];
  itemImageVisible: boolean = false;
  captions: UploaderCaptions;
  tokenSubject: Subject<any> = new Subject<any>();
  itemImageUrlDefault = 'assets/images/1.png';
  itemImageSubject: Subject<string> = new Subject<string>();
  selectedFiles: FileList;
  element: HTMLElement;
  elementButton: HTMLElement;
  currentFileUpload: File;
  localUrl: any;
  fileName: string;
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;
  fileTypes: any;
  hasResponse: boolean = false;
  offlineMode: any = {
    status: false,
    from: 'nonde',
  };

  //acceptable file types

  //

  isFileUpload = false;
  dataImageSelectedFromBackend = null;
  user: UserModel;

  constructor(
    private responseService: ResponseService,
    private uploadService: UploadService,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private imageCompress: NgxImageCompressService,
    private offlineService: OfflineService,
    public dialog: MatDialog
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
  }

  ngOnInit(): void {
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        if (offlineHeader) {
          this.offlineMode = offlineHeader;
        }

        if (this.dataImageSelected) {
          this.isFileUpload = true;
          if (!this.dataImageSelected.includes('data:image')) {
            this.uploadService
              .getFiles(this.dataImageSelected)
              .subscribe((url) => {
                this.dataImageSelectedFromBackend = url.url;
              });
          }
        }
        this.fileTypes = this.fileOptions.allowableFile.toLocaleLowerCase();
        this.fileOptions.allowableFile.split(',').forEach((element) => {
          this.fileExtensions.push(`'${element.toLocaleLowerCase()}'`);
          this.acceptFile.push(`.${element.toLocaleLowerCase().trim()}`);
        });
        this.acceptFileString = this.acceptFile.toString();
      });
  }
  ngOnChanges(): void {
    this._questionId = this.questionId;
    this._fieldId = this.fieldId;
  }

  loadComponentFile(mode) {
    if (mode === 1) {
      // with data
    } else {
      // without data
      this.itemImageSubject.next(undefined);

      this.itemImageUrlDefault = undefined;
      // this.itemImageVisible = true;

      this.fileName = '';
    }
  }

  public onUploadSuccess(event): void {
    this.itemImageVisible = false;
    // event['token']=this.adapter.getToken()
    // this.optionSelected.emit(`${this.adapter.getToken()}/${event.file.name}`);
    event['token'] = this.adapter.getToken();
    this.optionSelected.emit(`${this.adapter.getToken()}/${event.file.name}`);
  }

  public onRemoveSuccess(e: FilePreviewModel) {
    this.optionSelected.emit(null);
  }

  public onFileAdded(file: FilePreviewModel) {
    this.myFiles.push(file);
  }
  public onRemoveFirstFile() {
    this.myFiles.forEach((file) => {
      if (this.onlyPhone == true) {
        // this.loadfilechild2C.removeFile(file)
      } else {
        this.uploader.removeFileFromList(file);
      }
    });
  }
  callSelectFile() {
    this.elementButton = document.getElementById(this.getId()) as HTMLElement;
    this.elementButton.click();
  }
  selectFile(event) {
    this.spinner.show();
    this.selectedFiles = event.target.files;
    const file = (event.target as HTMLInputElement).files[0];
    const token = uuidv4();
    if (event.target.type === 'file') {
      // this.spinner.show('sp6');
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
      var extension = event.target.files[0].name.split('.').pop().toLowerCase();
      const isSuccess = this.fileTypes.indexOf(extension) > -1;
      if (isSuccess) {
        this.isFileUpload = true;

        //yes
        reader.onload = (event: any) => {
          this.localUrl = event.target.result;
          this.compressFile(this.localUrl, file.name);
        };
        this.spinner.hide();
      } else {
        //no
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: `Tipo de archivo no valido`,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  }

  convertBlobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  async upload(imagen, token: string, fileName: string) {
    this.spinner.show(this.getLoaderId());
    this.progress.percentage = 0;
    this.currentFileUpload = this.selectedFiles.item(0);

    const base64String = (await this.convertBlobToBase64(imagen)) as string;

    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      this.imageSelected.emit({
        fieldId: this._fieldId,
        base64: base64String,
        name: fileName,
        token: uuidv4(),
      });
      // this.prepareComponentFile(token, fileName);
      this.spinner.hide(this.getLoaderId());

      return;
    }

    this.uploadService
      .pushFileBase64ToTempFolder(base64String, fileName)
      .subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            let percentage = Math.round((event.loaded / event.total) * 100);
          } else if (event instanceof HttpResponse) {
            this.imageSelected.emit({
              fieldId: this._fieldId,
              base64: base64String,
              name: event.body?.name,
              token: event?.body?.token,
            });
            // this.prepareComponentFile(token, fileName);
            this.spinner.hide(this.getLoaderId());
          }
        },
        (err) => {
          this.spinner.hide(this.getLoaderId());
          Swal.fire({
            icon: 'error',
            title: `Error en el envio de la Imagen la servidor`,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      );

    // this.uploadService.pushFileToStorage(imagen, token, fileName).subscribe(
    //   (event) => {
    //     if (event.type === HttpEventType.UploadProgress) {
    //       this.progress.percentage = Math.round(
    //         (event.loaded / event.total) * 100
    //       );
    //       if (this.progress.percentage == 100) {
    //         this.optionSelected.emit(`${token}/${fileName}`);
    //       }
    //     } else if (event instanceof HttpResponse) {
    //       console.log('aqui deberia poder ver el archivo');
    //       this.prepareComponentFile(token, fileName);
    //       this.spinner.hide();
    //     }
    //     this.itemImageVisible = true;
    //   },
    //   (err) => {
    //     this.selectedFiles = undefined;
    //     this.spinner.hide();
    //     // console.log('HTTP Error', err)
    //     Swal.fire({
    //       icon: 'error',
    //       title: `Error en el envio de la Imagen la servidor`,
    //       showConfirmButton: false,
    //       timer: 3000,
    //     });
    //   }
    // );
    // this.selectedFiles = undefined;
  }

  prepareComponentFile(token, fileName) {
    this.optionSelected.emit(`${token}/${fileName}`);
    this.spinner.hide();
  }

  compressFile(image, fileName) {
    var orientation = -1;

    this.imageCompress
      .compressFile(image, orientation, 50, 50)
      .then((result) => {
        this.upload(
          this.dataURItoBlob(result.split(',')[1]),
          uuidv4(),
          fileName
        );
      });
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  removeUpload() {
    this.isFileUpload = false;
    this.imageSelected.emit({
      fieldId: this._fieldId,
      base64: null,
      name: null,
      token: null,
    });
    // this.imageSelected.emit(null);
  }

  replaceImage() {
    this.removeUpload();
    this.elementButton = document.getElementById(
      `control-input-file`
    ) as HTMLElement;
    this.elementButton.click();
  }

  getId() {
    return `control-input-file-${this._fieldId}`;
  }

  getImage(): string {
    return this.dataImageSelected?.includes('data:image')
      ? this.dataImageSelected
      : this.dataImageSelectedFromBackend
        ? this.dataImageSelectedFromBackend
        : this.dataImageSelected;
  }

  getLoaderId() {
    return `sp6-${this._fieldId}`;
  }

  async takeWebCam(index: number) {
    // this.dialog.open(ModalsComponent, {
    //   maxWidth: '100vw',
    //   maxHeight: '100vh',
    //   height: '100%',
    //   width: '100%',
    //   panelClass: 'full-screen-modal',
    //   data: {
    //     labelButton: this.tagQuestion,
    //   },
    // });
    const client = JSON.parse(localStorage.getItem('client'));
    const poll = JSON.parse(localStorage.getItem('poll'));

    let dialogRef = this.dialog.open(ModalsComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'custom-dialog-container',

      disableClose: true,
    });
    dialogRef.componentInstance.data = {};

    const sub = dialogRef.componentInstance.onAdd.subscribe(
      async (data: any) => {
        this.dataImageSelected = data;
        const fileUploadBase64 = [];
        let fileName: string;
        if (this.hasResponse) {
          fileName = this.question.title.replace(/\s/g, '') + '.jpg';
        } else {
          fileName = this.question.title.replace(/\s/g, '') + '.jpg';
        }
        this.uploadService.pushFileBase64ToTempFolder(data, fileName).subscribe(
          (event) => {
            if (event.type === HttpEventType.UploadProgress) {
              let percentage = Math.round((event.loaded / event.total) * 100);
            } else if (event instanceof HttpResponse) {
              const token = event.body.token;
              this.optionSelected.emit({
                fieldId: this._fieldId,
                base64: data,
                name: fileName,
                token: token,
              });
              this.imageSelected.emit({
                fieldId: this._fieldId,
                base64: data,
                name: fileName,
                token: token,
              });
              // this.prepareComponentFile(token, fileName);

              this.spinner.hide(this.getLoaderId());
              this.isFileUpload = true;
            }
          },
          (err) => {
            this.spinner.hide(this.getLoaderId());
            Swal.fire({
              icon: 'error',
              title: `Error en el envio de la Imagen la servidor`,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        );
      }
    );
  }
}
