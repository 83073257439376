<div fxLayoutAlign="center center" fxLayout="row">
  <mat-card class="result-card">
    <mat-card-content>
      <div class="label-container label-horizontal">
        <span class="sub-title">{{ minScaleLabel }}</span>
        <span class="sub-title">{{ maxScaleLabel }}</span>
      </div>
      <mat-slider
        class="example-margin"
        [max]="maxValue"
        [min]="minValue"
        [step]="1"
        [thumbLabel]="thumbLabel"
        [value]="minValue"
        tickInterval="1"
        [(ngModel)]="value"
        aria-label="units"
        (change)="selectScale($event)"
      >
      </mat-slider>

      <br />

      <div class="label-container label-horizontal">
        <label id="name-label" class="sub-title">Valor seleccionado</label>
        <label class="sub-title">{{ value }}</label>
      </div>
    </mat-card-content>
  </mat-card>
</div>
