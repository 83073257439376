import * as moment from 'moment';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-template-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.css'],
})
export class TaskTemplateDateComponent {
  @Input() answerMaded: string = null;
  @Output() answer: EventEmitter<Date> = new EventEmitter();
  minDate: Date;

  constructor() {
    this.minDate = moment().startOf('day').toDate(); // La fecha mínima se mantiene igual
  }

  handleAnswer(answer) {
    const selectedDate: Date = moment(answer.value).toDate();
    this.answer.emit(selectedDate);
  }
}
