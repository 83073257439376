<section class="content-clients" infiniteScroll *ngIf="clients.length">
  <div fxLayout="column" *ngFor="let client of clients">
    <mat-accordion class="accordion-headers-align" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="panelTitle">
            <div
              [ngClass]="{
                'indicator-in-time': client?.pending || false,
                'indicator-to-expire': client?.toExpired || false,
                'indicator-late': client?.expired || false
              }"
              class="tasks-client-badge"
              matBadge="{{ client.tasks.length }}"
              matBadgePosition="before"
            ></div>

            <span style="margin-top: 5px">
              {{ client.customer.descr_cliente }}
              <br />
              {{ client.customer.direccion }}
              <br />
              <span class="datePoll">Código: {{ client.customer.cliente_id }}</span>
            </span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-container>
          <mat-card *ngFor="let task of client.tasks" class="task-box">
            <div class="task-info-box">
              <div class="task-info-icon">
                <mat-icon>task_alt</mat-icon>
              </div>

              <div>
                <div fxFlex="column" style="padding-left: 5px">
                  <div>
                    {{ task.task.title }}
                    <br />
                  </div>

                  <div>
                    <span class="text-small-bold">Expira: {{ task.limit_date | date : 'dd MMM yyyy' }}</span>
                  </div>

                  <div class="limit-date-badge" style="margin-top: 5px">
                    <div
                      class="badge"
                      [ngClass]="{
                        'badge-late': task?.expired,
                        'badge-to-expire': task?.toExpired,
                        'badge-in-time': task?.inTime
                      }"
                    >
                      {{
                        task?.expired
                          ? 'Atrasado'
                          : task?.toExpired
                          ? 'Por vencer'
                          : task?.inTime
                          ? 'En fecha'
                          : ''
                      }}
                    </div>
                    <div
                      class="badge"
                      [ngClass]="{
                        'badge-late-completed': task?.template_status === 'Rechazada'
                      }"
                    >
                      {{ task?.template_status === 'Rechazada' ? 'Rechazada' : '' }}
                    </div>
                    <div
                      *ngIf="isTabIndex_0"
                      class="badge"
                      [ngClass]="{
                        'badge-in-time': task?.original_task
                      }"
                    >
                      {{ task?.original_task ? 'Reactivada' : '' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="actions-btns">
              <button
                mat-mini-fab
                color="black"
                aria-label="Seleccionar"
                class="btn-action ml-4"
                (click)="handleViewInfo(task)"
              >
                <mat-icon class="action-icon">navigate_next</mat-icon>
              </button>
            </div>
          </mat-card>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</section>
