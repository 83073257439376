import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { PollModel } from '../../models/poll.model';
import { Categories } from '../clients/clients.component';
import { db } from 'src/app/db/db';
import { Category } from '../../models/category.models';

import {
  MatListOption,
  MatSelectionList,
  MatSelectionListChange,
} from '@angular/material/list';
import * as jwt_decode from 'jwt-decode';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CategoryTargetService } from '../../services/category-target.service';
import { Observable, empty, EMPTY, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

import { dev } from 'src/environments/environment';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { ClientsService } from '../../services/clients.service';
import { OfflineService } from '../../services/offline.service';
import { UserSalesEstucture } from '../../models/usersaleestucture';
import { EnterpriseModel } from '../../models/enterprise';
import { UserModel } from '../../models/user.model';
import { log } from 'console';
import { logWarnings } from 'protractor/built/driverProviders';
import { stringify } from 'querystring';

export interface ItemFilter {
  name: string;
}

@Component({
  selector: 'app-filters-menu-in-clients',
  templateUrl: './filters-menu-in-clients.component.html',
  styleUrls: ['./filters-menu-in-clients.component.css'],
})
export class FiltersMenuInClientsComponent implements OnInit {
  @Output() optionSelected = new EventEmitter();
  @Output() refreshClients = new EventEmitter();
  @Output() closeNavEvent = new EventEmitter();

  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  loading: boolean;
  preview: boolean;
  keepFilter: boolean = false;
  addOnBlur = true;
  itemsFilter: any[] = [];
  categoriesTarget: Categories[] = [];
  isOfflineMode: boolean = false;
  categoriesClientCount: number;
  userId: number;
  poll: PollModel;
  selectedEnterprise: EnterpriseModel;
  enterprises: EnterpriseModel[] = [];
  modeSync: string;

  compareFunction = (o1: any, o2: any) => o1.code === o2.code;
  compareEnterprise = (o1: any, o2: any) => o1?.id === o2?.id;

  //Observables
  filteredVendedores: Observable<string[]>;
  filteredRelevancias: Observable<string[]>;
  filteredNiveles: Observable<string[]>;
  filteredSegmentos: Observable<string[]>;
  filteredJefeVtas: Observable<string[]>;
  filteredSubGerente: Observable<string[]>;
  filteredGerente: Observable<string[]>;
  filteredOficCom: Observable<string[]>;
  filteredDistrito: Observable<string[]>;
  filteredZona: Observable<string[]>;

  // Controls
  vendedorCtrl = new FormControl();
  segmentoCtrl = new FormControl();
  relevanciaCtrl = new FormControl();
  nivelCtrl = new FormControl();
  jefeVtaCtrl = new FormControl();
  SubGerenteCtrl = new FormControl();
  GerenteCtrl = new FormControl();
  OficComCtrl = new FormControl();
  DistritoCtrl = new FormControl();
  ZonaCtrl = new FormControl();

  //View-Vendedor
  @ViewChild('vendedorInput') vendedorInput: ElementRef;
  @ViewChild('VendedorTarget') VendedorTarget: MatSelectionList;
  @ViewChildren('optionsVendedores')
  optionsVendedores: QueryList<MatListOption>;

  //View-Segmentos
  @ViewChild('segmentoInput') segmentoInput: ElementRef;
  @ViewChild('SegmentoTarget') SegmentoTarget: MatSelectionList;
  @ViewChildren('optionsSegmentos') optionsSegmentos: QueryList<MatListOption>;

  //View-Relevancia
  @ViewChild('relevanciaInput') relevanciaInput: ElementRef;
  @ViewChild('RelevanciaTarget') RelevanciaTarget: MatSelectionList;
  @ViewChildren('optionsRelevancias')
  optionsRelevancias: QueryList<MatListOption>;
  // View-Nivels
  @ViewChild('nivelInput') nivelInput: ElementRef;
  @ViewChild('NivelTarget') NivelTarget: MatSelectionList;
  @ViewChildren('optionsNiveles') optionsNiveles: QueryList<MatListOption>;

  // ViewJefeVta
  @ViewChild('jefeVtaInput') jefeVtaInput: ElementRef;
  @ViewChild('JefeVtaTarget') JefeVtaTarget: MatSelectionList;
  @ViewChildren('optionsJefeVta') optionsJefeVta: QueryList<MatListOption>;

  // View-SubGerente
  @ViewChild('SubGerenteInput') SubGerenteInput: ElementRef;
  @ViewChild('SubGerenteTarget') SubGerenteTarget: MatSelectionList;
  @ViewChildren('optionsSubGerente')
  optionsSubGerente: QueryList<MatListOption>;

  // View-Gerente
  @ViewChild('GerenteInput') GerenteInput: ElementRef;
  @ViewChild('GerenteTarget') GerenteTarget: MatSelectionList;
  @ViewChildren('optionsGerente') optionsGerente: QueryList<MatListOption>;
  // View-OficComI
  @ViewChild('OficComInput') OficComInput: ElementRef;
  @ViewChild('OficComTarget') OficComTarget: MatSelectionList;
  @ViewChildren('optionsOficCom') optionsOficCom: QueryList<MatListOption>;
  //View-Distrito-Region
  @ViewChild('DistritoInput') DistritoInput: ElementRef;
  @ViewChild('DistritoTarget') DistritoTarget: MatSelectionList;
  @ViewChildren('optionsDistrito') optionsDistrito: QueryList<MatListOption>;
  // View-Zona
  @ViewChild('ZonaInput') ZonaInput: ElementRef;
  @ViewChild('ZonaTarget') ZonaTarget: MatSelectionList;
  @ViewChildren('optionsZona') optionsZona: QueryList<MatListOption>;
  //Models
  valueCategory: Array<any> = [];
  allVendedor: Category[] = [];
  allSegmento: Category[] = [];
  allRelevancia: Category[] = [];
  allNivel: Category[] = [];
  allJefeVta: Category[] = [];
  allSubGerente: Category[] = [];
  allGerente: Category[] = [];
  allOficCom: Category[] = [];
  allDistrito: Category[] = [];
  allZona: Category[] = [];

  //Options
  selectedOptionsVendedor: Category[] = [];
  selectedOptionsRelevancia: Category[] = [];
  selectedOptionsSegmentos: Category[] = [];
  selectedOptionsNivel: Category[] = [];
  selectedOptionsJefeVta: Category[] = [];
  selectedOptionsSubGerente: Category[] = [];
  selectedOptionsGerente: Category[] = [];
  selectedOptionsOficCom: Category[] = [];
  selectedOptionsDistrito: Category[] = [];
  selectedOptionsZona: Category[] = [];
  user: UserModel;

  constructor(
    private spinner: NgxSpinnerService,
    private analytics: AngularFireAnalytics,
    private offlineService: OfflineService,
    private clientService: ClientsService
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
    this.poll = JSON.parse(localStorage.getItem('poll'));
    this.preview = JSON.parse(localStorage.getItem('preview'));
  }

  async checkFiltersMulti() {
    // Chequea los filtroa pra luego aplicar
    return new Promise<any>(async (resolve, reject) => {
      const res: any = localStorage.getItem('filterClientsAllData');
      if (res !== null) {
        if (this.selectedEnterprise && this.selectedEnterprise.id != 0) {
          const filterClientsAllData = JSON.parse(res).filter(
            (aif) =>
              aif.user_id === this.user.id &&
              aif.enterprise_id === this.selectedEnterprise.id
          );
          filterClientsAllData.forEach((item) => {
            switch (item.source) {
              case 'ven':
                this.selectedOptionsVendedor.push(item);
                break;
              case 'rel':
                this.selectedOptionsRelevancia.push(item);
                break;
              case 'niv':
                this.selectedOptionsNivel.push(item);
                break;
              case 'seg':
                this.selectedOptionsSegmentos.push(item);
                break;
              case 'jef':
                this.selectedOptionsJefeVta.push(item);
                break;
              case 'sgr':
                this.selectedOptionsSubGerente.push(item);
                break;
              case 'grt':
                this.selectedOptionsGerente.push(item);
                break;
              case 'ofc':
                this.selectedOptionsOficCom.push(item);
                break;
              case 'dtt':
                this.selectedOptionsDistrito.push(item);
                break;
              default:
                break;
            }
          });
        }
        setTimeout(() => {
          /* const data = JSON.parse(res).filter((item) => {
            return (
              item.user_id === this.userId && item.poll_id === this.poll.id
            );
          }); */
          const data = JSON.parse(res);
          if (data.length > 0) {
            this.keepFilter = true;
          }
          resolve(data);
        }, 500);
      } else {
        resolve(res);
      }
    });
  }

  loadConfigFilters() {
    return new Promise<any>(async (resolve) => {
      const dataEstructure = JSON.parse(localStorage.getItem('sales'));
      if (dataEstructure) {
        dataEstructure.forEach((usersale) => {
          if (this.enterprises.indexOf(usersale.enterprise) != -1) {
            this.enterprises.push(usersale.enterprise);
          }
        });
        this.enterprises = [
          {
            enterprise_id: 0,

            name: 'Seleccione',
          },
          ...JSON.parse(localStorage.getItem('enterprises')),
        ];

        if (localStorage.getItem('enterpriseFilterAllClients') !== null) {
          const enterpriseFilterAllClients = JSON.parse(
            localStorage.getItem('enterpriseFilterAllClients')
          );
          const enterpriseSelect = enterpriseFilterAllClients.filter(
            (se) => se.user_id === this.user.id
          );
          if (enterpriseSelect.length === 1) {
            this.selectedEnterprise = enterpriseSelect[0];
          }
          this.searchDataSales('');
        } else {
          if (JSON.parse(localStorage.getItem('enterprises')).length == 1) {
            this.selectedEnterprise = this.enterprises[1];
            this.searchDataSales('');
          }
        }

        this.filteredSegmentos = this.segmentoCtrl.valueChanges.pipe(
          startWith(null),
          map((segmento: string | null) =>
            segmento ? this._filterSegmento(segmento) : this.allSegmento.slice()
          )
        );

        this.filteredRelevancias = this.relevanciaCtrl.valueChanges.pipe(
          startWith(null),
          map((relevancia: string | null) =>
            relevancia
              ? this._filterRelevancia(relevancia)
              : this.allRelevancia.slice()
          )
        );

        this.filteredNiveles = this.nivelCtrl.valueChanges.pipe(
          startWith(null),
          map((nivel: string | null) =>
            nivel ? this._filterNivel(nivel) : this.allNivel.slice()
          )
        );

        this.filteredVendedores = this.vendedorCtrl.valueChanges.pipe(
          startWith(null),
          map((nivel: string | null) =>
            nivel ? this._filterVendedor(nivel) : this.allVendedor.slice()
          )
        );

        this.filteredJefeVtas = this.jefeVtaCtrl.valueChanges.pipe(
          startWith(null),
          map((nivel: string | null) =>
            nivel ? this._filterJefeVta(nivel) : this.allJefeVta.slice()
          )
        );

        this.filteredSubGerente = this.SubGerenteCtrl.valueChanges.pipe(
          startWith(null),
          map((subgerente: string | null) =>
            subgerente
              ? this._filterSubGerente(subgerente)
              : this.allSubGerente.slice()
          )
        );

        this.filteredGerente = this.GerenteCtrl.valueChanges.pipe(
          startWith(null),
          map((gerente: string | null) =>
            gerente ? this._filterGerente(gerente) : this.allGerente.slice()
          )
        );

        this.filteredOficCom = this.OficComCtrl.valueChanges.pipe(
          startWith(null),
          map((oficina: string | null) =>
            oficina ? this._filterOfiCom(oficina) : this.allOficCom.slice()
          )
        );
        this.filteredDistrito = this.DistritoCtrl.valueChanges.pipe(
          startWith(null),
          map((distrito: string | null) =>
            distrito ? this._filterDistrito(distrito) : this.allDistrito.slice()
          )
        );

        this.filteredZona = this.ZonaCtrl.valueChanges.pipe(
          startWith(null),
          map((zona: string | null) =>
            zona ? this._filterZona(zona) : this.allZona.slice()
          )
        );
      }
      resolve(true);
    });
  }

  ngOnInit() {
    this.loadConfigFilters();
    this.userId = Number(localStorage.getItem('userId'));
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        this.isOfflineMode = offlineHeader ? offlineHeader.status : false;
        if (this.isOfflineMode) {
          if (
            offlineHeader.dataFilters.isFilter &&
            offlineHeader.dataFilters.keepFilter
          ) {
            this.keepFilter = offlineHeader.dataFilters.keepFilter;
            this.selectedEnterprise = this.enterprises.filter(
              (e) => e.id === offlineHeader.dataFilters.enterpriseId
            )[0];
            this.searchDataSales('');
            offlineHeader.dataFilters.filters.forEach((filter) => {
              // this.valueCategory[filter.source] = filter.code;
              this.onListSelectionChange(null, filter);
            });
          }
        } else {
          let kf = localStorage.getItem('keepFilterAllClients');
          setTimeout(() => {
            this.keepFilter = kf ? (kf === 'true' ? true : false) : false;
          }, 500);
        }
      });
  }

  addItemFilter(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.itemsFilter.push({ name: value });
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  refresh() {}

  deleteSelected(ngOnInit = true) {
    this.selectedEnterprise = undefined;
    this.itemsFilter = [];
    this.SegmentoTarget?.deselectAll();
    this.RelevanciaTarget?.deselectAll();
    this.NivelTarget?.deselectAll();
    this.VendedorTarget?.deselectAll();
    this.JefeVtaTarget?.deselectAll();
    this.SubGerenteTarget?.deselectAll();
    this.GerenteTarget?.deselectAll();
    this.OficComTarget?.deselectAll();
    this.DistritoTarget?.deselectAll();
    this.ZonaTarget?.deselectAll();
    this.keepFilter = false;
    if (ngOnInit) {
      this.ngOnInit();
    } else {
      this.fetchCategoryTargetFilter([]);
    }
  }

  refreshFilterSelected() {
    this.itemsFilter = [];
    this.SegmentoTarget?.deselectAll();
    this.RelevanciaTarget?.deselectAll();
    this.NivelTarget?.deselectAll();
    this.VendedorTarget?.deselectAll();
    this.JefeVtaTarget?.deselectAll();
    this.SubGerenteTarget?.deselectAll();
    this.GerenteTarget?.deselectAll();
    this.OficComTarget?.deselectAll();
    this.DistritoTarget?.deselectAll();
    this.ZonaTarget?.deselectAll();
    // this.fetchCategoryTargetFilter([]);
  }

  async removeAllFilterItems(AllItemsFilter, enterprise_id, user_id) {
    return new Promise<any>(async (resolve, reject) => {
      if (AllItemsFilter !== null) {
        if (localStorage.getItem('enterpriseFilterAllClients') !== null) {
          let enterpriseFilterAllClients: any = JSON.parse(
            localStorage.getItem('enterpriseFilterAllClients')
          );
          let selectedIndex = enterpriseFilterAllClients.findIndex(
            (ef) => ef.user_id === user_id && ef.enterprise_id === enterprise_id
          );
          {
            if (selectedIndex !== -1) {
              enterpriseFilterAllClients.splice(selectedIndex, 1);
            }
            localStorage.setItem(
              'enterpriseFilterAllClients',
              JSON.stringify(enterpriseFilterAllClients)
            );
          }
        }

        const itemForRemove = AllItemsFilter.filter((element) => {
          return element.user_id === this.user.id;
        });
        if (itemForRemove.length > 0) {
          var count = 0;
          itemForRemove.forEach((elementRemove) => {
            const IdxIndex = AllItemsFilter.findIndex(
              (element) => element === elementRemove
            );
            if (IdxIndex != -1) {
              AllItemsFilter.splice(IdxIndex, 1);
            }
            count += 1;
            if (count === itemForRemove.length) {
              resolve(AllItemsFilter);
            }
          });
        } else {
          resolve(AllItemsFilter);
        }
      } else {
        resolve([]);
      }
    });
  }

  setEnterpriseFilter(enterprise, user) {
    enterprise.user_id = user.id;
    /*  let enterpriseFilterAllClients: any = localStorage.getItem(
      'enterpriseFilterAllClients'
    )
      ? JSON.parse(localStorage.getItem('enterpriseFilterAllClients'))
      : []; */
    // console.log('enterpriseFilterAllClients', enterpriseFilterAllClients);
    /* let indexEnterprise =
      enterpriseFilterAllClients.length > 0
        ? enterpriseFilterAllClients.findIndex(
            (efac) => efac.id === enterprise.id && efac.user_id === user.id
          )
        : -1; */
    // if (indexEnterprise !== -1) {
    /* enterpriseFilterAllClients.push({
        ...enterprise,
        user_id: user.id,
      }); */
    let enterpriseFilterAllClients = [enterprise];
    localStorage.setItem(
      'enterpriseFilterAllClients',
      JSON.stringify(enterpriseFilterAllClients)
    );
    // }
  }

  setFilterClientsAllData(enterprise, itemsFilter, user) {
    /* let filterClientsAllData: any = localStorage.getItem('filterClientsAllData')
      ? JSON.parse(localStorage.getItem('filterClientsAllData'))
      : []; */
    itemsFilter.forEach((item) => {
      item.enterprise_id = enterprise.id;
      item.user_id = user.id;
    });
    localStorage.setItem('filterClientsAllData', JSON.stringify(itemsFilter));
  }

  async applyFilter() {
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then(async (offlineHeader) => {
        let AllItemsFilter = JSON.parse(
          localStorage.getItem('filterClientsAllData')
        );
        if (this.itemsFilter.length > 0 && this.selectedEnterprise.id != 0) {
          if (AllItemsFilter) {
            AllItemsFilter = AllItemsFilter.filter(
              (aif) =>
                aif.user_id === this.user.id &&
                aif.enterprise_id === this.selectedEnterprise.id
            );
          }
          this.optionSelected.emit(this.itemsFilter);
          this.closeNavEvent.emit();
          // Validamos si se mantiene filtros
          localStorage.setItem(
            'keepFilterAllClients',
            JSON.stringify(this.keepFilter)
          );
          // if (this.keepFilter) {
          if (offlineHeader && offlineHeader.status) {
            // None
          } else {
            if (this.itemsFilter.length === 0) {
              AllItemsFilter = await this.removeAllFilterItems(
                AllItemsFilter,
                this.selectedEnterprise.id,
                this.user.id
              );
            }
            if (AllItemsFilter) {
              // Si existen filtros en la encuesta se reemplazan por los actuales
              if (this.itemsFilter.length > 0) {
                this.removeAllFilterItems(
                  AllItemsFilter,
                  this.selectedEnterprise.id,
                  this.user.id
                ).then((data) => {
                  const union = Array.from(
                    new Set([...this.itemsFilter, ...data])
                  );
                  this.setEnterpriseFilter(this.selectedEnterprise, this.user);
                  this.setFilterClientsAllData(
                    this.selectedEnterprise,
                    union,
                    this.user
                  );
                });
              } else {
                const union = Array.from(
                  new Set([...AllItemsFilter, ...this.itemsFilter])
                );
                this.setEnterpriseFilter(this.selectedEnterprise, this.user);
                this.setFilterClientsAllData(
                  this.selectedEnterprise,
                  union,
                  this.user
                );
              }
            } else {
              this.setEnterpriseFilter(this.selectedEnterprise, this.user);
              this.setFilterClientsAllData(
                this.selectedEnterprise,
                this.itemsFilter,
                this.user
              );
            }
          }
          /* } else {
            // localStorage.removeItem('enterpriseFilterAllClients');
            // localStorage.removeItem('filterClientsAllData');
            if (AllItemsFilter) {
              // Se eliminan filtros anterior del usuario para la encuesta actual
              this.removeAllFilterItems(
                AllItemsFilter,
                this.selectedEnterprise.id,
                this.user.id
              ).then((data) => {
                this.setEnterpriseFilter(this.selectedEnterprise, this.user);
                this.setFilterClientsAllData(
                  this.selectedEnterprise,
                  data,
                  this.user
                );
              });
            }
          } */
          setTimeout(async () => {
            if (offlineHeader && offlineHeader.status) {
              await this.offlineService.setOfflineHeader({
                id: 1,
                user_id: this.user.id,
                dataFilters: {
                  enterpriseId: this.selectedEnterprise.id,
                  filters: this.itemsFilter,
                  isFilter: true,
                  keepFilter: this.keepFilter,
                  from: 'clients',
                },
              });
              this.refreshClients.emit(this.selectedEnterprise);
              return;
            }
            this.refreshClients.emit(this.selectedEnterprise);
            this.analytics.logEvent(`filtrado_clientes_`, {
              value: JSON.stringify(this.itemsFilter),
            });
          }, 500);
        } else {
          localStorage.removeItem('enterpriseFilterAllClients');
          localStorage.removeItem('filterClientsAllData');
          if (offlineHeader && offlineHeader.dataFilters.isFilter) {
            await this.offlineService.setOfflineHeader({
              id: 1,
              user_id: this.user.id,
              dataFilters: {
                enterpriseId: null,
                filters: [],
                isFilter: false,
                keepFilter: false,
              },
            });
          }

          setTimeout(() => {
            this.refreshClients.emit(this.selectedEnterprise);
          }, 100);
          this.closeNavFilter();
        }
      });
  }

  closeNavFilter() {
    this.closeNavEvent.emit();
  }

  onListSelectionChange(ob: MatSelectionListChange, value: any = false) {
    const valueItemFilter = value ? value : ob.options[0]['_value'];
    valueItemFilter.user_id = this.userId;
    // Buscamos si existen mas 2 filter de la misma categoria
    const searchFilter = this.itemsFilter.filter(
      (itf) => itf.source.trim() === valueItemFilter.source.trim()
    );
    if (searchFilter.length > 0) {
      const idFilter = searchFilter.findIndex(
        (elementFilter) =>
          elementFilter.source.trim() === valueItemFilter.source.trim() &&
          elementFilter.code.toString().trim() ===
            valueItemFilter.code.toString().trim() &&
          elementFilter.user_id === valueItemFilter.user_id
      );
      // Si elemento seleccionado ya estaba seleccionado se limpia completo
      if (idFilter !== -1) {
        this.removeItemFilterCategory(valueItemFilter.source);
      }
      // Si elemento seleccionado no estaba seleccionado se limpia completo y se agrega
      else {
        this.removeItemFilterCategory(valueItemFilter.source);
        this.itemsFilter.push(valueItemFilter);
      }
    } else {
      this.itemsFilter.push(valueItemFilter);
    }
    this.selectedOptionsVendedor = this.itemsFilter;
    this.selectedOptionsRelevancia = this.itemsFilter;
    this.selectedOptionsSegmentos = this.itemsFilter;
    this.selectedOptionsNivel = this.itemsFilter;
    this.selectedOptionsJefeVta = this.itemsFilter;
    this.selectedOptionsSubGerente = this.itemsFilter;
    this.selectedOptionsGerente = this.itemsFilter;
    this.selectedOptionsOficCom = this.itemsFilter;
    this.selectedOptionsDistrito = this.itemsFilter;
    this.selectedOptionsZona = this.itemsFilter;
    setTimeout(() => {
      this.refreshItemsCategory();
    }, 100);
  }

  removeItemFilterCategory(source) {
    this.itemsFilter.forEach((item) => {
      const index = this.itemsFilter.findIndex((itf) => itf.source === source);
      if (index !== -1) {
        this.itemsFilter.splice(index, 1);
      }
    });
  }

  getCategory(category) {
    return this.categoriesTarget.filter((ct) => ct.category === category)[0];
  }

  checkedVendedor() {
    const vendedorForCheck = this.itemsFilter.filter(
      (item) => item.source === 'ven'
    );
    if (vendedorForCheck.length > 0) {
      if (this.optionsVendedores.get(0) !== undefined) {
        setTimeout(() => {
          this.optionsVendedores.get(0)._setSelected(true);
        }, 100);
      }
    }
  }

  refreshItemsCategory() {
    this.fetchCategoryTargetFilter(this.itemsFilter);
  }

  loadCategoryTargetFilter(categories) {
    if (this.isOfflineMode) {
      this.categoriesTarget = categories;
    } else {
      this.categoriesTarget = categories;
    }
    const SegmentoItems = categories.filter(
      (category) => category.category.trim().toLowerCase() === 'segmento'
    );
    SegmentoItems.length > 0
      ? (this.allSegmento = SegmentoItems[0].values)
      : [];

    const RelevanciaItems = categories.filter(
      (category) => category.category.trim().toLowerCase() === 'relevancia'
    );
    RelevanciaItems.length > 0
      ? (this.allRelevancia = RelevanciaItems[0].values)
      : [];

    const NivelItems = categories.filter(
      (category) => category.category.trim().toLowerCase() === 'nivel'
    );
    NivelItems.length > 0 ? (this.allNivel = NivelItems[0].values) : [];

    const VendedoresItems = categories.filter(
      (category) => category.category.trim().toLowerCase() === 'vendedor'
    );
    VendedoresItems.length > 0
      ? (this.allVendedor = VendedoresItems[0].values)
      : [];

    const JefeVtaItems = categories.filter(
      (category) => category.category.trim().toLowerCase() === 'jefe venta'
    );
    JefeVtaItems.length > 0 ? (this.allJefeVta = JefeVtaItems[0].values) : [];

    const SubGrteItems = categories.filter(
      (category) => category.category.trim().toLowerCase() === 'subgerente'
    );
    SubGrteItems.length > 0
      ? (this.allSubGerente = SubGrteItems[0].values)
      : [];

    const GrteItems = categories.filter(
      (category) => category.category.trim().toLowerCase() === 'gerente'
    );
    GrteItems.length > 0 ? (this.allGerente = GrteItems[0].values) : [];

    const OficComItems = categories.filter(
      (category) =>
        category.category.trim().toLowerCase() === 'oficina comercial'
    );
    OficComItems.length > 0 ? (this.allOficCom = OficComItems[0].values) : [];

    const DistritoItems = categories.filter(
      (category) => category.category.trim().toLowerCase() === 'distrito'
    );
    DistritoItems.length > 0
      ? (this.allDistrito = DistritoItems[0].values)
      : [];

    this.spinner.hide('loading');
  }

  async fetchCategoryTargetFilter(categories: Categories[]) {
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then(async (offlineHeader) => {
        this.isOfflineMode = offlineHeader ? offlineHeader.status : false;

        if (this.selectedEnterprise && this.selectedEnterprise.id) {
          this.spinner.show('loading');
          // Init Offline Mode
          if (this.isOfflineMode) {
            if (this.itemsFilter.length > 0) {
              categories = await this.offlineService.getCategoriesByFilters(
                this.itemsFilter,
                this.selectedEnterprise.id
              );
            } else {
              const dataCat = await this.offlineService.getCategories(
                `${this.user.id}-${this.selectedEnterprise.id}`
              );
              categories = dataCat?.categories || [];
            }
            this.categoriesClientCount = categories.length;

            this.loadCategoryTargetFilter(categories);
            return;
          }
          // End Offline Mode
          this.clientService
            .getCustomerCategoriesFilter(categories, this.selectedEnterprise.id)
            .subscribe((categories) => {
              this.categoriesClientCount = categories[0]?.count;
              this.loadCategoryTargetFilter(categories);
            });
        } else {
          this.loadCategoryTargetFilter([]);
        }
      });
  }

  selectedSegmento(
    event: MatAutocompleteSelectedEvent,
    category: string
  ): void {
    const idxItemSearch = this.optionsSegmentos['_results'].findIndex(
      (item) => item._value === event.option.value
    );
    // Mat-Chip
    const idxFilter = this.itemsFilter.findIndex(
      (elementFilter) =>
        elementFilter.source.trim() === event.option.value.source.trim() &&
        elementFilter.code.toString().trim() ===
          event.option.value.code.toString().trim()
    );
    if (idxFilter !== -1) {
      this.itemsFilter.splice(idxFilter, 1);
      this.segmentoInput.nativeElement.value = '';
      this.segmentoCtrl.setValue(null);
    } else {
      this.itemsFilter.push(event.option.value);
      this.segmentoInput.nativeElement.value = '';
      this.segmentoCtrl.setValue(null);
    }

    this.selectedOptionsSegmentos.push(event.option.value);
    if (this.optionsSegmentos.get(idxItemSearch).selected !== true) {
      this.optionsSegmentos.get(idxItemSearch).selected = true;
    }
    // }
    setTimeout(() => {
      this.refreshItemsCategory();
    }, 100);
  }

  selectedRelevancia(
    event: MatAutocompleteSelectedEvent,
    category: string
  ): void {
    const idxItemSearch = this.optionsRelevancias['_results'].findIndex(
      (item) => item._value === event.option.value
    );
    // Mat-Chip
    const idxFilter = this.itemsFilter.findIndex(
      (elementFilter) =>
        elementFilter.source.trim() === event.option.value.source.trim() &&
        elementFilter.code.toString().trim() ===
          event.option.value.code.toString().trim()
    );

    if (idxFilter !== -1) {
      this.itemsFilter.splice(idxFilter, 1);
      this.relevanciaInput.nativeElement.value = '';
      this.relevanciaCtrl.setValue(null);
    } else {
      this.itemsFilter.push(event.option.value);
      this.relevanciaInput.nativeElement.value = '';
      this.relevanciaCtrl.setValue(null);
    }
    this.selectedOptionsRelevancia.push(event.option.value);

    if (this.optionsRelevancias.get(idxItemSearch).selected !== true) {
      this.optionsRelevancias.get(idxItemSearch).selected = true;
    }
    this.refreshItemsCategory();
  }

  selectedNivel(event: MatAutocompleteSelectedEvent, category: string): void {
    const idxItemSearch = this.optionsNiveles['_results'].findIndex(
      (item) => item._value === event.option.value
    );
    // Mat-Chip
    const idxFilter = this.itemsFilter.findIndex(
      (elementFilter) =>
        elementFilter.source.trim() === event.option.value.source.trim() &&
        elementFilter.code.toString().trim() ===
          event.option.value.code.toString().trim()
    );

    if (idxFilter !== -1) {
      this.itemsFilter.splice(idxFilter, 1);
      this.nivelInput.nativeElement.value = '';
      this.nivelCtrl.setValue(null);
    } else {
      this.itemsFilter.push(event.option.value);
      this.nivelInput.nativeElement.value = '';
      this.nivelCtrl.setValue(null);
    }
    this.selectedOptionsNivel.push(event.option.value);

    if (this.optionsNiveles.get(idxItemSearch).selected !== true) {
      this.optionsNiveles.get(idxItemSearch).selected = true;
    }
    this.refreshItemsCategory();
  }
  selectedVendedor(
    event: MatAutocompleteSelectedEvent,
    category: string
  ): void {
    const idxItemSearch = this.optionsVendedores['_results'].findIndex(
      (item) => item._value === event.option.value
    );
    // Mat-Chip
    const idxFilter = this.itemsFilter.findIndex(
      (elementFilter) =>
        elementFilter.source.trim() === event.option.value.source.trim() &&
        elementFilter.code.toString().trim() ===
          event.option.value.code.toString().trim()
    );

    if (idxFilter !== -1) {
      this.itemsFilter.splice(idxFilter, 1);
      this.vendedorInput.nativeElement.value = '';
      this.vendedorCtrl.setValue(null);
    } else {
      this.itemsFilter.push(event.option.value);
      this.vendedorInput.nativeElement.value = '';
      this.vendedorCtrl.setValue(null);
    }

    this.selectedOptionsVendedor.push(event.option.value);

    if (this.optionsVendedores.get(idxItemSearch).selected !== true) {
      this.optionsVendedores.get(idxItemSearch).selected = true;
    }
    this.refreshItemsCategory();
  }
  selectedJefeVta(event: MatAutocompleteSelectedEvent, category: string): void {
    const idxItemSearch = this.optionsJefeVta['_results'].findIndex(
      (item) => item._value === event.option.value
    );
    // Mat-Chip
    const idxFilter = this.itemsFilter.findIndex(
      (elementFilter) =>
        elementFilter.source.trim() === event.option.value.source.trim() &&
        elementFilter.code.toString().trim() ===
          event.option.value.code.toString().trim()
    );

    if (idxFilter !== -1) {
      this.itemsFilter.splice(idxFilter, 1);
      this.jefeVtaInput.nativeElement.value = '';
      this.jefeVtaCtrl.setValue(null);
    } else {
      this.itemsFilter.push(event.option.value);
      this.jefeVtaInput.nativeElement.value = '';
      this.jefeVtaCtrl.setValue(null);
    }
    this.selectedOptionsJefeVta.push(event.option.value);

    if (this.optionsJefeVta.get(idxItemSearch).selected !== true) {
      this.optionsJefeVta.get(idxItemSearch).selected = true;
    }
    this.refreshItemsCategory();
  }

  selectedSubGerente(
    event: MatAutocompleteSelectedEvent,
    category: string
  ): void {
    const idxItemSearch = this.optionsSubGerente['_results'].findIndex(
      (item) => item._value === event.option.value
    );
    // Mat-Chip
    const idxFilter = this.itemsFilter.findIndex(
      (elementFilter) =>
        elementFilter.source.trim() === event.option.value.source.trim() &&
        elementFilter.code.toString().trim() ===
          event.option.value.code.toString().trim()
    );

    if (idxFilter !== -1) {
      this.itemsFilter.splice(idxFilter, 1);
      this.SubGerenteInput.nativeElement.value = '';
      this.SubGerenteCtrl.setValue(null);
    } else {
      this.itemsFilter.push(event.option.value);
      this.SubGerenteInput.nativeElement.value = '';
      this.SubGerenteCtrl.setValue(null);
    }

    this.selectedOptionsSubGerente.push(event.option.value);

    if (this.optionsSubGerente.get(idxItemSearch).selected !== true) {
      this.optionsSubGerente.get(idxItemSearch).selected = true;
    }
    this.refreshItemsCategory();
  }

  selectedGerente(event: MatAutocompleteSelectedEvent, category: string): void {
    const idxItemSearch = this.optionsGerente['_results'].findIndex(
      (item) => item._value === event.option.value
    );
    // Mat-Chip
    const idxFilter = this.itemsFilter.findIndex(
      (elementFilter) =>
        elementFilter.source.trim() === event.option.value.source.trim() &&
        elementFilter.code.toString().trim() ===
          event.option.value.code.toString().trim()
    );

    if (idxFilter !== -1) {
      this.itemsFilter.splice(idxFilter, 1);
      this.GerenteInput.nativeElement.value = '';
      this.GerenteCtrl.setValue(null);
    } else {
      this.itemsFilter.push(event.option.value);
      this.GerenteInput.nativeElement.value = '';
      this.GerenteCtrl.setValue(null);
    }

    this.selectedOptionsGerente.push(event.option.value);

    if (this.optionsGerente.get(idxItemSearch).selected !== true) {
      this.optionsGerente.get(idxItemSearch).selected = true;
    }
    this.refreshItemsCategory();
  }

  selectedOficCom(event: MatAutocompleteSelectedEvent, category: string): void {
    const idxItemSearch = this.optionsOficCom['_results'].findIndex(
      (item) => item._value === event.option.value
    );
    // Mat-Chip
    const idxFilter = this.itemsFilter.findIndex(
      (elementFilter) =>
        elementFilter.source.trim() === event.option.value.source.trim() &&
        elementFilter.code.toString().trim() ===
          event.option.value.code.toString().trim()
    );

    if (idxFilter !== -1) {
      this.itemsFilter.splice(idxFilter, 1);
      this.OficComInput.nativeElement.value = '';
      this.OficComCtrl.setValue(null);
    } else {
      this.itemsFilter.push(event.option.value);
      this.OficComInput.nativeElement.value = '';
      this.OficComCtrl.setValue(null);
    }

    this.selectedOptionsOficCom.push(event.option.value);

    if (this.optionsOficCom.get(idxItemSearch).selected !== true) {
      this.optionsOficCom.get(idxItemSearch).selected = true;
    }
    this.refreshItemsCategory();
  }
  selectedDistrito(
    event: MatAutocompleteSelectedEvent,
    category: string
  ): void {
    const idxItemSearch = this.optionsDistrito['_results'].findIndex(
      (item) => item._value === event.option.value
    );
    // Mat-Chip
    const idxFilter = this.itemsFilter.findIndex(
      (elementFilter) =>
        elementFilter.source.trim() === event.option.value.source.trim() &&
        elementFilter.code.toString().trim() ===
          event.option.value.code.toString().trim()
    );

    if (idxFilter !== -1) {
      this.itemsFilter.splice(idxFilter, 1);
      this.DistritoInput.nativeElement.value = '';
      this.DistritoCtrl.setValue(null);
    } else {
      this.itemsFilter.push(event.option.value);
      this.DistritoInput.nativeElement.value = '';
      this.DistritoCtrl.setValue(null);
    }

    this.selectedOptionsDistrito.push(event.option.value);

    if (this.optionsDistrito.get(idxItemSearch).selected !== true) {
      this.optionsDistrito.get(idxItemSearch).selected = true;
    }
    this.refreshItemsCategory();
  }

  selectedZona(event: MatAutocompleteSelectedEvent, category: string): void {
    const idxItemSearch = this.optionsZona['_results'].findIndex(
      (item) => item._value === event.option.value
    );
    // Mat-Chip
    const idxFilter = this.itemsFilter.findIndex(
      (elementFilter) =>
        elementFilter.source.trim() === event.option.value.source.trim() &&
        elementFilter.code.toString().trim() ===
          event.option.value.code.toString().trim()
    );

    if (idxFilter !== -1) {
      this.itemsFilter.splice(idxFilter, 1);
      this.ZonaInput.nativeElement.value = '';
      this.ZonaCtrl.setValue(null);
    } else {
      this.itemsFilter.push(event.option.value);
      this.ZonaInput.nativeElement.value = '';
      this.ZonaCtrl.setValue(null);
    }

    // this.selectedOptionsVendedor.push(event.option.value);
    this.selectedOptionsZona.push(event.option.value);

    if (this.optionsZona.get(idxItemSearch).selected !== true) {
      this.optionsZona.get(idxItemSearch).selected = true;
    }
    this.refreshItemsCategory();
  }

  private _filterSegmento(value: any): any[] {
    let searchValue = '';

    typeof value === 'string'
      ? (searchValue = value)
      : (searchValue = value.name);
    return this.allSegmento.filter((segmento) =>
      segmento.name?.toLowerCase().includes(searchValue?.toLowerCase())
    );
  }

  private _filterRelevancia(value: any): any[] {
    let searchValue = '';

    typeof value === 'string'
      ? (searchValue = value)
      : (searchValue = value.name);
    return this.allRelevancia.filter((relevancia) =>
      relevancia.name?.toLowerCase().includes(searchValue?.toLowerCase())
    );
  }

  private _filterNivel(value: any): any[] {
    let searchValue = '';

    typeof value === 'string'
      ? (searchValue = value)
      : (searchValue = value.name);
    return this.allNivel.filter((nivel) =>
      nivel.name?.toLowerCase().includes(searchValue?.toLowerCase())
    );
  }
  private _filterVendedor(value: any): any[] {
    let searchValue = '';

    typeof value === 'string'
      ? (searchValue = value)
      : (searchValue = value.name);
    return this.allVendedor.filter((vendedor) => {
      return (
        vendedor.name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        vendedor.code?.toLowerCase().includes(searchValue?.toLowerCase())
      );
    });
  }

  private _filterJefeVta(value: any): any[] {
    let searchValue = '';

    typeof value === 'string'
      ? (searchValue = value)
      : (searchValue = value.name);

    return this.allJefeVta.filter((jefe) => {
      return (
        jefe.name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        jefe.code?.toString().toLowerCase().includes(searchValue?.toLowerCase())
      );
    });
  }

  private _filterSubGerente(value: any): any[] {
    let searchValue = '';

    typeof value === 'string'
      ? (searchValue = value)
      : (searchValue = value.name);
    return this.allSubGerente.filter((subgerente) => {
      return (
        subgerente.name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        subgerente.code
          ?.toString()
          .toLowerCase()
          .includes(searchValue?.toLowerCase())
      );
    });
  }
  private _filterGerente(value: any): any[] {
    let searchValue = '';

    typeof value === 'string'
      ? (searchValue = value)
      : (searchValue = value.name);
    return this.allGerente.filter((gerente) =>
      gerente.name?.toLowerCase().includes(searchValue?.toLowerCase())
    );
  }

  private _filterOfiCom(value: any): any[] {
    let searchValue = '';

    typeof value === 'string'
      ? (searchValue = value)
      : (searchValue = value.name);
    return this.allOficCom.filter((oficina) =>
      oficina.name?.toLowerCase().includes(searchValue?.toLowerCase())
    );
  }

  private _filterDistrito(value: any): any[] {
    let searchValue = '';

    typeof value === 'string'
      ? (searchValue = value)
      : (searchValue = value.name);
    return this.allDistrito.filter((distrito) =>
      distrito.name?.toLowerCase().includes(searchValue?.toLowerCase())
    );
  }

  private _filterZona(value: any): any[] {
    let searchValue = '';

    typeof value === 'string'
      ? (searchValue = value)
      : (searchValue = value.name);
    return this.allZona.filter((zona) =>
      zona.name?.toLowerCase().includes(searchValue?.toLowerCase())
    );
  }

  desTildar(option) {
    switch (option.source) {
      case 'seg': {
        const index = this.selectedOptionsSegmentos.findIndex((object) => {
          return object.source === option.source && object.code === option.code;
        });

        if (index >= 0) {
          this.selectedOptionsSegmentos.splice(index, 1);
        }
        this.selectedOptionsSegmentos = this.selectedOptionsSegmentos.filter(
          (s) => s !== option
        );
        break;
      }
      case 'rel': {
        const index = this.selectedOptionsRelevancia.findIndex((object) => {
          return object.source === option.source && object.code === option.code;
        });

        if (index >= 0) {
          this.selectedOptionsRelevancia.splice(index, 1);
        }
        this.selectedOptionsRelevancia = this.selectedOptionsRelevancia.filter(
          (s) => s !== option
        );
        break;
      }
      case 'niv': {
        const index = this.selectedOptionsNivel.findIndex((object) => {
          return object.source === option.source && object.code === option.code;
        });

        if (index >= 0) {
          this.selectedOptionsNivel.splice(index, 1);
        }

        this.selectedOptionsNivel = this.selectedOptionsNivel.filter(
          (s) => s !== option
        );
        break;
      }
      case 'ven': {
        const index = this.selectedOptionsVendedor.findIndex((object) => {
          return object.source === option.source && object.code === option.code;
        });

        if (index >= 0) {
          this.selectedOptionsVendedor.splice(index, 1);
        }
        this.selectedOptionsVendedor = this.selectedOptionsVendedor.filter(
          (s) => s !== option
        );
        break;
      }
      case 'jef': {
        const index = this.selectedOptionsJefeVta.findIndex((object) => {
          return object.source === option.source && object.code === option.code;
        });

        if (index >= 0) {
          this.selectedOptionsJefeVta.splice(index, 1);
        }
        this.selectedOptionsJefeVta = this.selectedOptionsJefeVta.filter(
          (s) => s !== option
        );
        break;
      }
      case 'sgr': {
        const index = this.selectedOptionsSubGerente.findIndex((object) => {
          return object.source === option.source && object.code === option.code;
        });

        if (index >= 0) {
          this.selectedOptionsSubGerente.splice(index, 1);
        }
        this.selectedOptionsSubGerente = this.selectedOptionsSubGerente.filter(
          (s) => s !== option
        );
        break;
      }
      case 'grt': {
        const index = this.selectedOptionsGerente.findIndex((object) => {
          return object.source === option.source && object.code === option.code;
        });

        if (index >= 0) {
          this.selectedOptionsGerente.splice(index, 1);
        }
        this.selectedOptionsGerente = this.selectedOptionsGerente.filter(
          (s) => s !== option
        );
        break;
      }
      case 'ofc': {
        const index = this.selectedOptionsOficCom.findIndex((object) => {
          return object.source === option.source && object.code === option.code;
        });

        if (index >= 0) {
          this.selectedOptionsOficCom.splice(index, 1);
        }
        this.selectedOptionsOficCom = this.selectedOptionsOficCom.filter(
          (s) => s !== option
        );
        break;
      }
      case 'dtt': {
        const index = this.selectedOptionsDistrito.findIndex((object) => {
          return object.source === option.source && object.code === option.code;
        });

        if (index >= 0) {
          this.selectedOptionsDistrito.splice(index, 1);
        }
        this.selectedOptionsDistrito = this.selectedOptionsDistrito.filter(
          (s) => s !== option
        );
        break;
      }
      case 'zna': {
        const index = this.selectedOptionsZona.findIndex((object) => {
          return object.source === option.source && object.code === option.code;
        });

        if (index >= 0) {
          this.selectedOptionsZona.splice(index, 1);
        }
        this.selectedOptionsZona = this.selectedOptionsZona.filter(
          (s) => s !== option
        );
        break;
      }
      default: {
        //statements;
        break;
      }
    }
    // debugger;
  }

  removeItemFilter(itemSegmento: ItemFilter): void {
    const index = this.itemsFilter.indexOf(itemSegmento);
    this.desTildar(itemSegmento);
    if (index >= 0) {
      this.itemsFilter.splice(index, 1);

      this.fetchCategoryTargetFilter(this.itemsFilter);
    }
  }
  searchDataSales(event: any) {
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        this.refreshFilterSelected();
        this.checkFiltersMulti().then((dataFilter) => {
          if (dataFilter) {
            this.itemsFilter = dataFilter;
            this.fetchCategoryTargetFilter(dataFilter);
          } else {
            this.fetchCategoryTargetFilter([]);
          }
        });
      });
  }
}
