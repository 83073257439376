import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { PollModel } from 'src/app/polls/models/poll.model';
import { QuestionModel } from 'src/app/polls/models/question.model';
import { SectionModel } from 'src/app/polls/models/section.model';
import { SectionsService } from 'src/app/polls/services/sections.service';
import { OfflineService } from 'src/app/polls/services/offline.service';
import { DialogImageHelpComponent } from '../../dialog-image-help/dialog-image-help.component';
import { ClientModel } from 'src/app/polls/models/client.model';
import { ResponseService } from 'src/app/polls/services/response.service';
import { UserModel } from 'src/app/polls/models/user.model';
import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { OptionJumpModel } from 'src/app/polls/models/option-jump.model';
import { OptionModel } from 'src/app/polls/models/option.model';
import { error } from 'protractor';
import { Subject } from 'rxjs';
import { LoadfileComponent } from '../loadfile/loadfile.component';
import { FilesWebcamComponent } from '../files-webcam/files-webcam.component';
import { RankingmvComponent } from '../rankingmv/rankingmv.component';
import { ScaleComponent } from '../scale/scale.component';
import { SelectionComponent } from '../selection/selection.component';
import { NumberComponent } from '../number/number.component';
import { PricedropdownComponent } from '../pricedropdown/pricedropdown.component';
import { TextComponent } from '../text/text.component';
import { UploadService } from 'src/app/polls/services/upload.service';
import { HttpResponse, HttpEventType, HttpClient } from '@angular/common/http';

// Dexie DB
import { db } from 'src/app/db/db';
import { auth, dev } from 'src/environments/environment';

import * as _moment from 'moment';
import { log } from 'console';
import { QuestionValidations } from 'src/app/polls/models/questionvalidations.model';
import { async } from '@firebase/util';
import { CoolerComponent } from '../cooler/cooler.component';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
// import { MultipleFilesComponent } from '../multiple-files/multiple-files.component';
const moment = _moment;
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css'],
})
export class QuestionComponent implements OnInit {
  // V1
  @Input() nextQuestion: any;
  @Input() actualQuestion: Number;
  @Input() type: Number;
  @Input() alternative: any;
  @Input() textQuestion: String;
  @Input() response: any;
  lastQuestion: Boolean;
  inResume: Boolean;
  // end V1
  selected = undefined;
  questionRequired: boolean;
  historyStorage = false;
  sectionCompare: number;
  totalSection: number;
  totalQuestionSection: number;
  idxSection: number;
  idxQuestion: number;
  idResponse: number;
  option: OptionModel;
  optionsJump: OptionJumpModel[];
  optionJump: OptionJumpModel;
  questionvalidations: QuestionValidations[];
  poll: PollModel;
  client: ClientModel;
  sectionImg = 'market.png';
  sectionTitle: String;
  pollTitle: String;
  section: SectionModel;
  question: QuestionModel;
  questionPrev: QuestionModel;
  loading: boolean;
  optionsSelected = [];
  user: UserModel;
  // actualSection = 1;
  actualSectionSubject: Subject<number> = new Subject<number>();
  questionSubject: Subject<QuestionModel> = new Subject<QuestionModel>();
  actualSection: number;
  navHistory = [];
  typeaccess: string;
  selectPoll: string;
  lat: string;
  long: string;
  messageValidation: string = null;
  preview: boolean;
  offlineMode: any = {
    status: false,
    from: 'nonde',
  };
  @ViewChild(LoadfileComponent, { static: false })
  loadfilechildC: LoadfileComponent;
  @ViewChild(RankingmvComponent, { static: false })
  rankingComponenteC: RankingmvComponent;
  @ViewChild(SelectionComponent, { static: false })
  selectionComponentC: SelectionComponent;
  @ViewChild(ScaleComponent, { static: false })
  scaleComponenteC: ScaleComponent;
  @ViewChild(NumberComponent, { static: false })
  numberComponenteC: NumberComponent;
  @ViewChild(PricedropdownComponent, { static: false })
  PriceDropdownComponentC: PricedropdownComponent;
  @ViewChild(TextComponent, { static: false })
  TextComponentC: TextComponent;
  @ViewChild(CoolerComponent, { static: false })
  CoolerComponenteC: CoolerComponent;
  // @ViewChild(MultipleFilesComponent, { static: false })
  // MultipleFilesComponent: MultipleFilesComponent;
  @ViewChild(FilesWebcamComponent, { static: false })
  FilesWebcamComponent: FilesWebcamComponent;

  answerCoolerBody;

  API_URL: string = auth.urlConfig;

  constructor(
    private sectionsService: SectionsService,
    private offlineService: OfflineService,
    private responseService: ResponseService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private dialog: MatDialog,
    private _location: Location,
    private ref: ChangeDetectorRef,
    private analytics: AngularFireAnalytics,
    private uploadService: UploadService
  ) {
    // this.actualSectionSubject.subscribe(section =>this.actualSection = section)
    this.actualSectionSubject.subscribe((section) => {
      this.actualSection = section;
    });
    this.questionSubject.subscribe((question) => {
      this.question = question;
      if (question.type_id === 8) {
        this.questionRequired = true;
      } else {
        this.questionRequired = question.required;
      }

      this.getSelected();
    });

    this.poll = JSON.parse(localStorage.getItem('poll'));
    this.client = JSON.parse(localStorage.getItem('client'));
    const decodedToken = jwt_decode(localStorage.getItem('token'));
    this.preview = JSON.parse(localStorage.getItem('preview'));
    this.idResponse = Number(localStorage.getItem('idResponse'));
    this.selectPoll = localStorage.getItem('source');
    this.user = decodedToken.user;
    this.typeaccess = decodedToken.user.typeaccess;
    this.sectionTitle = 'Principal';
    this.nextQuestion = undefined;
    this.idxSection = 0;
    this.idxQuestion = 0;
    this.lat = localStorage.getItem('lat');
    this.long = localStorage.getItem('long');
    this.setActualSection();
  }

  ngOnInit(): void {
    window.addEventListener('keyup', disableF5);

    window.addEventListener('keydown', disableF5);

    function disableF5(e) {
      if ((e.which || e.keyCode) == 116) e.preventDefault();
    }
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        if (offlineHeader) {
          this.offlineMode = offlineHeader;
        }
        this.spinner.show();

        // Init Offline Mode
        if (this.offlineMode.status && this.offlineMode.from === 'clients') {
          if (localStorage.getItem('navHistory') === null) {
            this.setHistory();
          } else {
            this.historyStorage = true;
            this.navHistory = JSON.parse(localStorage.getItem('navHistory'));
            this.idxSection = this.navHistory[this.navHistory.length - 1].sec;
            this.idxQuestion = this.navHistory[this.navHistory.length - 1].que;
            this.setActualSection();
            this.navHistory.slice(-1);
            localStorage.setItem('navHistory', JSON.stringify(this.navHistory));
          }
          this.moveSection();
          return;
        }
        // End Offline Mode
        if (this.idResponse == 0 && !this.preview) {
          var prefixNameFiles = `${this.question.id}-${this.poll.id}-${this.client.id}-${this.user.id}-`;
          this.spinner.hide();
          this.router.navigate([
            '/poll/client',
            { id: this.poll.id, name: this.poll.name },
          ]);
        } else {
          if (localStorage.getItem('navHistory') === null) {
            this.setHistory();
          } else {
            this.historyStorage = true;
            this.navHistory = JSON.parse(localStorage.getItem('navHistory'));
            this.idxSection = this.navHistory[this.navHistory.length - 1].sec;
            this.idxQuestion = this.navHistory[this.navHistory.length - 1].que;
            this.setActualSection();
            this.navHistory.slice(-1);
            localStorage.setItem('navHistory', JSON.stringify(this.navHistory));
          }
          this.moveSection();
        }
      });
  }

  setActualSection() {
    if (
      this.poll.messages.find((x) => x.type_message == 'initial') == undefined
    ) {
      this.totalSection = this.poll.sections.length;
      this.actualSectionSubject.next(this.idxSection + 1);
    } else {
      this.totalSection = this.poll.sections.length + 1;
      this.actualSectionSubject.next(this.idxSection + 2);
    }
  }

  getHelp(message: string, image: String) {
    const dialog = this.dialog.open(DialogImageHelpComponent, {
      data: {
        message: message,
        image: image,
      },
    });
  }

  getHelp64(question) {
    const { help, helpfile64 } = question;
    const dialog = this.dialog.open(DialogImageHelpComponent, {
      data: {
        message: help,
        image: helpfile64,
      },
    });
  }

  moveSection() {
    if (this.poll.sections.length > 0) {
      if (this.poll.sections[this.idxSection].id === undefined) {
        this.router.navigate(['/poll/client', { id: this.poll.id }]);
      } else {
        this.getSection(this.poll.sections[this.idxSection].id);
      }
    } else {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: `Error en el contenido de la Encuesta`,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        this.router.navigate(['/poll/client', { id: this.poll.id }]);
      });
    }
  }

  getSection(id: number) {
    this.selected = null;
    this.optionsSelected = [];

    // Init Offline Mode
    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      this.setSection(this.poll.sections[this.idxSection]);

      return;
    }
    // End Offline Mode

    this.sectionsService.getSection(id, this.client.cliente_id).subscribe(
      (section) => {
        this.setSection(section);
      },
      async (err) => {
        this.spinner.hide();
        const fileMethod = 'question.getSection';
        await this.sendErrorToSentry({
          error: err,
          poll: this.poll,
          user: this.user,
          fileMethod,
        });
        Swal.fire({
          icon: 'error',
          title: `Ocurrio un error opteniendo la sección`,
          html: `${fileMethod} <br/> Error: ${err.status} <br/> ${err.statusText}`,
          showConfirmButton: true,
          // timer: 3000,
        }).then(() => {
          localStorage.clear();
          this.router.navigate(['']);
        });
      }
    );
  }

  setSection(section) {
    this.section = section;
    this.question = this.section.questions[this.idxQuestion];
    this.pollTitle = this.poll.name;
    this.sectionTitle = this.section.name;
    this.totalQuestionSection = this.section.questions.length;
    // Init Offline Mode
    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      const question = this.section.questions[this.idxQuestion];
      this.question = question;

      if (question.type_id === 8) {
        this.questionRequired = true;
      } else {
        this.questionRequired = question.required;
      }

      this.getSelected();
    } // End Offline Mode
    else {
      this.questionSubject.next(this.section.questions[this.idxQuestion]);
    }
    this.questionRequired = this.question.required;
    this.spinner.hide();
  }

  closeResponse() {
    this.spinner.hide();
    this.client = JSON.parse(localStorage.getItem('client'));
    if (!this.preview) {
      Swal.fire({
        title: 'Finalizar encuesta',
        html:
          'Se procedera a finalizar la encuesta, <br>' +
          '<strong>¿Deseas continuar?</strong>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        this.analytics.logEvent(
          `fin_encuesta_${this.poll.name.replace(' ', '_').toLowerCase()}`,
          {
            usuario: this.user.email,
            cliente: this.client.descr_cliente,
            encueta: this.poll.name,
          }
        );

        if (result.isConfirmed) {
          const today = new Date(Date.now());
          const date_finish = moment().format('MM/DD/YYYY');
          let date_finish_save = new Date();
          localStorage.removeItem('navHistory');
          // Init Offline Mode
          if (this.offlineMode.status && this.offlineMode.from === 'clients') {
            const idSynPoll = `${this.poll.id}-${this.client.id}-${this.user.id}`;
            const syncPoll = await this.offlineService.getSyncPoll(idSynPoll);

            let data: any = {
              id: idSynPoll,
              status:
                syncPoll.status === 'edit-offline'
                  ? 'unsynchronized-edit'
                  : 'unsynchronized',
              coords_finish: {
                lat: 0,
                long: 0,
              },
              date_finish,
              date_finish_save,
            };

            if (this.poll.geolocation === true) {
              await db.setSyncPoll(data);
              await navigator.geolocation.getCurrentPosition(
                async (position) => {
                  const coords = position.coords;
                  data.coords_finish.lat = coords.latitude.toString();
                  data.coords_finish.long = coords.longitude.toString();

                  await db.setSyncPoll(data);
                },
                async (error) => {
                  await db.setSyncPoll(data);
                }
              );
            } else {
              data.coords_finish.lat = '0';
              data.coords_finish.long = '0';
              await db.setSyncPoll(data);
            }
            const pollId = this.poll.id.toString();

            const clientId: any = this.client.cliente_id;
            this.client = (await this.offlineService.getClient(
              clientId
            )) as unknown as ClientModel;

            this.client.polls = this.client.polls.map((p: any) => {
              if (p.id === pollId) {
                p.edit = 'trueOffline';
              }
              return p;
            });
            await db.clients.update(clientId, this.client);

            const dataStasts = await db.statsPolls
              .where('[poll_id+user_id]')
              .equals([this.poll.id, this.user.id])
              .toArray();
            console.info('dataStasts', dataStasts);
            if (dataStasts.length > 0) {
              console.info('pollByDay', dataStasts[0].pollByDay);
              const dayStatsToday = moment().format('YYYY-MM-DD');
              console.info('Dia ', dayStatsToday);
              const dataStastsOld = dataStasts[0].pollByDay;

              let dataStatstotalFinit = dataStasts[0].totalFinit;
              dataStatstotalFinit++;

              let dataStatstotalToday = dataStasts[0].totalToday;
              dataStatstotalToday++;

              const dataStatsToday = dataStasts[0].pollByDay.filter(
                (item) => item.day == dayStatsToday
              );

              const idxData = dataStasts[0].pollByDay.findIndex(
                (c) => c.day === dayStatsToday
              );
              var dayData;

              if (idxData == -1) {
                dayData = 1;
                dataStasts[0].pollByDay.push({
                  day: dayStatsToday,
                  count: dayData.toString(),
                });
              } else {
                if (
                  dataStasts[0].pollByDay[idxData] &&
                  dataStasts[0].pollByDay[idxData].count
                ) {
                  dayData = dataStasts[0].pollByDay[idxData].count;
                  dayData++;
                  dataStasts[0].pollByDay[idxData].count = dayData.toString();
                } else {
                  dayData = 1;
                  dataStasts[0].pollByDay[idxData] = {
                    day: dayStatsToday,
                    count: dayData.toString(),
                  };
                }
              }

              db.statsPolls
                .get({ user_id: this.user.id, poll_id: this.poll.id })
                .then((itemP) => {
                  db.statsPolls
                    .update(itemP.id, {
                      pollByDay: dataStasts[0].pollByDay,
                      totalToday: dataStatstotalToday,
                      totalFinit: dataStatstotalFinit,
                    })
                    .then(function (updated) {});
                });
            }
            Swal.fire({
              icon: 'success',
              title: 'Excelente haz culminado tu encuesta.',
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              this.runBackgroundSync('webworker');
              this.router.navigate(['poll/client/finish']);
            });
            return;
          }
          // End Offline Mode
          else {
            this.responseService
              .closeResponse({
                customer_id: this.client.id,
                poll_id: this.poll.id,
                cliente_id: this.client.cliente_id,
                empresa_id: this.client.empresa_id,
                response_id: this.idResponse,
                lat: this.lat,
                long: this.long,
              })
              .subscribe(
                async (data) => {
                  Swal.fire({
                    icon: 'success',
                    title: 'Excelente haz culminado tu encuesta.',
                    showConfirmButton: false,
                    timer: 2000,
                  }).then(() => {
                    // window.open('', '_self', ''); //bug fix
                    // window.close();
                    this.runBackgroundSync('webworker');
                    this.router.navigate(['poll/client/finish']);
                  });
                },
                (err) => {
                  this.spinner.hide();
                  Swal.fire({
                    icon: 'error',
                    title: `No se pudo terminar la encuesta`,
                    showConfirmButton: false,
                    timer: 3000,
                  }).then(() => {
                    if (this.user.id === this.client.id) {
                      localStorage.clear();
                      localStorage.clear();
                      this.router.navigate(['']);
                    } else {
                      this.router.navigate([
                        '/poll/client',
                        { id: this.poll.id },
                      ]);
                    }
                  });
                }
              );
          }
        }
      });
    } else {
      Swal.fire({
        icon: 'success',
        title: 'Excelente haz culminado tu encuesta!!!.',
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        this.router.navigate(['poll/client/finish']);
      });
    }
  }

  async refreshData(direction: string = 'next') {
    this.selected = null;
    this.optionsSelected = [];
    if (this.optionJump !== undefined && direction === 'next') {
      if (this.optionJump.skip_target.toLowerCase() === 'end-poll') {
        this.closeResponse();
      } else {
        if (this.section.id === Number(this.optionJump.skip_target)) {
          this.idxQuestion = this.section.questions.indexOf(
            this.section.questions.find(
              (x) => x.id === Number(this.optionJump.skip_destine)
            )
          );

          if (this.idxSection === -1) {
            this.returnMainPage();
          }

          this.question = this.section.questions[this.idxQuestion];
          this.questionRequired =
            this.section.questions[this.idxQuestion].required;
          this.optionJump = undefined;

          if (this.questionPrev.type_id === 1) {
            this.selectionComponentC?.refreshOptions();
          }
          if (this.questionPrev.type_id === 8) {
            this.rankingComponenteC.refreshOptions(
              this.section.questions[this.idxQuestion].questionoptions
            );
          } else if (this.questionPrev.type_id === 5) {
            this.scaleComponenteC.refreshOptions(
              this.section.questions[this.idxQuestion]
            );
          } else if (this.questionPrev.type_id === 10) {
            this.numberComponenteC.refreshOptions(
              this.section.questions[this.idxQuestion]
            );
          } else if (this.questionPrev.type_id === 12) {
            this.PriceDropdownComponentC.refreshOptions(
              this.section.questions[this.idxQuestion]
            );
          } else if (this.questionPrev.type_id === 3) {
            this.TextComponentC.refreshOptions(
              this.section.questions[this.idxQuestion]
            );
          } else if (this.questionPrev.type_id === 4) {
            this.FilesWebcamComponent.refreshOptions(
              this.section.questions[this.idxQuestion]
            );

            // this.MultipleFilesComponent.refreshOptions(
            //   this.section.questions[this.idxQuestion]
            // );
          } else if (this.questionPrev.type_id === 13) {
            this.CoolerComponenteC?.refreshOptions(
              this.section.questions[this.idxQuestion]
            );
          }
        } else {
          this.idxSection = this.poll.sections.indexOf(
            this.poll.sections.find(
              (x) => x.id === Number(this.optionJump.skip_target)
            )
          );
          this.setActualSection();
          // Init Offline Mode
          if (this.offlineMode.status && this.offlineMode.from === 'clients') {
            const section = await db.getSectionDB(
              this.poll.id,
              Number(this.optionJump.skip_target)
            );
            this.loadSectionRefreshData(section);
            return;
          }
          // End Offline Mode
          this.sectionsService
            .getSection(
              Number(this.optionJump.skip_target),
              this.client.cliente_id
            )
            .subscribe(
              (section) => {
                this.loadSectionRefreshData(section);
              },
              async (err) => {
                this.spinner.hide();
                const fileMethod = 'question.refreshData';
                await this.sendErrorToSentry({
                  error: err,
                  poll: this.poll,
                  user: this.user,
                  fileMethod,
                });
                Swal.fire({
                  icon: 'error',
                  title: `Ocurrio un error refrescando data`,
                  html: `${fileMethod} <br/> Error: ${err.status} <br/> ${err.statusText}`,
                  showConfirmButton: true,
                  // timer: 3000,
                }).then(() => {
                  this.router.navigate(['/poll/']);
                });
              }
            );
        }
      }
    } else {
      if (this.idxQuestion < this.totalQuestionSection) {
        this.question = this.section.questions[this.idxQuestion];
        this.ref.detectChanges();
        this.questionRequired =
          this.section.questions[this.idxQuestion].required;
        if (this.questionPrev.type_id === 1) {
          this.selectionComponentC?.refreshOptions();
        }
        if (this.questionPrev?.type_id === 8) {
          this.rankingComponenteC?.refreshOptions(
            this.section.questions[this.idxQuestion].questionoptions
          );
        } else if (this.questionPrev?.type_id === 5) {
          this.scaleComponenteC?.refreshOptions(
            this.section.questions[this.idxQuestion]
          );
        } else if (this.questionPrev?.type_id === 10) {
          // type number
          this.numberComponenteC?.refreshOptions(
            this.section.questions[this.idxQuestion]
          );
        } else if (this.questionPrev?.type_id === 12) {
          this.PriceDropdownComponentC?.refreshOptions(
            this.section.questions[this.idxQuestion]
          );
          // this.questionRequired = false
        } else if (this.questionPrev?.type_id === 3) {
          this.TextComponentC?.refreshOptions(
            this.section.questions[this.idxQuestion]
          );
        } else if (this.questionPrev?.type_id === 13) {
          this.CoolerComponenteC?.refreshOptions(
            this.section.questions[this.idxQuestion]
          );
        } else if (this.questionPrev?.type_id === 4) {
          FilesWebcamComponent;
          this.FilesWebcamComponent?.refreshOptions(
            this.section.questions[this.idxQuestion]
          );
          // this.MultipleFilesComponent?.refreshOptions(
          //   this.section.questions[this.idxQuestion]
          // );
        }
      } else {
        if (direction === 'next') {
          this.idxSection = this.idxSection + 1;
          this.setActualSection();
          this.idxQuestion = 0;
          this.optionJump = undefined;
        }
        if (this.idxSection < this.poll.sections.length) {
          this.moveSection();
        } else {
          this.closeResponse();
        }
      }
    }
    this.spinner.hide();
  }

  loadSectionRefreshData(section) {
    this.section = section;
    this.sectionTitle = this.section.name;
    this.totalQuestionSection = this.section.questions.length;
    this.idxQuestion = this.optionJump.skip_destine
      ? this.section.questions.indexOf(
          this.section.questions.find(
            (x) => x.id === Number(this.optionJump.skip_destine)
          )
        )
      : 0;
    this.question = this.section.questions[this.idxQuestion];
    this.optionJump = undefined;
    if (this.questionPrev.type_id === 1) {
      this.selectionComponentC?.refreshOptions();
    }
    if (this.questionPrev.type_id === 8) {
      this.rankingComponenteC.refreshOptions(
        this.section.questions[this.idxQuestion].questionoptions
      );
    } else if (this.questionPrev.type_id === 5) {
      this.scaleComponenteC.refreshOptions(
        this.section.questions[this.idxQuestion]
      );
    } else if (this.questionPrev.type_id === 10) {
      this.numberComponenteC.refreshOptions(
        this.section.questions[this.idxQuestion]
      );
    } else if (this.questionPrev.type_id === 3) {
      this.TextComponentC.refreshOptions(
        this.section.questions[this.idxQuestion]
      );
    } else if (this.questionPrev.type_id === 4) {
      this.FilesWebcamComponent.refreshOptions(
        this.section.questions[this.idxQuestion]
      );
      // this.MultipleFilesComponent.refreshOptions(
      //   this.section.questions[this.idxQuestion]
      // );
    } else if (this.questionPrev?.type_id === 13) {
      this.CoolerComponenteC?.refreshOptions(
        this.section.questions[this.idxQuestion]
      );
    }
  }

  save() {
    this.client = JSON.parse(localStorage.getItem('client'));
    this.questionPrev = this.question;
    if (this.preview) {
      this.movePreview();
    } else {
      this.saveQuestion();
    }
  }

  async movePreview() {
    if (
      this.question.type_id == 2 ||
      this.question.type_id == 3 ||
      this.question.type_id == 4 ||
      this.question.type_id == 9 ||
      this.question.type_id == 12
    ) {
      this.moveGeneral();
    } else if (this.question.type_id == 8) {
      await this.generateStepLogicRanking(this.selected);
      this.moveGeneral();
    } else if (this.question.type_id == 1) {
      this.moveSimple();
    } else if (this.question.type_id == 5) {
      this.moveScale();
    } else if (this.question.type_id == 10) {
      this.moveNumber(this.selected);
    }
  }

  moveSimple() {
    this.option = this.question.questionoptions.find(
      (x) => x.id === this.optionsSelected[0]
    );
    this.optionJump = this.option.optionjumps[0];
    this.idxQuestion = this.idxQuestion + 1;
    this.selected = false;
    if (this.optionJump !== undefined) {
      if (this.optionJump.skip_destine == 'end-poll') {
        // this.closeResponse();
        this.closeResponse();
      } else {
        this.refreshData();
      }
    } else {
      this.refreshData();
    }
  }

  async moveGeneral() {
    if (!this.offlineMode.status && Number(this.question.type_id) == 4) {
      var prefixNameFiles = `${this.question.id}-${this.poll.id}-${this.client.id}-${this.user.id}-`;
      var tasksUpload = (
        await db.files
          .where('id')
          .startsWithIgnoreCase(prefixNameFiles)
          .sortBy('id')
      ).filter(function (task) {
        return task;
      });
      tasksUpload.forEach((element) => {
        setTimeout(() => {
          this.deleteFileDexie(element.id);
        }, 500);
      });
    }
    this.idxQuestion = this.idxQuestion + 1;
    this.selected = false;
    if (this.question.skip_method !== null) {
      this.optionJump = {
        skip_value: null,
        skip_destine: this.question.skip_destine,
        skip_method: this.question.skip_method,
        skip_target: this.question.skip_target,
        priority: null,
      };
    } else {
      this.optionJump = undefined;
    }

    this.refreshData();
  }

  moveScale() {
    const scaleValue = this.selected;
    this.optionsJump = this.question.questionoptions[0].optionjumps;
    this.idxQuestion = this.idxQuestion + 1;
    this.selected = false;
    if (this.optionsJump?.length > 0) {
      this.optionsJump.sort((a, b) => {
        var x = a.priority;
        var y = b.priority;
        return x > y ? -1 : x < y ? 1 : 0;
      });

      this.idxQuestion = this.idxQuestion + 1;
      this.selected = false;
      this.optionsJump.forEach((option) => {
        // smaller
        // greater
        // equal
        if (
          option.skip_method === 'smaller' &&
          scaleValue < option.skip_value
        ) {
          this.optionJump = option;
        }

        if (
          option.skip_method === 'greater' &&
          scaleValue > option.skip_value
        ) {
          this.optionJump = option;
        }

        if (
          option.skip_method === 'equal' &&
          scaleValue === option.skip_value
        ) {
          this.optionJump = option;
        }
      });

      this.refreshData();
    } else {
      this.refreshData();
    }
  }

  async moveNumber(valueScale: number) {
    var itemJump: boolean | undefined = false;
    this.option = this.question.questionoptions[0];
    this.idxQuestion = this.idxQuestion + 1;

    const jumpsNumber: OptionJumpModel[] =
      this.question.questionoptions[0].optionjumps
        .filter((item) => {
          return item.type_jump == 'jump';
        })
        .sort((a, b) => a.priority - b.priority);
    for await (const iterator of jumpsNumber) {
      if (typeof itemJump === 'boolean' && itemJump === false) {
        itemJump = this.jumpNumberEvaluate(valueScale, iterator);

        if (itemJump) {
          this.optionJump = iterator;
          this.refreshData();
          return;
        }
      }
    }

    if (this.question.skip_method !== null) {
      this.optionJump = {
        skip_value: null,
        skip_destine: this.question.skip_destine,
        skip_method: this.question.skip_method,
        skip_target: this.question.skip_target,
        priority: null,
      };
    } else {
      this.optionJump = undefined;
    }
    this.refreshData();
  }

  async moveRanking(valueRanking) {
    await this.generateStepLogicRanking(valueRanking);
    this.idxQuestion = this.idxQuestion + 1;
    this.selected = false;
    this.refreshData();
  }

  async saveCoolerQuestion() {
    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      // Al sincronizar enviar con moment
      const response = {
        id: `${this.question.id}-${this.poll.id}-${this.client.id}-${this.user.id}`,
        sync_poll_id: `${this.poll.id}-${this.client.id}-${this.user.id}`,
        poll_id: this.poll.id,
        client_id: this.client.id,
        is_offline: true,
        type_save: 'saveDateQuestion',
        service: 'sendResponseQuestion',
        data: {
          response_id: null,
          is_offline: true,
          question_id: this.question.id,
          answerCoolerBody: this.answerCoolerBody,
        },
        type_question: 'cooler',
      };
      await db.setResponse(response);
      this.moveGeneral();
      return;
    }
    // End Offline Mode

    this.responseService
      .sendResponseQuestion(
        {
          response_id: this.idResponse,
          question_id: this.question.id,
          answerCoolerBody: this.answerCoolerBody,
        },
        'cooler'
      )
      .subscribe(
        (data) => {
          this.moveGeneral();
        },
        async (err) => {
          this.spinner.hide();
          const fileMethod = 'question.saveCoolerQuestion';
          await this.sendErrorToSentry({
            error: err,
            poll: this.poll,
            user: this.user,
            fileMethod,
          });
          Swal.fire({
            icon: 'error',
            title: `Ocurrio un error guardando pregunta tipo Cooler`,
            html: `${fileMethod} <br/> Error: ${err.status} <br/> ${err.statusText}`,
            showConfirmButton: true,
          }).then(() => {
            // this.router.navigate(['/poll/client', { id: this.poll.id }]);
            this.router.navigate(['/poll']);
          });
        }
      );
  }

  async browseItems(
    itemValidation: QuestionValidations,
    variables_section: any[]
  ): Promise<any> {
    return new Promise(async (resolve) => {
      var formuString = itemValidation.formule;
      var formuValueString = itemValidation.validation_value;
      var newFormule: string = itemValidation.formule;
      var evalCustom: boolean = false;
      let count = 0;
      var varCustom;
      try {
        variables_section.forEach(async (itemVar) => {
          if (itemVar.sec !== undefined && itemVar.que !== undefined) {
            if (
              itemVar.sec === this.section.id &&
              itemVar.que === this.question.id
            ) {
              // formuString.replace(itemVar.id, this.selected);
              if (itemValidation.formule.indexOf(itemVar.id) !== -1) {
                formuString = formuString.replace(itemVar.id, this.selected);
              }

              if (itemValidation.validation_value.indexOf(itemVar.id) !== -1) {
                formuValueString = formuValueString.replace(
                  itemVar.id,
                  this.selected
                );
              }
            } else {
              if (itemVar.sec === 'custom') {
                evalCustom = true;
                switch (Number(itemVar.que)) {
                  case 1: {
                    varCustom = this.client.custom1;

                    break;
                  }
                  case 2: {
                    //statements;
                    varCustom = this.client.custom2;
                    break;
                  }
                  case 3: {
                    //statements;
                    varCustom = this.client.custom3;
                    break;
                  }
                  case 4: {
                    //statements;
                    varCustom = this.client.custom4;
                    break;
                  }
                  case 5: {
                    //statements;
                    varCustom = this.client.custom5;
                    break;
                  }
                }
                // setTimeout(() => {
                if (itemValidation.formule.indexOf(itemVar.id) !== -1) {
                  formuString = formuString.replace(itemVar.id, varCustom);
                }
                if (
                  itemValidation.validation_value.indexOf(itemVar.id) !== -1
                ) {
                  formuValueString = formuValueString.replace(
                    itemVar.id,
                    varCustom
                  );
                }
                // }, 200);
              } else {
                var dataQuestion;
                if (
                  this.offlineMode.status &&
                  this.offlineMode.from === 'clients'
                ) {
                  const id = `${itemVar.que}-${this.client.id}`;
                  const valueQuestion = await db
                    .getQuestionResponseDB(id)
                    .then(async (questionData) => {
                      if (questionData !== undefined) {
                        return questionData.data.value;
                      } else {
                        return null;
                      }
                    });
                  // if (valueQuestion !== null) {
                  if (itemValidation.formule.indexOf(itemVar.id) !== -1) {
                    formuString = formuString.replace(
                      itemVar.id,
                      valueQuestion !== null ? valueQuestion : '0'
                    );
                  }
                  if (
                    itemValidation.validation_value.indexOf(itemVar.id) !== -1
                  ) {
                    formuValueString = formuValueString.replace(
                      itemVar.id,
                      valueQuestion !== null ? valueQuestion : '0'
                    );
                  }
                  // }
                } else {
                  if (itemVar.que !== null) {
                    dataQuestion = await this.responseService
                      .getResponseQuestion({
                        response_id: this.idResponse,
                        question_id: itemVar.que,
                      })
                      .toPromise();
                    // if (dataQuestion.length > 0) {
                    if (itemValidation.formule.indexOf(itemVar.id) !== -1) {
                      formuString = formuString.replace(
                        itemVar.id,
                        dataQuestion.length > 0 ? dataQuestion[0].value : '0'
                      );
                    }
                    if (
                      itemValidation.validation_value.indexOf(itemVar.id) !== -1
                    ) {
                      formuValueString = formuValueString.replace(
                        itemVar.id,
                        dataQuestion.length > 0 ? dataQuestion[0].value : '0'
                      );
                      // }
                    } else {
                    }
                  } else {
                    formuString = formuString.replace(itemVar.id, '0');
                    formuString = formuString.replace(itemVar.id, '0');
                  }
                }
              }
            }
          }
          count += 1;
          var validation_message;
          if (count == variables_section.length) {
            try {
              if (evalCustom) {
                if (varCustom !== null) {
                  validation_message = itemValidation.validation_message
                    .replace('$valor', eval(eval(formuValueString)))
                    .replace('$formula', eval(formuString));
                }
              } else {
                validation_message = itemValidation.validation_message
                  .replace('$valor', eval(eval(formuValueString)))
                  .replace('$formula', eval(formuString));
              }

              resolve({
                formula: eval(formuString),
                valor: eval(formuValueString),
                message: validation_message,
              });
            } catch (error) {
              resolve({
                formula: null,
                valor: null,
                message: null,
              });
            }
          }
        });
      } catch (error) {
        resolve({
          formula: null,
          valor: null,
        });
      }
    });
  }

  async checkItem(
    itemValidation: QuestionValidations,
    variables_section: any[]
  ): Promise<any> {
    return new Promise(async (resolve) => {
      var validataRules = this.browseItems(itemValidation, variables_section);
      validataRules.then((data) => {
        if (data.formula !== null && data.valor !== null) {
          if (itemValidation.method === 'smaller') {
            if (eval(data.formula) < eval(data.valor)) {
              resolve({
                estatus: true,
                title: data.message,
              });
              return;
            } else {
              resolve({
                estatus: false,
                title: data.message,
              });
            }
          } else if (itemValidation.method === 'greater') {
            if (eval(data.formula) > eval(data.valor)) {
              resolve({
                estatus: true,
                title: data.message,
              });
              return;
            } else {
              resolve({
                estatus: false,
                title: data.message,
              });
            }
          } else if (itemValidation.method === 'equal') {
            if (eval(data.valor) === eval(data.formula)) {
              resolve({
                estatus: true,
                title: data.message,
              });
              return;
            } else {
              resolve({
                estatus: false,
                title: data.message,
              });
            }
          } else {
            resolve(false);
          }
        } else {
          resolve(false);
        }
      });
    });
  }

  sendErrorToSentry({ error, poll, user, fileMethod }) {
    return new Promise<any>((resolve, reject) => {
      Sentry.configureScope((scope) => {
        scope.setUser({
          email: user.email,
        });
        scope.setTags({
          email: user.email,
        });

        scope.setTags({
          poll: poll.name + '(ID:' + poll.id + ')',
        });
        scope.setTransactionName(
          `${error.status} - ${error.statusText} - ${fileMethod}`
        );
        Sentry.setContext('Encuesta', {
          name: poll.name,
          id: poll.id,
        });
      });
      Sentry.captureException(new Error(`${error.message}`));
      resolve(true);
    });
  }

  async getValidationQuestion(data: QuestionValidations[]) {
    return new Promise<any>(async (resolve, reject) => {
      let count = 0;
      var objectResult;
      if (data) {
        try {
          data.forEach(async (itemValidation) => {
            if (JSON.parse(itemValidation.variables_section).length > 0) {
              await this.checkItem(
                itemValidation,
                JSON.parse(itemValidation.variables_section)
              ).then((res) => {
                if (res.estatus === true) {
                  objectResult = res;
                }
              });
            }
            count += 1;
            if (count == data.length) {
              resolve(objectResult);
            }
          });
        } catch (error) {
          reject(error);
        }
      } else {
        resolve(null);
      }
    });
  }

  async numberValidation() {
    const itemValidate: any =
      this.question.questionoptions[0].optionjumps.filter((item) => {
        return item.type_jump == 'validate';
      });
    if (itemValidate.length > 0) {
      this.optionJump = itemValidate[0];
    } else {
      this.optionJump = undefined;
    }

    if (this.optionJump !== undefined) {
      // Init Offline Mode
      if (this.offlineMode.status && this.offlineMode.from === 'clients') {
        const id = `${this.optionJump.skip_destine}-${this.poll.id}-${this.client.id}-${this.user.id}`;
        this.offlineService
          .getQuestionResponseDB(id)
          .then(async (questionData) => {
            if (questionData) {
              this.validateNumberQuestionMessage(
                questionData.data.value,
                questionData.data.title
              );
            }
          });
        return;
      }
      // End Offline Mode
      this.responseService
        .getResponseQuestion({
          response_id: this.idResponse,
          question_id: this.optionJump.skip_destine,
        })
        .subscribe((data) => {
          this.validateNumberQuestionMessage(
            data[0].value,
            data[0].responsequestion.title
          );
        });
    } else {
      if (this.selected === undefined || this.selected === null) {
        this.moveNumber(null);
      } else {
        this.saveNumberQuestion();
      }
    }
  }
  async saveQuestion() {
    this.setHistory();
    this.spinner.show();
    if (this.question.type_id == 1) {
      // Select
      this.saveSimpleQuestion();
    } else if (this.question.type_id == 2) {
      this.saveMultipleQuestion();
    } else if (this.question.type_id == 3) {
      // text
      if (this.selected === undefined || this.selected === null) {
        this.moveGeneral();
      } else {
        this.saveTextQuestion();
      }
    } else if (this.question.type_id == 4) {
      // Photo
      if (this.selected === undefined || this.selected === null) {
        this.moveGeneral();
      } else {
        this.saveFileQuestion();
      }
    } else if (this.question.type_id == 5) {
      // Scale
      this.saveScaleQuestion();
    } else if (this.question.type_id == 8) {
      this.saveRankingQuestion();
    } else if (this.question.type_id == 9) {
      this.saveDateQuestion();
    } else if (this.question.type_id == 10) {
      // Number

      if (this.question.questionvalidations.length > 0) {
        this.getValidationQuestion(this.question.questionvalidations).then(
          (data) => {
            if (data !== undefined) {
              if (data.estatus === true) {
                this.spinner.hide();
                Swal.fire({
                  icon: 'warning',
                  title: 'Alerta',
                  html: `${data.title}.<br><strong>¿Deseas continuar?</strong>`,
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Si',
                  cancelButtonText: 'Corregir',
                }).then((result) => {
                  this.questionPrev = this.question;
                  if (result.isConfirmed) {
                    this.numberValidation();
                  }
                });
              }
            } else {
              this.numberValidation();
            }
          }
        );
      } else {
        this.numberValidation();
      }
      // this.optionJump = this.question.questionoptions[0].optionjumps[0];
      // if (this.optionJump !== undefined) {
      //   // Init Offline Mode
      //   if (if (offlineHeader) {
      // this.offlineMode = offlineHeader;
      // }) {
      //     const id = `${this.optionJump.skip_destine}-${this.poll.id}-${this.client.id}-${this.user.id}`;
      //     this.offlineService.getQuestionResponseDB(id).then(async (questionData) => {
      //       if (questionData) {
      //         this.validateNumberQuestionMessage(
      //           questionData.data.value,
      //           questionData.data.title
      //         );
      //       }
      //     });
      //     return;
      //   }
      //   // End Offline Mode
      //   this.responseService
      //     .getResponseQuestion({
      //       response_id: this.idResponse,
      //       question_id: this.optionJump.skip_destine,
      //     })
      //     .subscribe((data) => {
      //       this.validateNumberQuestionMessage(
      //         data[0].value,
      //         data[0].responsequestion.title
      //       );
      //     });
      // } else {
      //   if (this.selected === undefined || this.selected === null) {
      //     this.moveNumber(null);
      //   } else {
      //     this.saveNumberQuestion();
      //   }
      // }
    } else if (this.question.type_id === 12) {
      // Price
      this.savePriceDropdownQuestion();
    } else if (this.question.type_id === 13) {
      this.saveCoolerQuestion();
    }
  }

  validateNumberQuestionMessage(value, title) {
    this.spinner.hide();
    if (this.optionJump.skip_method === 'smaller') {
      if (this.selected > Number(value)) {
        Swal.fire({
          icon: 'error',
          title: `La respuesta actual (${this.selected}) es mayor al valor de la respuesta de la pregunta: <br>${title}`,
        });
      } else {
        this.saveNumberQuestion();
      }
    } else if (this.optionJump.skip_method === 'greater') {
      if (this.selected < Number(value)) {
        Swal.fire({
          icon: 'error',
          title: `La respuesta actual (${this.selected}) es menor al valor de la respuesta de la pregunta: <br> ${title}`,
        });
      } else {
        this.saveNumberQuestion();
      }
    }
  }

  async saveSimpleQuestion() {
    if (this.selected !== null && this.selected !== undefined) {
      this.option = this.question.questionoptions.find(
        (x) => x.id === this.optionsSelected[0]
      );
      if (this.option.optionjumps.length > 0) {
        this.optionJump = this.option.optionjumps[0];
      } else {
        this.optionJump = undefined;
      }
      // Init Offline Mode
      if (this.offlineMode.status && this.offlineMode.from === 'clients') {
        const params = {
          option_id: this.optionsSelected[0],
          value: this.option.title,
        };

        await this.offlineService.setResponse({
          question: this.question,
          poll: this.poll,
          client: this.client,
          user: this.user,
          params,
        });

        this.moveSimple();
        return;
      }
      // End Offline Mode
      this.responseService
        .sendResponseQuestion(
          {
            response_id: this.idResponse,
            question_id: this.question.id,
            option_id: this.optionsSelected[0],
            value: this.option.title,
          },
          'selected'
        )
        .subscribe(
          (data) => {
            this.moveSimple();
          },
          async (err) => {
            this.spinner.hide();
            const fileMethod = 'question.saveSimpleQuestion';
            await this.sendErrorToSentry({
              error: err,
              poll: this.poll,
              user: this.user,
              fileMethod,
            });
            Swal.fire({
              icon: 'error',
              title: `Ocurrio un error guardando pregunta tipo seleccion simple`,
              html: `${fileMethod} <br/> Error: ${err.status} <br/> ${err.statusText}`,
              showConfirmButton: true,
              // timer: 3000,
            }).then(() => {
              // this.router.navigate(['/poll/client', { id: this.poll.id }]);
              this.router.navigate(['/poll']);
            });
          }
        );
    } else {
      if (this.question.required === true) {
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: `Debe seleccionar una opcion para poder continuar!`,
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        this.idxQuestion = this.idxQuestion + 1;
        this.selected = false;
        this.refreshData();
      }
    }
  }

  showHideSpiner(option) {
    option === 'show' ? this.spinner.show() : this.spinner.hide();
  }

  async savePriceDropdownQuestion() {
    let question: any = this.question;
    question.questionoptions = question.questionoptions.filter((qo) => {
      return qo.value !== '';
    });
    this.question = question;
    // Init Offline Mode
    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      await this.offlineService.setResponse({
        question: this.question,
        poll: this.poll,
        client: this.client,
        user: this.user,
      });
      this.moveGeneral();
      return;
    }
    // End Offline Mode

    this.responseService
      .sendResponseQuestion(
        {
          response_id: this.idResponse,
          question_id: this.question.id,
          questionoptions: this.question.questionoptions,
        },
        'priceDropdown'
      )
      .subscribe(
        (data) => {
          this.moveGeneral();
        },
        async (err) => {
          this.spinner.hide();
          const fileMethod = 'question.savePriceDropdownQuestion';
          await this.sendErrorToSentry({
            error: err,
            poll: this.poll,
            user: this.user,
            fileMethod,
          });
          Swal.fire({
            icon: 'error',
            title: `Ocurrio un error guardando pregunta tipo precio`,
            html: `${fileMethod} <br/> Error: ${err.status} <br/> ${err.statusText}`,
            showConfirmButton: true,
          }).then(() => {
            this.router.navigate(['/poll']);
          });
        }
      );
  }

  async saveMultipleQuestion() {
    let options = [];
    if (this.optionsSelected.length > 0) {
      this.optionsSelected.forEach((option) => {
        this.option = this.question.questionoptions[option];
        options.push({ option_id: this.option.id, value: this.option.title });
      });
    }
    // Init Offline Mode
    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      await this.offlineService.setResponse({
        question: this.question,
        poll: this.poll,
        client: this.client,
        user: this.user,
        selected: this.selected,
        params: {
          options,
        },
      });

      this.moveGeneral();
      return;
    }
    // End Offline Mode

    this.responseService
      .sendResponseQuestion(
        {
          response_id: this.idResponse,
          question_id: this.question.id,
          options,
        },
        'multiple'
      )
      .subscribe(
        (data) => {
          this.optionsSelected = [];
          this.moveGeneral();
        },
        async (err) => {
          this.spinner.hide();
          const fileMethod = 'question.saveMultipleQuestion';
          await this.sendErrorToSentry({
            error: err,
            poll: this.poll,
            user: this.user,
            fileMethod,
          });
          Swal.fire({
            icon: 'error',
            title: `Ocurrio un error guardando pregunta tipo seleccion multiple`,
            html: `${fileMethod} <br/> Error: ${err.status} <br/> ${err.statusText}`,
            showConfirmButton: true,
            // timer: 3000,
          }).then(() => {
            // this.router.navigate(['/poll/client', { id: this.poll.id }]);
            this.router.navigate(['/poll']);
          });
        }
      );
  }

  async saveTextQuestion() {
    // Init Offline Mode
    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      await this.offlineService.setResponse({
        question: this.question,
        poll: this.poll,
        client: this.client,
        user: this.user,
        selected: this.selected,
      });

      this.moveGeneral();
      return;
    }
    // End Offline Mode
    this.responseService
      .sendResponseQuestion(
        {
          response_id: this.idResponse,
          question_id: this.question.id,
          text: this.selected,
        },
        'text'
      )
      .subscribe(
        (data) => {
          this.moveGeneral();
        },
        async (err) => {
          this.spinner.hide();
          const fileMethod = 'question.saveTextQuestion';
          await this.sendErrorToSentry({
            error: err,
            poll: this.poll,
            user: this.user,
            fileMethod,
          });
          Swal.fire({
            icon: 'error',
            title: `Ocurrio un error guardando pregunta tipo texto`,
            html: `${fileMethod} <br/> Error: ${err.status} <br/> ${err.statusText}`,
            showConfirmButton: true,
            // timer: 3000,
          }).then(() => {
            // this.router.navigate(['/poll/client', { id: this.poll.id }]);
            this.router.navigate(['/poll']);
          });
        }
      );
  }

  async saveFileQuestion() {
    this.optionJump = undefined;
    // this.loadfilechildC.onRemoveFirstFile();
    // Init Offline Mode

    var prefixNameFiles = `${this.question.id}-${this.poll.id}-${this.client.id}-${this.user.id}-`;

    var tasksUpload = (
      await db.files
        .where('id')
        .startsWithIgnoreCase(prefixNameFiles)
        .sortBy('id')
    ).filter(function (task) {
      return task;
    });

    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      await this.offlineService.setResponse({
        question: this.question,
        poll: this.poll,
        client: this.client,
        user: this.user,
        selected: this.selected,
      });
      this.moveGeneral();
      return;
    }
    // End Offline Mode
    let i = 0;
    var files64 = tasksUpload;
    if (files64) {
      if (files64.length > 0) {
        files64.forEach(async (element) => {
          await this.uploadFile(
            element.data.imagenBase64,
            element.data.fileName,
            element.data.token
          );
          i++;
          setTimeout(() => {
            this.deleteFileDexie(element.id);
          }, 500);
          if (i == files64.length) {
            this.responseService
              .sendResponseQuestion(
                {
                  response_id: this.idResponse,
                  question_id: this.question.id,
                  option_id: null,
                  value: this.selected,
                },
                'file'
              )
              .subscribe(
                (data) => {
                  localStorage.removeItem('fileResponse');
                  this.moveGeneral();
                },
                async (err) => {
                  this.spinner.hide();
                  const fileMethod = 'question.saveFileQuestion';
                  await this.sendErrorToSentry({
                    error: err,
                    poll: this.poll,
                    user: this.user,
                    fileMethod,
                  });
                  Swal.fire({
                    icon: 'error',
                    title: `Ocurrio un error guardando pregunta tipo archivo`,
                    html: `${fileMethod} <br/> Error: ${err.status} <br/> ${err.statusText}`,
                    showConfirmButton: true,
                    // timer: 3000,
                  }).then(() => {
                    this.router.navigate([
                      '/poll/client',
                      { id: this.poll.id },
                    ]);
                  });
                }
              );
          }
        });
      } else {
        this.moveGeneral();
      }
    } else {
      this.moveGeneral();
    }
  }

  async uploadFile(fileBase64, name, token) {
    return new Promise<any>(async (resolve, reject) => {
      this.uploadService
        .pushFileBase64ToStorage(fileBase64, name, token)
        .subscribe(
          (event) => {
            if (event['body'] !== undefined && this.selected !== undefined) {
              this.selected.push(event['body']);
            }

            if (event.type === HttpEventType.UploadProgress) {
              let percentage = Math.round((event.loaded / event.total) * 100);
            } else if (event instanceof HttpResponse) {
              resolve(event);
            }
          },
          (err) => {
            this.spinner.hide();
            Swal.fire({
              icon: 'error',
              title: `Error en el envio de la Imagen la servidor`,
              customClass: {
                container: 'poll-popup-alert-position',
              },
              showConfirmButton: false,
              timer: 3000,
            });
          }
        );
    });
  }

  async saveScaleQuestion() {
    this.optionsJump = this.question.questionoptions[0].optionjumps;
    // Init Offline Mode
    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      await this.offlineService.setResponse({
        question: this.question,
        poll: this.poll,
        client: this.client,
        user: this.user,
        selected: this.selected,
      });

      this.moveScale();
      return;
    }
    // End Offline Mode

    this.responseService
      .sendResponseQuestion(
        {
          response_id: this.idResponse,
          question_id: this.question.id,
          option_id: null,
          value: this.selected,
        },
        'scale'
      )
      .subscribe(
        (data) => {
          this.moveScale();
        },
        async (err) => {
          this.spinner.hide();
          const fileMethod = 'question.saveScaleQuestion';
          await this.sendErrorToSentry({
            error: err,
            poll: this.poll,
            user: this.user,
            fileMethod,
          });
          Swal.fire({
            icon: 'error',
            title: `Ocurrio un error guardando pregunta tipo escala`,
            html: `${fileMethod} <br/> Error: ${err.status} <br/> ${err.statusText}`,
            showConfirmButton: true,
            // timer: 3000,
          }).then(() => {
            // this.router.navigate(['/poll/client', { id: this.poll.id }]);
            this.router.navigate(['/poll']);
          });
        }
      );
  }

  async saveNumberQuestion() {
    const value = this.selected;
    this.selected = null;
    if (this.question.questionoptions.length > 0) {
      this.optionsJump = this.question.questionoptions[0].optionjumps;
    }
    this.numberComponenteC.clearValue();
    // Init Offline Mode
    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      await this.offlineService.setResponse({
        question: this.question,
        poll: this.poll,
        client: this.client,
        user: this.user,
        selected: value,
      });

      this.moveNumber(value);
      return;
    }
    // End Offline Mode
    this.responseService
      .sendResponseQuestion(
        {
          response_id: this.idResponse,
          question_id: this.question.id,
          option_id: null,
          value: value,
        },
        'number'
      )
      .subscribe(
        (data) => {
          this.moveNumber(value);
        },
        async (err) => {
          this.spinner.hide();
          const fileMethod = 'question.saveNumberQuestion';
          await this.sendErrorToSentry({
            error: err,
            poll: this.poll,
            user: this.user,
            fileMethod,
          });
          Swal.fire({
            icon: 'error',
            title: `Ocurrio un error guardando pregunta tipo numero`,
            html: `${fileMethod} <br/> Error: ${err.status} <br/> ${err.statusText}`,
            showConfirmButton: true,
            // timer: 3000,
          }).then(() => {
            this.router.navigate(['/poll']);
          });
        }
      );
  }

  updateQuestionRequired(value) {
    this.questionRequired = value;
  }

  generateStepLogicRanking(valueRanking: any) {
    var optionsJumpsQuestion = [];
    var candidateJump;

    this.selected = false;
    this.optionJump = undefined;
    this.question.questionoptions.forEach((option, index) => {
      if ((option.id, option.optionjumps.length > 0)) {
        optionsJumpsQuestion.push(option.optionjumps[0]);
      }
    });
    optionsJumpsQuestion = optionsJumpsQuestion.sort((a, b) =>
      a.priority > b.priority ? 1 : -1
    );

    optionsJumpsQuestion.forEach((optionJump) => {
      candidateJump =
        valueRanking[
          valueRanking.findIndex((q) => q.option_id == optionJump.option_id)
        ];

      if (Number(candidateJump['value']) === Number(optionJump.skip_value)) {
        this.optionJump = optionJump;
      }
    });
    return this.optionJump;
  }

  async saveRankingQuestion() {
    const valueRanking = this.selected;

    // Init Offline Mode
    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      await this.offlineService.setResponse({
        question: this.question,
        poll: this.poll,
        client: this.client,
        user: this.user,
        selected: valueRanking,
      });

      this.moveRanking(valueRanking);
      return;
    }
    // End Offline Mode

    this.responseService
      .sendResponseQuestion(
        {
          response_id: this.idResponse,
          question_id: this.question.id,
          option_id: null,
          value: valueRanking,
        },
        'ranking'
      )
      .subscribe(
        (data) => {
          this.moveRanking(valueRanking);
        },
        async (err) => {
          this.spinner.hide();
          const fileMethod = 'question.saveRankingQuestion';
          await this.sendErrorToSentry({
            error: err,
            poll: this.poll,
            user: this.user,
            fileMethod,
          });
          Swal.fire({
            icon: 'error',
            title: `Ocurrio un error guardando pregunta tipo ranking`,
            html: `${fileMethod} <br/> Error: ${err.status} <br/> ${err.statusText}`,
            showConfirmButton: true,
            // timer: 3000,
          }).then(() => {
            // this.router.navigate(['/poll/client', { id: this.poll.id }]);
            this.router.navigate(['/poll']);
          });
        }
      );
  }

  async saveDateQuestion() {
    // Init Offline Mode
    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      await this.offlineService.setResponse({
        question: this.question,
        poll: this.poll,
        client: this.client,
        user: this.user,
        selected: moment(this.selected).format(),
      });

      this.moveGeneral();
      return;
    }
    // End Offline Mode

    this.responseService
      .sendResponseQuestion(
        {
          response_id: this.idResponse,
          question_id: this.question.id,
          value: this.selected,
        },
        'date'
      )
      .subscribe(
        (data) => {
          this.moveGeneral();
        },
        async (err) => {
          this.spinner.hide();
          const fileMethod = 'question.saveDateQuestion';
          await this.sendErrorToSentry({
            error: err,
            poll: this.poll,
            user: this.user,
            fileMethod,
          });
          Swal.fire({
            icon: 'error',
            title: `Ocurrio un error guardando pregunta tipo fecha`,
            html: `${fileMethod} <br/> Error: ${err.status} <br/> ${err.statusText}`,
            showConfirmButton: true,
          }).then(() => {
            this.router.navigate(['/poll']);
          });
        }
      );
  }

  async getSelected($event = undefined) {
    if ($event !== undefined && $event !== null) {
      this.selected = $event;
      this.questionRequired = false;
      if (this.question.type_id == 1) {
        this.optionsSelected = [];
        if (this.question.questionoptions[$event] !== undefined) {
          this.optionsSelected.push(this.question.questionoptions[$event].id);
        }
      } else if (this.question.type_id == 2) {
        this.optionsSelected = $event;
        if (
          this.question.required === true &&
          this.optionsSelected.length === 0
        ) {
          this.questionRequired = true;
        }
      } else if (this.question.type_id == 12) {
        this.question.required = false;
        this.questionRequired = false;
      }
    } else {
      if (this.question === undefined) {
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: `Error, no se puede completar la encuesta.`,
          showConfirmButton: true,
          timer: 3000,
          confirmButtonText: 'Volver',
        }).then((result) => {
          this.returnMainPage();
        });
      } else {
        this.selected = $event;
        this.questionRequired = this.question.required;
      }
    }
    // this.spinner.hide();
  }

  async backClicked() {
    this.optionsSelected = [];
    const navHistory = JSON.parse(localStorage.getItem('navHistory'));
    const LastQuestionHistory = navHistory[navHistory.length - 1];
    if (navHistory.length > 1) {
      if (this.question.questionoptions.length > 0) {
        this.sectionsService
          .getSection(
            this.poll.sections[this.idxSection].id,
            this.client.cliente_id
          )
          .subscribe(
            (section) => {
              this.setSection(section);
            },
            async (err) => {
              this.spinner.hide();
              const fileMethod = 'question.refreshData';
              await this.sendErrorToSentry({
                error: err,
                poll: this.poll,
                user: this.user,
                fileMethod,
              });
              Swal.fire({
                icon: 'error',
                title: `Ocurrio un error refrescando data`,
                html: `${fileMethod} <br/> Error: ${err.status} <br/> ${err.statusText}`,
                showConfirmButton: true,
              }).then(() => {
                this.router.navigate(['/poll/']);
              });
            }
          );
      }
    } else {
      this.idxSection = LastQuestionHistory.sec;
      this.idxQuestion = LastQuestionHistory.que;
      this.questionPrev = this.section.questions[this.idxQuestion];
    }

    if (this.historyStorage == true) {
      // PAra saber que el history lo tenemos de storage
      this.navHistory.splice(-1);
      this.historyStorage = false;
    }

    if (
      this.navHistory.length < 1 ||
      (this.idxSection == 0 && this.idxQuestion == 0)
    ) {
      localStorage.removeItem('navHistory');
      this.returnMainPage();
    } else {
      this.setActualSection();
      if (this.navHistory[this.navHistory.length - 1].sec !== this.idxSection) {
        this.idxSection = this.navHistory[this.navHistory.length - 1].sec;
        // Init Offline Mode
        if (this.offlineMode.status && this.offlineMode.from === 'clients') {
          const section = await db.getSectionDB(
            this.poll.id,
            this.poll.sections[this.idxSection].id
          );
          this.loadSectionBackClicked(section);
          return;
        }
        // End Offline Mode
        else {
          this.sectionsService
            .getSection(
              this.poll.sections[this.idxSection].id,
              this.client.cliente_id
            )
            .subscribe(
              (section) => {
                this.loadSectionBackClicked(section);
              },
              (err) => {
                this.spinner.hide();
                Swal.fire({
                  icon: 'error',
                  title: `Sección no encontrada!!!`,
                  showConfirmButton: false,
                  timer: 3000,
                }).then(() => {
                  // this.router.navigate(['/poll/client', { id: this.poll.id }]);
                  this.router.navigate(['/poll']);
                });
              }
            );
        }
      } else {
        this.idxSection = this.navHistory[this.navHistory.length - 1].sec;
        this.idxQuestion = this.navHistory[this.navHistory.length - 1].que;
        this.navHistory.splice(-1);
        this.refreshData('revert');
      }
    }
  }

  loadSectionBackClicked(section) {
    this.section = section;
    this.sectionTitle = this.section.name;
    this.totalQuestionSection = this.section.questions.length;
    this.idxQuestion = this.navHistory[this.navHistory.length - 1].que;
    this.navHistory.splice(-1);

    this.refreshData('revert');
    this.setActualSection();
  }

  setHistory(direction: string = 'next') {
    if (direction === 'next') {
      if (
        this.navHistory.find(
          (x) => x.sec == this.idxSection && x.que == this.idxQuestion
        ) == undefined
      ) {
        this.navHistory.push({ sec: this.idxSection, que: this.idxQuestion });
      }
      localStorage.setItem('navHistory', JSON.stringify(this.navHistory));
    } else {
      localStorage.setItem('navHistory', JSON.stringify(this.navHistory));
    }
  }

  setAnswerCoolerBody($event) {
    this.answerCoolerBody = $event;
  }

  setMessageValidate(message: string) {
    this.messageValidation = message;
  }

  returnMainPage() {
    console.info(this.selectPoll);
    console.info(this.typeaccess);
    if (this.selectPoll === 'poll') {
      this.router.navigate(['/poll/client'], {
        queryParams: { id: this.poll.id },
      });
    } else {
      if (this.typeaccess === 'user') {
        this.router.navigate(['/poll'], {
          queryParams: { id: this.poll.id },
        });
      } else if (this.typeaccess === 'customer') {
        this.router.navigate(['poll/client/info']);
      }
    }
  }

  async cleanDta(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }
  async deleteFileDexie(element_id: string) {
    db.files
      .where('id')
      .equals(element_id)
      .delete()
      .then(() => {
        return;
      })
      .catch(async (error) => {
        // The operation failed
        const fileMethod = 'question.saveFileQuestion.DeleteFileDexie';
        await this.sendErrorToSentry({
          error: error,
          poll: this.poll,
          user: this.user,
          fileMethod,
        });
        return;
      });
  }

  jumpNumberEvaluate(value: number, itemValidation: OptionJumpModel) {
    var itemJumpValue: boolean | undefined = false;
    switch (itemValidation.skip_method) {
      case 'greater':
        if (Number(value) > Number(itemValidation.skip_value)) {
          itemJumpValue = true;
        }
        break;
      case 'smaller':
        if (Number(value) < Number(itemValidation.skip_value)) {
          itemJumpValue = true;
        }

        break;
      case 'equal':
        if (Number(value) == Number(itemValidation.skip_value)) {
          itemJumpValue = true;
        }
        break;

      default:
        break;
    }
    return itemJumpValue;
  }

  moveToNextSection() {
    this.save();
  }

  async runBackgroundSync(option = 'backsynapi') {
    await this.offlineService.setBackSyncHeader({
      id: 1,
      user: this.user,
      token: localStorage.getItem('token'),
      status: 'running',
      api_url: this.API_URL,
    });
    if (option === 'webworker') {
      if (typeof Worker !== 'undefined') {
        const worker = new Worker(
          new URL('src/app/web-workers/background-sync.worker', import.meta.url)
        );
        worker.onmessage = ({ data }) => {};
        worker.postMessage({ isAllPolls: true });
      } else {
        // Web workers are not supported in this environment.
        console.log('Web workers are not supported in this environment.');
        // You should add a fallback so that your program still executes correctly.
      }
    } else {
      await this.offlineService.runBackgroundSync();
    }
  }
}
