import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  GoogleMap,
  MapDirectionsService,
  MapInfoWindow,
  MapMarker,
  MapAnchorPoint,
} from '@angular/google-maps';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { ClientModel } from 'src/app/polls/models/client.model';
import { ClientsService } from 'src/app/polls/services/clients.service';
import Swal from 'sweetalert2';
import * as jwt_decode from 'jwt-decode';
import { UserModel } from 'src/app/polls/models/user.model';
import { OfflineService } from 'src/app/polls/services/offline.service';
import { PollModel } from 'src/app/polls/models/poll.model';
import { Router } from '@angular/router';
import { PollsService } from 'src/app/polls/services/polls.service';
import { db } from 'src/app/db/db';
import { dev } from '../../../../environments/environment';
import { Network } from '@ngx-pwa/offline';
import { IndicatorsService } from 'src/app/polls/services/indicators.service';
import { IndicatorModel } from 'src/app/polls/models/indicator.model';

@Component({
  selector: 'app-independent-maps',
  templateUrl: './independent-maps.component.html',
  styleUrls: ['./independent-maps.component.css'],
})
export class IndependentMapsComponent implements OnInit, AfterViewInit {
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow>;
  @ViewChildren(MapMarker) MapMarkers: QueryList<MapMarker>;
  @ViewChild('mapSearchField') searchField: ElementRef;
  @Output() runPollSync = new EventEmitter();
  @Input() centerPositionSubject: Observable<any>;
  devenv: String;
  clients: ClientModel[] = [];
  isOfflineMode: boolean = false;
  poll: PollModel;
  client: any;
  inputVal: string;
  center: google.maps.LatLngLiteral;
  positionActuall: google.maps.LatLngLiteral;
  zoom = 15;
  markerOptions: google.maps.MarkerOptions = { draggable: true };
  markerPositions: any[] = [];
  markerPosition: any[] = [];
  centerInit: google.maps.LatLngLiteral;
  typeCenter = '';
  codeClientMaker = '';
  nameClientMaker = '';
  dirClientMaker = '';
  polls: Observable<any[]>;
  pollsClient: Subject<any[]> = new Subject<any[]>();
  user: UserModel;
  currentPosition: { lat: number; lng: number };
  display: google.maps.LatLngLiteral;
  offlineHeader: any;
  progressUploadPollValue: number = 0;
  progressUploadPollText: string = 'Descargando respuestas';
  isOnline: boolean;
  online$ = this.network.onlineChanges;
  allowedDistance: number = 0.5;

  options: google.maps.MapOptions = {
    // mapTypeId: 'hybrid',
    // zoomControl: false,
    // scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 8,
    disableDefaultUI: true,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: true,
    keyboardShortcuts: false,
    zoomControlOptions: {
      style: google.maps.ZoomControlStyle.SMALL,
    },
  };
  directionsResults$: Observable<google.maps.DirectionsResult | undefined>;
  public activeInEnv =
    dev.env === 'local' || dev.env === 'develop' || dev.env === 'qa';
  customerIndicators: IndicatorModel[] = [];
  showIndicators: boolean = false;

  constructor(
    private offlineService: OfflineService,
    mapDirectionsService: MapDirectionsService,
    private pollsService: PollsService,
    private spinner: NgxSpinnerService,
    private clientsService: ClientsService,
    private indicatorsService: IndicatorsService,
    private router: Router,
    protected network: Network
  ) {
    this.devenv = dev.env;
    this.user = jwt_decode(localStorage.getItem('token')).user;
  }

  ngOnInit(): void {
    this.isOnline = localStorage.getItem('statusConnection') === 'true';
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (
          position.coords &&
          position.coords.longitude &&
          position.coords.latitude
        ) {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          this.centerInit = this.center;
          this.offlineService.loadOfflineHeader().then(() => {
            this.offlineService
              .getOfflineHeader(1, this.user.id)
              .then((offlineHeader) => {
                this.offlineHeader = offlineHeader ? offlineHeader : null;
                this.isOfflineMode = offlineHeader
                  ? offlineHeader.status
                  : false;
                this.setActualPosition();
              });
          });
        } else {
          //Failure
          console.log('Error getting GPS coordinates.');
        }
      });
    } else {
      //Geolocation API not supported
      console.log('Geolocation API not supported.');
    }
    this.centerPositionSubject.subscribe((position) => {
      this.center = {
        lat: position.lat,
        lng: position.lng,
      };
      this.map.panTo(
        new google.maps.LatLng({ lat: position.lat, lng: position.lng }, true)
      );
      this.currentPosition = this.center;
      this.markerPositions = [];
      this.fetchClients({});
    });
  }

  setActualPosition() {
    // Creando marcador manual
    const svgMarker = {
      path: 'M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z',
      fillColor: 'blue',
      fillOpacity: 0.6,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
      anchor: new google.maps.Point(0, 20),
    };
    let maker = {
      position: this.center,
      label: {
        text: 'Posicion Actual',
      },

      opacity: 0.6,
      options: {
        animation: google.maps.Animation.DROP,
        draggable: false,
        icon: {
          url: 'https://maps.gstatic.com/mapfiles/ms2/micons/green-dot.png',
          zIndex: 100,
        },
        // icon: {
        //   url: svgMarker,
        //   zIndex: 100,
        // },

        // icon: '../../assets/images/truck_pin.svg',
      },
    };

    this.markerPositions.push(maker);
    this.moveCenter();
  }
  addMarker(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.markerPositions.push(event.latLng.toJSON());
  }
  async openInfo(event: Event, marker: MapMarker, content: any, i: number) {
    this.nameClientMaker = '';
    this.codeClientMaker = '';
    this.dirClientMaker = '';
    this.markerPosition = [];

    if (content && !content.descr_cliente) {
      return;
    } else {
      if (!this.markerPositions[i].polls) {
        this.spinner.show();
      }
    }
    this.client = {
      id: content.id,
      cliente_id: content.id,
      descr_cliente: content.descr_cliente,
      empresa_id: content.empresa_id,
      direccion: content.direccion,
      georeferencia_x: content.lat,
      georeferencia_y: content.lng,
      orden_zona_venta: content.orden_zona_venta,
      descr_segmento: content.descr_segmento,
      descr_corta_comuna: content.descr_corta_comuna,
      descr_jefe_ventas: content.descr_jefe_ventas,
      descr_subgerente: content.descr_subgerente,
      descr_gerente: content.descr_gerente,
      descr_ofcom_venta: content.descr_ofcom_venta,
      descr_distrito_venta: content.descr_distrito_venta,
      descr_zona_venta: content.descr_zona_venta,
      descr_relevancia: content.descr_relevancia,
      vendedor_id: content.vendedor_id,
      jefe_ventas_id: content.jefe_ventas_id,
    };

    if (!this.markerPositions[i].polls) {
      const pollClient = await this.getPolls(content.id, content.empresa_id);
      this.markerPositions[i].polls = pollClient;
    }

    this.searchField.nativeElement.v;
    this.dirClientMaker = content.title;
    this.nameClientMaker = content.descr_cliente;
    this.codeClientMaker = content.info;
    this.polls = this.markerPositions[i].polls;
    this.markerPosition = content.position;
    this.inputVal = '';
    this.spinner.hide();
    if (this.infoWindow != undefined) this.infoWindow.open(marker);
  }

  eventHandler(event: any, name: string) {}

  viewMore(event: any, poll_id: any, client_id: any) {}

  async verifyInitPoll(poll) {
    this.poll = poll || poll.poll;
    const pollId = this.poll.id;
    // return;
    // Init Offline
    if (this.isOfflineMode) {
      const synchronizationIsNotUpToDate =
        await this.offlineService.synchronizationIsNotUpToDate();
      const syncPoll = await this.offlineService.getSyncPoll(
        `${pollId}-${this.client.id}-${this.user.id}`
      );
      if (
        synchronizationIsNotUpToDate === true ||
        (syncPoll && syncPoll.status === 'unsynchronized') ||
        this.offlineHeader.statusSync === 'problems' ||
        this.poll.edit === 'trueOffline'
      ) {
        this.initPoll(pollId, this.client);
        this.runPollSync.emit({
          initPoll: true,
          data: [pollId, this.client],
        });
        return;
      }

      if (this.poll.edit === 'true') {
        this.editPoll(this.poll, this.client);
        return;
      }
    }

    this.initPoll(pollId, this.client);
    // End Offline
  }

  async initPoll(pollId, client) {
    // debugger;
    localStorage.removeItem('navHistory');
    // Init Offline
    if (this.isOfflineMode) {
      this.offlineService.initPoll(pollId, client, this.user.id);
      return;
    }
    // End Offline
    this.clientsService
      .getClientsById(pollId, client.cliente_id)
      .subscribe((client) => {
        localStorage.removeItem('idResponse');
        localStorage.removeItem('poll');
        this.poll = client.poll;
        if (this.poll.geolocation === true) {
          navigator.geolocation.getCurrentPosition((position) => {
            const coords = position.coords;
            localStorage.setItem('lat', coords.latitude.toString());
            localStorage.setItem('long', coords.longitude.toString());
          });
        }
        const dataPoll = {
          id: this.poll.id,
          period_id: this.poll.period_id,
          name: this.poll.name,
          description: this.poll.description,
          sections: this.sortJSON(this.poll.sections, 'weight', 'asc'),
          messages: this.poll.messages,
          expire_date: this.poll.expire_date,
          publish_date: this.poll.publish_date,
          countquestion: this.poll.countquestion,
          geolocation: this.poll.geolocation,
          minutes: this.poll.minutes,
          infoextra: this.poll.infoextra,
          infoextrapoll: this.poll.infoextrapoll,
          infoextracustomer: this.poll.infoextracustomer,
          showmessageinit: this.poll.showmessageinit,
          addgoals: this.poll.addgoals,
          measuring_period: this.poll.measuring_period,
        };
        localStorage.setItem('poll', JSON.stringify(dataPoll));
        delete client.poll;
        localStorage.setItem('client', JSON.stringify(client));
        localStorage.setItem('source', 'list_client');
        if (
          this.poll.infoextra == true &&
          this.poll.infoextrapoll !== null &&
          this.poll.infoextrapoll !== undefined &&
          this.poll.infoextracustomer !== null &&
          this.poll.infoextracustomer !== undefined
        ) {
          this.router.navigate(['poll/client/info']);
        } else {
          this.router.navigate(['poll/client/init']);
        }
      });
  }
  async editPoll(pollEdit: PollModel, client: ClientModel) {
    var lat;
    var long;

    if (pollEdit.geolocation === true) {
      lat = localStorage.getItem('lat');
      long = localStorage.getItem('long');
    }

    Swal.fire({
      title: 'Editar encuesta',
      html:
        'Se creará registros adicionales de las respuesta del cliente<br><b>' +
        client.descr_cliente +
        '</b>, <br>' +
        'que posteriormente podrás modificar. <br>¿Deseas continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        // Init Offline
        if (this.isOfflineMode) {
          this.progressUploadPollValue = 0;
          this.progressUploadPollText = 'Descargando respuestas';
          let porcentaje = 0;
          const params = {
            clientId: client.cliente_id,
            pollId: pollEdit.id,
          };
          this.spinner.show('synchronizingEditPoll');
          this.clientsService
            .getResponsesPollsBySeller(params)
            .subscribe(async (poll: any) => {
              this.progressUploadPollValue = 50;
              this.progressUploadPollText = 'Guardando respuestas';
              const dataSyncPoll = {
                id: `${pollEdit.id}-${client.id}-${this.user.id}`,
                poll_id: pollEdit.id,
                period_id: pollEdit.period_id,
                client,
                status: 'edit-offline',
                coords_init: {
                  lat: localStorage.getItem('lat'),
                  long: localStorage.getItem('long'),
                },
                descr_segmento: client.descr_segmento,
                descr_relevancia: client.descr_relevancia,
                descr_nivel: client.descr_nivel,
                descr_vendedor: client.descr_vendedor,
                descr_jefe_ventas: client.descr_jefe_ventas,
                descr_subgerente: client.descr_subgerente,
                descr_gerente: client.descr_gerente,
                descr_ofcom_venta: client.descr_ofcom_venta,
                descr_distrito_venta: client.descr_distrito_venta,
                descr_zona_venta: client.descr_distrito_venta,
                coords_finish: null,
              };
              await db.setSyncPoll(dataSyncPoll);
              porcentaje = 50 / poll.sections.length;
              for (const section of poll.sections) {
                this.progressUploadPollValue =
                  this.progressUploadPollValue + porcentaje;
                for (const question of section.questions) {
                  question.questionoptions = question.responseoption;
                  await this.offlineService.setResponse({
                    question: question,
                    poll: pollEdit,
                    client: client,
                    user: this.user,
                    mode: 'offlineEdit',
                  });
                }
              }

              const idClient: any = client.id;
              /* const clientDexie = await db.clients
                .where({ id: idClient })
                .first(); */
              const allClients = await db.clients.toArray();
              const clientDexie = allClients.find(
                (client) => client.cliente_id == idClient
              );

              const indexPoll: number = clientDexie.polls.findIndex(
                (p) => p.id === pollEdit.id
              );
              if (indexPoll !== -1) {
                clientDexie.polls[indexPoll].edit = 'trueOffline';
              }
              await db.clients.update(idClient, clientDexie);
              const indexClient = this.clients.findIndex(
                (c) => c.id === idClient
              );
              if (indexClient !== -1) {
                this.clients[indexClient].polls[indexPoll].edit = 'trueOffline';
              }
              this.spinner.hide('synchronizingEditPoll');
              // this.verifyInitPoll(pollEdit, client);
              this.initPoll(pollEdit.id, client);
            });
          return;
        }
        // End Offline
        this.pollsService
          .sendPollEdit({
            customer_id: client.cliente_id,
            poll_id: pollEdit.id,
            user_id: this.user.id,
            lat: lat,
            long: long,
            method: 'cliente_id',
          })
          .subscribe((data) => {
            client.customer_id = data.customer_id;
            client.id = data.customer_id;
            if (data !== null) {
              Swal.fire({
                title: 'Editar encuesta',
                html:
                  'Desea ir a la ediccion de la encuesta del cliente:<br><b>' +
                  client.descr_cliente +
                  '</b>',
                icon: 'success',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Editar',
              }).then((result) => {
                if (result.isConfirmed) {
                  localStorage.setItem('client', JSON.stringify(client));
                  localStorage.removeItem('navHistory');
                  localStorage.setItem('idResponse', data.id);

                  this.poll = pollEdit;
                  localStorage.setItem('poll', JSON.stringify(this.poll));
                  localStorage.setItem('preview', 'false');
                  this.gotoeditPoll(pollEdit.id, client);
                }
              });
            } else {
              Swal.fire(
                'Editar encuesta',
                'No se ha podido editar la encuesta.',
                'error'
              );
            }
          });
      }
    });
  }

  goToLink(position: any, transport: string) {
    https: window.open(
      'https://www.google.com/maps/dir/' +
        this.centerInit.lat +
        ',' +
        this.centerInit.lng +
        '/' +
        position.lat +
        ',' +
        position.lng +
        '/data=' +
        transport,
      '_blank'
    );
  }

  moveCenter() {
    this.center = this.centerInit;
    this.currentPosition = this.centerInit;
    this.map.panTo(
      new google.maps.LatLng(
        { lat: this.centerInit.lat, lng: this.centerInit.lng },
        true
      )
    );

    let maker = {
      position: this.center,
      label: {
        text: 'Posicion Actual',
      },

      opacity: 0.6,
      options: {
        animation: google.maps.Animation.DROP,
        draggable: false,
        icon: {
          url: 'https://maps.gstatic.com/mapfiles/ms2/micons/green-dot.png',
          zIndex: 100,
        },
      },
    };

    this.markerPositions.push(maker);
  }

  ngAfterViewInit() {
    // const service = new google.maps.places.PlacesService();
    const searchBox = new google.maps.places.SearchBox(
      this.searchField.nativeElement
    );

    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(
      this.searchField.nativeElement
    );

    google.maps.event.addListener(this.map, 'load', function () {
      this.infoWindow.open(this.map, this.marker);
    });

    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();

      if (places.length === 0) return;

      const bounds = new google.maps.LatLngBounds();

      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) return;

        if (place.geometry.viewport) {
          const centerOld: { lat: number; lng: number } = this.center;

          this.center = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };

          let maker = {
            position: this.center,
            label: {
              text: place.name,
            },

            opacity: 0.6,
            options: {
              animation: google.maps.Animation.DROP,
              draggable: false,
              icon: {
                url: 'https://maps.gstatic.com/mapfiles/ms2/micons/green-dot.png',
                zIndex: 100,
              },
            },
          };

          this.markerPositions.push(maker);

          // this.markerPositions.push(maker);
          bounds.union(place.geometry.viewport);

          const distance = this.calculateDistance(
            place.geometry.location.lat(),
            place.geometry.location.lng(),
            centerOld.lat,
            centerOld.lng
          );

          setTimeout(() => {
            if (distance > this.allowedDistance) {
              this.currentPosition = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              };
              this.map.panTo(
                new google.maps.LatLng(
                  {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                  },
                  true
                )
              );
            }
          }, 10);
        } else {
          bounds.extend(place.geometry.location);
        }
      });

      this.fetchClients({}).then(() => {});
      this.inputVal = '';
      this.map.mapMouseout;
    });
  }

  async fetchClients({ isTpmFilter = false, stringSearch = '' }) {
    // Init Offline
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then(async (offlineHeader) => {
        this.spinner.show('loading-clients-maps');

        if (offlineHeader && offlineHeader.status) {
          if (!(localStorage.getItem('statusConnection') === 'true')) {
            this.spinner.hide('loading-clients-maps');
            return;
          }
        }

        setTimeout(() => {
          this.clientsService
            .getClientsByUser(
              1,
              50,
              '',
              this.currentPosition,
              0,
              98,
              this.user,
              'maps',
              this.allowedDistance
            )
            .subscribe((clients) => {
              this.spinner.show('loading-clients-maps');

              clients['rows'].forEach((client) => {
                let maker = {
                  position: {
                    lat: Number(client.georeferencia_x),
                    lng: Number(client.georeferencia_y),
                  },
                  descr_cliente: client.descr_cliente,
                  title: client.direccion,
                  info: client.cliente_id,
                  id: client.cliente_id,
                  polls: client.polls,
                  empresa_id: client.empresa_id,
                  opacity: 0.6,
                  options: {
                    animation:
                      Number(client.georeferencia_x) ===
                        Number(this.center.lat) &&
                      Number(client.georeferencia_y) === Number(this.center.lng)
                        ? google.maps.Animation.BOUNCE
                        : google.maps.Animation.DROP,
                    draggable: false,
                    title: client.descr_cliente,
                    icon: {
                      url:
                        Number(client.georeferencia_x) ===
                          Number(this.center.lat) &&
                        Number(client.georeferencia_y) ===
                          Number(this.center.lng)
                          ? 'https://maps.google.com/mapfiles/ms/micons/blue-dot.png'
                          : 'https://maps.gstatic.com/mapfiles/ms2/micons/red-dot.png',
                      zIndex: 100,
                      // This marker is 20 pixels wide by 32 pixels high.
                      // size: new google.maps.Size(20, 32),
                      // // The origin for this image is (0, 0).
                      // origin: new google.maps.Point(0, 0),
                      // // The anchor for this image is the base of the flagpole at (0, 32).
                      // anchor: new google.maps.Point(0, 32),
                    },

                    // icon: '../../assets/images/truck_pin.svg',
                    // icon: '../../assets/images/truck_pin_ccu.png',
                  },
                  lat: Number(client.georeferencia_x),
                  lng: Number(client.georeferencia_y),

                  // client,
                  orden_zona_venta: client.orden_zona_venta,
                  descr_segmento: client.descr_segmento,
                  descr_corta_comuna: client.descr_corta_comuna,
                  descr_jefe_ventas: client.descr_jefe_ventas,
                  descr_subgerente: client.descr_subgerente,
                  descr_gerente: client.descr_gerente,
                  descr_ofcom_venta: client.descr_ofcom_venta,
                  descr_distrito_venta: client.descr_distrito_venta,
                  descr_zona_venta: client.descr_zona_venta,
                  direccion: client.direccion,
                  descr_relevancia: client.descr_relevancia,
                  vendedor_id: client.vendedor_id,
                  jefe_ventas_id: client.jefe_ventas_id,
                };

                const indexPoll: number = this.markerPositions.findIndex(
                  (item) =>
                    item.lat === Number(client.georeferencia_x) &&
                    item.lng === Number(client.georeferencia_y)
                );

                if (Number(indexPoll) == -1) {
                  this.markerPositions.push(maker);
                }
              });

              this.spinner.hide('loading-clients-maps');
            });
        }, 10);
      });
  }

  async getPolls(cliente_id: string, empresa_id: number) {
    return new Promise<any>(async (resolve, reject) => {
      this.clientsService
        .getAllPollCustomerAvailable(cliente_id, empresa_id)
        .subscribe(
          (data) => {
            this.pollsClient.next(data);
            resolve(data);
            this.spinner.hide();
          },
          (err) => {
            Swal.fire({
              icon: 'error',
              title: `Error en el envio de la Imagen la servidor`,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        );
    });
  }
  move(event: google.maps.MapMouseEvent) {
    this.display = event.latLng.toJSON();
  }

  centerChanged() {}

  onDrag(e) {}

  onDragEnd(e) {
    this.currentPosition = {
      lat: this.map.googleMap.getCenter().lat(),
      lng: this.map.googleMap.getCenter().lng(),
    };

    const distance = this.calculateDistance(
      this.center.lat,
      this.center.lng,
      this.map.googleMap.getCenter().lat(),
      this.map.googleMap.getCenter().lng()
    );
    setTimeout(() => {
      if (
        distance > this.allowedDistance &&
        this.map.googleMap.getZoom() > 13
      ) {
        this.markerPositions = [];
        this.fetchClients({});
        this.center = {
          lat: this.map.googleMap.getCenter().lat(),
          lng: this.map.googleMap.getCenter().lng(),
        };
      }
    }, 500);

    // https://www.google.com/maps/@10.4904227,-66.9040982,14z/data=!10m1!1e1!12b1?source=apiv3&rapsrc=apiv3
    // https://maps.google.com/maps?ll=10.490423,-66.904098&z=14&t=m&hl=es&gl=US&mapclient=apiv3
    // https://maps.google.com/maps?ll=10.466622,-66.939375&z=14&t=m&hl=es&gl=US&mapclient=apiv3
  }

  calculateDistance(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ): number {
    const x = 69.1 * (lat2 - lat1);
    const y = 69.1 * (lon2 - lon1) * Math.cos(lat1 / 57.3);
    return Math.sqrt(x * x + y * y) * 1.609344;
  }
  async gotoeditPoll(id: number, client: ClientModel) {
    // Init Offline Mode
    if (this.isOfflineMode) {
      this.offlineService.initPoll(this.poll.id, client, this.user.id);
    }

    // debugger;
    // End Offline Mode

    if (
      this.poll.infoextra == true &&
      this.poll.infoextrapoll !== null &&
      this.poll.infoextrapoll !== undefined &&
      this.poll.infoextracustomer !== null &&
      this.poll.infoextracustomer !== undefined
    ) {
      this.router.navigate(['poll/client/info']);
    } else {
      this.router.navigate(['poll/client/init']);
    }
  }
  sortJSON(data, key, orden) {
    return data.sort(function (a, b) {
      var x = a[key],
        y = b[key];

      if (orden === 'asc') {
        return x < y ? -1 : x > y ? 1 : 0;
      }

      if (orden === 'desc') {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  }
  isPollEdit(edit) {
    // return this.isOfflineMode ? edit.split(',')[1] : edit;
    return edit;
  }

  onMapTab() {
    this.fetchClients({});
  }

  handleCreateTask(client) {
    if (!(localStorage.getItem('statusConnection') === 'true')) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Debe tener conexión a internet para acceder a tareas.',
      });
      return;
    }

    localStorage.setItem(
      `create_task_${client.cliente_id}`,
      JSON.stringify(client)
    );

    this.router.navigate([`/tasks/create/${client.cliente_id}`]);
  }

  findIndicators(client) {
    const message =
      localStorage.getItem('statusConnection') === 'true'
        ? 'Ocurrio un error en la busqueda de los indicadores.'
        : 'Debe tener conexión a internet para acceder a indicadores.';

    this.spinner.show('loading-indicators');
    this.indicatorsService
      .getIndicator(client.cliente_id, client.empresa_id)
      .subscribe(
        (data) => {
          this.customerIndicators = data;
          this.spinner.hide('loading-indicators');
          if (this.customerIndicators.length === 1) {
            return window.open(this.customerIndicators[0].url);
          }
          this.showIndicators = true;
        },
        (error) => {
          this.spinner.hide('loading-indicators');
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: message,
          });
        }
      );
  }

  hideIndicators() {
    this.showIndicators = false;
  }
}
