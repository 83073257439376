import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-question-single-select-view',
  templateUrl: './question-single-select-view.html',
  styleUrls: ['./question-single-select-view.css'],
})
export class QuestionSingleSelectViewComponent implements OnInit {
  @Input() question: any;
  @Input() selected: any;
  @Output() handleResponse: EventEmitter<number> = new EventEmitter();

  selectedOptionIndex: number = -1;
  public options: any[];
  constructor() {}

  ngOnInit(): void {
    this.options = this.question.options.sort((a, b) => a.order - b.order);
    if (this.selected?.response) {
      this.selectedOptionIndex = this.question.response.answer;
    }
  }

  handleAnswer(index: number): void {
    this.selectedOptionIndex = index;

    this.handleResponse.emit(this.question.options[index]);
  }
}
