import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { auth } from 'src/environments/environment';
import { ResponseSimpleQuesion } from '../models/response-simple-quesion.model';

@Injectable({
  providedIn: 'root',
})
export class ResponseService {
  private API_URL = `${auth.urlConfig}`;

  constructor(private httpClient: HttpClient) {}

  sendResponseQuestion(data: any, type_question: string) {
    const url = `${auth.urlConfig}/response/${data.response_id}/${type_question}`;
    return this.httpClient.post<any>(url, data).pipe(
      // retry(3),
      // catchError(this.handleError)
      catchError((error) => {
        return this.handleError(error);
      })
    );
  }

  sendResponse(data: any) {
    const url = `${auth.urlConfig}/response`;
    return this.httpClient.post<any>(url, data).pipe(
      // retry(3),
      catchError(this.handleError)
    );
  }

  closeResponse(data: any) {
    const url = `${auth.urlConfig}/response/${data.response_id}/finish`;
    return this.httpClient.put<any>(url, data).pipe(
      // retry(3),
      catchError(this.handleError)
    );
  }

  getResponsePoll(pollId: number, clientId: number) {
    const url = `${auth.urlConfig}/response`;
    const params = new HttpParams()
      .set('customer_id', clientId)
      .set('poll_id', pollId);
    return this.httpClient.get<any>(url, { params }).pipe(
      // retry(3),
      map((section) => section[0]),
      catchError(this.handleError)
    );
  }

  getResponseQuestion(data: any) {
    const url = `${auth.urlConfig}/response/${data.response_id}/${data.question_id}`;

    return this.httpClient.get<ResponseSimpleQuesion[]>(url).pipe(
      // retry(3),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else if (error.message) {
      return throwError(error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      console.log(error);
      const message =
        error.status === 409
          ? error
          : 'Something bad happened; please try again later.';
      return throwError(message);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  getResponseDependencyQuestion(data: any) {
    // :responseId//:sectionId/:questionId/:title
    const url = `${auth.urlConfig}/response/cooler/dependency/${data.poll_id}?responseId=${data.response_id}&sectionId=${data.section_id}&questionId=${data.question_id}&title=${data.title}`;

    return this.httpClient.get<ResponseSimpleQuesion[]>(url).pipe(
      // retry(3),
      catchError(this.handleError)
    );
  }

  deleteResponseFile(data: any) {
    const url = `${auth.urlConfig}/response/${data.response_id}/deleteFile`;
    return this.httpClient.post<any>(url, data).pipe(
      // retry(3),
      catchError(this.handleError)
    );
  }

  replaceResponseFile(files: any) {
    const formData: FormData = new FormData();
    formData.append('question_id', files.question_id);
    formData.append('response_id', files.idResponse);
    formData.append('oldValue', files.oldValue);
    formData.append('token', files.token);
    formData.append('value', files.value);
    formData.append('files', files.file, files.fileName);
    const url = `${auth.urlConfig}/response/${files.idResponse}/replaceFile`;
    return this.httpClient.post<any>(url, formData).pipe(
      // retry(3),
      catchError(this.handleError)
    );
  }

  replaceResponseFileValue(data: any, idResponse: number) {
    const url = `${auth.urlConfig}/response/${idResponse}/replaceFileValue`;
    return this.httpClient.post<any>(url, data).pipe(
      // retry(3),
      catchError(this.handleError)
    );
  }
}
