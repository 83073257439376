import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
} from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { OptionModel } from 'src/app/polls/models/option.model';
import { QuestionModel } from 'src/app/polls/models/question.model';
import { ResponseSimpleQuesion } from 'src/app/polls/models/response-simple-quesion.model';
import { ResponseService } from 'src/app/polls/services/response.service';
import { OfflineService } from 'src/app/polls/services/offline.service';
import { UserModel } from 'src/app/polls/models/user.model';
import { PollModel } from 'src/app/polls/models/poll.model';

// Dexie DB
import { db } from 'src/app/db/db';

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.css'],
})
export class SelectionComponent implements OnChanges {
  @Input() question: QuestionModel;
  @Input() selected: any;
  @Input() idResponse: number;
  @Output() optionSelected = new EventEmitter();
  @Output() showHideSpiner = new EventEmitter();
  offlineMode: any = {
    status: false,
    from: 'nonde',
  };
  user: UserModel;
  poll: PollModel;

  responseQuestion: ResponseSimpleQuesion;
  constructor(
    private responseService: ResponseService,
    private offlineService: OfflineService
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
    this.poll = JSON.parse(localStorage.getItem('poll'));
  }

  ngOnChanges(): void {
    if (this.selected == null) {
      this.getResponse();
    }
  }

  onSelected(i: number, question: String) {
    this.selected = i;
    this.optionSelected.emit(i);
  }

  refreshOptions() {
    this.selected = null;
    this.optionSelected.emit(this.selected);
  }

  getResponse() {
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        if (offlineHeader) {
          this.offlineMode = offlineHeader;
        }

        // Init Offline Mode
        if (this.offlineMode.status && this.offlineMode.from === 'clients') {
          const client = JSON.parse(localStorage.getItem('client'));
          const id = `${this.question.id}-${this.poll.id}-${client.id}-${this.user.id}`;
          this.offlineService
            .getQuestionResponseDB(id)
            .then(async (questionData) => {
              if (questionData) {
                this.selected = this.question.questionoptions.indexOf(
                  this.question.questionoptions.find(
                    (x) => x.id == questionData.data.option_id
                  )
                );
                if (this.selected != -1) {
                  this.optionSelected.emit(this.selected);
                }
              }
              this.showHideSpiner.emit('hide');
            });
          return;
        }
        // End Offline Mode

        this.showHideSpiner.emit('show');
        this.responseService
          .getResponseQuestion({
            response_id: this.idResponse,
            question_id: this.question.id,
          })
          .subscribe((data) => {
            if (data.length > 0) {
              this.selected = this.question.questionoptions.indexOf(
                this.question.questionoptions.find(
                  (x) => x.id == data[0].option_id
                )
              );
              if (this.selected != -1) {
                this.optionSelected.emit(this.selected);
              }
            }
            this.showHideSpiner.emit('hide');
          });
      });
  }
}
