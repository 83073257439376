import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { QuestionModel } from 'src/app/polls/models/question.model';
import { ResponseService } from 'src/app/polls/services/response.service';
import { OfflineService } from 'src/app/polls/services/offline.service';
// Dexie DB
import { db } from 'src/app/db/db';
import { UserModel } from 'src/app/polls/models/user.model';
import { PollModel } from 'src/app/polls/models/poll.model';
import * as jwt_decode from 'jwt-decode';

export interface DragDropListItem {
  option_id: string;
  title: string;
  value: number;
}

@Component({
  selector: 'app-rankingmv',
  templateUrl: './rankingmv.component.html',
  styleUrls: ['./rankingmv.component.css'],
})
export class RankingmvComponent implements OnInit, OnChanges {
  @Input() idResponse: number;
  @Input() question: QuestionModel;
  @Input() selected: any;
  @Output() optionSelected = new EventEmitter();
  @Output() showHideSpiner = new EventEmitter();
  offlineMode: any = {
    status: false,
    from: 'nonde',
  };
  rankingOut = [];
  unassignedTasks: DragDropListItem[] = [];
  user: UserModel;
  poll: PollModel;
  constructor(
    private responseService: ResponseService,
    private offlineService: OfflineService
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
    this.poll = JSON.parse(localStorage.getItem('poll'));
  }

  ngOnInit(): void {
    this.getResponse();
  }

  getResponse() {
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        if (offlineHeader) {
          this.offlineMode = offlineHeader;
        }

        // Init Offline Mode
        if (this.offlineMode.status && this.offlineMode.from === 'clients') {
          const client = JSON.parse(localStorage.getItem('client'));
          const id = `${this.question.id}-${this.poll.id}-${client.id}-${this.user.id}`;
          this.offlineService
            .getQuestionResponseDB(id)
            .then(async (questionData) => {
              this.unassignedTasks = [];
              const data = questionData?.data.value;
              if (questionData && data.length > 0) {
                this.rankingOut = data;
                data.sort((a, b) => {
                  var x = a.value;
                  var y = b.value;
                  return x < y ? -1 : x > y ? 1 : 0;
                });
                data.forEach((x, i) => {
                  var result = this.question.questionoptions.filter(
                    (y) => y.id == x.option_id
                  );

                  this.unassignedTasks.push({
                    option_id: result[0].id.toString(),
                    title: result[0].title,
                    value: i + 1,
                  });
                });
                this.optionSelected.emit(this.rankingOut);

                // this.optionSelected.emit( this.rankingOut);
              } else {
                this.unassignedTasks = [];
                this.question.questionoptions.forEach((x, i) => {
                  this.unassignedTasks.push({
                    option_id: x.id.toString(),
                    title: x.title,
                    value: i + 1,
                  });
                  this.optionSelected.emit(this.unassignedTasks);
                });
              }
              this.showHideSpiner.emit('hide');
            });
          return;
        }
        // End Offline Mode

        this.showHideSpiner.emit('show');
        this.responseService
          .getResponseQuestion({
            response_id: this.idResponse,
            question_id: this.question.id,
          })
          .subscribe((data) => {
            this.unassignedTasks = [];
            this.rankingOut = data;
            if (data.length > 0) {
              data.sort((a, b) => {
                var x = a.value;
                var y = b.value;
                return x < y ? -1 : x > y ? 1 : 0;
              });
              data.forEach((x, i) => {
                var result = this.question.questionoptions.filter(
                  (y) => y.id == x.option_id
                );

                this.unassignedTasks.push({
                  option_id: result[0].id.toString(),
                  title: result[0].title,
                  value: i + 1,
                });
              });
              this.optionSelected.emit(this.rankingOut);

              // this.optionSelected.emit( this.rankingOut);
            } else {
              this.unassignedTasks = [];
              this.question.questionoptions.forEach((x, i) => {
                this.unassignedTasks.push({
                  option_id: x.id.toString(),
                  title: x.title,
                  value: i + 1,
                });
                this.optionSelected.emit(this.unassignedTasks);
              });
            }
            this.showHideSpiner.emit('hide');
          });
      });
  }

  drop(event: CdkDragDrop<DragDropListItem[]>) {
    this.rankingOut = [];
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );
    Object.values(event.container.data).forEach((x, i) => {
      this.rankingOut.push({
        option_id: x.option_id,
        value: i + 1,
      });
    });
    this.optionSelected.emit(this.rankingOut);
    // {option_id: 79, value: "2"}
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.question?.currentValue !== undefined &&
      changes.question?.previousValue !== undefined
    ) {
      this.unassignedTasks = [];
      this.getResponse();
    }
  }

  public refreshOptions(data: any) {
    this.unassignedTasks = [];

    // Init Offline Mode
    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      const client = JSON.parse(localStorage.getItem('client'));
      const id = `${this.question.id}-${this.poll.id}-${client.id}-${this.user.id}`;
      this.offlineService
        .getQuestionResponseDB(id)
        .then(async (questionData) => {
          if (questionData && questionData.data.value.length > 0) {
            data.forEach((x, i) => {
              this.unassignedTasks.push({
                option_id: x.id.toString(),
                title: x.title,
                value: i + 1,
              });
              this.optionSelected.emit(this.unassignedTasks);
            });
          } else {
            this.getResponse();
          }
        });
      return;
    }
    // End Offline Mode

    this.responseService
      .getResponseQuestion({
        response_id: this.idResponse,
        question_id: this.question.id,
      })
      .subscribe((dataQuestionStorage) => {
        if (dataQuestionStorage.length === 0) {
          data.forEach((x, i) => {
            this.unassignedTasks.push({
              option_id: x.id.toString(),
              title: x.title,
              value: i + 1,
            });
            this.optionSelected.emit(this.unassignedTasks);
          });
        } else {
          this.getResponse();
        }
      });
  }
}
