<div>
  <mat-expansion-panel
    *ngFor="let section of this.sections; let indexSection = index"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    style="margin-top: 16px"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ section.value }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div fxLayoutAlign="center center">
        <table>
          <tr>
            <td
              class="horizontal-scroll-contenedor"
              style="width: calc(100% - 183px); overflow-x: hidden"
            >
              <div>
                <table style="padding-bottom: 8px">
                  <tr class="header">
                    <td class="first ws"></td>
                  </tr>
                  <tr *ngFor="let row of this.rows">
                    <td class="first ws ctrl-lh">
                      <button
                        mat-raised-button
                        #tooltip="matTooltip"
                        (click)="tooltip.toggle()"
                        matTooltip="{{ row.value }}"
                        matTooltipPosition="below"
                        aria-tooltip="Button that displays and hides a tooltip triggered by other buttons"
                        class="tooltip-left"
                      >
                        {{ row.value }}:
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
            <td
              class="horizontal-scroll-contenedor"
              (scroll)="onScroll($event, indexSection)"
            >
              <div>
                <table style="padding-bottom: 8px">
                  <tr class="header">
                    <td class="ws" *ngFor="let column of this.columns">
                      <button
                        mat-raised-button
                        #tooltip="matTooltip"
                        (click)="tooltip.toggle()"
                        matTooltip="{{ column.value }}"
                        matTooltipPosition="below"
                        aria-tooltip="Button that displays and hides a tooltip triggered by other buttons"
                        class="tooltip-header"
                      >
                        {{ column.value }}
                      </button>
                    </td>
                  </tr>
                  <tr *ngFor="let row of this.rows; let indexRow = index">
                    <td
                      *ngFor="
                        let column of this.columns;
                        let indexColumn = index
                      "
                    >
                      <input
                        [ngClass]="
                          values[
                            indexSection + '-' + indexRow + '-' + indexColumn
                          ].isBlock
                            ? 'blocking'
                            : ''
                        "
                        [(ngModel)]="
                          values[
                            indexSection + '-' + indexRow + '-' + indexColumn
                          ].value
                        "
                        type="number"
                        placeholder="0.00"
                        matInput
                        autocomplete="off"
                        (keyup)="
                          setValuePricesDropdown(
                            $event,
                            indexSection + '-' + indexRow + '-' + indexColumn
                          )
                        "
                        [disabled]="
                          values[
                            indexSection + '-' + indexRow + '-' + indexColumn
                          ].isBlock
                        "
                      />
                    </td>
                  </tr>
                </table>
              </div>
              <mat-icon
                *ngIf="
                  this.columns.length > 2 &&
                  isScroll.length > 0 &&
                  isScroll[indexSection]
                "
                class="icon-label scroll-pos"
                >chevron_right</mat-icon
              >
            </td>
          </tr>
        </table>
      </div>
    </ng-template>
  </mat-expansion-panel>
</div>
