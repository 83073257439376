import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { auth } from '../../../environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { UserSalesEstucture } from '../models/usersaleestucture';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private httpClient: HttpClient) {}

  getInfoSalesData(format: string) {
    const url = `${auth.urlConfig}/user/salesdata`;
    let params = new HttpParams();

    params = params.set('format', format);
    return this.httpClient
      .get<UserSalesEstucture[]>(url, { params })
      .pipe(retry(1), catchError(this.handleError));
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      return throwError('Something bad happened; please try again later.');
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
