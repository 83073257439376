<div *ngIf="isOnline">
  <div class="Input">
    <input
      #mapSearchField
      type="text"
      id="input"
      class="search-input"
      placeholder="Buscar..."
      [(ngModel)]="inputVal"
    />
  </div>
  <!-- {{ markerPositions | json }} -->
  <div
    fxLayout="column"
    fxLayoutAlign="center"
    style="margin-left: 5px; margin-right: 5px"
  >
    <google-map
      width="100%"
      [center]="center"
      [zoom]="zoom"
      [options]="options"
      (mapDblclick)="eventHandler($event, 'mapDblclick')"
      (mapMousemove)="move($event)"
      (centerChanged)="centerChanged()"
      (mapDragend)="onDragEnd($event)"
    >
      <map-marker
        #markerElem="mapMarker"
        *ngFor="let markerPosition of markerPositions; let i = index"
        [position]="markerPosition.position"
        [label]="markerPosition.label"
        [title]="markerPosition.title"
        [options]="markerPosition.options"
        (mapClick)="openInfo($event, markerElem, markerPosition, i)"
        [id]="markerPosition.info"
      >
      </map-marker>
      <map-info-window #infoWindow style="max-width: 40vh">
        <div class="row" style="font-weight: bold">
          <b>{{ nameClientMaker }}</b>
        </div>
        <div class="row">
          <b style="font-weight: bold">Código:</b> {{ codeClientMaker }}
        </div>
        <div class="row" style="font-weight: bold">
          <b
            ><i>{{ dirClientMaker }} </i></b
          >
        </div>
        <div class="row" style="margin-left: 5px">
          <span class="material-symbols-outlined"> </span>
          <button
            mat-icon-button
            aria-label="Recomendado"
            class="poll-icon-maps"
            (click)="goToLink(markerPosition, '!3m1!4b1')"
          >
            <mat-icon>star</mat-icon>
          </button>
          <button
            mat-icon-button
            aria-label="Automovil"
            class="poll-icon-maps"
            (click)="goToLink(markerPosition, '!3m1!4b1!4m2!4m1!3e0')"
          >
            <mat-icon>directions_car</mat-icon>
          </button>
          <button
            mat-icon-button
            class="poll-icon-maps"
            aria-label="Tranporte pùblico"
            (click)="goToLink(markerPosition, '!3m1!4b1!4m2!4m1!3e3')"
          >
            <mat-icon>directions_bus</mat-icon>
          </button>
          <button
            mat-icon-button
            aria-label="Caminar"
            class="poll-icon-maps"
            (click)="goToLink(markerPosition, '!3m1!4b1!4m2!4m1!3e2')"
          >
            <mat-icon>directions_walk</mat-icon>
          </button>
        </div>
        <br />
        <mat-divider></mat-divider>
        <br />
        <div
          class="row"
          style="
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            font-size: 12px;
          "
        >
          <div
            *ngIf="activeInEnv"
            class="tasks-icon row"
            style="
              flex-wrap: nowrap;
              gap: 5px;
              align-items: center;
              margin-left: 0px;
            "
            (click)="handleCreateTask(client)"
          >
            <span style="white-space: nowrap; color: #000; font-weight: bold"
              >Crear Tarea:</span
            >
            <mat-icon>task_alt</mat-icon>
          </div>
          <div style="margin-left: 15px">
            <span (click)="findIndicators(client)" class="tasks-icon">
              <div
                class="row"
                style="flex-wrap: nowrap; gap: 5px; align-items: center"
              >
                <span style="color: #000; font-weight: bold">Indicadores:</span>
                <mat-icon>analytics</mat-icon>
              </div>
            </span>
          </div>
        </div>
        <div class="row" style="margin-left: 0px">
          <mat-list>
            <div style="max-width: 80vw">
              <div mat-subheader>Encuestas</div>
              <mat-list-item *ngFor="let poll of polls">
                <div fxFlex="80">
                  <div mat-line>{{ poll.name }}</div>
                  <div mat-line>
                    Expira: {{ poll.expire_date | date : 'dd MMM yyyy' }}
                  </div>
                </div>
                <div fxFlex="20">
                  <button
                    *ngIf="
                      isPollEdit(poll.edit) === 'false' ||
                      isPollEdit(poll.edit) === false
                    "
                    mat-flat-button
                    class="btn-option"
                    aria-label="Aplicar encuesta"
                    (click)="verifyInitPoll(poll)"
                  >
                    <mat-icon
                      mat-list-icon
                      class="poll-icon-maps"
                      matTooltip="Aplicar encuesta"
                      >open_in_new</mat-icon
                    >
                  </button>
                  <button
                    *ngIf="poll.edit === 'true' || poll.edit === 'trueOffline'"
                    mat-flat-button
                    class="btn-option"
                    aria-label="Editar encuesta"
                    (click)="
                      isOfflineMode
                        ? verifyInitPoll(poll)
                        : editPoll(poll, client)
                    "
                  >
                    <mat-icon matTooltip="Editar encuesta">create</mat-icon>
                  </button>
                </div>

                <mat-divider></mat-divider>
              </mat-list-item>
            </div>
          </mat-list>
        </div>
      </map-info-window>
      <!-- <map-directions-renderer
        *ngIf="directionsResults$ | async as directionsResults"
        [directions]="directionsResults"
      ></map-directions-renderer> -->
    </google-map>

    <div class="sticky-bottom">
      <div class="flex-container" style="margin-bottom: 5px">
        <div class="flex-item" fxFlex="20"></div>

        <div class="flex-item" fxFlex="60" fxLayoutAlign="center center">
          <button
            mat-mini-fab
            color="primary"
            aria-label="Mi ubicacion"
            class="margin-button"
            (click)="moveCenter()"
          >
            <mat-icon>share_location</mat-icon>
          </button>
        </div>
        <div class="flex-item" fxFlex="20"></div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isOnline" style="text-align: center">
  <img
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAABNJJREFUaEPtmVuoFVUYgL81GhU9WqE9lN2wTkRC4ENJdM+HON04wTlJYWaeMoWConyxE0JERWYWYReN7kesqCBSECEoeyi6KVFEUSEoaT0EkXZm4p9ZM3vNmttae8+us6MFc/Y5e9b86//W/6//Mkcx4EMNuP78D/BvW/A/a4H7Abmm/SizwHJQT0M0MQgQNsAo8Iqx7dMeomiBgElCRjwhzgIuAU4DZutrDqjZEIXAT8DP+jJ/f79XH606xJNQC3EycJG+LgZO8FNEoYiI4DDwErAFeM9PRjK7LgpNohiRVfSw3UmsJKBtjb0QbIVQQJxhmsJokyXyECKtA9w9mGIXEY+5bFATwG3AU6YmAUyE+RBrQvwKfA4cTK7gIIRHAuJyp+jPY9zI4t14A1gHfFD1TBXAPAXrIliUfzDbYtudzgP2AL85KHe8BhHZVwPzC88ULblBg3xnzy0DuAd4yEGRtkLsEDCM4lKiOJJVjf3AvcAmc4IJIKZ9EbimYtfla4kWviHWYS+yKecDsoHDaYQpOVK5jUsBjgNe1bHcWjC25xfAE8CzwAvAjcaktixhrnuzBpkXx8kixfPA0hRyLvA6sKDCFx8GxAd/NO5vBJb1GeJY4G4NUrSiYjsR1wvfV4BkUnt8qgXsqPABSUA3JPfibeqHJUT4lcCTwIkleuyWleUQSbw3ISSR3KpTf50PbwWuzSYETBDmqtgztIzffQ5CydxztBcsNO7thsQCMjoQAZsIh8ZhzyHHRQV2keGqtiWuAB7Q9dAvgFxvAp84yk+nzQLWgxqDKFZeQrcZhYYCWBzCag/BcvjH9WXWQzaExHtRWo8YVwAkcLwDfOOx5oM6Wkre6bonPhpYoRU/tWJxC2LGKEyZpbrheawNYS3wpwdIdvq8nglgPITbgbM759cUkYt7tiWWABICy8bHJBDv+ijUVAtZsoINEK6oiM06GKmdEF1oPGhDSIx/rkZJqQIk4zoNJ4C5cNQP8BpwVUFqvOHxD4lIjwIfAW9Zc20I6fweqekjJmFkDLZMNVG4AJykw+wCvcWmzH26BRW3kHxijqaMPQdYrhNiFgAMB9ylI410cJXDAeCI+XBY8sTpmZRkle36IH9bI3+9gpVGJVCW7BIQxRqrZBC5Eio/6xEgflzK5bdBzYoLk4CNhNxB0hI2DXk9s6bmTKS37tIuKH8fgJnD8NeHTcIdLJCJuAzYpg+YS7ndWTtgFSGPO0DcAjwDXA6xhRuHD4AIk1JaSupuRqJcx8lLa6cZMDLlsYYvQDeKm8+MAS9nXxRrJ2/5bQNIvSK1z5mAFGDnao2kbJBeWdL/H/mygp6q2DYBJMvel4tWZfup2EkUfAnhyvR2yYsCZ0u0ATBT5wmrFTV06CS7OsW6skQbAFY/Ud4DZpp3bksI3gxqmdEzekO0ASC65SAaEGS+NDjSwEuvvRm4qSk6VZmuLYACRI2vHAAu0Ac6nSZl9qhRqjhbok0AF4i9gCTEuBmxRtNrzNI9aRugDuJ73aCXKZ8ql0A0JDuTpB8AZRBfA9dV7HxPlugXQAahNzNuwJ2De/KWxOkNYD8BUgj59FE+704d6tKD3W8Aj00vnZpa4k79drowaboDiMKL9b+h/rEo1Ouuez0/CBaoBRp4gL8BiesjTk4ehWEAAAAASUVORK5CYII="
    alt="Sin conexión"
  />
</div>

<div
  class="indicators-background"
  *ngIf="showIndicators"
  (click)="hideIndicators()"
>
  <div class="indicators-container">
    <div class="indicators-title">
      <h2 class="indicators-text">Indicadores disponibles:</h2>

      <button class="close-button" (click)="hideIndicators()">
        <mat-icon aria-label="Cerrar">close</mat-icon>
      </button>
    </div>

    <ul class="indicators-list">
      <li class="indicators-content" *ngIf="!customerIndicators.length">
        <div class="alert-message">
          <mat-icon class="alert-icon">warning</mat-icon>
          <p>Este cliente no tiene indicadores para visualizar.</p>
        </div>
      </li>

      <li
        class="indicators-content"
        *ngFor="let data of customerIndicators; let i = index"
      >
        <img class="indicator-icon" src="assets/icons/indicator_icon.png" />

        <a class="indicator-link" href="{{ data.url }}" target="_blank">
          {{ data.name }}</a
        >
        <mat-icon class="lunch-icon">launch</mat-icon>
      </li>
    </ul>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="rgb(92, 184, 92)"
  type="ball-spin-fade"
>
  <p style="font-size: 20px; color: rgb(92, 184, 92)">
    Cargando encuesta del {{ this.nameClientMaker }}...
  </p>
</ngx-spinner>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  name="loading-clients-maps"
  color="rgb(92, 184, 92)"
  type="ball-spin-fade"
>
  <p style="font-size: 20px; color: rgb(255, 255, 255)">Cargando clientes</p>
</ngx-spinner>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  name="loading-by-address"
  color="rgb(92, 184, 92)"
  type="ball-spin-fade"
>
  <p style="font-size: 20px; color: rgb(92, 184, 92)">Cargando dirección</p>
</ngx-spinner>
