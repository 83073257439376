import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { auth } from 'src/environments/environment';

import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as jwt_decode from 'jwt-decode';
import { Subject } from 'rxjs';
import * as authFirebase from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { catchError, retry, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as moment from 'moment';
import { UserService } from '../polls/services/user.service';
import { EnterpriseModel } from '../polls/models/enterprise';
import * as Sentry from '@sentry/angular-ivy';

import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private token: string;

  private authStatus = new Subject<boolean>();
  public isAuth;
  tokenTimer: NodeJS.Timer;
  private enterprises: EnterpriseModel[];

  private API_URL = `${auth.urlConfig}`;

  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    public dialog: MatDialog,
    public afAuth: AngularFireAuth,
    public ngZone: NgZone,
    private userService: UserService,
    private spinner: NgxSpinnerService
  ) {
    this.enterprises = [];
  }

  getToken() {
    if (!this.token) {
      return localStorage.getItem('token');
    }
    return this.token;
  }

  getAuthStatusListener() {
    return this.authStatus.asObservable();
  }

  getIsAuth() {
    try {
      const decodedToken = jwt_decode(localStorage.getItem('token'));
      const expiresInDuration = parseInt(
        localStorage.getItem('expiresInDuration')
      );

      if (
        decodedToken &&
        expiresInDuration &&
        moment.unix(expiresInDuration) > moment()
      ) {
        this.isAuth = true;
      } else {
        this.isAuth = false;
      }
      return this.isAuth;
    } catch (error) {
      console.log(error);
    }
  }

  authenticateUser(email: string, password: string) {
    let message: string;
    let status: number;
    let route: string;
    this.http
      .post<{ message: string; token: string; expiresIn: number }>(
        `${auth.urlConfig}/user/login`,
        {
          email: email,
          password: password,
        }
      )
      .subscribe((resp) => {
        this.token = resp.token;
        if (resp.token) {
          const decodedToken = jwt_decode(this.token);
          const expiresInDuration = resp.expiresIn;
          this.setAuthTimer(expiresInDuration);
          this.isAuth = true;
          this.authStatus.next(true);
          const now = new Date();
          const expirationDate = new Date(
            now.getTime() + expiresInDuration * 1000
          );

          const newOperation = {
            username: decodedToken.operation.username,
            init_date: decodedToken.operation.init_date,
            end_date: decodedToken.operation.end_date,
          };
          this.saveAuthData(this.token, expirationDate, newOperation);
          message = '¡Usuario logueado con éxito!.';
          status = 0;
          route = 'user-selection';
        } else {
          message =
            'Usuario o contraseña incorrectos. Verifique sus credenciales y vuelva a intentarlo';
          status = 1;
          route = '';
        }
        this.dialog.open(DialogComponent, {
          data: {
            message: message,
            status: status,
            route: route,
          },
        });
      });
  }

  authenticateUserConfig(token: string) {
    return this.http.get<{
      message: string;
      token: string;
      expiresIn: number;
      permissions: [];
    }>(
      `${this.API_URL}/config/login/token/?token=${token}&op=client&authentication_method=gmail`,
      {}
    );
  }
  authenticateUserFirebase(token: string) {
    let message: string;
    let status: number;
    let route: string;
    return this.http.post<{
      message: string;
      token: string;
      expiresIn: number;
      permissions: [];
    }>(`${auth.urlConfig}/user/login`, {
      token: token,
    });
  }

  isLoggedIn() {
    // this.operationData = this.getOperation();
    // if (this.operationData === null) {
    //   this.logout();
    //   this.openSnackBar('Debe estar logueado para ver el contenido', 'x');
    // }
    // return true;
  }

  changeUser() {
    localStorage.removeItem('seller');
    this.router.navigateByUrl('user-selection');
  }

  isUserSelected() {
    // this.sellerData = this.getSeller();
    // if (this.sellerData === null) {
    //   this.router.navigateByUrl('/user-selection');
    //   this.openSnackBar('Seleccione un usuario para ver contenido', 'x');
    //   return false;
    // } else {
    //   return true;
    // }
  }

  loginAuth() {
    // this.operationData = this.getOperation();
    // this.sellerData = this.getSeller();
    // if (this.operationData !== null) {
    //   if (this.sellerData !== null) {
    //     this.router.navigateByUrl('/dashboard');
    //   } else {
    //     this.router.navigateByUrl('/user-selection');
    //   }
    // }
  }

  private getOperation() {
    return localStorage.getItem('operation') !== undefined
      ? JSON.parse(localStorage.getItem('operation'))
      : null;
  }

  autoAuthUser() {
    const authInfo = this.getAuthData();
    if (!authInfo) {
      return;
    }
    const now = new Date();
    const expiresIn = authInfo.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      (this.token = authInfo.token), (this.isAuth = true);
      this.setAuthTimer(expiresIn / 1000);
      this.authStatus.next(true);
    }
  }

  logout() {
    this.token = null;
    this.isAuth = false;
    this.authStatus.next(false);
    this.clearAuthData();
    this.router.navigateByUrl('/login');
    clearTimeout(this.tokenTimer);
    return false;
  }

  private setAuthTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }

  private saveAuthData(token: string, expirationDate: Date, operation: any) {
    localStorage.setItem('token', token);
    localStorage.setItem('expirationDate', expirationDate.toISOString());
    localStorage.removeItem('operation');
    localStorage.removeItem('seller');
    localStorage.setItem('operation', JSON.stringify(operation));
  }

  private clearAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
  }

  private getAuthData() {
    const token = localStorage.getItem('token');
    const expiresIn = localStorage.getItem('expirationDate');
    if (!token || !expiresIn) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expiresIn),
    };
  }

  private getSeller() {
    return localStorage.getItem('seller') !== undefined
      ? JSON.parse(localStorage.getItem('seller'))
      : null;
  }

  private openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['ccu-snackbar'],
    });
  }
  // Firebase
  // Sign in with Google
  async GoogleAuth(codClient: string, pollToken: string, lat: any, lng: any) {
    this.spinner.show();

    await this.AuthLogin(
      new authFirebase.GoogleAuthProvider(),
      codClient,
      pollToken,
      lat,
      lng
    );
  }

  // Auth logic to run auth providers
  AuthLogin(
    provider: any,
    codClient: string,
    pollToken: string,
    lat: any,
    lng: any
  ) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.authenticateUserConfig(result.credential['idToken']).subscribe(
          (resp) => {
            if (resp === null) {
              this.SignOut();
              this.spinner.hide();

              this.dialog
                .open(DialogComponent, {
                  data: {
                    message: 'Usuario no encontrado',
                    status: 2,
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  this.router.navigate(['login']);
                });
            } else {
              this.SetUserData(resp, codClient, pollToken, lat, lng);
            }
          }
        );
        // this.ngZone.run(() => {
        //   this.router.navigate(['poll']);
        // });
        // this.SetUserData(result.user);
      })
      .catch((error) => {
        this.spinner.hide();

        this.dialog
          .open(DialogComponent, {
            data: {
              message: 'Lo siento, ocurrio un error inesperado.',
              status: 1,
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.router.navigate(['login']);
          });
      });
  }
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('expiresInDuration');
      this.router.navigate(['login']);
    });
  }

  // Sign in with email/password
  SignInEmailPass(
    email: string,
    password: string,
    codClient: string,
    pollToken: string,
    lat: any,
    lng: any
  ) {
    this.spinner.show();

    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        if (userCredential.user !== null || userCredential.user !== undefined) {
          // Validar email verficado
          userCredential.user.getIdToken().then((data) => {
            this.authenticateUserFirebase(data).subscribe((resp) => {
              if (resp === null) {
                this.spinner.hide();

                this.SignOut();
                this.dialog
                  .open(DialogComponent, {
                    data: {
                      message: 'Usuario no encontrado',
                      status: 2,
                    },
                  })
                  .afterClosed()
                  .subscribe(() => {
                    this.router.navigate(['login']);
                  });
              } else {
                this.SetUserData(resp, codClient, pollToken, lat, lng);
              }
            });
          });
        } else {
          this.spinner.hide();

          this.dialog
            .open(DialogComponent, {
              data: {
                message:
                  'Usuario o contraseña incorrectos. Verifique sus credenciales y vuelva a intentarlo',
                status: 1,
              },
            })
            .afterClosed()
            .subscribe(() => {
              this.router.navigate(['login']);
            });
        }
      })
      .catch((error) => {
        this.spinner.hide();

        this.dialog
          .open(DialogComponent, {
            data: {
              message:
                'Usuario o contraseña incorrectos. Verifique sus credenciales y vuelva a intentarlo',
              status: 2,
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.router.navigate(['login']);
          });
      });
  }

  SignInusername(email: string, password: string) {
    const op = 'client';

    this.spinner.show();

    return this.http
      .post<{
        message: string;
        token: string;
        expiresIn: number;
        permissions: [];
      }>(`${this.API_URL}/user/username/`, { email, password, op })
      .pipe(
        retry(1),
        tap(() => this.spinner.hide()),
        catchError(this.handleError)
      );
  }

  SetUserData(
    resp: any,
    codClient: string,
    pollToken: string,
    lat: any,
    lng: any
  ) {
    const decodedToken = jwt_decode(resp.token);

    if (resp.permissions.length == 0) {
      this.spinner.hide();

      this.dialog.open(DialogComponent, {
        data: {
          message:
            'Usuario o contraseña incorrectos. Verifique sus credenciales y vuelva a intentarlo',
          status: 2,
        },
      });
    } else {
      localStorage.setItem('token', resp.token);
      localStorage.setItem('expiresInDuration', decodedToken.exp);
      localStorage.setItem('userId', decodedToken.user.id);
      localStorage.setItem('uens', JSON.stringify(decodedToken.user.uens));

      this.isAuth = true;
      this.userService.getInfoSalesData('unit').subscribe((data) => {
        let i = 0;
        localStorage.setItem('sales', JSON.stringify(data));
        localStorage.setItem('sales', JSON.stringify(data));

        data.forEach((usersale) => {
          i++;

          if (
            this.enterprises.findIndex(
              (usersaleItem) => usersaleItem.id == usersale.enterprise.id
            ) == -1
          ) {
            this.enterprises.push(usersale.enterprise);
          }

          if (i >= data.length) {
            localStorage.setItem(
              'enterprises',
              JSON.stringify(this.enterprises)
            );
            localStorage.setItem(
              'enterprises',
              JSON.stringify(this.enterprises)
            );
          }
        });
      });

      localStorage.setItem(
        'currenLocation',
        JSON.stringify({ lat: lat, lng: lng })
      );

      Sentry.configureScope((scope) => {
        scope.setUser({
          email: decodedToken.user.email,
          username: decodedToken.user.name,
          // id: decodedToken.user.id,
        });
        scope.setTags({
          email: decodedToken.user.email,
        });
      });

      if (pollToken != undefined && codClient !== undefined) {
        this.spinner.hide();

        this.router.navigate(['/autlogin'], {
          queryParams: { pollToken, codClient },
        });
      } else {
        this.router.navigate(['poll']);

        this.spinner.hide();
      }
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      return throwError('Something bad happened; please try again later.');
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
