<div fxLayout="column">
  <div fxLayoutAlign="center center">
    <!-- <mat-icon class="icon-success icon-dialog" *ngIf="this.status === 0" fxLayoutAlign="center center">check_circle</mat-icon> -->
    <mat-icon class="icon-warning icon-dialog" fxLayoutAlign="center center"
      >info</mat-icon
    >
    <!-- <mat-icon class="icon-warning icon-dialog" *ngIf="this.status === 2" fxLayoutAlign="center center">cancel</mat-icon> -->
  </div>
  <br />
  <h1 mat-dialog-title fxLayoutAlign="center center">{{ title }}</h1>
  <span fxLayoutAlign="center center">{{ this.message }}</span>
  <div mat-dialog-actions fxLayoutAlign="end end" class="footer-dialog">
    <button *ngIf="cancel" mat-button [mat-dialog-close]>Cancelar</button>
    <button mat-button [mat-dialog-close]="true" class="btn-continue">
      Aceptar
    </button>
  </div>
</div>
