import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { auth } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PollResponseService {
  public API_URL: string = auth.urlConfig;

  constructor(private readonly httpClient: HttpClient) {}

  getPollResponse(): Observable<any> {
    const url: string = `${this.API_URL}/poll-responses/user`;

    return this.httpClient.get<any>(url).pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    console.error('An error occurred:', error);

    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }

    return throwError('Something bad happened; please try again later.');
  }
}
