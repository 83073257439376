import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { HttpResponse, HttpClient, HttpEventType } from '@angular/common/http';
import { LoadFilePickerAdapter } from '../loadfile/load-file-picker.adapter';
import { FilePickerComponent } from 'ngx-awesome-uploader';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxImageCompressService } from 'ngx-image-compress';
import { UploaderCaptions } from 'ngx-awesome-uploader';
import { Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

import { QuestionModel } from 'src/app/polls/models/question.model';
import { ResponseService } from 'src/app/polls/services/response.service';
import { UploadService } from 'src/app/polls/services/upload.service';
import { OfflineService } from 'src/app/polls/services/offline.service';
import { UserModel } from 'src/app/polls/models/user.model';
import { PollModel } from 'src/app/polls/models/poll.model';
import * as jwt_decode from 'jwt-decode';
// import { Loadfile2Component } from '../loadfile2/loadfile2.component';

// Dexie DB
import { db } from 'src/app/db/db';

export interface FilePreviewModel {
  /** uploadResponse is the response of api after file uploaded */
  uploadResponse?: any;
  file: File | Blob;
  fileName: string;
  token?: any;
}
@Component({
  selector: 'app-loadfile',
  templateUrl: './loadfile.component.html',
  styleUrls: ['./loadfile.component.css'],
})
export class LoadfileComponent implements OnInit, OnChanges {
  // @ViewChild(Loadfile2Component, { static: false }) loadfilechild2C: Loadfile2Component;
  @ViewChild('uploader', { static: false }) uploader: FilePickerComponent;
  public adapter = new LoadFilePickerAdapter(this.http);
  public myFiles: FilePreviewModel[] = [];

  @Input() idResponse: number;
  @Input() question: QuestionModel;
  @Input() selected: any;
  @Output() optionSelected = new EventEmitter();
  @Output() showHideSpiner = new EventEmitter();
  @ViewChild('FileInputQuestion', { static: false })

  // this InputVar is a reference to our input.
  FileInputQuestionControl: ElementRef;

  progress: { percentage: number } = { percentage: 0 };
  acceptFileString = '';
  acceptFile = [];
  fileExtensions = [];
  itemImageVisible: boolean = false;
  captions: UploaderCaptions;
  tokenSubject: Subject<any> = new Subject<any>();
  itemImageUrlDefault = 'assets/images/1.png';
  itemImageSubject: Subject<string> = new Subject<string>();
  selectedFiles: FileList;
  element: HTMLElement;
  elementButton: HTMLElement;
  currentFileUpload: File;
  localUrl: any;
  fileName: string;
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;
  fileTypes: any;
  offlineMode: any = {
    status: false,
    from: 'nonde',
  };
  user: UserModel;
  poll: PollModel;

  //acceptable file types

  constructor(
    private responseService: ResponseService,
    private uploadService: UploadService,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private imageCompress: NgxImageCompressService,
    private offlineService: OfflineService
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
    this.poll = JSON.parse(localStorage.getItem('poll'));
  }

  ngOnInit(): void {
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        if (offlineHeader) {
          this.offlineMode = offlineHeader;
        }
        this.spinner.show('sp6');
        this.itemImageVisible = false;
        this.fileTypes =
          this.question.questionoptions[0].allowableFile.toLocaleLowerCase();

        this.itemImageSubject.subscribe((img) => {
          this.itemImageUrlDefault = img;
          if (img != undefined) {
            if (img.length > 0) {
              this.itemImageUrlDefault = img;
              //  Init Offline Mode
              if (
                this.offlineMode.status &&
                this.offlineMode.from === 'clients'
              ) {
                const client = JSON.parse(localStorage.getItem('client'));
                const id = `${this.question.id}-${client.id}`;
                db.files.get(id).then(async (fileData) => {
                  if (fileData) {
                    if (this.itemImageUrlDefault !== 'assets/images/1.png') {
                      this.itemImageUrlDefault = fileData.data.imagenBase64;
                      this.element = document.getElementById(
                        `image-question`
                      ) as HTMLElement;
                      this.element.setAttribute(
                        'src',
                        fileData.data.imagenBase64
                      );
                      this.fileName = fileData.data.token;
                    }
                  }
                });
                return;
              }
              // End Offline Mode

              this.uploadService.getFiles(img).subscribe((url) => {
                if (this.itemImageUrlDefault !== 'assets/images/1.png') {
                  this.itemImageUrlDefault = url.url;
                  // this.itemImageVisible = true;
                  this.element = document.getElementById(
                    `image-question`
                  ) as HTMLElement;
                  this.element.setAttribute('src', url.url);
                  this.fileName = img.split('/')[1];
                }
              });
            } else {
              this.element = document.getElementById(
                `image-upload-wrap`
              ) as HTMLElement;
              this.element.style.setProperty('display', 'block');

              this.element = document.getElementById(
                `upload-btn`
              ) as HTMLElement;
              this.element.style.setProperty('display', 'block');

              this.element = document.getElementById(
                `file-upload-content`
              ) as HTMLElement;
              this.element.style.setProperty('display', 'none');
            }
          }
        });

        this.question.questionoptions[0].allowableFile
          .split(',')
          .forEach((element) => {
            this.fileExtensions.push(`'${element.toLocaleLowerCase()}'`);
            this.acceptFile.push(`.${element.toLocaleLowerCase().trim()}`);
            // .jpg,.png,.pdf
            // "'.jpg, .png'"
          });
        this.acceptFileString = this.acceptFile.toString();

        // this.fileExtensions = [this.question.questionoptions[0].allowableFile];
      });
  }

  ngOnChanges(): void {
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        if (offlineHeader) {
          this.offlineMode = offlineHeader;
        }
        this.getResponse();
        if (this.adapter.getToken() === null) {
          this.optionSelected.emit(null);
        }
      });
  }

  getResponse() {
    this.spinner.show('sp6');

    // Init Offline Mode
    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      const client = JSON.parse(localStorage.getItem('client'));
      const id = `${this.question.id}-${this.poll.id}-${client.id}-${this.user.id}`;
      this.offlineService
        .getQuestionResponseDB(id)
        .then(async (questionData) => {
          if (questionData) {
            db.files.get(id).then(async (fileData) => {
              if (fileData) {
                this.itemImageUrlDefault = fileData.data.token;
                this.itemImageSubject.next(fileData.data.imagenBase64);
                this.loadComponentFile(1);
                setTimeout(() => {
                  this.optionSelected.emit(fileData.data.imagenBase64);
                }, 1000);
              }
            });
            setTimeout(() => {
              this.spinner.hide('sp6');
            }, 2000);
          } else {
            this.loadComponentFile(0);
            setTimeout(() => {
              this.optionSelected.emit(undefined);
            }, 2000);
          }
          this.showHideSpiner.emit('hide');
        });
      return;
    }
    // End Offline Mode

    this.responseService
      .getResponseQuestion({
        response_id: this.idResponse,
        question_id: this.question.id,
      })
      .subscribe((data) => {
        if (data.length > 0) {
          this.itemImageUrlDefault = data[0].value.split('/')[1];
          this.itemImageSubject.next(data[0].value);
          this.loadComponentFile(1);
          setTimeout(() => {
            this.optionSelected.emit(data[0].value);
          }, 1000);
        } else {
          this.loadComponentFile(0);
          setTimeout(() => {
            this.optionSelected.emit(undefined);
          }, 2000);
        }
        setTimeout(() => {
          this.spinner.hide('sp6');
        }, 2000);
        this.showHideSpiner.emit('hide');
      });
  }

  loadComponentFile(mode) {
    if (mode === 1) {
      // with data
      this.element = document.getElementById(
        `image-upload-wrap`
      ) as HTMLElement;
      this.element.style.setProperty('display', 'none');
      this.element = document.getElementById(`upload-btn`) as HTMLElement;
      this.element.style.setProperty('display', 'none');
      this.element = document.getElementById(
        `file-upload-content`
      ) as HTMLElement;
      this.element.style.setProperty('display', 'block');
    } else {
      // without data
      this.itemImageSubject.next(undefined);
      this.element = document.getElementById(
        `image-upload-wrap`
      ) as HTMLElement;
      this.element.style.setProperty('display', 'block');
      this.element = document.getElementById(`upload-btn`) as HTMLElement;
      this.element.style.setProperty('display', 'block');
      this.element = document.getElementById(
        `file-upload-content`
      ) as HTMLElement;
      this.element.style.setProperty('display', 'none');
      this.itemImageUrlDefault = undefined;
      // this.itemImageVisible = true;
      this.element = document.getElementById(`image-question`) as HTMLElement;
      this.element.removeAttribute('src');
      this.fileName = '';
    }
  }

  public onUploadSuccess(event): void {
    this.itemImageVisible = false;
    // event['token']=this.adapter.getToken()
    // this.optionSelected.emit(`${this.adapter.getToken()}/${event.file.name}`);
    event['token'] = this.adapter.getToken();
    this.optionSelected.emit(`${this.adapter.getToken()}/${event.file.name}`);
  }

  public onRemoveSuccess(e: FilePreviewModel) {
    this.optionSelected.emit(null);
  }

  public onFileAdded(file: FilePreviewModel) {
    this.myFiles.push(file);
  }

  public onRemoveFirstFile() {
    this.myFiles.forEach((file) => {
      if (this.question.only_phone == true) {
        // this.loadfilechild2C.removeFile(file)
      } else {
        this.uploader.removeFileFromList(file);
      }
    });
  }

  callSelectFile() {
    this.elementButton = document.getElementById(
      `control-input-file`
    ) as HTMLElement;
    this.elementButton.click();
  }

  selectFile(event) {
    this.spinner.show();
    this.selectedFiles = event.target.files;
    const file = (event.target as HTMLInputElement).files[0];
    const token = uuidv4();
    // var  fileName : any;

    if (event.target.type === 'file') {
      this.spinner.show('sp6');
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
      var extension = event.target.files[0].name.split('.').pop().toLowerCase();
      const isSuccess = this.fileTypes.indexOf(extension) > -1;
      if (isSuccess) {
        //yes
        reader.onload = (event: any) => {
          this.localUrl = event.target.result;
          this.compressFile(this.localUrl, file.name);
          this.spinner.hide('sp6');
          this.element = document.getElementById(
            `image-question`
          ) as HTMLElement;
          this.element.setAttribute('src', event.target.result);

          this.element = document.getElementById(
            `image-upload-wrap`
          ) as HTMLElement;
          this.element.style.setProperty('display', 'none');
          this.element = document.getElementById(
            `warnig-exits-file`
          ) as HTMLElement;
          this.element.style.setProperty('display', 'block');
          this.fileName = file.name;
          // this.upload(token);
        };
      } else {
        //no
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: `Tipo de archivo no valido`,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  }

  async upload(imagen, token: string, fileName: string) {
    this.progress.percentage = 0;
    this.currentFileUpload = this.selectedFiles.item(0);
    const modeSync = localStorage.getItem('modeSync');
    // Init Offline Mode
    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      const client = JSON.parse(localStorage.getItem('client'));
      const poll = JSON.parse(localStorage.getItem('poll'));
      const reader = new FileReader();
      reader.readAsDataURL(imagen);
      reader.onload = async (event: any) => {
        const file = {
          id: `${this.question.id}-${poll.id}-${client.id}-${this.user.id}`,
          poll_id: poll.id,
          data: {
            imagenBase64: event.target.result,
            imagen: imagen,
            token: token,
            fileName: fileName,
          },
        };
        await db.setFile(file);
        this.prepareComponentFile(token, fileName);
        this.spinner.hide();
      };
      this.selectedFiles = undefined;
      return;
    }
    // End Offline Mode
    this.uploadService.pushFileToStorage(imagen, token, fileName).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress.percentage = Math.round(
            (event.loaded / event.total) * 100
          );
          if (this.progress.percentage == 100) {
            this.optionSelected.emit(`${token}/${fileName}`);
          }
        } else if (event instanceof HttpResponse) {
          this.prepareComponentFile(token, fileName);
          this.spinner.hide();
        }
        this.itemImageVisible = true;
      },
      (err) => {
        this.selectedFiles = undefined;
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: `Error en el envio de la Imagen la servidor`,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    );
    this.selectedFiles = undefined;
  }

  prepareComponentFile(token, fileName) {
    this.element = document.getElementById(
      `file-upload-content`
    ) as HTMLElement;
    this.element.style.setProperty('display', 'block');
    this.element = document.getElementById(`image-upload-wrap`) as HTMLElement;
    this.element.style.setProperty('display', 'none');
    this.element = document.getElementById(`upload-btn`) as HTMLElement;
    this.element.style.setProperty('display', 'none');
    this.optionSelected.emit(`${token}/${fileName}`);
    this.spinner.hide();
  }

  compressFile(image, fileName) {
    var orientation = -1;

    this.imageCompress
      .compressFile(image, orientation, 50, 50)
      .then((result) => {
        this.upload(
          this.dataURItoBlob(result.split(',')[1]),
          uuidv4(),
          fileName
        );
      });
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  removeUpload() {
    this.optionSelected.emit(undefined);
    this.element = document.getElementById(`image-upload-wrap`) as HTMLElement;
    this.element.style.setProperty('display', 'block');

    this.element = document.getElementById(`upload-btn`) as HTMLElement;
    this.element.style.setProperty('display', 'block');

    this.element = document.getElementById(
      `file-upload-content`
    ) as HTMLElement;
    this.element.style.setProperty('display', 'none');
    this.FileInputQuestionControl.nativeElement.value = '';
    this.optionSelected.emit(null);
  }
  replaceImage() {
    this.removeUpload();
    this.elementButton = document.getElementById(
      `control-input-file`
    ) as HTMLElement;
    this.elementButton.click();
  }
}
