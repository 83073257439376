<app-header></app-header>

<div fxLayout="row" class="ccu-img-person">
  <div fxFlex="25"></div>
  <div fxFlex="50" fxLayoutAlign="center center">
    <img
      id="image-user-form"
      class="images"
      src="assets/images/login/login.png"
    />
  </div>
  <div fxFlex="25"></div>
</div>
<div fxLayout="row">
  <div fxFlex="15"></div>
  <div fxFlex="70" fxLayoutAlign="center center">
    <a
      href="http://localhost:35261/autlogin?codClient=6930287&pollToken=e935b732-07d0-4455-b3a6-b451689862ec"
      ><img class="images" src="assets/images/login/logo.png"
    /></a>
  </div>
  <div fxFlex="15"></div>
</div>
<br />

<div class="login-cridentials">
  <ng-container>
    <div fxLayout="row">
      <div fxFlex="5"></div>
      <div fxFlex="90" fxLayoutAlign="center center">
        <form
          style="width: 100%"
          class="validate-form"
          [formGroup]="authForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="container-form">
            <div fxLayout="column">
              <mat-form-field class="example-full-width" appearance="legacy">
                <mat-label>Correo electrónico ó nombre de usuario</mat-label>
                <input matInput formControlName="username" />
                <mat-icon matSuffix>face</mat-icon>
                <mat-error
                  *ngIf="authForm.get('username').hasError('required')"
                >
                  Correo electrónico es requerido
                </mat-error>
              </mat-form-field>
              <mat-form-field class="example-full-width" appearance="legacy">
                <mat-label>Contraseña</mat-label>
                <input
                  matInput
                  [type]="hide ? 'password' : 'text'"
                  formControlName="password"
                />
                <a
                  class="show-pwd-icon"
                  href="#"
                  onClick="return false;"
                  matSuffix
                  (click)="hide = !hide"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide"
                >
                  <mat-icon>{{
                    hide ? 'visibility_off' : 'visibility'
                  }}</mat-icon>
                </a>
                <mat-error
                  *ngIf="authForm.get('password').hasError('required')"
                >
                  Contraseña es requerido
                </mat-error>
              </mat-form-field>

              <button mat-button color="success" type="submit">
                Iniciar Sesión
              </button>
            </div>
          </div>
        </form>
      </div>
      <div fxFlex="5"></div>
    </div>
  </ng-container>

  <div fxLayout="row">
    <div fxFlex="5"></div>
    <div fxFlex="90"><hr class="separador" /></div>
    <div fxFlex="5"></div>
  </div>
  <div fxLayout="row">
    <div fxFlex="5"></div>
    <div fxFlex="90" fxLayoutAlign="center center">
      <button
        style="width: 100%"
        mat-button
        class="btn-google"
        color="success"
        type="button"
        (click)="this.GoogleAuth()"
        [disabled]="this.openPopUp"
      >
        <img class="img-icon" src="assets/images/login/icon-google.png" />
        <span class="btn-text-center">Iniciar Sesión con Google</span>
      </button>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="rgb(92, 184, 92)"
  type="ball-spin-fade"
  class="content-spinner"
>
  <p style="color: white; font-size: 20px">Iniciando sesion...</p>
</ngx-spinner>
