import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SynchronizationService {
  private readonly start: Subject<void> = new Subject();
  private readonly stop: Subject<void> = new Subject();
  private readonly isRunning: Subject<{
    status: boolean;
    step: string;
  }> = new Subject();

  private readonly stopIsRunning: Subject<{
    status: boolean;
    step: string;
  }> = new Subject();

  private readonly synchronizationPolls: Subject<{
    deactiveOffline?: boolean;
    initPoll?: boolean;
    data?: any;
  }> = new Subject();

  private readonly uploadIsRunning: Subject<boolean> = new Subject();
  private readonly pollPreview: Subject<any> = new Subject();
  private readonly updateCount: Subject<number> = new Subject<number>();

  getStartObservable() {
    return this.start.asObservable();
  }

  nextStart() {
    this.start.next();
  }

  getStopObservable() {
    return this.stop.asObservable();
  }

  nextStop() {
    this.stop.next();
  }

  getIsRunningObservable() {
    return this.isRunning.asObservable();
  }

  nextIsRunning(data: { status: boolean; step: string }) {
    this.isRunning.next(data);
  }

  getStopIsRunningObservable() {
    return this.stopIsRunning.asObservable();
  }

  nextStopIsRunning(data: { status: boolean; step: string }) {
    this.stopIsRunning.next(data);
  }

  getSynchronizationPollsObservable() {
    return this.synchronizationPolls.asObservable();
  }

  nextSynchronizationPolls(data: {
    deactiveOffline?: boolean;
    initPoll?: boolean;
    data?: any;
  }) {
    this.synchronizationPolls.next(data);
  }

  getUploadIsRunningObservable() {
    return this.uploadIsRunning.asObservable();
  }

  nextUploadIsRunning(data: boolean) {
    this.uploadIsRunning.next(data);
  }

  getPollPreviewObservable() {
    return this.pollPreview.asObservable();
  }

  nextPollPreview(data: any) {
    this.pollPreview.next(data);
  }

  getUpdateCount() {
    return this.updateCount.asObservable();
  }

  nextUpdateCount(count: number) {
    this.updateCount.next(count);
  }
}
