import { Output } from '@angular/core';
import { Component, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-template-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css'],
})
export class TaskTemplateTextComponent {
  @Input() isOnlyText: boolean = false;
  @Input() question: any;
  @Output() handleResponse: EventEmitter<string> = new EventEmitter();

  public response: string = '';

  constructor() {}

  handleAnswer(answer: string): void {
    this.handleResponse.emit(answer);
  }
}
