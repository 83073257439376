<ng-container *ngIf="question.format === 'button'">
  <div
    fxLayoutAlign="center center"
    fxLayout="column"
    *ngFor="let option of this.question.questionoptions; let i = index"
  >
    <mat-card
      mat-ripple
      class="card-question {{
        this.selecteds.indexOf(i) !== -1 ? 'card-selected' : ''
      }}"
      (click)="onSelected(i, null)"
    >
      <div fxLayout="column" fxLayoutAlign="center center">
        <div class="title">{{ option.title }}</div>
      </div>
    </mat-card>
  </div>
</ng-container>
<ng-container *ngIf="question.format === 'lista_desp'">
  <div fxLayoutAlign="center center" fxLayout="column">
    <mat-form-field class="input-full-width mb-3" appearance="outline">
      <mat-label>Opciones</mat-label>
      <mat-select #select multiple>
        <!-- <mat-option>--</mat-option> -->
        <mat-option
          *ngFor="let option of this.question.questionoptions; let i = index"
          [value]="this.question.questionoptions[i]"
          (click)="onSelected(i, null)"
          id="ques-{{ question.id }}-opt-{{ question.questionoptions[i].id }}"
        >
          {{ option.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-container>
