import { Component, Input, OnInit } from '@angular/core';
import { TaskByClient } from '../../../../interfaces';
import * as moment from 'moment';
import { ETaskStatus } from '../../../../entities/task.entity';
import { NavigationExtras, Router } from '@angular/router';
import { TasksService } from 'src/app/tasks/services/tasks.service';

@Component({
  selector: 'app-tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.scss'],
})
export class TasksListComponent implements OnInit {
  @Input() clients: TaskByClient[] = [];
  @Input() status: string[];

  public isTabIndex_0: boolean = true;

  constructor(private readonly router: Router, private taskService: TasksService) {}

  ngOnInit(): void {
    this.taskService.$isTabIndex_0.subscribe((tabIndex) => {
      this.isTabIndex_0 = tabIndex !== 1 ? true : false;
    });
    this.checkLimitDate();
    this.filterTasks();
  }

  filterTasks() {
    this.clients = this.clients.filter((c) => {
      c.tasks = c.tasks.filter((t) => this.status.includes(t.template_status));

      return Boolean(c.tasks.length);
    });
  }

  checkLimitDate() {
    this.clients = this.clients.map((client) => {
      client.tasks = client.tasks.map((task) => {
        const { limit_date } = task;

        if (this._checkIfExpired(limit_date)) task['expired'] = true;
        else if (this._checkIfToExpire(limit_date)) task['toExpired'] = true;
        else if (this._checkIfInTime(limit_date)) task['inTime'] = true;

        return task;
      });

      const expired = client.tasks.find((t) => t['expired']);
      const toExpired = client.tasks.find((t) => t['toExpired']);

      if (expired) return { ...client, expired: true };
      if (toExpired) return { ...client, toExpired: true };

      const published = client.tasks.find((t) => t.template_status === ETaskStatus.COMPLETED);

      if (published) return { ...client, inProgress: true };

      return { ...client, pending: true };
    });
  }

  handleViewInfo(task) {
    localStorage.setItem('response_task', JSON.stringify(task));
    const navigationExtras: NavigationExtras = {
      state: {
        isCreatedTask: true,
      },
    };
    this.isTabIndex_0
      ? this.router.navigate([`/tasks/${task.id}/response`], navigationExtras)
      : this.router.navigate([`/tasks/info/${task.id}`]);
  }

  _checkIfExpired(limitDate: string): boolean {
    return moment(new Date()).isAfter(limitDate);
  }

  _checkIfToExpire(limitDate: string): boolean {
    const dayBefore = moment(new Date()).add(1, 'd');

    return moment(dayBefore).isSameOrAfter(limitDate);
  }

  _checkIfInTime(limitDate: string): boolean {
    return moment(new Date()).isBefore(limitDate);
  }
}
