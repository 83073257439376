<div class="offline" *ngIf="!isOnline">Sin conexión</div>
<div class="body-container">
  <!-- <app-login></app-login> -->
  <!-- <app-user-selection></app-user-selection> -->
  <!-- <app-dashboard></app-dashboard> -->
  <!-- <app-client-list></app-client-list> -->
  <!-- <app-question></app-question> -->
  <div>
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</div>

<div class="footer-container">
  <app-shared-navbar *ngIf="showNavbarFlag"></app-shared-navbar>
</div>
