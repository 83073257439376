<div fxLayout="column">
  <button
    class="btn-change"
    mat-button
    routerLink=""
    (click)="logout()"
    fxLayoutAlign="end end"
  >
    Cerrar sesión
  </button>
  <span class="title">Bienvenido</span>
  <span class="sub-title">{{ user.name }}</span>
</div>
<br />
