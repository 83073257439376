<div fxLayout="column">
  <div fxLayoutAlign="center center">
    <mat-icon
      class="icon-success icon-dialog"
      *ngIf="this.status === 0"
      fxLayoutAlign="center center"
      >check_circle</mat-icon
    >
    <mat-icon
      class="icon-warning icon-dialog"
      *ngIf="this.status === 1"
      fxLayoutAlign="center center"
      >info</mat-icon
    >
    <mat-icon
      class="icon-warning icon-dialog"
      *ngIf="this.status === 2"
      fxLayoutAlign="center center"
      >cancel</mat-icon
    >
  </div>
  <br />
  <h1 *ngIf="!noOneButton" mat-dialog-title fxLayoutAlign="center center">Mensaje</h1>
  <span fxLayoutAlign="center center" style="font-size: 14px;">{{ this.message }}</span>
  <div mat-dialog-actions fxLayoutAlign="center end" class="footer-dialog">
    <button *ngIf="!noOneButton" mat-button (click)="closeDialog()">Cerrar</button>
    <button
      mat-button
      class="btn-continue"
      routerLink="{{ this.route }}"
      (click)="closeDialog()"
      *ngIf="!this.confirm && !this.onlyClose"
    >
      Aceptar
    </button>
    <button
      mat-button
      class="btn-continue"
      (click)="restartPoll()"
      *ngIf="this.confirm"
    >
      Reiniciar
    </button>
  </div>
</div>
