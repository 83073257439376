import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { SectionsService } from 'src/app/polls/services/sections.service';
import { ImagesService } from 'src/app/polls/services/images.service';
import { ClientsService } from 'src/app/polls/services/clients.service';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import Swal from 'sweetalert2';
import * as Sentry from '@sentry/angular-ivy';

import { EnterpriseModel } from 'src/app/polls/models/enterprise';
import { UserModel } from 'src/app/polls/models/user.model';
// Dexie DB
import { db } from 'src/app/db/db';
import { formatDate } from '@angular/common';
import { client } from 'src/environments/environment';
import { promise } from 'protractor';
import { log } from 'console';
import * as moment from 'moment';
import { BrowserStack } from 'protractor/built/driverProviders';
import { StatsService } from './stats.service';
import { BackgroundSyncApiService } from './background-sync-api.service';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { SynchronizationService } from 'src/app/synchronization/services/synchronization.service';

@Injectable({
  providedIn: 'root',
})
export class OfflineService {
  user: UserModel;
  monitoringIntervals: number[] = [];

  constructor(
    private sectionsService: SectionsService,
    private imagesService: ImagesService,
    private clientsService: ClientsService,
    private router: Router,
    private statsService: StatsService,
    private backgroundSyncApiService: BackgroundSyncApiService,
    private http: HttpClient,
    private swUpdate: SwUpdate,
    private synchronizationService: SynchronizationService
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
  }

  sortJSON(data, key, orden) {
    return data.sort(function (a, b) {
      var x = a[key],
        y = b[key];

      if (orden === 'asc') {
        return x < y ? -1 : x > y ? 1 : 0;
      }

      if (orden === 'desc') {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  }

  runBackgroundSync() {
    this.backgroundSyncApiService.testBackgroundSync({});
  }

  testConection() {
    return new Promise<any>(async (resolve, reject) => {
      let imageAddr: string =
        'https://forms.ccu.cl/assets/images/login/login.png' +
        '?n=' +
        Math.random();
      let startTime: number, endTime: number;
      let downloadSize: number = 87762; //bytes
      let download: HTMLImageElement = new Image();
      download.onload = function (): void {
        endTime = new Date().getTime();
        let duration: number = (endTime - startTime) / 1000; //en segundos
        let bitsLoaded: number = downloadSize * 8;
        let speedBps: string = (bitsLoaded / duration).toFixed(2);
        let speedKbps: string = (Number(speedBps) / 1024).toFixed(2);
        const speedMbps: any = {
          speed: (Number(speedKbps) / 1024).toFixed(2),
          duration,
        };

        resolve(speedMbps);
      };
      startTime = new Date().getTime();
      download.src = imageAddr;
    });
  }

  async onSync() {
    if ('serviceWorker' in navigator && 'SyncManager' in window) {
      const registration = await navigator.serviceWorker.ready;
      await registration.sync.register('mySyncTag');
    }
  }

  async initPoll(pollId, client, user_id) {
    localStorage.setItem('selectPoll', 'customer-list');
    localStorage.removeItem('idResponse');
    localStorage.removeItem('poll');
    const poll = await this.getPoll(pollId);

    if (poll.geolocation === true) {
      navigator.geolocation.getCurrentPosition((position) => {
        const coords = position.coords;
        const latLong = [coords.latitude, coords.longitude];
        localStorage.setItem('lat', coords.latitude.toString());
        localStorage.setItem('long', coords.longitude.toString());
      });
    }
    // return;
    const dataPoll = {
      id: poll.id,
      period_id: poll.period_id,
      name: poll.name,
      description: poll.description,
      sections: this.sortJSON(poll.sections, 'weight', 'asc'),
      messages: poll.messages,
      expire_date: poll.expire_date,
      publish_date: poll.publish_date,
      countquestion: poll.countquestion,
      geolocation: poll.geolocation,
      minutes: poll.minutes,
      infoextra: poll.infoextra,
      infoextrapoll: poll.infoextrapoll,
      infoextracustomer: poll.infoextracustomer,
      showmessageinit: poll.showmessageinit,
      addgoals: poll.addgoals,
      measuring_period: poll.measuring_period,
    };

    const id = `${poll.id}-${client.id}-${this.user.id}`;
    const syncPoll = await this.getSyncPoll(id);
    const status =
      syncPoll && syncPoll.status === 'unsynchronized'
        ? 'unsynchronized'
        : (syncPoll && syncPoll.status === 'unsynchronized-edit') ||
          (syncPoll && syncPoll.status === 'edit-offline')
        ? 'edit-offline'
        : 'iniciada';
    let date_init_save = new Date();
    // date_init_save.setDate(date_init_save.getDate() - 2);
    const dataSyncPoll = {
      id,
      poll_id: poll.id,
      period_id: poll.period_id,
      client,
      status,
      coords_init: {
        lat: localStorage.getItem('lat'),
        long: localStorage.getItem('long'),
      },
      descr_segmento: client.descr_segmento,
      descr_relevancia: client.descr_relevancia,
      descr_nivel: client.descr_nivel,
      descr_vendedor: client.descr_vendedor,
      descr_jefe_ventas: client.descr_jefe_ventas,
      descr_subgerente: client.descr_subgerente,
      descr_gerente: client.descr_gerente,
      descr_ofcom_venta: client.descr_ofcom_venta,
      descr_distrito_venta: client.descr_distrito_venta,
      descr_zona_venta: client.descr_distrito_venta,
      coords_finish: null,
      date_init: moment().format('MM/DD/YYYY'),
      date_init_save,
      user_id: this.user.id,
    };

    await db.setSyncPoll(dataSyncPoll);

    this.setOfflineHeader({
      id: 1,
      user_id: this.user.id,
      from: 'clients',
    });
    localStorage.setItem('poll', JSON.stringify(dataPoll));
    delete client.poll;
    localStorage.setItem('client', JSON.stringify(client));
    localStorage.setItem('source', 'list_client');
    if (
      poll.infoextra == true &&
      poll.infoextrapoll !== null &&
      poll.infoextrapoll !== undefined &&
      poll.infoextracustomer !== null &&
      poll.infoextracustomer !== undefined
    ) {
      this.router.navigate(['poll/client/info']);
    } else {
      this.router.navigate(['poll/client/init']);
    }
  }

  async loadOfflineHeader() {
    return new Promise<any>(async (resolve, reject) => {
      let statusArray = [];
      let statusSync = 'good';
      this.getSyncPolls().then(async (syncPolls) => {
        if (syncPolls.length > 0) {
          syncPolls.forEach((syncPoll) => {
            statusArray.push(syncPoll.status);
          });
        }
        const conditionsUnsync = ['unsynchronized', 'unsynchronized-edit'];
        if (conditionsUnsync.some((el) => statusArray.includes(el))) {
          statusSync = 'unsynchronized';
        }
        const conditionsProblems = [
          'sync-responses-completed',
          'sync-poll-completed',
          'sync-clients-completed',
          'sync-close-response',
          'sync-completed',
        ];
        if (conditionsProblems.some((el) => statusArray.includes(el))) {
          statusSync = 'problems';
        }
        const offlineHeader = await this.getOfflineHeader(1, this.user.id);

        if (offlineHeader && offlineHeader.status) {
          await this.setOfflineHeader({
            id: 1,
            user_id: this.user.id,
            statusSync,
          });
        }
        resolve(true);
      });
    });
  }

  async getSectionsPoll(polls) {
    return new Promise<void>(async (resolve) => {
      for (const poll of polls) {
        let pollData = poll;
        for (const item of pollData.sections) {
          this.synchronizationService.nextIsRunning({
            status: true,
            step: `Descargando seccion ${item.id}`,
          });

          const section = await this.sectionsService
            .getSection(item.id)
            .toPromise();
          item.image = section.image;
          item.questions = section.questions;
          for await (const question of item.questions) {
            if (question.helpfile.length > 0) {
              try {
                const img = await this.imagesService
                  .getImageHelpQuestionBase64(question.helpfile, 'config')
                  .toPromise();
                question.helpfile64 = img.url;
              } catch (error) {
                Sentry.configureScope((scope) => {
                  scope.setUser({
                    email: this.user.email,
                  });
                  scope.setTags({
                    email: this.user.email,
                  });

                  scope.setTags({
                    poll: poll.name + '(ID:' + poll.id + ')',
                  });
                  scope.setTags({
                    seccion: item.name + '(ID:' + item.id + ')',
                  });
                  scope.setTags({
                    question: question.title + '(ID:' + question.id + ')',
                  });
                  scope.setTransactionName(
                    `offline.service.getImageHelpQuestionBase64`
                  );
                  Sentry.setContext('Encuesta', {
                    name: poll.name,
                    id: poll.id,
                  });
                  Sentry.setContext('Seccion', {
                    name: item.name,
                    id: item.id,
                  });
                  Sentry.setContext('Question', {
                    name: question.title,
                    id: question.id,
                  });
                });
                Sentry.captureException(
                  new Error(`Descargando la imagen de Ayuda`)
                );
              }
            }
          }
        }
      }
      resolve(polls);
    });
  }

  synchronizationIsNotUpToDate() {
    return new Promise<any>(async (resolve) => {
      const today = formatDate(new Date(), 'yyyy/MM/dd', 'en');
      const offlineHeader = await this.getOfflineHeader(1, this.user.id);

      const res = today > offlineHeader.date;
      resolve(res);
    });
  }

  getClient(clientId: string) {
    return db.clients
      .where('cliente_id')
      .equals(clientId)
      .first()
      .then((client) => {
        if (client) {
          return client;
        }
      })
      .catch(() => {});
  }

  getClients(filter = null) {
    return new Promise<any>(async (resolve) => {
      this.getOfflineHeader(1, this.user.id).then(async (offlineHeader) => {
        let clients = [];
        const {
          stringSearch = '',
          pageSize,
          pageNum,
          isTpmFilter,
          count = false,
        } = filter;

        if (
          offlineHeader.dataFilters.isFilter &&
          (offlineHeader.dataFilters.keepFilter || isTpmFilter)
        ) {
          const { enterpriseId, filters } = offlineHeader.dataFilters;
          if (count) {
            const countClients = await this.filterClients(
              filters,
              enterpriseId,
              0,
              null,
              true,
              stringSearch
            );
            resolve(countClients);
            return;
          }
          clients = await this.filterClients(
            filters,
            enterpriseId,
            pageSize,
            pageNum,
            false,
            stringSearch
          );
          resolve(clients);
        } else {
          const query = db.clients;
          if (filter && stringSearch) {
            if (count) {
              const countClients = await query
                .offset(0)
                .sortBy('id')
                .then((data) => {
                  return data.filter((client) =>
                    client.searchstr
                      .toLowerCase()
                      .includes(stringSearch.toLowerCase())
                  );
                });

              resolve(countClients.length);
              return;
            }

            clients = await query
              .offset(0)
              .sortBy('id')
              .then((data) => {
                return data.filter((client) =>
                  client.searchstr
                    .toLowerCase()
                    .includes(stringSearch.toLowerCase())
                );
              });

            const filteredClients = this.getClientsByPage(
              clients,
              pageSize,
              pageNum
            );

            resolve(filteredClients);
            return;
          } else {
            if (count) {
              const countClients = await query.count();
              resolve(countClients);
              return;
            }
            clients = await query
              .offset(pageSize * (pageNum - 1))
              .limit(pageSize)
              .toArray();
          }
          resolve(clients);
        }
      });
    });
  }

  getClientsByPage(
    filteredClients: any[],
    pageSize: number,
    pageNum: number
  ): any[] {
    const startIndex = (pageNum - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredClients.slice(startIndex, endIndex);
  }

  getCLientsByPoll({
    pollId,
    pageNum = null,
    pageSize = null,
    type,
    count = false,
    stringSearch = null,
    isTpmFilter = false,
    enterprise_id = null,
    all = false,
    filtersView = [],
  }) {
    return new Promise<any>(async (resolve) => {
      let query = db.clients.where('flag').equals('y');
      const offlineHeader = await this.getOfflineHeader(1, this.user.id);
      const conditions: object = {};

      let clients: any[] = [];

      if (filtersView.length === 0) {
        if (stringSearch) {
          const data = await query.offset(0).sortBy('id');

          clients = data.filter((client) =>
            client.searchstr.toLowerCase().includes(stringSearch.toLowerCase())
          );
        }
      } else {
        filtersView.forEach((filter) => {
          conditions[this.builCategoryName(filter.source)] = filter.code;
        });
        if (stringSearch) {
          const regexStringSearch = new RegExp(stringSearch, 'i');
          query = db.clients
            .where(conditions)
            .and((client) =>
              client.searchstr.match(regexStringSearch) ? true : false
            );
        } else {
          query = db.clients.where(conditions);
        }
      }

      if (
        offlineHeader &&
        offlineHeader.dataFiltersPoll[pollId] &&
        offlineHeader.dataFiltersPoll[pollId].isFilter &&
        (offlineHeader.dataFiltersPoll[pollId].keepFilter || isTpmFilter)
      ) {
        const { filters } = offlineHeader.dataFiltersPoll[pollId];
        const conditions: object = {};
        filters.forEach((filter) => {
          conditions[this.builCategoryName(filter.source)] = filter.code;
        });

        query = db.clients.where(conditions);

        if (stringSearch) {
          const regexStringSearch = new RegExp(stringSearch, 'i');
          query = query.and((client) =>
            client.searchstr.match(regexStringSearch) ? true : false
          );
        }
      }

      if (clients.length === 0) {
        clients = await query
          .offset(0)
          .sortBy('id')
          .then((data) => {
            return data.filter((c) => {
              const matchesSearch = c.searchstr
                .toLowerCase()
                .includes(stringSearch?.toLowerCase());
              const matchesPoll =
                type === 'pending'
                  ? c.polls.findIndex(
                      (poll) => poll.id == pollId && poll.edit == 'false'
                    ) != -1
                  : c.polls.findIndex(
                      (poll) =>
                        poll.id == pollId &&
                        (poll.edit === 'true' || poll.edit == 'trueOffline')
                    ) != -1;
              return (
                matchesSearch && matchesPoll && c.empresa_id == enterprise_id
              );
            });
          });
      }

      if (count) {
        const countClients = clients.length;
        resolve(countClients);
        return;
      }

      if (!all) {
        const filteredClients = this.getClientsByPage(
          clients,
          pageSize,
          pageNum
        );
        resolve(filteredClients);
        return;
      } else {
        clients = all
          ? await query.toArray()
          : await query
              .offset(pageSize * (pageNum - 1))
              .limit(pageSize)
              .toArray();
        resolve(clients);
      }
    });
  }

  filterClients(
    filters,
    empresa_id,
    pageSize,
    pageNum,
    count = false,
    stringSearch
  ) {
    return new Promise<any>(async (resolve) => {
      const conditions: object = {};
      filters.forEach((filter) => {
        conditions[this.builCategoryName(filter.source)] = filter.code;
      });
      let clients: any = [];
      let query: any;
      if (stringSearch === '') {
        query = db.clients
          .where(conditions)
          .and((client) => client.empresa_id === empresa_id);
      } else {
        const regexStringSearch = new RegExp(stringSearch, 'i');
        query = db.clients
          .where(conditions)
          /* .and('messageWords')
              .startsWithIgnoreCase(stringSearch)
              .distinct() */
          .and(
            (client) =>
              client.empresa_id === empresa_id &&
              client.searchstr.match(regexStringSearch)
                ? true
                : false
            // client.messageWords.includes(stringSearch.toUpperCase())
          );
      }
      if (pageSize > 0) {
        clients = await query
          .offset(pageSize * (pageNum - 1))
          .limit(pageSize)
          .toArray();
      } else if (count) {
        clients = await query.count();
      } else if (pageSize === 0) {
        clients = await query.sortBy('id');
      }

      resolve(clients);
    });
  }

  builCategoryName(source) {
    const categories: object = {
      seg: 'segmento',
      rel: 'relevancia',
      niv: 'nivel',
      ven: 'vendedor_id',
      jef: 'jefe_ventas_id',
      grt: 'gerente_id',
      sgr: 'subgerente_id',
      ofc: 'orden_ofcom_venta',
      dtt: 'orden_distrito_venta',
      zna: 'orden_zona_venta',
    };
    return categories[source] || null;
  }

  builCategoryNameClient(source) {
    const categories: object = {
      seg: ['segmento', 'descr_segmento'],
      rel: ['relevancia', 'descr_relevancia'],
      niv: ['nivel', 'descr_nivel'],
      ven: ['vendedor_id', 'descr_vendedor'],
      jef: ['jefe_ventas_id', 'descr_jefe_ventas'],
      grt: ['gerente_id', 'descr_gerente'],
      sgr: ['subgerente_id', 'descr_subgerente'],
      ofc: ['orden_ofcom_venta', 'descr_ofcom_venta'],
      dtt: ['orden_distrito_venta', 'descr_distrito_venta'],
      zna: ['orden_zona_venta', 'descr_zona_venta'],
    };
    return categories[source] || null;
  }

  builQueryArray(item) {
    let filter: any = {};
    switch (item.source) {
      case 'seg':
        filter = {
          segmento: item.code,
        };
        break;
      case 'rel':
        filter = {
          relevancia: item.code,
        };
        break;
      case 'niv':
        filter = {
          nivel: item.code,
        };
        break;
      case 'ven':
        filter = {
          vendedor_id: item.code,
        };
        break;
      case 'jef':
        // query.jefe_ventas_id = item.code;
        break;
      case 'sgr':
        // query.subgerente_id = item.code;
        break;
      case 'grt':
        // query.gerente_id = item.code;
        break;
      case 'ofc':
        // query.oficina_comercial_id = item.code;
        break;
      case 'dtt':
        // query.orden_distrito_venta = item.code;
        break;
      case 'zna':
        // query.orden_zona_venta = item.code;
        break;
    }
    return filter;
  }

  getSyncPolls() {
    /*  return new Promise<any>(async (resolve) => {
      const syncpolls = await db.syncpolls
        .where({ status: 'unsynchronized' })
        .toArray();
      resolve(syncpolls);
    }); */
    /* return new Promise<any>(async (resolve) => {
      const syncpolls = await db.syncpolls.toArray();
      resolve(syncpolls);
    }); */
    return new Promise<any>(async (resolve) => {
      const syncpolls = await db.syncpolls
        .where('status')
        .notEqual('iniciada')
        .and((syncPoll) => syncPoll.user_id === this.user.id)
        .and((syncPoll) => syncPoll.status !== 'edit-offline')
        .and((syncPoll) => syncPoll.client !== undefined)
        .toArray();
      resolve(syncpolls);
    });
  }

  filterCategoriesByEnterprise(empresa_id, clients) {
    return new Promise<any>(async (resolve) => {
      const categories: Array<any> = this.buildCategories(empresa_id);
      let clientCode = '';
      for (const client of clients) {
        for (const itemCat of categories) {
          clientCode = client[this.builCategoryNameClient(itemCat.source)[0]]
            .toString()
            .trim();
          let result = itemCat.values.findIndex(
            (icv) => icv.code === clientCode
          );
          if (result === -1) {
            let count = clients.filter(
              (c) =>
                c[this.builCategoryNameClient(itemCat.source)[0]]
                  .toString()
                  .trim() === clientCode
            ).length;
            itemCat.values.push({
              code: clientCode,
              name: client[this.builCategoryNameClient(itemCat.source)[1]],
              source: itemCat.source,
              count: count,
            });
          }
        }
      }
      resolve(categories);
    });
  }

  getCategoriesByFilters(filters, empresa_id) {
    return new Promise<any>(async (resolve) => {
      const clients = await this.filterClients(
        filters,
        empresa_id,
        0,
        0,
        false,
        ''
      );
      const categories = await this.filterCategoriesByEnterprise(
        empresa_id,
        clients
      );
      resolve(categories);
    });
  }

  getCategoriesByFiltersPoll({
    filters = [],
    isTpmFilter = false,
    enterprise_id,
    pollId,
    type,
  }) {
    return new Promise<any>(async (resolve) => {
      const clients = await this.getCLientsByPoll({
        pollId,
        type: 'pending',
        all: true,
        isTpmFilter,
        enterprise_id,
        filtersView: filters,
      });
      const categories = await this.filterCategoriesByEnterprise(
        enterprise_id,
        clients
      );
      resolve(categories);
    });
  }

  setCategories(id, categories) {
    return new Promise<void>(async (resolve) => {
      // await db.categories.clear();
      await db.categories.add({ id, categories });
      resolve();
    });
  }

  getCategories(id) {
    return new Promise<any>(async (resolve) => {
      const result = await db.categories.get(id);
      resolve(result);
    });
  }

  downloadCategories(sellers) {
    return new Promise<any>(async (resolve) => {
      db.categories.clear();
      const dataEstructure = JSON.parse(localStorage.getItem('sales'));
      if (dataEstructure) {
        let enterprises: EnterpriseModel[] = JSON.parse(
          localStorage.getItem('enterprises')
        );
        let index = 0;
        const getCustomerCategoriesFilter = async () => {
          let sellersEnterprise = sellers.filter(
            (s) => s.empresa_id === enterprises[index].id
          );
          await this.clientsService
            .getCustomerCategoriesFilter(
              sellersEnterprise,
              enterprises[index].id
            )
            .subscribe(async (categories) => {
              await this.setCategories(
                `${this.user.id}-${enterprises[index].id}`,
                categories
              );
              if (index < enterprises.length - 1) {
                index++;
                setTimeout(() => {
                  getCustomerCategoriesFilter();
                }, 100);
              } else {
                resolve(true);
              }
            });
        };
        getCustomerCategoriesFilter();
      } else {
        resolve(true);
      }
    });
  }

  clearPollStats() {
    return new Promise<any>(async (resolve) => {
      db.statsPolls.clear();
      resolve(true);
    });
  }

  setPollStats(data) {
    return new Promise<void>(async (resolve) => {
      if (data.id !== undefined) {
        await db.statsPolls
          .where('[poll_id+user_id]')
          .equals([data.poll_id, data.user_id])
          .delete()
          .then(() => {
            db.statsPolls.add(data);
          });
      } else {
        const key_id = `${data.poll_id}-${data.user_id}`;

        const syncPollData = await db.statsPolls
          .where('[poll_id+user_id]')
          .equals([data.poll_id, data.user_id])
          .toArray();
        console.info(syncPollData);
        if (syncPollData.length == 0) {
          db.statsPolls.add(data);
          resolve(data);
        } else {
          await db.statsPolls
            .where('[poll_id+user_id]')
            .equals([data.poll_id, data.user_id])
            .delete()
            .then(() => {
              db.statsPolls.add(data);
            });
        }
      }

      resolve();
    });
  }

  buildCategories(enterpriseId) {
    const categories: Array<any> = [
      {
        category: 'Segmento',
        count: 0,
        label: enterpriseId === 99 ? 'Ramo' : 'Segmento',
        source: 'seg',
        values: [],
      },
      {
        category: 'Relevancia',
        count: 0,
        label: 'Relevancia',
        source: 'rel',
        values: [],
      },
      {
        category: 'Vendedor',
        count: 0,
        label: 'Vendedor',
        source: 'ven',
        values: [],
      },
      {
        category: 'Nivel',
        count: 0,
        label: 'Nivel',
        source: 'niv',
        values: [],
      },
      {
        category: 'Jefe Ventas',
        count: 0,
        label: enterpriseId === 99 ? 'Supervisor' : 'Jefe Ventas',
        source: 'jef',
        values: [],
      },
      {
        category: 'Subgerente',
        count: 0,
        label: enterpriseId === 99 ? 'Oficina comercial' : 'Subgerente',
        source: 'sgr',
        values: [],
      },
      {
        category: 'Gerente',
        count: 0,
        label: enterpriseId === 99 ? 'Operación' : 'Gerente',
        source: 'grt',
        values: [],
      },
      {
        category: 'Oficina comercial',
        count: 0,
        label: enterpriseId === 99 ? 'Jefe de ventas' : 'Oficina comercial',
        source: 'ofc',
        values: [],
      },
      {
        category: 'Distrito',
        count: 0,
        label: enterpriseId === 99 ? 'Región' : 'Distrito',
        source: 'dtt',
        values: [],
      },
      {
        category: 'Zona',
        count: 0,
        label: enterpriseId === 99 ? 'Gerencia' : 'Zona',
        source: 'zna',
        values: [],
      },
    ];
    return categories;
  }

  getPolls() {
    return new Promise<any>(async (resolve) => {
      const polls = await db.polls.toArray();
      resolve(polls);
    });
  }

  getPollofClientsWithExclude(client, pollId, userId) {
    return new Promise<any>(async (resolve) => {
      const clientsPolls = client.polls;
      const polls: Array<any> = [];
      let id;
      let result;

      for (const clientPoll of clientsPolls) {
        id = `${clientPoll.id}-${client.id}-${userId}`;
        result = await this.getSyncPoll(id);
        if (
          clientPoll.id !== pollId &&
          !result &&
          clientPoll.edit === 'false'
        ) {
          polls.push(clientPoll);
        }
      }
      resolve(polls);
    });
  }

  getSyncPoll(id) {
    return new Promise<any>(async (resolve) => {
      // const syncpolls = await db.syncpolls.where({ id }).toArray();
      const syncpolls = await db.syncpolls.get(id);
      resolve(syncpolls);
    });
  }

  getSyncPollsCount() {
    return new Promise<any>(async (resolve) => {
      const syncpolls = await db.syncpolls.count();
      resolve(syncpolls);
    });
  }

  getSyncPollStatsInit(poll_id, user_id) {
    return new Promise<any>(async (resolve) => {
      // const syncpolls = await db.syncpolls.where({ id }).toArray();

      const syncPolls = await db.syncpolls
        .where('[status+poll_id+user_id]')
        .equals(['iniciada', poll_id, user_id])
        .toArray();
      resolve(syncPolls);
    });
  }

  loadSyncPolls() {
    return new Promise<any>(async (resolve) => {
      const syncPolls = await db.syncpolls
        .where({ status: 'sync-completed' })
        .toArray();
      resolve(syncPolls);
      /* this.syncPollsCompleted = [];
    this.syncPolls = await db.syncpolls
      .where({ status: 'sync-completed' })
      .toArray();
    this.syncPolls.forEach((sp) => {
      this.syncPollsCompleted.push(sp.poll_id);
    }); */
    });
  }

  loadSyncIncompletePolls() {
    return new Promise<any>(async (resolve) => {
      const polls = await db.polls
        .where('status')
        .noneOf(['sync-completed', 'unsynchronized'])
        .toArray();
      resolve(polls);
      /* this.syncPollsIncomplete = [];
    const polls = await db.polls
      .where('status')
      .noneOf(['sync-completed', 'unsynchronized'])
      .toArray();
    polls.forEach((p) => {
      this.syncPollsIncomplete.push(p.id);
    }); */
    });
  }

  setClients(clients) {
    return new Promise<void>(async (resolve) => {
      await db.clients.clear();
      await db.clients.bulkAdd(clients);
      resolve();
    });
  }

  setCustomersSku(customersSku) {
    return new Promise<void>(async (resolve) => {
      await db.customersSku.clear();
      await db.customersSku.bulkAdd(customersSku);
      resolve();
    });
  }

  getPoll(id) {
    return new Promise<any>(async (resolve) => {
      const idPoll: number = parseInt(id);
      const poll = await db.polls.get({ id: idPoll });

      resolve(poll);
    });
  }

  setPolls(polls) {
    return new Promise<void>(async (resolve) => {
      await db.polls.clear();
      await db.polls.bulkAdd(polls);
      resolve();
    });
  }

  setPoll(poll) {
    return new Promise<void>(async (resolve) => {
      const syncPollData = await db.polls.get(poll.id);
      if (!syncPollData) {
        db.polls.add(poll);
        resolve(poll);
      } else {
        const result: any = await db.polls.update(poll.id, poll);
        resolve(result);
      }
      resolve();
    });
  }

  setOfflineHeader(data) {
    return new Promise<any>(async (resolve) => {
      const {
        id,
        user_id,
        date,
        number_clients,
        number_polls,
        status,
        statusSync,
        mode,
        from,
        sellers,
        isFilter,
        dataFilters,
        dataFiltersPoll,
        lastVerificationDateTime,
      } = data;
      this.getOfflineHeader(id, user_id).then(async (ohData) => {
        if (!ohData) {
          data.id = `${id}-${user_id}`;
          await db.offlineHeader.add(data);
          resolve(true);
        } else {
          let params: any = {};

          if (date) {
            params.date = date;
          }

          if (number_clients) {
            params.number_clients = number_clients;
          }

          if (number_polls) {
            params.number_polls = number_polls;
          }

          if (status) {
            params.status = status;
          }

          if (statusSync) {
            params.statusSync = statusSync;
          }

          if (mode) {
            params.mode = mode;
          }

          if (from) {
            params.from = from;
          }

          if (sellers) {
            params.sellers = sellers;
          }

          if (dataFilters) {
            params.dataFilters = dataFilters;
          }

          if (dataFiltersPoll) {
            params.dataFiltersPoll = dataFiltersPoll;
          }

          if (lastVerificationDateTime) {
            params.lastVerificationDateTime = lastVerificationDateTime;
          }

          params.token = localStorage.getItem('token');

          await db.offlineHeader.update(`${id}-${user_id}`, params);
          resolve(true);
        }
      });
    });
  }

  getOfflineHeader(id, user_id) {
    return new Promise<any>(async (resolve) => {
      const result = await db.offlineHeader.get(`${id}-${user_id}`);
      resolve(result);
    });
  }

  setBackSyncHeader({ id, user, token, status, api_url }) {
    return new Promise<any>(async (resolve) => {
      await db.backSyncHeader.clear();
      await db.backSyncHeader.add({
        id,
        user,
        token,
        status,
        api_url,
      });
      resolve(true);
    });
  }

  getBackSyncHeader(id) {
    return new Promise<any>(async (resolve) => {
      const result = await db.backSyncHeader.get(id);
      resolve(result);
    });
  }

  syncPolls() {
    return new Promise<any>(async (resolve) => {});
  }

  async getResponses() {
    return await db.responses.toArray();
  }

  async getQuestionResponseDB(id) {
    return new Promise<any>(async (resolve) => {
      const result = await db.responses.get(id);
      resolve(result);
    });
  }

  delResponses(poll, client, user) {
    return new Promise<any>(async (resolve) => {
      const sync_poll_id = `${poll.poll_id}-${client.id}-${user.id}`;
      await db.responses.where({ sync_poll_id }).delete();
      await db.files.where({ sync_poll_id }).delete();
      resolve(true);
    });
  }

  delSyncPolls() {
    return new Promise<any>(async (resolve) => {
      await db.syncpolls.clear();
      resolve(true);
    });
  }

  delSResponsesPolls() {
    return new Promise<any>(async (resolve) => {
      await db.responses.clear();
      resolve(true);
    });
  }

  deactiveOffline() {
    return new Promise<any>(async (resolve) => {
      db.clients.clear();
      db.files.clear();
      db.polls.clear();
      db.responses.clear();
      db.syncpolls.clear();
      db.offlineHeader.clear();
      db.categories.clear();
      resolve(true);
    });
  }

  async setResponse({
    question = null,
    poll = null,
    client = null,
    user = null,
    selected = null,
    mode = 'offline',
    params = null,
  }) {
    return new Promise<any>(async (resolve) => {
      let response: object = {};
      switch (question.type_id) {
        case 1: // Seleccion Simple
          let option_id;
          let value;
          if (mode === 'offline') {
            option_id = params.option_id;
            value = params.value;
          }
          if (mode === 'offlineEdit') {
            option_id = question.responseoption[0].option_id;
            value = question.responseoption[0].value;
          }
          response = {
            id: `${question.id}-${poll.id}-${client.id}-${user.id}`,
            sync_poll_id: `${poll.id}-${client.id}-${user.id}`,
            poll_id: poll.id,
            client_id: client.id,
            type_save: 'saveSimpleQuestion',
            service: 'sendResponseQuestion',
            data: {
              response_id: null,
              question_id: question.id,
              option_id,
              value,
            },
            type_question: 'selected',
            question_title: question.title,
            type_desc: question.type_desc,
          };
          await db.setResponse(response);
          break;
        case 2: // Seleccion Multiple Lista
          let options;
          if (mode === 'offline') {
            options = params.options;
          }
          if (mode === 'offlineEdit') {
            options = [];
            for (const option of question.responseoption) {
              options.push({
                option_id: option.option_id,
                value: option.value,
              });
            }
          }
          response = {
            id: `${question.id}-${poll.id}-${client.id}-${user.id}`,
            sync_poll_id: `${poll.id}-${client.id}-${user.id}`,
            poll_id: poll.id,
            client_id: client.id,
            type_save: 'saveMultipleQuestion',
            service: 'sendResponseQuestion',
            data: {
              response_id: null,
              question_id: question.id,
              options,
            },
            type_question: 'multiple',
            question_title: question.title,
            type_desc: question.type_desc,
          };
          await db.setResponse(response);
          break;
        case 3: // Text
          if (mode === 'offlineEdit') {
            selected = question.responseoption[0].value;
          }
          response = {
            id: `${question.id}-${poll.id}-${client.id}-${user.id}`,
            sync_poll_id: `${poll.id}-${client.id}-${user.id}`,
            poll_id: poll.id,
            client_id: client.id,
            type_save: 'saveTextQuestion',
            service: 'sendResponseQuestion',
            data: {
              response_id: null,
              question_id: question.id,
              text: selected,
            },
            type_question: 'text',
            question_title: question.title,
            type_desc: question.type_desc,
          };
          await db.setResponse(response);
          break;
        case 5: // Scale
          if (mode === 'offlineEdit') {
            selected = question.responseoption[0].value;
          }
          response = {
            id: `${question.id}-${poll.id}-${client.id}-${user.id}`,
            sync_poll_id: `${poll.id}-${client.id}-${user.id}`,
            poll_id: poll.id,
            client_id: client.id,
            type_save: 'saveScaleQuestion',
            service: 'sendResponseQuestion',
            data: {
              response_id: null,
              question_id: question.id,
              option_id: null,
              value: selected,
            },
            type_question: 'scale',
            question_title: question.title,
            type_desc: question.type_desc,
          };
          await db.setResponse(response);
          break;
        case 4: // File
          if (mode === 'offlineEdit') {
            let dataFile = {};
            selected = [];
            for (const response of question.responseoption) {
              selected.push({ value: response.value.url });
              dataFile = {
                id: `${question.id}-${poll.id}-${client.id}-${user.id}-${response.value.token}`,
                sync_poll_id: `${poll.id}-${client.id}-${user.id}`,
                poll_id: poll.id,
                data: {
                  imagenBase64: response.value.imagenBase64,
                  imagen: null,
                  token: response.value.token,
                  fileName: response.value.fileName,
                },
              };
              await db.setFile(dataFile);
            }
          }
          response = {
            id: `${question.id}-${poll.id}-${client.id}-${user.id}`,
            sync_poll_id: `${poll.id}-${client.id}-${user.id}`,
            poll_id: poll.id,
            client_id: client.id,
            type_save: 'saveFileQuestion',
            service: 'sendResponseQuestion',
            data: {
              response_id: null,
              question_id: question.id,
              option_id: null,
              value: selected,
            },
            type_question: 'file',
            question_title: question.title,
            type_desc: question.type_desc,
          };
          break;
        case 8: // Ranking
          if (mode === 'offlineEdit') {
            selected = [];
            for (const response of question.responseoption) {
              selected.push({
                option_id: response.option_id,
                value: parseInt(response.value),
              });
            }
          }
          response = {
            id: `${question.id}-${poll.id}-${client.id}-${user.id}`,
            sync_poll_id: `${poll.id}-${client.id}-${user.id}`,
            poll_id: poll.id,
            client_id: client.id,
            type_save: 'saveRankingQuestion',
            service: 'sendResponseQuestion',
            data: {
              response_id: null,
              question_id: question.id,
              option_id: null,
              value: selected,
            },
            type_question: 'ranking',
            question_title: question.title,
            type_desc: question.type_desc,
          };
          await db.setResponse(response);
          break;
        case 9: // Date
          if (mode === 'offlineEdit') {
            selected = moment(question.responseoption[0].value).format();
          }
          response = {
            id: `${question.id}-${poll.id}-${client.id}-${user.id}`,
            sync_poll_id: `${poll.id}-${client.id}-${user.id}`,
            poll_id: poll.id,
            client_id: client.id,
            type_save: 'saveDateQuestion',
            service: 'sendResponseQuestion',
            data: {
              response_id: null,
              question_id: question.id,
              value: selected,
            },
            type_question: 'date',
            question_title: question.title,
            type_desc: question.type_desc,
          };
          await db.setResponse(response);
          break;
        case 10: // Number
          if (mode === 'offlineEdit') {
            selected = question.responseoption[0].value;
          }
          response = {
            id: `${question.id}-${poll.id}-${client.id}-${user.id}`,
            sync_poll_id: `${poll.id}-${client.id}-${user.id}`,
            poll_id: poll.id,
            client_id: client.id,
            title: question.title,
            section_id: question?.section_id,
            type_save: 'saveNumberQuestion',
            service: 'sendResponseQuestion',
            data: {
              response_id: null,
              question_id: question.id,
              option_id: null,
              value: selected,
              title: question.title,
            },
            type_question: 'number',
            question_title: question.title,
            type_desc: question.type_desc,
          };
          await db.setResponse(response);
          break;
        case 12: // Price
          response = {
            id: `${question.id}-${poll.id}-${client.id}-${user.id}`,
            sync_poll_id: `${poll.id}-${client.id}-${user.id}`,
            poll_id: poll.id,
            client_id: client.id,
            type_save: 'savePriceDropdownQuestion',
            service: 'sendResponseQuestion',
            data: {
              response_id: null,
              question_id: question.id,
              questionoptions: question.questionoptions,
            },
            type_question: 'priceDropdown',
            question_title: question.title,
            type_desc: question.type_desc,
          };
          break;
        case 13: //! Cooler
          // resolve(true);
          break;
      }
      if (question.type_id !== 13) {
        await db.setResponse(response);
      }
      resolve(true);
    });
  }

  startMonitoringStatusSync() {
    this.checkStatusSyncAndUpdate();
  }

  async checkStatusSyncAndUpdate() {
    try {
      const item = await db.offlineHeader
        .where('statusSync')
        .equals('good')
        .first();
      if (!item || item?.statusSync === 'good') {
        this.countSyncPolls();
      }
    } catch (error) {
      console.log('Error al checkear el status sync y update', error);
    }
  }

  private async countSyncPolls() {
    const syncPolls = await this.getSyncPolls();
    const count = syncPolls.filter(
      (sp) =>
        sp.status === 'unsynchronized-edit' ||
        sp.status === 'unsynchronized' ||
        sp.status === 'sync-responses-completed' ||
        sp.status === 'sync-close-response' ||
        sp.status === 'sync-completed'
    ).length;

    this.synchronizationService?.nextUpdateCount(count);
  }
}
