<div
  *ngIf="fullScreenImage"
  class="full-screen-img"
  (click)="toggleFullScreen()"
>
  <img [src]="fullScreenImage" alt="Imagen en pantalla completa" />
</div>
<div class="photo-container">
  <div class="client-box">
    <div class="client-name-box">
      <p class="name-title">Tarea</p>
    </div>

    <div class="client-info-box">
      <div class="client-icon">
        <mat-icon>task_alt</mat-icon>
      </div>

      <div class="client-info">
        <p class="client-txt">Tarea: {{ responseTask.task.title }}</p>
        <p class="client-txt">
          Vencimiento: {{ responseTask.limit_date | date : 'dd MMM yyyy' }}
        </p>

        <div class="limit-date-badge">
          <div
            class="badge"
            [ngClass]="{
              'badge-late': responseTask?.expired,
              'badge-to-expire': responseTask?.toExpired,
              'badge-in-time': responseTask?.inTime
            }"
          >
            {{ responseTask?.expired ? 'Atrasado' : responseTask?.toExpired ?
            'Por vencer' : responseTask?.inTime ? 'En fecha' : '' }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="carousels">
    <div class="photo-title-box">
      <p class="photo-title" *ngIf="images_1.length > 0">
        {{ images_1.length }} Foto{{ images_1.length > 1 ? 's' : '' }} de
        respaldo
      </p>
    </div>
    <div class="first-carouel">
      <carousel #carousel_1 class="carousel" [interval]="-1">
        <slide *ngFor="let image of images_1">
          <img
            [src]="image"
            alt="Image slide"
            (click)="toggleFullScreen(image)"
          />
        </slide>
      </carousel>

      <div class="custom-controls-ref" *ngIf="images_1.length > 1">
        <button class="custom-prev" (click)="carousel_1.previousSlide()">
          <mat-icon class="action-icon">navigate_before</mat-icon>
        </button>
        <button class="custom-next" (click)="carousel_1.nextSlide()">
          <mat-icon class="action-icon">navigate_next</mat-icon>
        </button>
      </div>
    </div>
    <div
      *ngIf="photos_2 ? true : false"
      [ngClass]="{'second-carousel': images_1.length > 1, 'margin-adjusted': images_1.length < 2}"
    >
      <div class="photo-title-box">
        <p class="photo-title" *ngIf="images_2.length > 0">
          {{ images_2.length }} Foto{{ images_2.length > 1 ? 's' : '' }} de
          referencia
        </p>
      </div>
      <carousel #carousel_2 class="carousel" [interval]="-1">
        <slide *ngFor="let image of images_2">
          <img
            [src]="image"
            alt="Image slide"
            (click)="toggleFullScreen(image)"
          />
        </slide>
      </carousel>
      <div class="custom-controls-resp" *ngIf="images_2.length > 1">
        <button class="custom-prev" (click)="carousel_2.previousSlide()">
          <mat-icon class="action-icon">navigate_before</mat-icon>
        </button>
        <button class="custom-next" (click)="carousel_2.nextSlide()">
          <mat-icon class="action-icon">navigate_next</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
