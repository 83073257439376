<div class="text-container">
  <div class="text-title-box">
    <p class="text-title">{{ question.title }}</p>
  </div>
  <div fxLayoutAlign="center center" fxLayout="column" *ngFor="let option of options; let i = index">
    <mat-card
      mat-ripple
      class="card-question"
      [class.card-selected]="selectedOptionIndex === i"
      (click)="handleAnswer(i)"
    >
      <div fxLayout="column" fxLayoutAlign="center center">
        <div class="title">{{ option.title }}</div>
      </div>
    </mat-card>
  </div>
</div>
