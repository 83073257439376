import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  public watchId: number;
  public locationTrackingActive = false;
  reactiveDeviceLocation: BehaviorSubject<Object> = new BehaviorSubject<Object>(
    { latitude: 37.5, longitude: 122 }
  );

  public currentLocation: { lat: number; lng: number } = {
    lat: undefined,
    lng: undefined,
  };
  private reactiveDeviceLocation$: Subject<{ lat: number; lng: number }>;

  constructor() {
    this.reactiveDeviceLocation$ = new BehaviorSubject<{
      lat: number;
      lng: number;
    }>(this.currentLocation);
  }

  getLocation(): Observable<{ lat: number; lng: number }> {
    const opts = {
      enableHighAccuracy: true,
      maximumAge: 60000,
      timeout: 30000,
    };
    this.watchId = navigator.geolocation.watchPosition(
      (position) => {
        this.locationTrackingActive = true;
        this.currentLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        localStorage.setItem(
          'currenLocation',
          JSON.stringify({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
        );

        this.reactiveDeviceLocation.next(position.coords);
        this.reactiveDeviceLocation$.next(this.currentLocation);
      },
      (err) => {
        console.log(err);
        this.locationTrackingActive = false;
      },
      opts
    );
    return this.reactiveDeviceLocation$;
  }
  getValue(): any {
    return this.currentLocation;
  }
  getReactiveDeviceLocation() {
    return this.reactiveDeviceLocation;
  }
}
