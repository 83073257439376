import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/operators';
import { auth } from 'src/environments/environment';
import { DialogErrorComponent } from 'src/app/shared/components/dialog-error/dialog-error.component';
import { MatDialog } from '@angular/material/dialog';
import { tap, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CategoryTargetService {
  private API_URL = `${auth.urlConfig}`;

  constructor(
    private httpClient: HttpClient,
    public dialog: MatDialog
  ) {}

  getCategoryTarget(id: number, categories: any[]) {
    let httpParams = new HttpParams();
    Object.values(categories).forEach((category) => {
      httpParams = httpParams.append(category.source, category.code.toString());
    });
    const requestOptions = {
      params: httpParams,
    };
    const url = `${auth.urlConfig}/targetpoll/${id}`;

    return this.httpClient
      .get<any>(url, requestOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,

      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      return throwError('Something bad happened; please try again later.');
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
