import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
// import {
//   SocialAuthService,
//   GoogleLoginProvider,
//   SocialUser,
// } from 'angularx-social-login';

import * as jwt_decode from 'jwt-decode';
import { AuthService } from 'src/app/auth/auth.service';
import { LoginComponent } from 'src/app/login/components/login/login.component';
import { UserModel } from 'src/app/polls/models/user.model';

@Component({
  selector: 'app-header-user',
  templateUrl: './header-user.component.html',
  styleUrls: ['./header-user.component.css'],
})
export class HeaderUserComponent {
  @ViewChild('loginComponent') loginComponent: LoginComponent;
  user: UserModel;
  // userLogged: SocialUser;
  isLogged: boolean;
  // userSocial: SocialUser;
  constructor(
    // private authService: SocialAuthService,
    private authService: AuthService,

    private router: Router
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
  }

  logout(): void {
    let filterPollAllData: any = localStorage.getItem('filterPollAllData');

    localStorage.clear();
    localStorage.clear();
    sessionStorage.removeItem('sentryReplaySession');
    if (filterPollAllData !== null) {
      if (JSON.parse(filterPollAllData).length > 0) {
        localStorage.setItem('filterPollAllData', filterPollAllData);
      }
    }
    this.authService.SignOut().then((data) => this.router.navigateByUrl(''));
    // this.loginComponent.logOut();
  }
}
