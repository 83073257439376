<div fxLayoutAlign="center center" fxLayout="column" class="mat-elevation-z8">
  <div
    cdkDropList
    class="ranking-list"
    cdkDropList
    #unassignedList="cdkDropList"
    [cdkDropListData]="unassignedTasks"
    (cdkDropListDropped)="drop($event)"
  >
    <div
      class="ranking-box"
      *ngFor="let option of this.unassignedTasks; let i = index"
      [attr.data-index]="i"
      cdkDrag
    >
      <div class="ranking-custom-placeholder" *cdkDragPlaceholder></div>

      <span class="sub-title">{{ i + 1 }}. {{ option.title }}</span>
      <button mat-icon-button>
        <mat-icon color="primary">list</mat-icon>
      </button>
    </div>
  </div>
</div>
