<app-header [title]="poll.name" [sub_title]="''"> </app-header><br /><br />
<app-header-user></app-header-user>
<div fxLayout="column">
  <div class="content-resume" fxLayout="column" *ngIf="this.client">
    <span class="title" fxLayoutAlign="start center">Presentacion</span>
    <!-- <span class="description"> <span class="title">Cant. preguntas: </span>{{ this.poll.countquestion}}</span>
      <span class="description"> <span class="title">Tiempo estimado: </span>{{ this.poll.minutes }}</span>
      <span class="description"> <span class="title">Restrición por tiempo: </span>{{ labelBoolen(this.poll.minuteRetrict)
        }}</span>
      <span class="description"> <span class="title">Secciones: </span>{{ this.poll.sections.length}}</span>
      <span class="description"> <span class="title">Recoge ubicación: </span>{{ labelBoolen(this.poll.geolocation)
        }}</span> -->
    <div fxLayout="column" *ngFor="let item of infoextrapoll">
      <span
        class="description"
        *ngIf="
          item.field !== 'geolocation' &&
          item.field !== 'minute_retrict' &&
          item.field !== 'section'
        "
      >
        <span class="title">{{ item.title }}: </span
        >{{ this.poll[item.field] }}</span
      >
      <span
        class="description"
        *ngIf="item.field === 'geolocation' || item.field === 'minute_retrict'"
      >
        <span class="title">{{ item.title }}: </span
        >{{ labelBoolen(this.poll[item.field]) }}</span
      >
      <span class="description" *ngIf="item.field === 'section'">
        <span class="title">{{ item.title }}: </span
        >{{ this.poll.sections.length }}</span
      >
    </div>
    <mat-divider></mat-divider>

    <div fxLayout="column" *ngFor="let itemC of infoextracustomer">
      <ng-container
        *ngIf="
          itemC.field === 'custom1' &&
          client.label_custom1?.length > 0 &&
          client.label_custom1?.length > 0
        "
      >
        <span class="title">{{ client.label_custom1 }}</span>
        <span class="description">{{ this.client.custom1 }}</span>
      </ng-container>
      <ng-container
        *ngIf="
          itemC.field === 'custom2' &&
          client.label_custom2?.length > 0 &&
          client.label_custom2?.length > 0
        "
      >
        <span class="title">{{ client.label_custom2 }}</span>
        <span class="description">{{ this.client.custom2 }}</span>
      </ng-container>
      <ng-container
        *ngIf="
          itemC.field === 'custom3' &&
          client.label_custom3?.length > 0 &&
          client.label_custom3?.length > 0
        "
      >
        <span class="title">{{ client.label_custom3 }}</span>
        <span class="description">{{ this.client.custom3 }}</span>
      </ng-container>
      <ng-container
        *ngIf="
          itemC.field === 'custom4' &&
          client.label_custom4?.length > 0 &&
          client.label_custom4?.length > 0
        "
      >
        <span class="title">{{ client.label_custom4 }}</span>
        <span class="description">{{ this.client.custom4 }}</span>
      </ng-container>
      <ng-container
        *ngIf="
          itemC.field === 'custom5' &&
          client.label_custom5?.length > 0 &&
          client.label_custom5?.length > 0
        "
      >
        <span class="title">{{ client.label_custom5 }}</span>
        <span class="description">{{ this.client.custom5 }}</span>
      </ng-container>
      <ng-container
        *ngIf="
          itemC.field !== 'custom1' &&
          itemC.field !== 'custom2' &&
          itemC.field !== 'custom3' &&
          itemC.field !== 'custom4' &&
          itemC.field !== 'custom5'
        "
      >
        <span class="title">{{ itemC.title }}</span>
        <span
          class="description"
          *ngIf="
            itemC.field !== 'descr_ofcom_venta' &&
            itemC.field !== 'descr_zona_venta' &&
            itemC.field !== 'descr_distrito_venta' &&
            itemC.field !== 'descr_vendedor' &&
            itemC.field !== 'descr_jefe_ventas' &&
            itemC.field !== 'descr_subgerente' &&
            itemC.field !== 'descr_gerente'
          "
          >{{ this.client[itemC.field] }}</span
        >
        <span
          class="description"
          *ngIf="
            itemC.field === 'descr_ofcom_venta' ||
            itemC.field === 'descr_zona_venta' ||
            itemC.field === 'descr_distrito_venta' ||
            itemC.field === 'descr_vendedor' ||
            itemC.field === 'descr_jefe_ventas' ||
            itemC.field === 'descr_subgerente' ||
            itemC.field === 'descr_gerente'
          "
        >
          {{ this.client[itemC.value] }}
        </span>
      </ng-container>

      <mat-divider></mat-divider>
    </div>
  </div>
  <!-- <pre>{{ this.client|json}}</pre> -->
  <ng-container *ngIf="typeaccess === 'user'">
    <div fxLayout="row" class="btn-footer">
      <div fxFlex="40" fxLayoutAlign="start center">
        <button class="back-button" mat-button (click)="returnPoll()">
          Volver
        </button>
      </div>

      <div fxFlex="60" fxLayoutAlign="center center">
        <button class="next-button" mat-button (click)="initPoll()">
          Iniciar encuesta
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="typeaccess === 'customer'">
    <div fxLayout="column">
      <div fxLayoutAlign="center center" class="div-footer">
        <button
          mat-raised-button
          class="btn-next fixed-button"
          (click)="initPoll()"
        >
          Iniciar encuesta
        </button>
      </div>
    </div>
  </ng-container>
</div>
