import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';

import { B2btoken } from '../models/b2btoken';
import { auth } from 'src/environments/environment';
import { Router } from '@angular/router';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';

import { catchError, map, retry } from 'rxjs/operators';
import { PollModel } from 'src/app/polls/models/poll.model';
import { ClientModel } from 'src/app/polls/models/client.model';

@Injectable({
  providedIn: 'root',
})
export class Authb2bService {
  private API_URL = `${auth.urlConfig}`;
  isAuth: boolean;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    public dialog: MatDialog
  ) {}
  //https://60f5f86e18254c00176e00b0.mockapi.io/api/b2b/clients?cod_client=02348144_1002
  getValidateTokenClient(data: any) {
    return this.httpClient
      .post<{ status: boolean }>(`${this.API_URL}/user/b2b/token`, data)
      .pipe(
        retry(3),
        // map(customer => customer[0]),
        catchError(this.handleError)
      );
  }

  getPollByToken(token: string) {
    const url = `${auth.urlConfig}/poll/token/${token}`;

    return this.httpClient
      .get<PollModel>(url)
      .pipe(retry(3), catchError(this.handleError));
  }

  getClientByCodClient(cliente_id: string, token: string) {
    let params = new HttpParams();
    params = params.set('token', token);
    const url = `${auth.urlConfig}/customer/${cliente_id}`;
    return this.httpClient.get<ClientModel>(url, { params }).pipe(
      // retry(3),
      // map(poll => poll[0]),
      catchError(this.handleError)
    );
  }

  redirectClient(codClient: any) {
    // this.router.navigateByUrl("/clients/"+cod_client);
  }

  getIsAuth(isAuth: boolean): any {
    this.isAuth = isAuth;
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      return throwError('Something bad happened; please try again later.');
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
