<ngx-spinner></ngx-spinner>
<div fxFlexAlign="column">
  <img [src]="imageGcp" [id]="imageGcp" [attr.lazyLoad]="itemImageUrlDefault" />
  <br />
</div>

<div fxFlexAlign="column">
  <span class="help-text"
    >{{ this.message }} <br />
    <br
  /></span>
</div>

<div fxLayoutAlign="center center" class="footer-dialog">
  <button class="button-close" (click)="closeDialog()">Cerrar</button>
</div>
