import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { QuestionModel } from 'src/app/polls/models/question.model';
import { ResponseService } from 'src/app/polls/services/response.service';
import { OfflineService } from 'src/app/polls/services/offline.service';
import { UserModel } from 'src/app/polls/models/user.model';
import { PollModel } from 'src/app/polls/models/poll.model';
import * as jwt_decode from 'jwt-decode';
// Dexie DB
import { db } from 'src/app/db/db';

const moment = _moment;

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.css'],
})
export class DateComponent implements OnInit {
  @Input() title: string = 'Sin titulo';
  @Input() question: QuestionModel;
  @Input() selected: any;
  @Input() idResponse: number;
  @Output() optionSelected = new EventEmitter();
  @Output() showHideSpiner = new EventEmitter();
  date = moment();
  response = '';
  minDateExpire: Date;
  maxDateExpire: Date;
  maxDatePublish: Date;
  offlineMode: any = {
    status: false,
    from: 'nonde',
  };
  user: UserModel;
  poll: PollModel;

  constructor(
    private responseService: ResponseService,
    private offlineService: OfflineService
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
    this.poll = JSON.parse(localStorage.getItem('poll'));
    const currentYear = new Date().getFullYear();
    this.maxDatePublish = new Date(currentYear + 1, 10, 31);
  }

  ngOnInit(): void {
    this.title = this.question.questionoptions[0].title;
  }

  ngOnChanges(): void {
    if (this.selected == null) {
      this.getResponse();
    }
  }

  addDateResponse(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = moment(event.value);
    this.optionSelected.emit(this.date);
  }

  getResponse() {
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        if (offlineHeader) {
          this.offlineMode = offlineHeader;
        }

        // Init Offline Mode
        if (this.offlineMode.status && this.offlineMode.from === 'clients') {
          const client = JSON.parse(localStorage.getItem('client'));
          const id = `${this.question.id}-${this.poll.id}-${client.id}-${this.user.id}`;
          this.offlineService
            .getQuestionResponseDB(id)
            .then(async (questionData) => {
              if (questionData && questionData.data.value) {
                this.response = questionData.data.value;
                this.selected = this.response;
                this.optionSelected.emit(this.response);
              }
              this.showHideSpiner.emit('hide');
            });
          return;
        }
        // End Offline Mode

        this.showHideSpiner.emit('show');
        this.responseService
          .getResponseQuestion({
            response_id: this.idResponse,
            question_id: this.question.id,
          })
          .subscribe((data) => {
            if (data.length > 0) {
              this.response = data[0].value;

              if (this.response.length > 0) {
                this.selected = this.response;
                this.optionSelected.emit(this.response);
              }
            }
            this.showHideSpiner.emit('hide');
          });
      });
  }
}
