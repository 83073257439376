import { v4 as uuidv4 } from 'uuid';
import { MatDialog } from '@angular/material/dialog';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { db } from 'src/app/db/db';
import { ResponsePhotoModalComponent } from './modal/response-photo-modal.component';

@Component({
  selector: 'app-task-response-photo',
  templateUrl: './question-photo.component.html',
  styleUrls: ['./question-photo.component.css'],
})
export class TasksResponsePhotoComponent implements OnInit {
  @Input() question: any;
  @Input() client: any;
  @Input() user: any;
  @Output() answer: EventEmitter<any> = new EventEmitter();

  public images: any[] = [];

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    if (this.question?.response) {
      this.images = this.question.response.answer;
    }
  }

  async handleAnswer(id) {
    if (!id && this.question.images_allowed <= this.images.length) return;

    let dialogRef = this.dialog.open(ResponsePhotoModalComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'custom-dialog-container',
      data: { labelButton: '' },
      disableClose: true,
    });

    dialogRef.componentInstance.data = {
      labelButton: '',
      images_allowed: this.question.images_allowed,
      urls: this.images,
    };

    const sub = dialogRef.componentInstance.onAdd.subscribe(
      async (data: any) => {
        const token = uuidv4();
        let fileName: string = `${this.question.title
          .replace(/\s/g, '')
          .replace(/\//g, '_')}_${this.images.length.toString()}.jpg`;

        const file = {
          type: 'jpg',
          isUpdate: false,
          image: data,
          token,
          fileName,
          id:
            id ||
            `${this.question.id}-${this.client.id}-${this.user.id}-${token}`,
        };

        await db.setFile(file);

        if (id) {
          this.images = this.images.map((image) =>
            image.id === id ? file : image
          );
        } else this.images.push(file);

        this.answer.emit(this.images);
      }
    );
  }

  async removePhoto(id) {
    this.images = this.images.filter((image) => image.id !== id);

    await db.deleteFile(id);

    this.answer.emit(this.images);
  }
}
