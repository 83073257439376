import { Output } from '@angular/core';
import { Component, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-task-response-text',
  templateUrl: './question-text.component.html',
  styleUrls: ['./question-text.component.css'],
})
export class TaskResponseTextComponent {
  @Input() question: any;
  @Output() handleResponse: EventEmitter<string> = new EventEmitter();

  public response: string = '';

  constructor() {}

  handleAnswer(answer: string): void {
    this.handleResponse.emit(answer);
  }
}
