import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { auth } from 'src/environments/environment';
import { Category } from '../models/category.models';
import { ClientModel } from '../models/client.model';
import { PollModel } from '../models/poll.model';
import { resultClientModel } from '../models/resultClient.models';
import { UserModel } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class PollsService {
  private API_URL = `${auth.urlConfig}`;
  private user: UserModel;

  constructor(private httpClient: HttpClient) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
  }

  getPollsByUser() {
    const url = `${auth.urlConfig}/poll/user`;

    return this.httpClient
      .get<PollModel[]>(url)
      .pipe(catchError(this.handleError));
  }

  getClientByPoll(id: number, preview: boolean) {
    let params = new HttpParams();

    if (preview) {
      params = params.set('preview', preview);
    }

    const url = `${auth.urlConfig}/poll/${id}/customer`;
    return this.httpClient
      .get<ClientModel[]>(url, { params })
      .pipe(retry(3), catchError(this.handleError));
  }

  getClientByPollByPage(
    id: number,
    preview: boolean,
    pageNum: number,
    pageSize: number,
    status: boolean = false,
    searchString: string,
    categories: Category[]
  ) {
    let params = new HttpParams();
    params = params.set('pageNum', pageNum);
    params = params.set('status', status);
    params = params.set('pageSize', pageSize);

    Object.values(categories).forEach((category) => {
      params = params.append(category.source, category.code.toString());
    });

    if (preview) {
      params = params.set('preview', preview);
    }
    if (searchString.length > 2) {
      params = params.set('searchString', searchString);
    }
    const url = `${auth.urlConfig}/pollsters/poll/customerPeriodic/${id}`;

    return this.httpClient.get<resultClientModel>(url, { params }).pipe(
      // retry(3),
      catchError(this.handleError)
    );
  }

  sendPollRestart(data: any) {
    const url = `${auth.urlConfig}/poll/restart`;
    return this.httpClient.post<any>(url, data).pipe(
      // retry(3),
      catchError(this.handleError)
    );
  }

  sendPollEdit(data: any) {
    const url = `${auth.urlConfig}/poll/edit`;
    return this.httpClient.post<any>(url, data).pipe(
      // retry(3),
      catchError(this.handleError)
    );
  }

  getPollsDataMin(id: number) {
    const url = `${auth.urlConfig}/pollsters/poll/min/${id}`;

    return this.httpClient.get<PollModel>(url).pipe(
      // retry(3),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      return throwError('Something bad happened; please try again later.');
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
