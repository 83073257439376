<div fxLayout="column">
  <mat-sidenav-container>
    <mat-sidenav #sidenav="matSidenav" class="mat-elevation-z7">
      <app-filters-menu
        (optionSelected)="getSelected($event)"
        (refreshStats)="fetchStats()"
        (closeNavEvent)="closeNav()"
      ></app-filters-menu>
    </mat-sidenav>
    <mat-sidenav-content>
      <app-header
        [isOfflineMode]="isOfflineMode"
        [title]="poll.name"
        [sub_title]="'Dashboard'"
        [isOfflineMode]="offlineMode.status"
      >
      </app-header
      ><br />

      <div fxLayout="row-reverse">
        <button
          mat-mini-fab
          color="primary"
          class="example-tab-icon"
          (click)="sidenav.toggle()"
          class="mat-elevation-z"
        >
          <mat-icon> filter_alt</mat-icon>
        </button>
      </div>

      <div fxLayout="column" class="check-time">
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          class="chart-poll-period"
        >
          <div class="title">N° Encuestas-P</div>
          <div class="title">completadas</div>
          <div class="month">{{ monthLabel }}-{{ yearLabel }}</div>
          <div class="total">{{ totalFinish }}</div>
        </div>

        <div fxLayout="column" *ngIf="pollByDay.counts.length > 0">
          <apx-chart
            [series]="chartPollPeriod.series"
            [chart]="chartPollPeriod.chart"
            [colors]="chartPollPeriod.colors"
            [yaxis]="chartPollPeriod.yaxis"
            [dataLabels]="chartPollPeriod.dataLabels"
            [markers]="chartPollPeriod.markers"
            [stroke]="chartPollPeriod.stroke"
            [grid]="chartPollPeriod.grid"
            [xaxis]="chartPollPeriod.xaxis"
            [tooltip]="chartPollPeriod.tooltip"
          ></apx-chart>
        </div>
        <div fxLayout="column" class="div-advance">
          <div fxLayout="row" fxLayoutAlign="center center" class="divstats">
            <div fxFlex="25">
              <div fxLayout="column">
                <span class="indicators-text">Inicio:</span>
                <span class="indicators-value">{{ initDate }}</span>
              </div>
            </div>

            <div fxFlex="25">
              <div fxLayout="column">
                <span class="indicators-text">Transcurrido:</span>
                <span class="indicators-value">{{ daysElapsed }} días</span>
              </div>
            </div>
            <div fxFlex="25">
              <div fxLayout="column">
                <span class="indicators-text">Quedan:</span>
                <span class="indicators-value">{{ daysremaining }} días</span>
              </div>
            </div>
            <div fxFlex="25">
              <div fxLayout="column">
                <span class="indicators-text">Cierre:</span>
                <span class="indicators-value">{{ endDate }}</span>
              </div>
            </div>
          </div>
          <br />

          <span class="indicators-text span-value"
            >A este ritmo en el mes completadas:</span
          >
          <span class="indicators-value"> {{ projection }} Encuestas </span>
          <ng-container *ngIf="isHandleGoal">
            <mat-divider></mat-divider>
            <br />
            <span class="indicators-text span-value">Meta deseada:</span>
            <span class="indicators-value"> {{ goalValue }} Encuestas </span>
            <mat-progress-bar
              class="tp-margin"
              color="green"
              mode="determinate"
              value="10"
            >
            </mat-progress-bar>
          </ng-container>
        </div>
      </div>
      <div fxLayout="row" class="btn-footer">
        <div fxFlex="50" fxLayoutAlign="start center">
          <button class="back-button" mat-button (click)="returnList()">
            Volver
          </button>
        </div>

        <div fxFlex="50" fxLayoutAlign="end center">
          <button class="next-button" mat-button (click)="aplyPoll()">
            Ir a Encuestar
          </button>
        </div>
      </div>
      <ngx-spinner
        bdColor="rgba(51,51,51,0.8)"
        size="medium"
        color="rgb(92, 184, 92)"
        type="ball-clip-rotate-pulse"
        class="content-spinner"
      ></ngx-spinner>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<!-- <pre>{{ chartPollPeriod?.series | json }}</pre> -->
