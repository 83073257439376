<div *ngIf="inventary.length">
  <ng-container *ngFor="let element of inventary; let i = index">
    <mat-expansion-panel style="margin-top: 16px">
      <mat-expansion-panel-header style="height: 100%; padding-left: 5px">
        <div style="display: flex; flex-direction: row">
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding-top: 19px;
            "
          >
            <div
              fxLatout="row"
              class="mt"
              style="flex-direction: row; box-sizing: border-box; display: flex"
            >
              <div fxFlex="" fxLayoutAlign="center center">
                <div
                  fxLayoutAlign="center center"
                  class="{{
                    !itenIsValid(inventary[i].fields) ? 'number-blocked' : ''
                  }}
                  {{
                    itenIsValid(inventary[i].fields) ? 'number-selected' : ''
                  }}"
                >
                  <span class="span-number">{{ i + 1 }}</span>
                </div>
              </div>
            </div>
          </div>
          <div style="padding: 10px">
            <ng-container
              *ngFor="let item of inventary[i].fields; let indexField = index"
            >
              <mat-panel-title style="padding: 3px 0 1px 0; font-size: small">
                <!-- <mat-panel-title
              style="padding: 10px 0 2.5px 0"
              *ngIf="indexField < 2"
            > -->
                <!-- {{ item | json }} -->
                {{
                  item.questionType === 1
                    ? item?.questionAnswer
                      ? item?.questionTitle + ': ' + item?.questionAnswer
                      : item?.questionTitle + ': Vacio'
                    : ''
                }}
              </mat-panel-title>
            </ng-container>
          </div>
          <!-- -->
        </div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div style="margin-top: 10px">
          <ng-container *ngFor="let item of getItems(); let j = index">
            <ng-container *ngIf="item.question_type_cooler === 1">
              <div>
                <mat-label>{{ item.title_list }}</mat-label>
                <mat-form-field
                  class="input-full-width mb-3"
                  appearance="outline"
                >
                  <mat-select
                    [id]="j"
                    placeholder="Seleccione opción"
                    name="optionSelect"
                    [value]="getSelectedValue(i, item.title_list)"
                    (selectionChange)="selectValue($event, i, item.title_list)"
                  >
                    <mat-option
                      *ngFor="let option of item.itemsoptions"
                      [value]="option.title"
                      >{{ option.title }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>
            <ng-container *ngIf="item.question_type_cooler === 4">
              <app-cooler-loadfile
                [required]="true"
                [onlyPhone]="item?.itemsoptions[0]?.isOnlyPhoneOptionAllow"
                [questionId]="i"
                [fieldId]="j"
                [fileOptions]="item.itemsoptions[0]"
                [dataImageSelected]="getFIleValue(i, j)"
                (imageSelected)="setImage($event, i)"
                [question]="question"
              >
              </app-cooler-loadfile>
            </ng-container>
          </ng-container>
          <div
            style="display: flex; flex-direction: row; justify-content: center"
            *ngIf="i > 0"
          >
            <button
              *ngIf="!question?.has_dependency"
              style="width: 50%; margin-top: 20px"
              mat-raised-button
              color="accent"
              (click)="delete(i)"
            >
              Eliminar Elemento
            </button>

            <!-- <button type="button" (click)="delete(i)" class="remove-image">
              Eliminar <span class="image-title">Elemento</span>
            </button> -->
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </ng-container>
</div>
<button
  *ngIf="!question?.has_dependency"
  style="
    position: fixed;
    bottom: 90px;
    right: 20px;
    background-color: #219652;
    font-size: 25px;
  "
  mat-fab
  color="primary"
  (click)="addItems()"
>
  +
</button>

<ng-container *ngIf="question?.has_dependency && dependencyCount === 0">
  <p style="text-align: center">
    Usted Ha marcadado una opcion incorrecta para esta pregunta vuelva y
    verifique su opción
  </p>
</ng-container>

<p style="display: none">{{ isValid() }}</p>

<!-- <br />
<br />
<br />
<br />
<pre>
  VALID: {{ isValid() }}
  {{ inventary | json }}
</pre> -->
