<div *ngIf="showHeader" class="header-container">
  <div *ngIf="showBackButton" class="header-back-button" (click)="onGoBack()">
    <mat-icon>arrow_back</mat-icon>
    <p class="header-back-button-text">Volver</p>
  </div>

  <div *ngIf="showTitle" class="header-content">
    <h1 class="header-title">{{ title }}</h1>
    <span
      class="header-badge"
      matBadge="{{ badgeNumber }}"
      matBadgePosition="before"
      matBadgeColor="accent"
      *ngIf="showBadge"
    ></span>
  </div>
</div>
