import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-init',
  template: ` <p>init works!</p> `,
  styles: [],
})
export class InitComponent implements OnInit {
  constructor(private route: Router) {}

  ngOnInit(): void {
    // if (window.navigator.onLine) {
    if (this.checkSession()) {
      this.route.navigateByUrl('/poll');
    } else {
      this.route.navigateByUrl('/login');
    }
    /* } else {
      this.route.navigateByUrl('/login');
    } */
  }

  checkSession() {
    const token = localStorage.getItem('token');
    const expiresInDuration = parseInt(
      localStorage.getItem('expiresInDuration')
    );
    if (token && moment.unix(expiresInDuration) > moment()) {
      return true;
    }
    return false;
  }
}
