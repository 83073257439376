import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuestionModel } from 'src/app/polls/models/question.model';
import { ResponseSimpleQuesion } from 'src/app/polls/models/response-simple-quesion.model';
import { ResponseService } from 'src/app/polls/services/response.service';
import { OfflineService } from 'src/app/polls/services/offline.service';
// Dexie
import { db } from 'src/app/db/db';
import { UserModel } from 'src/app/polls/models/user.model';
import { PollModel } from 'src/app/polls/models/poll.model';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-cooler',
  templateUrl: './cooler.component.html',
  styleUrls: ['./cooler.component.css'],
})
export class CoolerComponent implements OnInit {
  @Input() question: QuestionModel;
  @Input() selected: any;
  @Input() idResponse: number;
  @Output() optionSelected = new EventEmitter();
  @Output() updateQuestionRequired = new EventEmitter();
  @Output() answerBody = new EventEmitter();
  @Output() showHideSpiner = new EventEmitter();

  offlineMode: any = {
    status: false,
    from: 'nonde',
  };

  inventary = [];

  inventaryFields = [];

  answer = [];

  responseQuestion: ResponseSimpleQuesion;

  userHasResponseSavedOnDatabase;

  dependencyCount;

  client;
  user: UserModel;
  poll: PollModel;
  constructor(
    private responseService: ResponseService,
    private offlineService: OfflineService
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
    this.poll = JSON.parse(localStorage.getItem('poll'));
  }

  ngOnInit(): void {
    this.setupOffline();
  }

  setupOffline() {
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        // setup offline
        if (offlineHeader) {
          this.offlineMode = offlineHeader;
        }
        this.client = JSON.parse(localStorage.getItem('client'));
        this.getResponse();
      });
  }

  getItems() {
    // return this.mock.sections[0].questions[0].items;
    return this.question.items;
  }

  addItems() {
    this.inventary.push({
      id: this.inventary.length,
      fields: this.inventaryFields,
    });
  }

  delete(i: number) {
    this.inventary.splice(i, 1);
  }

  getAnswerValues(
    questionTitle,
    item,
    answer,
    index,
    elementFIeld,
    type,
    condition = null
  ) {
    if (condition) {
      return questionTitle === item.title_list && answer?.fieldId === index
        ? condition
        : elementFIeld[index][type]
          ? elementFIeld[index][type]
          : null;
    }

    return questionTitle === item.title_list
      ? answer
      : elementFIeld[index].questionAnswer
        ? elementFIeld[index].questionAnswer
        : null;
  }

  assignValueObject(
    _object,
    questionTitle,
    item,
    answer,
    index,
    elementFIeld,
    type,
    condition = null
  ) {
    _object[type] = this.getAnswerValues(
      questionTitle,
      item,
      answer,
      index,
      elementFIeld,
      type,
      condition
    );
  }

  getUpdateFields(answer, questionTitle, elementFIeld) {
    const items = this.getItems();
    const _fields = [];
    items.forEach((item, index) => {
      let _object;

      if (item.question_type_cooler === 4) {
        _object = {
          id: elementFIeld[index].id,
          questionType: item.question_type_cooler,
          questionTitle: 'Archivo',
          questionAnswer:
            answer?.fieldId === index
              ? answer?.base64
              : elementFIeld[index].questionAnswer
                ? elementFIeld[index].questionAnswer
                : null,
          questionToken:
            answer?.fieldId === index
              ? answer?.token
              : elementFIeld[index].questionToken
                ? elementFIeld[index].questionToken
                : null,
          questionName:
            answer?.fieldId === index
              ? answer?.name
              : elementFIeld[index].questionName
                ? elementFIeld[index].questionName
                : null,
        };
      } else {
        _object = {
          id: elementFIeld[index].id,
          questionType: item.question_type_cooler,
          questionTitle: item.title_list,
          questionAnswer:
            questionTitle === item.title_list
              ? answer
              : elementFIeld[index].questionAnswer
                ? elementFIeld[index].questionAnswer
                : null,
        };
      }

      _fields.push(_object);
    });

    return _fields;
  }

  selectValue($event, elementId, questionTitle) {
    // update answer
    const newInventary = this.inventary;
    newInventary[elementId].fields = this.getUpdateFields(
      $event.value,
      questionTitle,
      newInventary[elementId].fields
    );
    this.inventary = newInventary;
  }

  isValid() {
    this.answerBody.emit(this.inventary);
    if (this.inventary.length > 0) {
      this.updateQuestionRequired.emit(
        !this.inventary.every((element) => {
          return element.fields.every((field) => {
            return field.questionAnswer;
          });
        })
      );
      return this.inventary.every((element) => {
        return element.fields.every((field) => {
          return field.questionAnswer;
        });
      });
    }
    this.updateQuestionRequired.emit(!false);
    return false;
  }

  getFIleValue(index, fieldIndex) {
    return this.inventary[index].fields[fieldIndex].questionAnswer;
  }

  setImage($event, index) {
    const newInventary = this.inventary;
    newInventary[index].fields = this.getUpdateFields(
      $event,
      null,
      newInventary[index].fields
    );
    this.inventary = newInventary;
  }

  getInventaryFields() {
    const items = this.getItems();

    items.forEach((item) => {
      let object;
      object = {
        id: item.id,
        questionType: item.question_type_cooler,
        questionAnswer: null,
      };

      if (item.question_type_cooler === 4) {
        object.questionTitle = 'Archivo';
        object.questionToken = null;
        object.questionName = null;
      } else {
        object.questionTitle = item.title_list;
      }

      this.inventaryFields.push(object);
    });
  }

  buildBasicObjectWithoutData(data) {
    this.dependencyCount = +data;
    for (let i = 0; i < +data; i++) {
      this.inventary.push({
        id: i,
        fields: this.inventaryFields,
      });
    }
  }

  async initDataWithoutResponse() {
    this.getInventaryFields();

    if (this.question.has_dependency) {
      if (this.offlineMode.status && this.offlineMode.from === 'clients') {
        // looking on dixie db when i have skup value (question id)
        // the value for create the empty options
        if (this.question.question_dependency) {
          const id = `${this.question.question_dependency}-${this.poll.id}-${this.client.id}-${this.user.id}`;
          this.offlineService
            .getQuestionResponseDB(id)
            .then(async (questionData) => {
              if (questionData) {
                this.buildBasicObjectWithoutData(questionData?.data?.value);
              }
            });
          return;
        } else if (!this.question.question_dependency) {
          const response = await db.responses
            .where({
              client_id: this.client.id,
              poll_id: this.question.poll_id,
              section_id: Number(this.question.section_dependency),
              title: this.question.skip_target,
            })
            .toArray();

          if (response.length > 0) {
            this.buildBasicObjectWithoutData(response[0]?.data?.value);
          }

          return;
        }
      }
      this.responseService
        .getResponseDependencyQuestion(this.buildBodyForGetDependencyQuestion())
        .subscribe((data) => {
          this.dependencyCount = +data;
          this.buildBasicObjectWithoutData(data);
        });
    } else {
      this.inventary = [
        {
          id: 0,
          fields: this.inventaryFields,
        },
      ];
    }
  }

  orderResponseByOrderId(data) {
    let nuevoObjeto = {};
    //Recorremos el arreglo
    data.forEach((x) => {
      //Si la ciudad no existe en nuevoObjeto entonces
      //la creamos e inicializamos el arreglo de profesionales.
      if (!nuevoObjeto.hasOwnProperty(x.position)) {
        nuevoObjeto[x.position] = {
          data: [],
        };
      }

      //Agregamos los datos de profesionales.
      nuevoObjeto[x.position].data.push(x);
    });

    return nuevoObjeto;
  }

  buildItemsWhenHaveDependencyValue(data) {
    const newData = this.orderResponseByOrderId(data);

    const items = this.getItems();

    items.forEach((item) => {
      let object;

      if (item.question_type_cooler === 4) {
        object = {
          id: item.id,
          questionType: item.question_type_cooler,
          questionTitle: 'Archivo',
          questionAnswer: null,
          questionToken: null,
          questionName: null,
        };
      } else {
        object = {
          id: item.id,
          questionType: item.question_type_cooler,
          questionTitle: item.title_list,
          questionAnswer: null,
        };
      }

      this.inventaryFields.push(object);
    });
    return {
      newData,
      items,
    };
  }

  buildBodyForGetDependencyQuestion() {
    return {
      response_id: this.idResponse,
      poll_id: this.question.poll_id,
      section_id: this.question.section_dependency,
      question_id: this.question.question_dependency
        ? this.question.question_dependency
        : 0,
      title: this.question?.skip_target,
    };
  }

  processForResponseQuestion(data) {
    if (data?.length > 0) {
      const { newData, items } = this.buildItemsWhenHaveDependencyValue(data);

      if (this.question.has_dependency) {
        this.responseService
          .getResponseDependencyQuestion(
            this.buildBodyForGetDependencyQuestion()
          )
          .subscribe((data) => {
            this.dependencyCount = +data;
            let count = +data;
            Object.entries(newData).forEach(([key, value]: [any, any]) => {
              count--;

              let _fields = [];

              items.forEach((item) => {
                let object;

                if (item.question_type_cooler === 4) {
                  object = {
                    id: item.id,
                    questionType: item.question_type_cooler,
                    questionTitle: 'Archivo',
                    questionAnswer: value?.data.filter(
                      (element) => element.item_id === item.id
                    )[0].value,
                    questionToken: value?.data
                      .filter((element) => element.item_id === item.id)[0]
                      .value.split('/')[0],
                    questionName: value?.data
                      .filter((element) => element.item_id === item.id)[0]
                      .value.split('/')[1],
                  };
                } else {
                  object = {
                    id: item.id,
                    questionType: item.question_type_cooler,
                    questionTitle: item.title_list,
                    questionAnswer: value?.data.filter(
                      (element) => element.item_id === item.id
                    )[0].value,
                  };
                }

                _fields.push(object);
              });

              if (+key + 1 <= +data) {
                this.inventary.push({
                  id: key,
                  fields: _fields,
                });
              }
            });
            if (count > 0) {
              for (let i = 0; i < count; i++) {
                this.inventary.push({
                  id: this.inventary.length,
                  fields: this.inventaryFields,
                });
              }
            }

            this.showHideSpiner.emit('hide');
          });
      } else {
        Object.entries(newData).forEach(([key, value]: [any, any]) => {
          let _fields = [];

          items.forEach((item) => {
            let object;

            if (item.question_type_cooler === 4) {
              object = {
                id: item.id,
                questionType: item.question_type_cooler,
                questionTitle: 'Archivo',
                questionAnswer: value?.data.filter(
                  (element) => element.item_id === item.id
                )[0].value,
                questionToken: value?.data
                  .filter((element) => element.item_id === item.id)[0]
                  .value.split('/')[0],
                questionName: value?.data
                  .filter((element) => element.item_id === item.id)[0]
                  .value.split('/')[1],
              };
            } else {
              object = {
                id: item.id,
                questionType: item.question_type_cooler,
                questionTitle: item.title_list,
                questionAnswer: value?.data.filter(
                  (element) => element.item_id === item.id
                )[0].value,
              };
            }

            _fields.push(object);
          });

          this.inventary.push({
            id: key,
            fields: _fields,
          });
        });
        this.showHideSpiner.emit('hide');
      }
    } else {
      this.showHideSpiner.emit('hide');
      this.initDataWithoutResponse();
    }
  }

  processForResponseQuestionOffline(data) {
    if (data?.answerCoolerBody?.length > 0) {
      const { newData, items } = this.buildItemsWhenHaveDependencyValue(data);

      if (this.question.has_dependency) {
        this.responseService
          .getResponseDependencyQuestion(
            this.buildBodyForGetDependencyQuestion()
          )
          .subscribe((data) => {
            this.dependencyCount = +data;
            let count = +data;
            Object.entries(newData).forEach(([key, value]: [any, any]) => {
              count--;

              let _fields = [];

              items.forEach((item) => {
                let object;

                if (item.question_type_cooler === 4) {
                  object = {
                    id: item.id,
                    questionType: item.question_type_cooler,
                    questionTitle: 'Archivo',
                    questionAnswer: value?.data.filter(
                      (element) => element.item_id === item.id
                    )[0].value,
                    questionToken: value?.data
                      .filter((element) => element.item_id === item.id)[0]
                      .value.split('/')[0],
                    questionName: value?.data
                      .filter((element) => element.item_id === item.id)[0]
                      .value.split('/')[1],
                  };
                } else {
                  object = {
                    id: item.id,
                    questionType: item.question_type_cooler,
                    questionTitle: item.title_list,
                    questionAnswer: value?.data.filter(
                      (element) => element.item_id === item.id
                    )[0].value,
                  };
                }

                _fields.push(object);
              });

              if (+key + 1 <= +data) {
                this.inventary.push({
                  id: key,
                  fields: _fields,
                });
              }
            });
            if (count > 0) {
              for (let i = 0; i < count; i++) {
                this.inventary.push({
                  id: this.inventary.length,
                  fields: this.inventaryFields,
                });
              }
            }
          });
      } else {
        Object.entries(newData).forEach(([key, value]: [any, any]) => {
          let _fields = [];

          items.forEach((item) => {
            let object;

            if (item.question_type_cooler === 4) {
              object = {
                id: item.id,
                questionType: item.question_type_cooler,
                questionTitle: 'Archivo',
                questionAnswer: value?.data.filter(
                  (element) => element.item_id === item.id
                )[0].value,
                questionToken: value?.data
                  .filter((element) => element.item_id === item.id)[0]
                  .value.split('/')[0],
                questionName: value?.data
                  .filter((element) => element.item_id === item.id)[0]
                  .value.split('/')[1],
              };
            } else {
              object = {
                id: item.id,
                questionType: item.question_type_cooler,
                questionTitle: item.title_list,
                questionAnswer: value?.data.filter(
                  (element) => element.item_id === item.id
                )[0].value,
              };
            }

            _fields.push(object);
          });

          this.inventary.push({
            id: key,
            fields: _fields,
          });
        });
      }
    } else {
      this.initDataWithoutResponse();
    }
  }

  // todo: refactor
  getResponse() {
    // Init Offline Mode
    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      // search on dixie db for previus response
      const id = `${this.question.id}-${this.poll.id}-${this.client.id}-${this.user.id}`;
      this.offlineService
        .getQuestionResponseDB(id)
        .then(async (questionData) => {
          if (questionData?.data?.answerCoolerBody.length > 0) {
            const newData = questionData?.data?.answerCoolerBody;

            const items = this.getItems();

            items.forEach((item) => {
              let object;

              if (item.question_type_cooler === 4) {
                object = {
                  id: item.id,
                  questionType: item.question_type_cooler,
                  questionTitle: 'Archivo',
                  questionAnswer: null,
                  questionToken: null,
                  questionName: null,
                };
              } else {
                object = {
                  id: item.id,
                  questionType: item.question_type_cooler,
                  questionTitle: item.title_list,
                  questionAnswer: null,
                };
              }

              this.inventaryFields.push(object);
            });

            if (this.question.has_dependency) {
              if (this.question.question_dependency) {
                // get data with skip destine
                const id = `${this.question.question_dependency}-${this.poll.id}-${this.client.id}-${this.user.id}`;
                this.offlineService
                  .getQuestionResponseDB(id)
                  .then(async (questionData) => {
                    this.dependencyCount = +questionData?.data?.value;
                    let count = +questionData?.data?.value;
                    Object.entries(newData).forEach(
                      ([key, value]: [any, any]) => {
                        count--;

                        let _fields = [];

                        items.forEach((item) => {
                          let object;

                          if (item.question_type_cooler === 4) {
                            // todo: check multiple files
                            object = {
                              id: item.id,
                              questionType: item.question_type_cooler,
                              questionTitle: 'Archivo',
                              questionAnswer: value?.fields.filter(
                                (element) => element.questionTitle === 'Archivo'
                              )[0].questionAnswer,
                              questionToken: value?.fields.filter(
                                (element) => element.questionTitle === 'Archivo'
                              )[0].questionToken,
                              questionName: value?.fields.filter(
                                (element) => element.questionTitle === 'Archivo'
                              )[0].questionName,
                            };
                          } else {
                            object = {
                              id: item.id,
                              questionType: item.question_type_cooler,
                              questionTitle: item.title_list,
                              questionAnswer: value?.fields.filter(
                                (element) =>
                                  element.questionTitle === item.title_list
                              )[0].questionAnswer,
                            };
                          }

                          _fields.push(object);
                        });

                        if (+key + 1 <= +questionData?.data?.value) {
                          this.inventary.push({
                            id: key,
                            fields: _fields,
                          });
                        }
                      }
                    );
                    if (count > 0) {
                      for (let i = 0; i < count; i++) {
                        this.inventary.push({
                          id: this.inventary.length,
                          fields: this.inventaryFields,
                        });
                      }
                    }
                  });
              }
              // Se mantiene por logica diseña pero refencias a campo
              else if (!this.question.question_dependency) {
                const response = await db.responses
                  .where({
                    client_id: this.client.id,
                    poll_id: this.question.poll_id,
                    section_id: Number(this.question.section_dependency),
                    title: this.question.skip_target,
                  })
                  .toArray();

                if (response.length > 0) {
                  this.dependencyCount = +response[0]?.data?.value;
                  let count = +response[0]?.data?.value;
                  Object.entries(newData).forEach(
                    ([key, value]: [any, any]) => {
                      count--;

                      let _fields = [];

                      items.forEach((item) => {
                        let object;

                        if (item.question_type_cooler === 4) {
                          // todo: check multiple files
                          object = {
                            id: item.id,
                            questionType: item.question_type_cooler,
                            questionTitle: 'Archivo',
                            questionAnswer: value?.fields.filter(
                              (element) => element.questionTitle === 'Archivo'
                            )[0].questionAnswer,
                            questionToken: value?.fields.filter(
                              (element) => element.questionTitle === 'Archivo'
                            )[0].questionToken,
                            questionName: value?.fields.filter(
                              (element) => element.questionTitle === 'Archivo'
                            )[0].questionName,
                          };
                        } else {
                          object = {
                            id: item.id,
                            questionType: item.question_type_cooler,
                            questionTitle: item.title_list,
                            questionAnswer: value?.fields.filter(
                              (element) =>
                                element.questionTitle === item.title_list
                            )[0].questionAnswer,
                          };
                        }

                        _fields.push(object);
                      });

                      if (+key + 1 <= +response[0]?.data?.value) {
                        this.inventary.push({
                          id: key,
                          fields: _fields,
                        });
                      }
                    }
                  );
                  if (count > 0) {
                    for (let i = 0; i < count; i++) {
                      this.inventary.push({
                        id: this.inventary.length,
                        fields: this.inventaryFields,
                      });
                    }
                  }
                }
              }
            } else {
              Object.entries(newData).forEach(([key, value]: [any, any]) => {
                let _fields = [];

                items.forEach((item) => {
                  let object;
                  if (item.question_type_cooler === 4) {
                    object = {
                      id: item.id,
                      questionType: item.question_type_cooler,
                      questionTitle: 'Archivo',
                      questionAnswer:
                        value?.fields !== undefined
                          ? value?.fields[0].questionAnswer
                          : null,
                      questionToken:
                        value?.fields !== undefined
                          ? value?.fields[0].questionToken
                          : null,
                      questionName:
                        value?.fields !== undefined
                          ? value?.fields[0].questionName
                          : null,
                    };
                  } else {
                    object = {
                      id: item.id,
                      questionType: item.question_type_cooler,
                      questionTitle: item.title_list,
                      questionAnswer: value?.data.filter(
                        (element) => element.item_id === item.id
                      )[0].value,
                    };
                  }

                  _fields.push(object);
                });

                this.inventary.push({
                  id: key,
                  fields: _fields,
                });
              });
            }
          } else {
            this.initDataWithoutResponse();
          }
          this.showHideSpiner.emit('hide');
        });
      return;
    }
    // End Offline Mode
    this.showHideSpiner.emit('show');
    this.responseService
      .getResponseQuestion({
        response_id: this.idResponse,
        question_id: this.question.id,
      })
      .subscribe((data) => {
        this.processForResponseQuestion(data);
      });
  }

  getSelectedValue(elementId, questionTitle) {
    return this.inventary[elementId]['fields']?.filter(
      (element) => element.questionTitle === questionTitle
    )[0]?.questionAnswer;
  }

  itenIsValid(fields) {
    return fields.every((element) => element.questionAnswer !== null);
  }

  refreshOptions(data: QuestionModel) {
    this.inventaryFields = [];
  }
}
