import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { QuestionModel } from 'src/app/polls/models/question.model';
import { ResponseSimpleQuesion } from 'src/app/polls/models/response-simple-quesion.model';
import { ResponseService } from 'src/app/polls/services/response.service';
import { OfflineService } from 'src/app/polls/services/offline.service';
// Dexie DB
import { db } from 'src/app/db/db';
import { UserModel } from 'src/app/polls/models/user.model';
import { PollModel } from 'src/app/polls/models/poll.model';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-pricedropdown',
  templateUrl: './pricedropdown.component.html',
  styleUrls: ['./pricedropdown.component.css'],
})
export class PricedropdownComponent implements OnInit {
  panelOpenState: boolean = false;
  @Input() question: QuestionModel;
  @Input() selected: any;
  @Input() idResponse: number;
  @Output() optionSelected = new EventEmitter();
  @Output() updateQuestionRequired = new EventEmitter();
  @Output() showHideSpiner = new EventEmitter();
  isScroll: Array<boolean> = [];
  sections: any;
  rows: any;
  columns: any;
  excludes_price: any;
  pricesDropdown: Array<any> = [];
  values: Array<any> = [];
  responseQuestion: ResponseSimpleQuesion;
  offlineMode: any = {
    status: false,
    from: 'nonde',
  };
  user: UserModel;
  poll: PollModel;
  constructor(
    private responseService: ResponseService,
    private offlineService: OfflineService
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
    this.poll = JSON.parse(localStorage.getItem('poll'));
  }

  ngOnInit(): void {
    this.loadData();
    this.getResponse();
  }

  loadData() {
    let question: any = this.question;
    this.sections = JSON.parse(question.packaging_prod);
    this.rows = JSON.parse(question.brand_prod);
    this.columns = JSON.parse(question.format_prod);
    this.excludes_price = JSON.parse(question.excludes_price);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.question?.currentValue !== undefined &&
      changes.question?.previousValue !== undefined
    ) {
      setTimeout(() => {
        this.loadData();
        this.getResponse();
      }, 100);
    }
  }

  public refreshOptions(data: QuestionModel) {
    this.loadData();
    this.getResponse();
  }

  getResponse() {
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        if (offlineHeader) {
          this.offlineMode = offlineHeader;
        }
        // Init Offline Mode
        if (this.offlineMode.status && this.offlineMode.from === 'clients') {
          const client = JSON.parse(localStorage.getItem('client'));
          const id = `${this.question.id}-${this.poll.id}-${client.id}-${this.user.id}`;
          this.offlineService
            .getQuestionResponseDB(id)
            .then(async (questionData) => {
              if (questionData) {
                this.loadServiceData(questionData.data.questionoptions);
              } else {
                this.loadServiceData([]);
              }
              this.showHideSpiner.emit('hide');
            });
          return;
        }
        // End Offline Mode

        this.showHideSpiner.emit('show');
        this.responseService
          .getResponseQuestion({
            response_id: this.idResponse,
            question_id: this.question.id,
          })
          .subscribe((data) => {
            this.loadServiceData(data);
            this.showHideSpiner.emit('hide');
          });
      });
  }

  async loadServiceData(data) {
    if (this.question['config_by_client']) {
      const client = JSON.parse(localStorage.getItem('client'));
      const questionDB: any = await db.getCustomerSkuDB(
        this.question['config_name'],
        client.cliente_id
      );
      if (questionDB) {
        this.question.format = questionDB['format_prod'];
        this.question['packaging_prod'] = questionDB['packaging_prod'];
        this.question['brand_prod'] = questionDB['brand_prod'];
        this.rows = questionDB['brand_prod'];
      }
    }
    this.question.questionoptions = data;
    this.pricesDropdown = [];
    this.sections?.forEach((section, indexSection) => {
      this.isScroll.push(true);
      this.rows.forEach((row, indexRow) => {
        this.columns.forEach((column, indexColumn) => {
          const ctlPosition = indexSection + '-' + indexRow + '-' + indexColumn;
          const index = data.findIndex((d) => d.position === ctlPosition);
          const isBlock = this.excludes_price.findIndex(
            (ep) =>
              ep.packaging_prod === section.value &&
              ep.brand_prod === row.value &&
              ep.format_prod === column.value
          );
          const isData = index !== -1 ? true : false;
          this.pricesDropdown.push({
            position: isData ? data[index].position : ctlPosition,
            packaging_prod: isData ? data[index].packaging_prod : section.value,
            brand_prod: isData ? data[index].brand_prod : row.value,
            format_prod: isData ? data[index].format_prod : column.value,
            value: isData ? data[index].value : '',
          });
          this.values[ctlPosition] = {
            position: ctlPosition,
            value: isData ? data[index].value : '',
            isBlock: isBlock !== -1 ? true : false,
          };
        });
      });
    });
    setTimeout(() => {
      this.validate();
    }, 100);
  }

  setValuePricesDropdown(event, position) {
    this.pricesDropdown = this.pricesDropdown.map((p) => {
      if (p.position === position) {
        p.value = event.target.value;
      }
      return p;
    });
    this.question.questionoptions = this.pricesDropdown;
    this.validate();
  }

  validate() {
    if (this.question.required) {
      let arrayVal = [];
      this.pricesDropdown.forEach((item: any) => {
        if (this.values[item.position]?.isBlock === false) {
          arrayVal.push(item.value === '' ? true : false);
        }
      });
      this.updateQuestionRequired.emit(
        arrayVal.length > 0 && !arrayVal.includes(true) ? false : true
      );
    } else {
      this.updateQuestionRequired.emit(false);
    }
  }

  onScroll(event: any, index: number) {
    // visible Width + pixel scrolled >= total Width
    this.isScroll[index] =
      event.target.offsetWidth + event.target.scrollLeft >=
      event.target.scrollWidth
        ? false
        : true;
  }
}
