<div fxLayout="column" style="background: transparent !important">
  <div fxLayoutAlign="center center" fxLayout="row">
    <webcam
      id="webcam"
      [height]="height"
      [width]="width"
      [trigger]="triggerObservable"
      (imageCapture)="handleImage($event)"
      *ngIf="showWebcam"
      [allowCameraSwitch]="allowCameraSwitch"
      [switchCamera]="nextWebcamObservable"
      [videoOptions]="videoOptions"
      [imageQuality]="1"
      (cameraSwitched)="cameraWasSwitched($event)"
      [captureImageData]="captureImageData"
      (initError)="handleInitError($event)"
      [videoOptions]="mediaConstraints"
    ></webcam>
  </div>
</div>
<mat-toolbar
  color="primary"
  class="footer"
  fxLayout="row"
  fxLayoutAlign="center center"
>
  <div fxFlex="30" fxLayoutAlign="center center">
    <button mat-button (click)="closeModal()">
      <mat-icon class="material-icons_other">keyboard_arrow_left</mat-icon>
    </button>
  </div>
  <div fxFlex="40" fxLayoutAlign="center center">
    <button mat-button (click)="triggerSnapshot()" [disabled]="disabledTake()">
      <mat-icon class="material-icons_cam">photo_camera</mat-icon>
    </button>
  </div>
  <div fxFlex="30" fxLayoutAlign="center center">
    <button
      mat-button
      (click)="showNextWebcam(true)"
      [disabled]="!multipleWebcamsAvailable"
    >
      <mat-icon class="material-icons_other">change_circle</mat-icon>
    </button>
  </div>
</mat-toolbar>
