import { Component, SecurityContext, OnInit } from '@angular/core';
import { UserModel } from '../../models/user.model';
import * as jwt_decode from 'jwt-decode';
import Swal from 'sweetalert2';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { ClientModel } from '../../models/client.model';
import { Router } from '@angular/router';
import { PollModel } from '../../models/poll.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResponseService } from '../../services/response.service';
import { OfflineService } from '../../services/offline.service';
import { PollMessageModel } from '../../models/pollmesagge.model';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { dev } from 'src/environments/environment';

@Component({
  selector: 'app-message-init',
  templateUrl: './message-init.component.html',
  styleUrls: ['./message-init.component.css'],
})
export class MessageInitComponent implements OnInit {
  user: UserModel;
  client: ClientModel;
  sectionTitle: String = 'CCU-Forms';
  poll: PollModel;
  sectionImg: String = 'poll.png';
  totalSection: number;
  pollMessageInit: PollMessageModel;
  html: SafeHtml;
  typeaccess: string;
  lat: string;
  long: string;
  preview: boolean;
  offlineMode: any = {
    status: false,
    from: 'nonde',
  };
  typesource: string;
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private responseService: ResponseService,
    private offlineService: OfflineService,
    private sanitizer: DomSanitizer,
    private analytics: AngularFireAnalytics
  ) {
    const decodedToken = jwt_decode(localStorage.getItem('token'));
    this.typeaccess = decodedToken.user.typeaccess;

    this.user = decodedToken.user;
    this.poll = JSON.parse(localStorage.getItem('poll'));
    this.sectionTitle = this.poll.name;
    this.totalSection = this.poll.sections.length;
    this.lat = localStorage.getItem('lat');
    this.long = localStorage.getItem('long');
    /*if (this.poll.showmessageinit== true){
      this.totalSection = this.totalSection + 1;
    }*/
    this.client = JSON.parse(localStorage.getItem('client'));

    this.pollMessageInit = this.poll.messages.find(
      (x) => x.type_message == 'initial'
    );

    if (
      this.pollMessageInit !== undefined &&
      this.poll.showmessageinit === true
    ) {
      this.html = this.pollMessageInit.message;
    }
  }

  ngOnInit(): void {
    this.typesource = localStorage.getItem('source');
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        if (offlineHeader) {
          this.offlineMode = offlineHeader;
        }

        this.preview = JSON.parse(localStorage.getItem('preview'));
        this.spinner.show();

        if (this.poll.showmessageinit == false) {
          this.initPoll();
        } else {
          this.spinner.hide();
        }
      });
  }

  returnPoll() {
    if (this.typeaccess === 'user') {
      switch (this.typesource) {
        case 'list_client':
          localStorage.removeItem('poll');
          this.router.navigate(['/poll']);
          break;
        case 'poll':
          this.router.navigate(['/poll/client'], {
            queryParams: { id: this.poll.id, name: this.poll.name },
          });
          break;

        default:
          this.router.navigate([
            '/poll/client',
            { id: this.poll.id, name: this.poll.name },
          ]);
          break;
      }
      // this.router.navigate([
      //   '/poll/client',
      //   { id: this.poll.id, name: this.poll.name },
      // ]);
    } else {
      this.router.navigate([
        '/poll/client/info',
        { id: this.poll.id, name: this.poll.name },
      ]);
    }
  }

  initPoll() {
    this.spinner.show();
    // Init Offline Mode
    if (this.offlineMode.status && this.offlineMode.from === 'clients') {
      this.spinner.hide();
      this.router.navigate(['/poll/client/questions']);
      return;
    }
    // End Offline Mode

    if (!this.preview) {
      this.saveResponse();
    } else {
      this.spinner.hide();
      this.router.navigate(['/poll/client/questions']);
    }
  }

  labelBoolen(value) {
    let label;
    value ? (label = 'Si') : (label = 'No');
    return label;
  }
  saveResponse() {
    // En modo Offline este servicio se usa al final de la encuesta cuando se vaya a sincronizar
    this.responseService
      .sendResponse({
        customer_id: this.client.id,
        poll_id: this.poll.id,
        user_id: this.user.id,
        lat: this.lat,
        long: this.long,
        cliente_id: this.client.cliente_id,
        empresa_id: this.client.empresa_id,
      })
      .subscribe(
        (data) => {
          localStorage.setItem('idResponse', data.id);
          this.spinner.hide();
          this.router.navigate(['/poll/client/questions']);
          // Firebase

          this.analytics.logEvent(
            `inicio_encuesta_${this.poll.name.replace(' ', '_').toLowerCase()}`,
            {
              usuario: this.user.email,
              cliente: this.client.descr_cliente,
              encuesta: this.poll.name,
            }
          );
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }
}
