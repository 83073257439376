import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { QuestionModel } from 'src/app/polls/models/question.model';
import { ResponseService } from 'src/app/polls/services/response.service';
import { OfflineService } from 'src/app/polls/services/offline.service';
// Dexie DB
import { db } from 'src/app/db/db';
import { QuestionValidations } from 'src/app/polls/models/questionvalidations.model';
import Swal from 'sweetalert2';
import { ClientModel } from 'src/app/polls/models/client.model';
import { SectionModel } from 'src/app/polls/models/section.model';
import { UserModel } from 'src/app/polls/models/user.model';
import { PollModel } from 'src/app/polls/models/poll.model';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.css'],
})
export class NumberComponent implements OnInit, OnChanges {
  inputValue: Number;
  @Input() question: QuestionModel;
  @Input() selected: any;
  @Input() idResponse: number;
  @Output() optionSelected = new EventEmitter();
  @Output() setMessageValidate = new EventEmitter();
  @Output() showHideSpiner = new EventEmitter();
  @Output() moveToNextSection = new EventEmitter<void>();
  @ViewChild('numberInput') numberInput: ElementRef;
  //@Output() isValidNumber = new EventEmitter<boolean>();
  @Input() section: SectionModel;
  offlineMode: any = {
    status: false,
    from: 'nonde',
  };
  keyboardOpened: boolean = false;
  questionvalidations: QuestionValidations[];
  client: ClientModel;
  showValidationMessage: boolean;
  user: UserModel;
  poll: PollModel;
  isValidNumber: boolean = true;
  isUpdatingValue: boolean = false;

  constructor(
    private responseService: ResponseService,
    private offlineService: OfflineService
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
    this.poll = JSON.parse(localStorage.getItem('poll'));
  }

  ngOnInit(): void {
    this.client = JSON.parse(localStorage.getItem('client'));
    this.getResponse();
    this.showValidationMessage = true;
  }

  updateValue(type: boolean) {
    this.showValidationMessage = true;
    this.selected = this.selected !== undefined ? this.selected : 0;
    if (type) {
      this.selected = +this.selected + 1;
    } else if (this.selected !== 0) {
      this.selected = +this.selected - 1;
    }

    this.optionSelected.emit(this.selected);
  }

  // TODO: Deprecated
  onKey(event) {
    this.inputValue = +event.target.value;
    this.selected = this.inputValue;
    this.showValidationMessage = true;

    if (event.target.value.length > 0) {
      this.optionSelected.emit(+event.target.value);
    } else {
      this.optionSelected.emit(undefined);
      this.selected = undefined;
    }
  }

  onInput(event) {
    const inputElement = event.target;
    const currentValue = inputElement.value;
    const lastChar = currentValue.slice(-1);

    if (
      !this.validateDecimalNumber(lastChar) ||
      ((lastChar === ',' || lastChar === '.') &&
        currentValue.indexOf(lastChar) !==
          currentValue.lastIndexOf(lastChar)) ||
      (currentValue.includes(',') && currentValue.includes('.'))
    ) {
      inputElement.value = currentValue.slice(0, -1);
      return;
    }

    if (parseFloat(currentValue) < 0) {
      this.selected = null;
      inputElement.value = 0;
      return;
    }

    this.isValidNumber = this.validateNumberInput(currentValue);
    this.selected = this.isValidNumber ? currentValue : this.selected;

    this.inputValue = inputElement.value;
    this.showValidationMessage = true;

    if (inputElement.value.length > 0) {
      this.optionSelected.emit(+this.convertNumberFormat(currentValue));
    } else {
      this.optionSelected.emit(undefined);
      this.inputValue = undefined;
    }
  }

  validateNumberInput(input: string): boolean {
    const pattern = /^(\d{1,3}(?:[,.]\d{3})*([,.]\d+)?|\d+(?:[,.]\d+)?)$/;

    return pattern.test(input);
  }

  validateDecimalNumber(value: string): boolean {
    const allowedCharacters = /[0-9,.]/;

    return allowedCharacters.test(value);
  }

  convertNumberFormat(input: string) {
    return input.replace(/,/g, '.');
  }

  getResponse() {
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        if (offlineHeader) {
          this.offlineMode = offlineHeader;
        }
        this.optionSelected.emit(null);

        // Init Offline Mode
        if (this.offlineMode.status && this.offlineMode.from === 'clients') {
          const client = JSON.parse(localStorage.getItem('client'));
          const id = `${this.question.id}-${this.poll.id}-${client.id}-${this.user.id}`;
          this.offlineService
            .getQuestionResponseDB(id)
            .then(async (questionData) => {
              if (questionData && questionData.data.value) {
                this.inputValue = Number(questionData.data.value);
                this.selected = questionData.data.value;
                this.optionSelected.emit(this.selected);
              }
              this.showHideSpiner.emit('hide');
            });
          return;
        }
        // End Offline Mode
        // setTimeout(() => {
        this.showHideSpiner.emit('show');
        // }, 100);
        this.responseService
          .getResponseQuestion({
            response_id: this.idResponse,
            question_id: this.question.id,
          })
          .subscribe((data) => {
            if (data.length > 0) {
              this.inputValue = Number(data[0].value);
              this.selected = data[0].value;
              this.optionSelected.emit(this.selected);
            }
            this.showHideSpiner.emit('hide');
          });
      });
  }
  ngOnChanges(changes: SimpleChanges) {
    this.optionSelected.emit(this.selected);

    if (
      changes.question?.currentValue !== undefined &&
      changes.question?.previousValue !== undefined
    ) {
      this.getResponse();
    }
  }

  refreshOptions(data: QuestionModel) {
    this.question = data;
    this.showValidationMessage = true;
    this.getResponse();
  }

  clearValue() {
    this.selected = undefined;
  }

  async browseItems(
    itemValidation: QuestionValidations,
    variables_section: any[]
  ): Promise<any> {
    return new Promise(async (resolve) => {
      var formuString = itemValidation.formule;
      var formuValueString = itemValidation.validation_value;
      var newFormule: string = itemValidation.formule;
      var evalCustom: boolean = false;
      let count = 0;
      var varCustom;
      try {
        variables_section.forEach(async (itemVar) => {
          if (itemVar.sec !== undefined && itemVar.que !== undefined) {
            if (
              itemVar.sec === this.section.id &&
              itemVar.que === this.question.id
            ) {
              if (itemValidation.formule.indexOf(itemVar.id) !== -1) {
                formuString = formuString.replace(itemVar.id, this.selected);
              }

              if (itemValidation.validation_value.indexOf(itemVar.id) !== -1) {
                formuValueString = formuValueString.replace(
                  itemVar.id,
                  this.selected
                );
              }
            } else {
              if (itemVar.sec === 'custom') {
                evalCustom = true;
                switch (Number(itemVar.que)) {
                  case 1: {
                    varCustom = this.client.custom1;

                    break;
                  }
                  case 2: {
                    //statements;
                    varCustom = this.client.custom2;
                    break;
                  }
                  case 3: {
                    //statements;
                    varCustom = this.client.custom3;
                    break;
                  }
                  case 4: {
                    //statements;
                    varCustom = this.client.custom4;
                    break;
                  }
                  case 5: {
                    //statements;
                    varCustom = this.client.custom5;
                    break;
                  }
                }
                // setTimeout(() => {
                if (itemValidation.formule.indexOf(itemVar.id) !== -1) {
                  formuString = formuString.replace(itemVar.id, varCustom);
                }
                if (
                  itemValidation.validation_value.indexOf(itemVar.id) !== -1
                ) {
                  formuValueString = formuValueString.replace(
                    itemVar.id,
                    varCustom
                  );
                }
                // }, 200);
              } else {
                var dataQuestion;
                if (
                  this.offlineMode.status &&
                  this.offlineMode.from === 'clients'
                ) {
                  const id = `${itemVar.que}-${this.client.id}`;
                  const valueQuestion = await db
                    .getQuestionResponseDB(id)
                    .then(async (questionData) => {
                      if (questionData !== undefined) {
                        return questionData.data.value;
                      } else {
                        return null;
                      }
                    });
                  if (valueQuestion !== null) {
                    if (itemValidation.formule.indexOf(itemVar.id) !== -1) {
                      formuString = formuString.replace(
                        itemVar.id,
                        valueQuestion
                      );
                    }
                    if (
                      itemValidation.validation_value.indexOf(itemVar.id) !== -1
                    ) {
                      formuValueString = formuValueString.replace(
                        itemVar.id,
                        valueQuestion
                      );
                    }
                  }
                } else {
                  dataQuestion = await this.responseService
                    .getResponseQuestion({
                      response_id: this.idResponse,
                      question_id: itemVar.que,
                    })
                    .toPromise();
                  if (dataQuestion.length > 0) {
                    if (itemValidation.formule.indexOf(itemVar.id) !== -1) {
                      formuString = formuString.replace(
                        itemVar.id,
                        dataQuestion[0].value
                      );
                    }
                    if (
                      itemValidation.validation_value.indexOf(itemVar.id) !== -1
                    ) {
                      formuValueString = formuValueString.replace(
                        itemVar.id,
                        dataQuestion[0].value
                      );
                    }
                  }
                }
              }
            }
          }
          count += 1;
          var validation_message;
          if (count == variables_section.length) {
            try {
              if (evalCustom) {
                if (varCustom !== null) {
                  validation_message = itemValidation.validation_message
                    .replace('$valor', eval(eval(formuValueString)))
                    .replace('$formula', eval(formuString));
                }
              } else {
                validation_message = itemValidation.validation_message
                  .replace('$valor', eval(eval(formuValueString)))
                  .replace('$formula', eval(formuString));
              }

              resolve({
                formula: eval(formuString),
                valor: eval(formuValueString),
                message: validation_message,
              });
            } catch (error) {
              console.log(error);
            }
          }
        });
      } catch (error) {
        resolve({
          formula: null,
          valor: null,
        });
      }
    });
  }

  async checkItem(
    itemValidation: QuestionValidations,
    variables_section: any[]
  ): Promise<any> {
    return new Promise(async (resolve) => {
      var validataRules = this.browseItems(itemValidation, variables_section);
      validataRules.then((data) => {
        if (data.formula !== null && data.valor !== null) {
          if (itemValidation.method === 'smaller') {
            if (eval(data.formula) < eval(data.valor)) {
              resolve({
                estatus: false,
                title: data.message,
              });
            }
          } else if (itemValidation.method === 'greater') {
            if (eval(data.formula) > eval(data.valor)) {
              resolve({
                estatus: false,
                title: data.messages,
              });
            }
          } else if (itemValidation.method === 'equal') {
            if (eval(data.valor) === eval(data.formula)) {
              resolve({
                estatus: false,
                title: data.message,
              });
            }
          } else {
            resolve(true);
          }
        } else {
          resolve(true);
        }
      });
    });
  }

  async getValidationQuestion(data: QuestionValidations[]) {
    return new Promise<void>(async (resolve, reject) => {
      if (data) {
        try {
          if (this.selected === undefined) {
            this.selected = this.inputValue;
          }
          await data.forEach((itemValidation) => {
            this.checkItem(
              itemValidation,
              JSON.parse(itemValidation.variables_section)
            ).then((res) => {
              if (res.estatus === false && this.showValidationMessage) {
                this.setMessageValidate.emit(res);
                this.showValidationMessage = false;
                Swal.fire({
                  icon: 'warning',
                  title: res.title,
                  timer: 2000,
                });
              } else {
                resolve(res);
              }
            });
          });
          resolve(null);
        } catch (error) {
          reject(error);
        }
      } else {
        resolve(null);
      }
    });
  }
  focusOutFunction() {
    this.getValidationQuestion(this.question.questionvalidations);
  }

  onEnterHideKeyboard() {
    this.moveToNextSection.emit();
    this.keyboardOpened = true;
  }

  // activateFocus() {
  //   this.showValidationMessage = true;
  // }
}
