import { Component, Input, OnInit } from '@angular/core';
import { TaskByClient } from '../../../../interfaces';
import * as moment from 'moment';
import { ETaskStatus } from '../../../../entities/task.entity';
import { Router } from '@angular/router';
import { TasksService } from 'src/app/tasks/services/tasks.service';

@Component({
  selector: 'app-assigned-tasks-list',
  templateUrl: './assigned-tasks-list.component.html',
  styleUrls: ['./assigned-tasks-list.component.scss'],
})
export class AssignedTasksListComponent implements OnInit {
  @Input() status: string[];
  clientsAssigned: TaskByClient[] = [];
  clientsCreated: TaskByClient[] = [];
  clients: TaskByClient[] = [];
  isTabIndex_0: boolean = true;

  constructor(private readonly router: Router, private tasksService: TasksService) {}

  ngOnInit(): void {
    this.tasksService.$isTabIndex_0.subscribe((tabIndex) => {
      this.isTabIndex_0 = tabIndex !== 1 ? true : false;
    });
    if (!localStorage.getItem('assigned_task')) {
      this.clientsAssigned = JSON.parse(localStorage.getItem('assigned_task'));
    } else {
      this.tasksService.fetchAssignedTasks().subscribe();

      this.clientsAssigned = JSON.parse(localStorage.getItem('assigned_task'));
    }

    this.clients = this.clientsAssigned;

    this.checkLimitDate();
    this.filterTasks();
  }

  checkLimitDate() {
    this.clients = this.clients.map((client) => {
      client.tasks = client.tasks.map((task) => {
        const { limit_date } = task;

        if (this._checkIfExpired(limit_date)) task['expired'] = true;
        else if (this._checkIfToExpire(limit_date)) task['toExpired'] = true;
        else if (this._checkIfInTime(limit_date)) task['inTime'] = true;

        return task;
      });

      const expired = client.tasks.find((t) => t['expired']);
      const toExpired = client.tasks.find((t) => t['toExpired']);

      if (expired) return { ...client, expired: true };
      if (toExpired) return { ...client, toExpired: true };

      const published = client.tasks.find((t) => t.template_status === ETaskStatus.CONFIRMED);

      if (published) return { ...client, inProgress: true };

      return { ...client, pending: true };
    });
  }

  filterTasks() {
    this.clients = this.clients.filter((c) => {
      c.tasks = c.tasks.filter((t) => this.status.includes(t.template_status));

      return Boolean(c.tasks.length);
    });
  }

  handleViewInfo(task) {
    localStorage.setItem('response_task', JSON.stringify(task));

    this.router.navigate([`/tasks/${task.id}/response`]);
  }

  _checkIfExpired(limitDate: string): boolean {
    return moment(new Date()).isAfter(limitDate);
  }

  _checkIfToExpire(limitDate: string): boolean {
    const dayBefore = moment(new Date()).add(1, 'd');

    return moment(dayBefore).isSameOrAfter(limitDate);
  }

  _checkIfInTime(limitDate: string): boolean {
    return moment(new Date()).isBefore(limitDate);
  }

  getTasksToShow(tasks) {
    return tasks.tasks.filter((task) => task.status !== 'rechazada');
  }
}
