<div class="Input">
  <input
    #mapSearchField
    type="text"
    id="input"
    class="search-input"
    placeholder="Buscar..."
    [(ngModel)]="inputVal"
  />
</div>
<div
  fxLayout="column"
  fxLayoutAlign="center"
  style="margin-left: 5px; margin-right: 5px"
>
  <google-map
    width="100%"
    [center]="center"
    [zoom]="zoom"
    [options]="options"
    (mapDblclick)="eventHandler($event, 'mapDblclick')"
  >
    <map-marker
      #markerElem="mapMarker"
      *ngFor="let markerPosition of markerPositions; let i = index"
      [position]="markerPosition.position"
      [label]="markerPosition.label"
      [title]="markerPosition.title"
      [options]="markerPosition.options"
      (mapClick)="openInfo(markerElem, markerPosition, i)"
      [id]="markerPosition.info"
    >
    </map-marker>
    <map-info-window #infoWindow>
      <div class="row">
        <b>{{ nameClientMaker }}</b>
      </div>
      <div class="row"><b>Codigo:</b> {{ codeClientMaker }}</div>
      <div class="row">
        <b
          ><i>{{ dirClientMaker }} </i></b
        >
      </div>
      <div class="row">
        <span class="material-symbols-outlined"> </span>
        <button
          mat-icon-button
          aria-label="Recomendado"
          class="poll-icon-maps"
          (click)="goToLink(markerPosition, '!3m1!4b1')"
        >
          <mat-icon>star</mat-icon>
        </button>
        <button
          mat-icon-button
          aria-label="Automovil"
          class="poll-icon-maps"
          (click)="goToLink(markerPosition, '!3m1!4b1!4m2!4m1!3e0')"
        >
          <mat-icon>directions_car</mat-icon>
        </button>
        <button
          mat-icon-button
          class="poll-icon-maps"
          aria-label="Tranporte pùblico"
          (click)="goToLink(markerPosition, '!3m1!4b1!4m2!4m1!3e3')"
        >
          <mat-icon>directions_bus</mat-icon>
        </button>
        <button
          mat-icon-button
          aria-label="Caminar"
          class="poll-icon-maps"
          (click)="goToLink(markerPosition, '!3m1!4b1!4m2!4m1!3e2')"
        >
          <mat-icon>directions_walk</mat-icon>
        </button>
      </div>

      <div class="row">
        <mat-list>
          <div mat-subheader>Encuestas</div>
          <mat-list-item *ngFor="let poll of polls">
            <div mat-line>{{ poll.name }}</div>
            <div mat-line>
              Expira: {{ poll.expire_date | date: 'dd MMM yyyy' }}
            </div>
            <button
              mat-icon-button
              (click)="viewMore($event, poll.id, codeClientMaker)"
            >
              <mat-icon mat-list-icon class="poll-icon-maps"
                >open_in_new</mat-icon
              >
            </button>

            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-list>
      </div>
    </map-info-window>
    <!-- <map-directions-renderer
      *ngIf="directionsResults$ | async as directionsResults"
      [directions]="directionsResults"
    ></map-directions-renderer> -->
  </google-map>

  <div class="box2" fxLayout="row" fxLayoutAlign="center center">
    <div class="example-button-container">
      <button
        mat-mini-fab
        color="primary"
        aria-label="Encuestas"
        class="margin-button"
      >
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <div class="example-button-container">
      <button
        mat-mini-fab
        color="primary"
        aria-label="Mi ubicacion"
        class="margin-button"
        (click)="moveCenter()"
      >
        <mat-icon>share_location</mat-icon>
      </button>
    </div>
  </div>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="rgb(92, 184, 92)"
    type="ball-spin-fade"
  >
    <p style="font-size: 20px; color: rgb(92, 184, 92)">Cargando encuesta...</p>
  </ngx-spinner>
</div>
