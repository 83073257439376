import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { auth } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private readonly API_URL = `${auth.urlConfig}/file/upload`;
  private readonly API_URL_MULTI = `${auth.urlConfig}/file/upload/multi`;
  private readonly API_URL64 = `${auth.urlConfig}/file/upload64`;
  private readonly API_URlPDF = `${auth.urlConfig}/file/uploadPDF`;
  private readonly API_URL64_TEMP_FOLDER = `${auth.urlConfig}/file/uploadFileBase64ToTempWithoutGCP`;
  constructor(private http: HttpClient) {}

  pushFileToStorage(
    file: File,
    token: string,
    name: string
  ): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();
    formdata.append('token', token);
    formdata.append('files', file, name);
    const req = new HttpRequest('POST', `${this.API_URL}`, formdata, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.http.request(req);
  }

  pushFileToStorages(files: any): Observable<HttpEvent<{}>> {
    const formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('token', files[i].token);
      formData.append('files', files[i].file, files[i].fileName);
    }
    const req = new HttpRequest('POST', `${this.API_URL_MULTI}`, formData, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.http.request(req);
  }

  pushFileBase64ToStorage(
    fileBase64: string,
    name: string,
    token: string
  ): Observable<HttpEvent<{}>> {
    const data = {
      name,
      inconming: 'front',
      data: fileBase64,
      token,
    };
    const req = new HttpRequest('POST', `${this.API_URL64}`, data, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.http.request(req);
  }

  pushFilePDFToStorage(file: any, name: string): Observable<HttpEvent<{}>> {
    const data = {
      name,
      inconming: 'front',
      data: file,
    };
    const req = new HttpRequest('POST', `${this.API_URlPDF}`, data, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.http.request(req);
  }

  pushFileBase64ToTempFolder(
    fileBase64: string,
    name: string
  ): Observable<HttpEvent<any>> {
    const data = {
      name,
      inconming: 'front',
      data: fileBase64,
    };

    const req = new HttpRequest('POST', `${this.API_URL64_TEMP_FOLDER}`, data, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.http.request(req);
  }

  getFiles(path: string): Observable<any> {
    return this.http.get(
      `${auth.urlConfig}/file/signedurl?path=${path}&inconming=front`
    );
  }
}
