import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuestionModel } from 'src/app/polls/models/question.model';
import { ResponseService } from 'src/app/polls/services/response.service';
import { OfflineService } from 'src/app/polls/services/offline.service';
// Dexie DB
import { db } from 'src/app/db/db';
import { UserModel } from 'src/app/polls/models/user.model';
import { PollModel } from 'src/app/polls/models/poll.model';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-scale',
  templateUrl: './scale.component.html',
  styleUrls: ['./scale.component.css'],
})
export class ScaleComponent implements OnInit {
  @Input() idResponse: number;
  @Input() question: QuestionModel;
  @Input() selected: any;
  @Output() optionSelected = new EventEmitter();
  @Output() showHideSpiner = new EventEmitter();
  styles = { backgroundColor: '#0965ee', margin: '10px', fontSize: '32px' };
  minValue = 0;
  maxValue = 7;
  value = 0;
  thumbLabel = true;
  response: number;
  maxScaleLabel: string;
  minScaleLabel: string;
  offlineMode: any = {
    status: false,
    from: 'nonde',
  };
  user: UserModel;
  poll: PollModel;
  constructor(
    private responseService: ResponseService,
    private offlineService: OfflineService
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
    this.poll = JSON.parse(localStorage.getItem('poll'));
  }

  ngOnInit(): void {
    this.minScaleLabel = this.question.questionoptions[0].minScaleLabel;
    this.maxScaleLabel = this.question.questionoptions[0].maxScaleLabel;
    this.maxValue = Number(this.question.questionoptions[0].maxScaleValue);
    this.minValue = Number(this.question.questionoptions[0].minScaleValue);
    this.response = this.question.questionoptions[0].minScaleValue;
  }

  ngOnChanges(): void {
    if (this.selected == null) {
      this.getResponse();
    }
  }

  formatLabel(value: number) {
    if (value >= 8) {
      return Math.round(value / 100) + 'k';
    }

    return value;
  }

  selectScale(e) {
    this.optionSelected.emit(this.value);
  }

  getResponse() {
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        if (offlineHeader) {
          this.offlineMode = offlineHeader;
        }

        // Init Offline Mode
        if (this.offlineMode.status && this.offlineMode.from === 'clients') {
          const client = JSON.parse(localStorage.getItem('client'));
          const id = `${this.question.id}-${this.poll.id}-${client.id}-${this.user.id}`;
          this.offlineService
            .getQuestionResponseDB(id)
            .then(async (questionData) => {
              if (questionData) {
                this.value = Number(questionData.data.value);
                this.optionSelected.emit(this.value);
              }
              this.showHideSpiner.emit('hide');
            });
          return;
        }
        // End Offline Mode

        this.showHideSpiner.emit('show');
        this.responseService
          .getResponseQuestion({
            response_id: this.idResponse,
            question_id: this.question.id,
          })
          .subscribe((data) => {
            if (data.length > 0) {
              this.value = Number(data[0].value);
              this.optionSelected.emit(this.value);
              /*this.response =data[0].value

        if (this.response.length> 0){
          this.optionSelected.emit( this.response);
        }
        */
            }
            this.showHideSpiner.emit('hide');
          });
      });
  }

  public refreshOptions(data: any) {
    this.question = data;
    this.minScaleLabel = data.questionoptions[0].minScaleLabel;
    this.maxScaleLabel = data.questionoptions[0].maxScaleLabel;
    this.maxValue = Number(data.questionoptions[0].maxScaleValue);
    this.minValue = Number(data.questionoptions[0].minScaleValue);
    this.response = data.questionoptions[0].minScaleValue;
    this.value = null;
    this.optionSelected.emit(this.value);
    this.getResponse();
  }
}
